import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import DateComponent from '../components/DateComponent';
import InquiryService from '../service/InquiryService';
//import Address from '../components/Address.js';
import { Dropdown } from 'primereact/dropdown';
import { Translation } from 'react-i18next';

class InquiriesDetailsShareholdersManagement extends Component {
  constructor(props){
    super(props);
    this.state = {
      managers : this.props.managers,
      countries: [],
      managerPositions: [],
      toast: this.props.toast
    }
    this.inquiryService = new InquiryService();
    this.handleAddManager = this.handleAddManager.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.renderCompanManager = this.renderCompanManager.bind(this);
    this.renderIndividualIndividual = this.renderIndividualIndividual.bind(this);
    this.handleManagerChange = this.handleManagerChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleDeleteManager = this.handleDeleteManager.bind(this);

    this.inquiryService.getManagerPositions().then(data =>{
      this.setState({managerPositions:data})
    })

    this.types =[
      {value: "NA", label: "Not Available"},
      {value: "DIRECT", label: "Direct Shareholder"},
      {value: "INDIRECT", label: "Indirect Shareholder"}
    ]
  }
  componentDidMount(){
    if(!sessionStorage.getItem('ses_countries')){
      this.inquiryService.getCountries().then(data =>{
        this.setState({countries:data})
        sessionStorage.setItem('ses_countries',JSON.stringify(data))
      });
    }else{  
      this.setState({countries: JSON.parse(sessionStorage.getItem('ses_countries'))});   
    }
  }
  handleDeleteManager(indx, event){
    const managers = this.state.managers;
    managers.splice(indx,  1);
    this.setState({managers: managers}, function(){
      this.dataRefresh();
    })
  }

  handlePhoneChange(event){
    let managers = this.state.managers
    let contactInfo = managers[event.target.id].contact_info
    if(!contactInfo){
      contactInfo = {telephones: []}
    }
    if (contactInfo.telephones.length == 0){
      let obj = {}
      obj[event.target.name] = event.target.value
      contactInfo.telephones.push(obj)
    } else {
      contactInfo.telephones[0][event.target.name] = event.target.value
    }

    managers[event.target.id].contact_info = contactInfo
    this.setState({managers:managers}, function(){
      this.dataRefresh();
    })
  }

  dataRefresh(){
    // function that reads the state and updates the session storage
    const management = sessionStorage.getItem('ses_management_' + this.props.report_id);
    let managementJSON = JSON.parse(management)
    managementJSON.managers = this.state.managers
    sessionStorage.setItem('ses_management_' + this.props.report_id, JSON.stringify(managementJSON));
  }



  handleManagerChange(mngr_idx, parentField, childField, event){
    var managers = this.state.managers
    if(childField){
      // company | financials | contact info
      if(!managers[mngr_idx][parentField]){
        managers[mngr_idx][parentField] = {}
      }
      managers[mngr_idx][parentField][childField] = event.target.value
    } else{
      // type and shares percentage
      managers[mngr_idx][parentField] = event.target.value
    }

    this.setState({managers: managers}, function(){
      this.dataRefresh();
    });
  }

  renderIndividualIndividual(i, mngr){
    return(
        <div className="col-9">
           {/* <div className="row">
                    <div className="col-sm-2">
                        <label className="form-field-bold">{<Translation>{ t => <span>{t('FULL_NAME')} </span>}</Translation>}</label>
                    </div>
                    <div className="col-sm-2">
                        <input type="text" autoFocus onChange={(e) => this.handleManagerChange(i, "full_name", null, e)} placeholder="name"
                          name={"individual.name"} value={mngr.full_name} className="form-control form-control-sm"/>
                    </div>
                    <div className="col-sm-1">
                        <label className="form-field-bold">{<Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation>}</label>
                    </div>
                    <div className="col-sm-3">

                      <Dropdown value = {mngr.address.country}  options={this.state.countries}
                        optionLabel="name" placeholder="Select A country Below" filter filterBy="name" onChange={(e) => this.handleManagerChange(i, "address", "country", e)}/>
                    </div>
                    <div className="col-sm-1">
                        <label className="form-field-bold">{<Translation>{ t => <span>{t('POSITION')} </span>}</Translation>}</label>
                    </div>
                    <div className="col-sm-3">
                      <Dropdown value = {mngr.position} options={this.state.managerPositions}
                            onChange={(e)=>this.handleManagerChange(i, "position", null, e)} optionLabel="name"
                              placeholder="Select a Type" filter filterBy="name" style = {{ "width" : "10rem"}}/>
                    </div>
    </div> */}
            <div className="lg-spacer"/>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-5">
                          <textarea className="form-control form-text-comments" value = {mngr.address?.full_address}
                            onChange={(e) => this.handleManagerChange(i, "address", "full_address", e)} />
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4">
                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('DATE_OF_BIRTH')} </span>}</Translation>}</label>
                                </div>
                                <div className="col-sm-6">
                                  <DateComponent date = {mngr.date_of_birth} update_date = {(e) => this.handleManagerChange(i, "date_of_birth", null, e)}/>
                                </div>
                            </div>
                            <div className="lg-spacer"/>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('ID_NUMBER')} </span>}</Translation>}</label>
                                </div>

                                <div className="col-sm-6">
                                <input type="text" onChange={(e) => this.handleManagerChange(i, "id_number", null, e)} placeholder="id number"
                                  name={"individual.id_number"} value={mngr.id_number} className="form-control form-control-sm"/>
                                </ div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-12">
                            <textarea placeholder = "comments"  onChange={(e) => this.handleManagerChange(i, "comments", null, e)}
                              className="form-control form-text-comments" name="comments"  value={mngr.comments}  rows="3"></textarea>
                        </div>
                    </div>
                </div>
        </div>
    )
  }



  renderCompanManager(i, mngr){
    return(
        <div className="col-9">
         {/* <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => <span>{t('FULL_NAME')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-2">
                  <input type="text" autoFocus onChange={(e) => this.handleManagerChange(i, "full_name", null,  e)}
                    placeholder="Full Name" name={"fullname "} value={mngr.full_name} className="form-control form-control-sm"/>
              </div>
              <div className="col-sm-1">
                  <label className="form-field-bold">{<Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-2">
                <Dropdown value = {mngr.address.country}  options={this.state.countries}
                  optionLabel="name" placeholder="Select A country Below" filter filterBy="name" onChange={(e) => this.handleManagerChange(i, "address", "country", e)}/>
              </div>
              <div className="col-sm-1">
                  <label className="form-field-bold">{<Translation>{ t => <span>{t('POSITION')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-3">
                <Dropdown value = {mngr.position} options={this.state.managerPositions}
                      onChange={(e)=>this.handleManagerChange(i, "position", null, e)} optionLabel="name" placeholder="Select a Position" filter filterBy="name" style = {{ "width" : "10rem"}}/>
              </div>
          </div> */}
          <div className="lg-spacer"/>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-1">
                  {<Translation>{ t => <span>{t('FULL_ADDRESS')} </span>}</Translation>}
                  </div>
                  <div className="col-sm-5">
                    <textarea className="form-control form-text-comments" value = {mngr.address?.full_address}
                      onChange={(e) => this.handleManagerChange(i, "address", "full_address", e)} />
                  </div>
                  <div className="col-sm-5">
                      <div className="row">
                          <div className="col-sm-4">
                              <label className="form-field-bold">{<Translation>{ t => <span>{t('REGISTRATION_NUMBER')} </span>}</Translation>}</label>
                          </div>
                          <div className="col-sm-6">
                                <input type = "text" placeholder="registration Number" onChange={(e) => this.handleManagerChange(i, "registration_id", null, e)}
                                  value = {mngr.registration_id} className="form-control form-control-sm" />
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-sm-4">
                              <label className="form-field-bold">{<Translation>{ t => <span>{t('PHONE')} </span>}</Translation>}</label>
                          </div>
                          <div className="col-sm-6">
                            <div className = "row phone">
                              <div className = "col-4">
                                <input type = "number" id = {i} name = "country_code" style={{'width':'150%'}}
                                    className="form-control form-control-sm" placeholder = "Country Code" onChange={this.handlePhoneChange} value ={mngr.contact_info?.telephones[0]?.country_code}/>
                              </div>
                              <div className = "col-8">
                                <input type = "number" className="form-control form-control-sm"  id = {i} name = "number" style={{'width':'100%'}}
                                  placeholder = "N" onChange={this.handlePhoneChange} value ={mngr.contact_info?.telephones[0]?.number} />
                              </div>
                            </div>
                          </ div>
                      </div>
                      <div className="row">
                          <div className="col-sm-4">
                              <label className="form-field-bold">{<Translation>{ t => <span>{t('WEBSITE')} </span>}</Translation>}</label>
                          </div>
                          <div className="col-sm-6">
                          <input type="text" onChange={(e) => this.handleManagerChange(i, "contact_info", "websites", e)}
                            placeholder="websites" name={"company.contact_info.websites"} value={mngr.contact_info?.websites} className="form-control form-control-sm"/>
                          </ div>
                      </div>
                  </div>

              </div>
          </div>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-12">
                      <textarea  className="form-control form-text-comments" name="comments" placeholder = "comments"
                        onChange={(e) => this.handleManagerChange(i, "comments", null, e)} value={mngr.comments}  rows="3"></textarea>
                  </div>
              </div>
          </div>
      </div>
    )
  }

  handleAddManager(isCompany, event){
    let managers_arr = this.state.managers;
    let obj = {
     type : isCompany ? "company" : "individual",
   };

   if(managers_arr.length == 0 || managers_arr[managers_arr.length - 1].full_name){
    managers_arr.push(obj);
    this.setState({managers: managers_arr}, function(){
      this.dataRefresh();
    });
  } else {
      this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter manager's name"});            
  }


    
  }
  render(){
    return(
      <div className="form-group">
        <div className="row" >
            <div className="col-sm-2">
               <span className="form-title">{<Translation>{ t => <span>{t('MANAGEMENT')} </span>}</Translation>}</span>
            </div>
        </div>
        <div className="row" >
          <div className="col-sm-2">
            <div className = "row" style = {{margin:"10px"}}>
              <label className="form-field-bold" style = {{marginRight:"10px"}}>{<Translation>{ t => <span>{t('ADD_COMPANY')} </span>}</Translation>}</label>
              <Button icon="pi pi-plus"  onClick={(e) => this.handleAddManager(true, e)}  className="p-button-square p-button-secondary ccp-add-btn" />
            </div>
            <div className = "row" style = {{margin:"10px"}}>
              <label className="form-field-bold" style = {{marginRight:"10px"}}>{<Translation>{ t => <span>{t('ADD_INDIVIDUAL')} </span>}</Translation>}</label>
              <Button icon="pi pi-plus"  onClick={(e) => this.handleAddManager(false, e)}  className="p-button-square p-button-secondary ccp-add-btn" />
            </div>
          </div>
          <div className="col-sm-10">
            <Accordion activeIndex="0">
              {
                this.state?.managers?.map((mngr, i)=> (
                  (mngr.type == "company")?
                    <AccordionTab headerClassName="manager-tab" headerStyle={{width: '100%'}} headerTemplate={
                      <React.Fragment>
                            <div className="row">
                                <div className="col-3">
                                    <input type="text" autoFocus onChange={(e) => this.handleManagerChange(i, "full_name", null,  e)} placeholder="Full Name" name={"fullname "} value={mngr.full_name} className="form-control form-control-sm"/>
                                </div>
                                <div className="col-5">
                                    {/*<Dropdown value = {mngr.position} options={this.state.managerPositions} onChange={(e)=>this.handleManagerChange(i, "position", null, e)}
                                      optionLabel="name" placeholder="Select a Position" filter filterBy="name" />*/}
                                      <input type="text" autoFocus onChange={(e) => this.handleManagerChange(i, "position", null, e)} placeholder="Manager Position" name={"individual.name"}
                                              value={mngr.position} className="form-control form-control-sm"/>
                                </div>
                                <div className="col-3">
                                    <Dropdown value = {mngr.address?.country}  options={this.state.countries}  optionLabel="name" placeholder="Select A country Below" filter filterBy="name" onChange={(e) => this.handleManagerChange(i, "address", "country", e)}/>
                                </div>

                                <div className="col-1">
                                      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                        onClick = {(e) => this.handleDeleteManager(i, e)} value = {null}  />
                                </div>
                            </div>
                      </React.Fragment>}>
                      {this.renderCompanManager(i, mngr)}
                    </AccordionTab>
                  :
                    <AccordionTab headerClassName="manager-tab" headerStyle={{width: '100%'}} headerTemplate={
                      <React.Fragment>
                            <div className="row">
                                <div className="col-3">
                                      <input type="text" autoFocus onChange={(e) => this.handleManagerChange(i, "full_name", null, e)} placeholder="name" name={"individual.name"} value={mngr.full_name} className="form-control form-control-sm"/>
                                </div>
                                <div className="col-5">
                                    <input type="text" autoFocus onChange={(e) => this.handleManagerChange(i, "position", null, e)} placeholder="Manager Position" name={"individual.name"}
                                            value={mngr.position} className="form-control form-control-sm"/>
                                </div>
                                <div className="col-3">
                                    <Dropdown value = {mngr.address?.country}  options={this.state.countries} optionLabel="name" placeholder="Select A country Below" filter filterBy="name" onChange={(e) => this.handleManagerChange(i, "address", "country", e)}/>
                                </div>

                                <div className="col-1">
                                      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                        onClick = {(e) => this.handleDeleteManager(i, e)} value = {null}  />
                                </div>
                            </div>
                      </React.Fragment>}>
                      {this.renderIndividualIndividual(i, mngr)}
                    </AccordionTab>
                ))
                }
            </Accordion>
          </div>
        </div>
      </div>
    )
  }


}

export default InquiriesDetailsShareholdersManagement;
