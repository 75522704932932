import React, { Component } from 'react';
import DateComponent from '../components/DateComponent';
import { Translation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
class InquiriesDetailsSummaryInterview extends Component {

  constructor(props) {
  	super(props);
    this.state = {
      interviews: this.props.interviews,
      toast: this.props.toast
    };
    this.addNewInterviews = this.addNewInterviews.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.handleInterviewChange = this.handleInterviewChange.bind(this);
    this.handleInterviewsChange = this.handleInterviewsChange.bind(this);
    this.deleteInterview = this.deleteInterview.bind(this);
  }

  deleteInterview(indx, event){
    let interviews = this.state.interviews;
    interviews.interviews.splice(indx,  1);
    this.setState({interviews:interviews}, function(){
      this.dataRefresh();
    })
  }

  addNewInterviews(){
    let interviews = this.state.interviews;
    const interviews_arr = interviews.interviews;
    console.log(interviews,interviews[interviews.length - 1])

    if(interviews_arr.length == 0 || interviews_arr[interviews_arr.length - 1].contact_name){
      interviews.interviews.push({})
      this.setState({interviews:interviews}, function(){
        this.dataRefresh();
      })
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter contact name"});            
    }

  }

  dataRefresh(){
    sessionStorage.setItem('ses_interviews_' + this.props.report_id, JSON.stringify(this.state.interviews));
  }

  handleInterviewChange(indx, field, event){
    let interviews = this.state.interviews
    interviews["interviews"][indx][field] = event.target.value
    this.setState({interviews: interviews}, function(){
      this.dataRefresh();
    })
  }

  handleInterviewsChange(field, event){
    let interviews = this.state.interviews
    interviews[field] = event.target.value
    this.setState({interviews: interviews}, this.dataRefresh)
  }

  render(){
    return (
      <div className="form-group">
         <Toast ref={this.state.toast} />
        <div className = "row">
            <div className = "col-5" style={{margin:"15px"}}>
              <Button icon="pi pi-plus"  onClick={this.addNewInterviews} className="p-button-square p-button-secondary" />
            </div>
        </div>
        <div className = "row">
          <div className="col-sm-2">
              <label className="form-field-bold">{<Translation>{ t => t('CONTACT_NAME')}</Translation>}</label>
          </div>
          {
            this.state.interviews.interviews.map((interview, i) =>
              <div className="col-sm-2" key={i}>
                <input type = "text" className="form-control form-control-sm" onChange={(e) => this.handleInterviewChange(i, "contact_name", e)} id = "contact-name" name = "contactName" value = {interview.contact_name} />
              </div>
            )
          }
        </div>
        <div className = "row">
          <div className="col-sm-2">
              <label className="form-field-bold">{<Translation>{ t => t('CONTACT_POSITION')}</Translation>}</label>
          </div>
          {
            this.state.interviews.interviews.map((interview, i) =>
              <div className="col-sm-2" key={i}>
                <input type = "text" className="form-control form-control-sm" onChange={(e) => this.handleInterviewChange(i, "contact_position", e)}
                      id = "contact-position" name = "contactPosition" value = {interview.contact_position}/>
              </div>
            )
          }
          </div>
        <div className = "row">
          <div className="col-sm-2">
              <label className="form-field-bold">{<Translation>{ t => t('DATE')}</Translation>}</label>
          </div>
          {
            this.state.interviews.interviews.map((interview, i) =>
              <div className="col-sm-2"  key={i}>
                <DateComponent date = {interview.date} update_date = {(e) => this.handleInterviewChange(i, "date", e)}/>
                 <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deleteInterview(i, e)} value = {null}  />
               </div>
            )
          }
        </div>
        <div className = "row">
          <div className="col-sm-2">
              <label className="form-field-bold">{<Translation>{ t => t('COMMENTS')}</Translation>}</label>
          </div>
          {
            this.state.interviews.interviews.map((interview, i) =>
              <div className="col-sm-2"  key={i}>
                  <textarea rows="2" className="form-control form-control-sm" onChange={(e) => this.handleInterviewChange(i, "comments", e)}
                        id = "comments" name = "comments" value = {interview.comments}/>
              </div>
            )
          }
        </div>

        <br></br>
        <div className="row" >
          <div className="col-sm-3">
              <label className = "form-field-bold">{<Translation>{ t => t('PREVIOUS_INTERVIEW')}</Translation>}</label>
          </div>
          <div className="col-sm-9">
            <textarea className = "form-control form-text-comments" name="previous-interview" rows="3" onChange={e => this.handleInterviewsChange("previous_interview", e)} value = {this.state.interviews.previous_interview}></textarea>
          </div>
        </div>

      </div>
    )
  }
}
export default InquiriesDetailsSummaryInterview
