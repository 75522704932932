import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';

class Delays extends Component {
  constructor(props){
    super(props);
    this.state = {
      delays: [],
      loading: true,
      selectedDelay: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    }
    this.delayNameBodyTemaplte = this.delayNameBodyTemaplte.bind(this);
    this.handleDelayClick = this.handleDelayClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleDelayNameChange = this.handleDelayNameChange.bind(this);
    this.inquiryService = new InquiryService();
  }

  componentDidMount(){
    this.inquiryService.getDelays().then(data =>{
      this.setState({
        delays: data, loading: false
      })
    })
  }

  handleDelayNameChange(event){
    var selectedDelay  = this.state.selectedDelay
    selectedDelay.name = event.target.value
    this.setState({selectedDelay: selectedDelay})
  }

  handleSaveClick(){
    if(this.state.selectedDelay.id == 0){
      this.inquiryService.createDelay(this.state.selectedDelay).then(data => {
        let delays = this.state.delays
        delays.push(data)
        this.setState({delays:delays,selectedDelay: data})
        alert("Delay Created !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    } else{
      this.inquiryService.updateDelay(this.state.selectedDelay.id, this.state.selectedDelay).then(data => {
        alert("Delay Updated !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    }
  }

  cancel(){
    this.setState({
      selectedDelay: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }

  handleDelayClick(rowData){
    this.setState({
      selectedDelay: rowData,
      button:{
        label:'Save',
        icon: 'pi pi-check'
      }
    })
  }
  delayNameBodyTemaplte(rowData){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleDelayClick(rowData)} > {rowData.name}</a>
        </React.Fragment>
    );
  }
  render(){
    return (
      <div>
        <div className="condiv">
          <div className="row">
            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.delays}
                  loading={this.state.loading}
                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No Delays found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="name" filterField="name" field="name" header="Name" body={this.delayNameBodyTemaplte} sortable filter filterPlaceholder="Search by company Name"/>
              </DataTable>
            </div>
            <div className = "col-4">
              <div className="col-sm-9" style={{'margin': '5px'}}>
                <input id = "name" onChange={this.handleDelayNameChange} type="text" placeholder="delay name" value={this.state.selectedDelay.name} className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}}/>
                <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Delays
