import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import '../Login.css';
import logo from '../img/logo.png';
import { Toast } from 'primereact/toast';


async function loginUser(credentials) {
 return fetch(process.env.REACT_APP_HOST + '/token-auth/', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
 .then(data => data.json())
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const toast = useRef(null);

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });

    if(typeof token.token != "undefined"){
      toast.current.show({severity:'info', summary: 'Successful Login', detail:'You login successfully!', life: 10000});
      setToken(token);
      if (token.user.is_staff){
        window.location.href='/inquiries';
      } else {
        window.location.href='/orders';
      }

    }else{
      toast.current.show({severity:'error', summary: 'Error Login', detail: token.non_field_errors[0], life: 3000});
    }
  }
  return(

    <div className="rows">
               <Toast ref={toast} />
                <div className="login-wrapper">

                    <img src={logo} alt="Cristal Credit Group"/><br/>
                        <div className="row">

                            <div className="form-group">
                                <form>
                                    <div className="row">
                                        <div className="col-4">
                                             <label htmlFor="username"><p>Username</p></label>
                                        </div>
                                        <div className="col-7">
                                            <input onChange={e => setUserName(e.target.value)} className="form-control form-control-m" type="text" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                             <label htmlFor="username"><p>Password</p></label>
                                        </div>
                                        <div className="col-7">
                                            <input  onChange={e => setPassword(e.target.value)} className="form-control form-control-m" type="password" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                         <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={handleSubmit}>Sign in</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                </div>
            </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
