import axios from 'axios';
import { api } from './baseService'

export default class CompanyService {

  getRequestOption(){
    const token = JSON.parse(sessionStorage.getItem("token"))
    const options = {
     headers: {
         'Content-Type' : 'application/json',
         'Accept': '*/*',
         'Authorization': 'JWT ' + token.token,
     }
   };
   return options
  }


  searchForCompany = async(body) =>{
    //return axios.post(process.env.REACT_APP_HOST + '/wand2/company/clients/search', body, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/clients/search', "post",body, this.getRequestOption()).then(res => res.data);
  }

  getCustomers = async()=>{
    //return axios.get(process.env.REACT_APP_HOST + '/wand2/company/customers', this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/customers', "get","",this.getRequestOption()).then(res => res.data);
  }

  getCustomersWithPagination = async(limit = 10, offset = 0) =>{
    //return axios.get(process.env.REACT_APP_HOST + `/wand2/company/customers_new?limit=${limit}&offset=${offset}`, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + `/wand2/company/customers_new?limit=${limit}&offset=${offset}`, "get","",this.getRequestOption()).then(res => res.data);
  }
  getCustomersWithPaginationSearch = async(limit = 10, offset = 0, data) =>{    
    //return axios.get(process.env.REACT_APP_HOST + `/wand2/company/customers_new?limit=${limit}&offset=${offset}`, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + `/wand2/company/customers_new?limit=${limit}&offset=${offset}`, "post", data ,this.getRequestOption()).then(res => res.data);
  }
  customerFilter = async(limit = 10, offset = 0, data) =>{
    //return axios.get(process.env.REACT_APP_HOST + `/wand2/company/customers_new?limit=${limit}&offset=${offset}`, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + `/wand2/company/customers_filter?limit=${limit}&offset=${offset}`, "post",data,this.getRequestOption()).then(res => res.data);
  }
 

  getCustomerInfoByUserName = async(username)=>{
    //return axios.get(process.env.REACT_APP_HOST + '/wand2/company/customers/username/' + username + '/customer_info', this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/customers/username/' + username + '/customer_info', "get","",this.getRequestOption()).then(res => res.data);
  }

  getCustomerInfoByCustomerId = async(id)=>{
    //return axios.get(process.env.REACT_APP_HOST + '/wand2/company/customers/' + id + '/customer_info', this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/customers/' + id + '/customer_info', "get","",this.getRequestOption()).then(res => res.data);
  }

  getCustomerByLegalName = async(legalName) => {
    //return axios.get(process.env.REACT_APP_HOST + '/wand2/company/customers/legal_name/' + legalName, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/customers/legal_name/' + legalName, "get","",this.getRequestOption()).then(res => res.data);
  }


  getLazyCompanies =async(lazyParams)=>{
    //return axios.post(process.env.REACT_APP_HOST + '/wand2/company/clients/lazy', lazyParams, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/clients/lazy', "post",lazyParams, this.getRequestOption()).then(res => res.data);
  }

  createCompany = async(companyData)=>{
    //return axios.post(process.env.REACT_APP_HOST + '/wand2/company/', companyData, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/', "post",companyData, this.getRequestOption()).then(res => res.data);
  }

  updateCompany(companyId,companyData){
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/company/'+companyId,companyData,this.getRequestOption()).then(res => res.data);
  }

  /*** Customer Delays */
  getCustomerDelays = async (companyId) =>{
    if(sessionStorage.getItem('api_customer_delays_'+companyId) !== null){
      return JSON.parse(sessionStorage.getItem('api_customer_delays_'+companyId))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/customers/' + companyId +'/delays', "get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_customer_delays_'+companyId,JSON.stringify(data));
      return data;
    } 
  }
  createCompanyCustomerDelay = async (companyId, customer_delay) =>{
    sessionStorage.removeItem("api_customer_delays_"+companyId);
    //return axios.post(process.env.REACT_APP_HOST + '/wand2/company/customers/' + companyId + '/delays', customer_delay, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/company/customers/' + companyId + '/delays', "post", customer_delay, this.getRequestOption()).then(res => res.data);
  }

  deleteCompanyCustomerDelay(companyId, customer_delay_id){
    sessionStorage.removeItem("api_customer_delays_"+companyId);
    return axios.delete(process.env.REACT_APP_HOST + '/wand2/company/customers/' + companyId + '/delays/' + customer_delay_id, this.getRequestOption()).then(res => res.data);
  }

  updateCompanyCustomerDelay(companyId, customer_delay_id, customer_delay){
    sessionStorage.removeItem("api_customer_delays_"+companyId);
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/company/customers/' + companyId + '/delays/' + customer_delay_id, customer_delay, this.getRequestOption()).then(res => res.data);
  }

  generateReportDownloadsLogs = async (searchParams) =>{
    //return axios.post(process.env.REACT_APP_HOST + '/wand2/logs/download_logs', searchParams, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/logs/download_logs',"post", searchParams, this.getRequestOption()).then(res => res.data);
  }
  generateApiReportDownloadsLogs = async (searchParams) =>{
    return await api(process.env.REACT_APP_HOST + '/wand2/logs/api_download_logs',"post", searchParams, this.getRequestOption()).then(res => res.data);
  }

  generateApiReportDownloadsLogsWithPagination = async(limit = 500, offset = 0 , searchParams) =>{
    //return axios.get(process.env.REACT_APP_HOST + `/wand2/company/customers_new?limit=${limit}&offset=${offset}`, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + `/wand2/logs/api_download_logs?limit=${limit}&offset=${offset}`, "post", searchParams,this.getRequestOption()).then(res => res.data);
  }

//---------------------- Added Session Storage Function ---------------------------//

//************** Company Suppliers ***********/
  getSuppliers =async()=>{
    if(sessionStorage.getItem('api_company_suppliers') !== null){
      return JSON.parse(sessionStorage.getItem('api_company_suppliers'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/suppliers',"get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_company_suppliers',JSON.stringify(data));
      return data;
    } 

  }
  createSupplier = async (supplierData)=>{
    sessionStorage.removeItem("api_company_suppliers");
    return await api(process.env.REACT_APP_HOST + '/wand2/company/suppliers',"post", supplierData, this.getRequestOption()).then(res => res.data);
  }
  updateSupplier(id, supplierData){
    sessionStorage.removeItem("api_company_suppliers");
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/company/supplier/' + id, supplierData, this.getRequestOption()).then(res => res.data);
  }
//************** END Company Suppliers ***********/

//************** Api Keys ************************/
  getApiKeys = async () =>{
    if(sessionStorage.getItem('api_apiKeys') !== null){
      return JSON.parse(sessionStorage.getItem('api_apiKeys'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/api_keys/', "get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_apiKeys',JSON.stringify(data));
      return data;
    } 
  }
  createApiKey = async (apiKeyInfo) =>{
    sessionStorage.removeItem("api_apiKeys");
    return await api(process.env.REACT_APP_HOST + '/wand2/api_keys/', "post",apiKeyInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Api Keys ***********/

//************** Companies ************************/
  getCompanies = async () =>{
    if(sessionStorage.getItem('api_companies') !== null){
      return JSON.parse(sessionStorage.getItem('api_companies'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/', "get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_companies',JSON.stringify(data));
      return data;
    } 
  }
  getCompanyById = async (id) => {
    if(sessionStorage.getItem('api_companyById_'+id) !== null){
      return JSON.parse(sessionStorage.getItem('api_companyById_'+id))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/' + id, "get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_companyById_'+id,JSON.stringify(data));
      return data;
    } 
  }
  deleteCompany(id){
    sessionStorage.removeItem('api_companies');
    sessionStorage.removeItem('api_companyById_'+id);
    return axios.delete(process.env.REACT_APP_HOST + '/wand2/company/' + id, this.getRequestOption()).then(res => res.data);
  }
//************** End Companies  ************************/
  
  getCustomersSummary = async()=>{
    if(sessionStorage.getItem('api_customer_summary') !== null){
      return JSON.parse(sessionStorage.getItem('api_customer_summary'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/customers_summary', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_customer_summary',JSON.stringify(data));
      return data;
    } 
  }

  getClients = async()=>{
    if(sessionStorage.getItem('api_getClients') !== null){
      return JSON.parse(sessionStorage.getItem('api_getClients'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/clients',"get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_getClients',JSON.stringify(data));
      return data;
    }
  }

  getReportCustomers = async()=>{
    if(sessionStorage.getItem('api_reportCustomers') !== null){
      return JSON.parse(sessionStorage.getItem('api_reportCustomers'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/report_customers',"get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_reportCustomers',JSON.stringify(data));
      return data;
    }
  }

  getReportCustomersInfo = async()=>{
    if(sessionStorage.getItem('api_reportCustomersInfo') !== null){
      return JSON.parse(sessionStorage.getItem('api_reportCustomersInfo'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/report_customer_info_list',"get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_reportCustomersInfo',JSON.stringify(data));
      return data;
    }
  }
  getCompanyXMLReport = async(companyId)=>{
    if(sessionStorage.getItem('api_companyXMLReport'+companyId) !== null){
      return JSON.parse(sessionStorage.getItem('api_companyXMLReport'+companyId))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/'+companyId+'/report/xml/generate', "get",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_companyXMLReport'+companyId,JSON.stringify(data));
      return data;
    }
  }
  
  getCompanyWORDReport =async(companyId)=>{
    if(sessionStorage.getItem('api_companyWordReport'+companyId) !== null){
      return JSON.parse(sessionStorage.getItem('api_companyWordReport'+companyId))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/company/'+companyId+'/report/word/generate',"post", {i18nextLng : localStorage.getItem("i18nextLng")}, this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_companyWordReport'+companyId,JSON.stringify(data));
      return data;
    }
  }

}
