import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./langauges/en/translation.json";
import translationFR from "./langauges/fr/translation.json";
import translationES from "./langauges/es/translation.json";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ["en"];
const availableLanguages = ["en", "es", "fr"];
const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
  es: { translation: translationES }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true
    },
    debug: true,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;