import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import InquiryService from '../service/InquiryService';
import { Dropdown } from 'primereact/dropdown';
import { Translation } from 'react-i18next';
class InquiriesDetailsShareholdersAffiliations extends Component {
  constructor(props){
    super(props);
    this.state = {
      affiliatedCompanies : this.props.affiliated_companies,
      countries: [],
      affiliationTypes: [],
      toast: this.props.toast
    }

    this.handleAddAffiliate = this.handleAddAffiliate.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.handleAffiliateChange = this.handleAffiliateChange.bind(this);
    this.handleDeleteAffiliatedCompany = this.handleDeleteAffiliatedCompany.bind(this);
    this.inquiryService = new InquiryService();
    this.handlePhoneChange = this.handlePhoneChange.bind(this);

    this.inquiryService.getAffiliationTypes().then(data =>{
      this.setState({affiliationTypes:data});
    })
  }
  componentDidMount(){
    if(!sessionStorage.getItem('ses_countries')){
      this.inquiryService.getCountries().then(data =>{
        this.setState({countries:data})
        sessionStorage.setItem('ses_countries',JSON.stringify(data))
      });
    }else{  
      this.setState({countries: JSON.parse(sessionStorage.getItem('ses_countries'))});   
    }
  }
  handleDeleteAffiliatedCompany(indx, event){
    const affiliatedCompanies = this.state.affiliatedCompanies;
    affiliatedCompanies.splice(indx,  1);
    this.setState({affiliatedCompanies: affiliatedCompanies}, function(){
      this.dataRefresh();
    })
  }

  handleAffiliateChange(aff_idx, parentField, childField, event){
    let affiliatedCompanies = this.state.affiliatedCompanies
    if(childField){
      if(!affiliatedCompanies[aff_idx][parentField]){
        affiliatedCompanies[aff_idx][parentField] = {}
      }
      affiliatedCompanies[aff_idx][parentField][childField] = event.target.value
    } else{
      affiliatedCompanies[aff_idx][parentField] = event.target.value
    }

    this.setState({affiliatedCompanies:affiliatedCompanies}, function(){
      this.dataRefresh();
    })
  }

  dataRefresh(){
    const affiliations = sessionStorage.getItem('ses_affiliations_' + this.props.report_id);
    const affiliationsJSON = JSON.parse(affiliations)
    affiliationsJSON.affiliated_companies = this.state.affiliatedCompanies
    sessionStorage.setItem('ses_affiliations_' + this.props.report_id, JSON.stringify(affiliationsJSON));
  }

  handleAddAffiliate(e){
      e.preventDefault();
      const new_affiliates = this.state.affiliatedCompanies;
      
      let obj = {};
     
      if(new_affiliates.length == 0 || new_affiliates[new_affiliates.length - 1].company_name){
        new_affiliates.push(obj);
        this.setState({affiliatedCompanies: new_affiliates}, function(){
          this.dataRefresh();
        })
      } else {
          this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter company name"});            
      }


      
  }

  handlePhoneChange(event){
    let affiliatedCompanies = this.state.affiliatedCompanies
    let contactInfo = affiliatedCompanies[event.target.id].contact_info
    if(!contactInfo){
      contactInfo = {telephones: []}
    }
    if (contactInfo.telephones.length == 0){
      let obj = {}
      obj[event.target.name] = event.target.value
      contactInfo.telephones.push(obj)
    } else {
      contactInfo.telephones[0][event.target.name] = event.target.value
    }

    affiliatedCompanies[event.target.id].contact_info = contactInfo
    this.setState({affiliatedCompanies:affiliatedCompanies}, function(){
      this.dataRefresh();
    })
  }

  render(){
    return(
      <div>
        <div className="form-group">
            <div className="row" >
                <div className="col-sm-2">
                   <span className="form-title">{<Translation>{ t => <span>{t('AFFILIATIONS')} </span>}</Translation>}</span>
                </div>
            </div>
        </div>
        <div className="form-group">
          <div className="row" >
            <div className="col-sm-2">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('ADD_AFFILIATED_COMPANY')} </span>}</Translation>}</label> <Button icon="pi pi-plus"  onClick={this.handleAddAffiliate} className="p-button-square p-button-secondary ccp-add-btn" />
            </div>
            <div className="col-sm-10">
                <Accordion activeIndex="0">
                    {
                        this.state.affiliatedCompanies?.map((affiliations,i) =>

                                <AccordionTab key={"key-"+i} header={
                                  <React.Fragment>
                                          <div className="row">
                                              <div className="col-5">
                                                  <input type="text" autoFocus onChange={(e) => this.handleAffiliateChange(i, "company_name", null, e)} placeholder="legal name" name={"affiliations.company.legal_name"} value={affiliations.company_name} className="form-control form-control-sm"/>
                                              </div>
                                              <div className="col-3">
                                                   <Dropdown value = {affiliations.address?.country}  options={this.state.countries}optionLabel="name" placeholder="Select A country Below" filter filterBy="name" onChange={(e) => this.handleAffiliateChange(i, "address", "country", e)}/>
                                              </div>
                                              <div className="col-3">
                                                    <Dropdown value = {affiliations.type}  options={this.state.affiliationTypes} optionLabel="name" placeholder="Select A Type" filter filterBy="name" onChange={(e) => this.handleAffiliateChange(i, "type", null, e)}/>
                                              </div>
                                              <div className="col-1">
                                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                                      onClick = {(e) => this.handleDeleteAffiliatedCompany(i, e)} value = {null}  />
                                              </div>
                                          </div>
                                  </React.Fragment>}>

                                  <div className="col-9">
                                        {/*<div className="form-group">

                                            <div className="row">
                                                <div className="col-sm-1">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('COMPANY_NAME')} </span>}</Translation>}</label>
                                                </div>
                                               <div className="col-sm-2">
                                                    <input type="text" autoFocus onChange={(e) => this.handleAffiliateChange(i, "company_name", null, e)} placeholder="legal name" name={"affiliations.company.legal_name"} value={affiliations.company_name} className="form-control form-control-sm"/>
                                                </ div>
                                                <div className="col-sm-1">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation>}</label>
                                                </div>
                                                <div className="col-sm-2">
                                                  <Dropdown value = {affiliations.address.country}  options={this.state.countries}
                                                    optionLabel="name" placeholder="Select A country Below" filter filterBy="name" onChange={(e) => this.handleAffiliateChange(i, "address", "country", e)}/>
                                                </div>
                                                <div className="col-sm-1">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('TYPE')} </span>}</Translation>}</label>
                                                </div>
                                                <div className="col-sm-2">
                                                  <Dropdown value = {affiliations.type}  options={this.state.affiliationTypes}
                                                    optionLabel="name" placeholder="Select A Type" filter filterBy="name" onChange={(e) => this.handleAffiliateChange(i, "type", null, e)}/>
                                                </div>
                                            </div>
                                          </div> */}
                                        <div className="spacer"/>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('STREET_ADDRESS')} </span>}</Translation>}</label>
                                                </div>
                                               <div className="col-sm-3">
                                                    <input type="text" placeholder="street address"  onChange={(e) => this.handleAffiliateChange(i, "address", "street",e)} name={"shareholder.company.legal_address.street_"+i} value={affiliations.address?.street} className="form-control form-control-sm"/>
                                                </ div>
                                                <div className="col-sm-2">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('REGISTRATION_NUMBER')} </span>}</Translation>}</label>
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="text" placeholder="registration" onChange={(e) => this.handleAffiliateChange(i, "registration_id", null, e)}  name={"supplier.company.registration_id_"+i} value={affiliations.registration_id} className="form-control form-control-sm"/>
                                                </ div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('CITY')} </span>}</Translation>}</label>
                                                </div>
                                               <div className="col-sm-3">
                                                    <input type="text" placeholder="city"   onChange={(e) => this.handleAffiliateChange(i, "address", "city", e)}  name={"shareholder.company.legal_address.city_"+i} value={affiliations.address?.city} className="form-control form-control-sm"/>
                                                </ div>
                                                <div className="col-sm-2">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('PHONE_NUMBER')} </span>}</Translation>}</label>
                                                </div>
                                                <div className="col-sm-3">
                                                  <div className = "row phone">
                                                    <div className = "col-4">
                                                      <input type = "number" id = {i} name = "country_code" style={{'width':'150%'}}
                                                          className="form-control form-control-sm" placeholder = "Country Code" onChange={this.handlePhoneChange} value ={affiliations.contact_info?.telephones?.length > 0 ? affiliations.contact_info?.telephones[0]?.country_code : null}/>
                                                    </div>
                                                    <div className = "col-8">
                                                      <input type = "number" className="form-control form-control-sm"  id = {i} name = "number" style={{'width':'100%'}}
                                                        placeholder = "N" onChange={this.handlePhoneChange} value ={affiliations.contact_info?.telephones?.length > 0 ? affiliations.contact_info?.telephones[0]?.number : null} />
                                                    </div>
                                                  </div>
                                                </ div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('STATE')} </span>}</Translation>}</label>
                                                </div>
                                               <div className="col-sm-3">
                                                    <input type="text" placeholder="state"  onChange={(e) => this.handleAffiliateChange(i, "address", "state", e)} name={"shareholder.company.legal_address.state_"+i} value={affiliations.address?.state} className="form-control form-control-sm"/>
                                                </ div>
                                                <div className="col-sm-2">
                                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('WEBSITE')} </span>}</Translation>}</label>
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="text" placeholder="website"  onChange={(e) => this.handleAffiliateChange(i, "contact_info", "websites", e)} name={"shares_percentage_"+i} value={affiliations.contact_info?.websites} className="form-control form-control-sm"/>
                                                </ div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                        )
                    }

                </Accordion>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InquiriesDetailsShareholdersAffiliations;
