import React, { Component } from 'react';
import { Button } from 'primereact/button';
import DateComponent from '../components/DateComponent';
import { Translation } from 'react-i18next';

class InquiriesDetailsIdentificationRegistrationChange extends Component {
  constructor(props){
    super(props);
    this.state = {
      registrationChanges: this.props.registration_changes,
      toast: this.props.toast

    }
    this.renderRegistrationChanges = this.renderRegistrationChanges.bind(this);
    this.handleAddRegistrationChange = this.handleAddRegistrationChange.bind(this);
    this.handleRegistrationChange = this.handleRegistrationChange.bind(this);
    this.handleDeleteRegisrtration = this.handleDeleteRegisrtration.bind(this);
  }

  handleDeleteRegisrtration(indx, event){
    let registrationChanges = this.state.registrationChanges;
    registrationChanges.splice(indx,  1);
    this.setState({registrationChanges: registrationChanges}, function(){
      this.dataRefresh();
    })
  }
  dataRefresh(){
    // function that reads the state and updates the session storage
    const registrationChanges = sessionStorage.getItem('ses_registration_changes_' + this.props.report_id);
    const registrationChangesJSON = JSON.parse(registrationChanges)
    var newRegistrationChanges = []
    this.state.registrationChanges.map((registrationChange,i)=>{
      newRegistrationChanges.push(registrationChange)
    })
    registrationChangesJSON.changes = newRegistrationChanges
    sessionStorage.setItem('ses_registration_changes_' + this.props.report_id, JSON.stringify(registrationChangesJSON));
  }

  handleRegistrationChange(reg_idx, field, event){
    var registration_arr = this.state.registrationChanges;
    registration_arr[reg_idx][field] = event.target.value
    this.setState({registrationChanges: registration_arr}, function(){
      this.dataRefresh();
    });
  }

  handleAddRegistrationChange(event){
    event.preventDefault();
    const registration_arr = this.state.registrationChanges;

    if(registration_arr.length == 0 || registration_arr[registration_arr.length - 1].change_date){
      registration_arr.push({});
      this.setState({registrationChanges: registration_arr}, function(){
        this.dataRefresh();
      });
    } else {
      this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please Select Registration Date"});
        
    }

  }
  renderRegistrationChanges(){
    if (this.state.registrationChanges.length == 0){
      return <div></div>
    }
    return(
      <div className="form-group" style={{margin:"25px"}}>
        <div className = "row">
          <div className="col-1">
            <div className="row" style = {{marginTop:"10px"}}>
              <span className="form-field-bold"><Translation>{ t => t('DATE')}</Translation></span>
            </div>
            <div className="row" style = {{marginTop:"10px"}}>
            </div>
            <br/>
            <br/>
            <div className="row" style = {{marginTop:"10px"}}>
              <span className="form-field-bold"><Translation>{ t => t('TYPE_OF_CHANGE')}</Translation></span>
            </div>
            <div className="row" style = {{marginTop:"10px"}}>
              <span className="form-field-bold"><Translation>{ t => t('DETAIL_OF_CHANGE')}</Translation></span>
            </div>
          </div>
          {
            this.state.registrationChanges.map((registrationChange, i) =>(
              <div className="col-2" style = {{marginLeft:"10px"}} key={i}>
                <div className = "row" style = {{marginTop:"5px"}}>
                  <div className="col-sm-9">
                      <DateComponent date = {registrationChange.change_date} update_date = {(e) => this.handleRegistrationChange(i, "change_date", e)}/>
                 </div>
                 <div className = "col-sm-1">
                   <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                     onClick = {(e) => this.handleDeleteRegisrtration(i, e)} value = {null}  />
                 </div>

                </div>
                <div className = "row" style = {{marginTop:"5px"}}>
                  <input className="form-control form-control-sm" type = "text" onChange={(e)=>this.handleRegistrationChange(i, "registration_Change_type", e)} value={registrationChange.registration_Change_type} />
                </div>
                <div className = "row" style = {{marginTop:"5px"}}>
                  <textarea className="form-control form-control-sm"  type = "text" onChange={(e)=>this.handleRegistrationChange(i, "registration_Change_detail", e)} value={registrationChange.registration_Change_detail} />
                </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  render(){
    return(
      <div className="form-group">
          <span className="form-title">{<Translation>{ t => <span>{t('REGISTRATION_CHANGES')} </span>}</Translation>}</span> <Button icon="pi pi-plus" onClick={this.handleAddRegistrationChange} className="p-button-square p-button-secondary" />
          {this.renderRegistrationChanges()}
      </div>
    )
  }

}

export default InquiriesDetailsIdentificationRegistrationChange
