import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Translation } from 'react-i18next';
import DateComponent from '../components/DateComponent';


class InquiriesDetailsFinancialsPressReleases extends Component {

  constructor(props){
    super(props);
    this.state = {
      pressReleases : this.props.press_releases,
      toast : this.props.toast
    }
    this.addNewPressRelease = this.addNewPressRelease.bind(this);
    this.handlePressReleaseChange = this.handlePressReleaseChange.bind(this);
    this.deletePressRelease = this.deletePressRelease.bind(this);
  }

  dataRefresh(){
    const pressReleases = sessionStorage.getItem('ses_press_' + this.props.report_id);
    let pressReleasesJSON = JSON.parse(pressReleases)
    pressReleasesJSON.press_releases = this.state.pressReleases
    sessionStorage.setItem('ses_press_' + this.props.report_id, JSON.stringify(pressReleasesJSON));
  }

  addNewPressRelease(event){
    var pressReleases = this.state.pressReleases
    var obj = {}
    obj["title"] = "New Press Release"
    if(pressReleases.length == 0 || pressReleases[pressReleases.length - 1].title !="New Press Release"){
      pressReleases.push(obj)
      this.setState({pressReleases: pressReleases}, function(){
        this.dataRefresh();
      })
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter press release title"});            
    }
  }

  handlePressReleaseChange(pressRelease_idx, field, value){
    var pressReleases = this.state.pressReleases
    var pressRelease = pressReleases[pressRelease_idx]
    pressRelease[field] = value
    pressReleases[pressRelease_idx] = pressRelease
    this.setState({pressReleases: pressReleases}, function(){
      this.dataRefresh();
    })

  }

  deletePressRelease(indx, event){
    let pressReleases = this.state.pressReleases;
    pressReleases.splice(indx,  1);
    this.setState({pressReleases: pressReleases}, function(){
      this.dataRefresh();
    })
  }

  render() {
    return (
      <div>
        <div className = "col-5" style={{margin:"15px"}}>
          <Button icon="pi pi-plus"  onClick={this.addNewPressRelease} className="p-button-square p-button-secondary" />
        </div>
        {
          this.state.pressReleases?.map((pressRelease, i) =>
            <div className = "col-5" style={{margin:"15px"}} key={i}>
              <input type = "text" onChange = {(e) => this.handlePressReleaseChange(i, "title", e.target.value)} className="form-control form-control-sm" placeholder="title" value={pressRelease.title}/>
              <input type = "text" onChange = {(e) => this.handlePressReleaseChange(i, "source", e.target.value)} className="form-control form-control-sm" placeholder="link" value={pressRelease.source}/>
              <DateComponent date = {pressRelease.date} update_date = {(e) => this.handlePressReleaseChange(i, "date", e.target.value)}/>
              <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deletePressRelease(i, e)} value = {null} style={{'top': '-7px'}}  />
              <textarea autoFocus onChange = {(e) => this.handlePressReleaseChange(i, "article", e.target.value)} className="form-control form-text-comments" placeholder="comments" name="comments"  value={pressRelease.article} class="form-control form-control-sm" />
            </div>
          )
        }
      </div>
    )
  }

}


export default InquiriesDetailsFinancialsPressReleases
