import React, { Component } from 'react';
import { Button } from 'primereact/button';
import InquiryService from '../service/InquiryService';
import { Dropdown } from 'primereact/dropdown';
import { Translation } from 'react-i18next';

class Address extends Component {
  constructor(props){
    super(props)
    this.state = {
      countries: [],
      addressType: false,
      address: this.props.address,
      countryDisabled: this.props.country_disabled ? this.props.country_disabled : false
    }
    if (this.props.address){
      if (this.props.address.full_address){
        this.state.addressType = true
      }
    }
    this.handleAddressFormChange = this.handleAddressFormChange.bind(this);
    this.refreshAddressType = this.refreshAddressType.bind(this);
    this.renderAddressForm = this.renderAddressForm.bind(this);
    this.new = this.new.bind(this);
    this.inquiryService = new InquiryService();
    this.inquiryService.getCountries().then(data =>{
      this.setState({countries:data})
    })
  }

  refreshAddressType(full, event){
    this.setState({addressType: full})
  }

  new () {
    let address = {}
    address["country"] = {"name": "United States"}
    this.setState({address: address})
  }

  handleAddressFormChange(field, event){
    let address = {...this.state.address}
    address[field] = event.target.value
    this.setState({address:address})
    this.props.AddressChange(this.props.field_name, address, this.props.label);
    event.preventDefault();
  }

  renderAddressForm(){
    if(!this.state.address){
      return(
        <div className="col-sm-9" style={{'marginLeft': '10px'}}>
          <Button onClick={this.new} label={<Translation>{ t => <span>{t('ADD_ADDRESS')} </span>}</Translation>} className="p-button-sm" icon="pi pi-plus" />
        </div>
      )
    }
    if(this.state.addressType){
      return (
        <div className="form-group">
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('ADDRESS')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-6">
                      <textarea onChange={(e)=>this.handleAddressFormChange("full_address", e)} name="full_address"
                        value={this.state.address.full_address} className="form-control form-control-sm" autoComplete="something" />
                  </div>
          </div>
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-4">
                    <Dropdown value = {this.state.address.country} options={this.state.countries} onChange={(e)=>this.handleAddressFormChange("country", e)}
                        optionLabel="name" placeholder="Select a Country" filter disabled = {this.state.countryDisabled} />
                  </div>
          </div>
        </div>
      )
    } else{
      return (
        <div className="form-group">
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('STREET')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-6">
                      <input type="text" name="street" onChange={(e)=>this.handleAddressFormChange("street", e)}
                        value={this.state.address.street} className="form-control form-control-sm" autoComplete="something" />
                  </div>
          </div>
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('CITY')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-6">
                      <input type="text" name="city" onChange={(e)=>this.handleAddressFormChange("city", e)}
                        value={this.state.address.city} className="form-control form-control-sm" autoComplete="something" />
                  </div>
          </div>
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('STATE')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-6">
                      <input type="text" name="state" onChange={(e)=>this.handleAddressFormChange("state", e)}
                        value={this.state.address.state} className="form-control form-control-sm" autoComplete="something" />
                  </div>
          </div>
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('POSTAL_CODE')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-6">
                      <input type="text" name="postal_code" onChange={(e)=>this.handleAddressFormChange("postal_code", e)}
                        value={this.state.address.postal_code} className="form-control form-control-sm" autoComplete="something" />
                  </div>
          </div>
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-4">
                    <Dropdown value = {this.state.address.country} options={this.state.countries} onChange={(e)=>this.handleAddressFormChange("country", e)}
                        optionLabel="name" placeholder="Select a Country" filter disabled = {this.state.countryDisabled} />
                  </div>
          </div>
          <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">Comments on Address</label>
                  </div>
                  <div className="col-sm-6">
                    <textarea name="comments" onChange={(e)=>this.handleAddressFormChange("comments", e)}
                      value={this.state.address.comments} className="form-control form-control-sm" row = "2" autoComplete="something" />
                  </div>
          </div>
        </div>
      )
    }

  }

  render() {
    return (
      <div className="form-group">
          <div className="row">
              <h6>{this.props.label}</h6>
              <div className="col-12">
                  <input type = "radio" id = {"full_address_" + this.props.label} name = {"full_address_" + this.props.label} value = "yes" checked={true == this.state.addressType} onChange={e => {
                    this.refreshAddressType(true, e);
                  }}/>
                  <label htmlFor = {"full_address_" + this.props.label} style={{marginLeft:"2px"}}> <strong> Full Address </strong> </label>
                  <input type = "radio" id = {"form_address_" + this.props.label} name = {"form_address_" + this.props.label} style={{marginLeft:"10px"}} value = "no" checked={false == this.state.addressType}
                  onChange={e => {
                    this.refreshAddressType(false, e);
                  }}/>
                  <label htmlFor = {"form_address_" + this.props.label} style={{marginLeft:"2px"}}> <strong> Form </strong> </label>
              </div>
          </div>
          <div>
            {this.renderAddressForm()}
          </div>
      </div>
    )
  }
}

export default Address
