import React, { Component } from 'react';
import logo from '../img/logo.png';
import { Translation } from 'react-i18next';
import i18next from "i18next";
import UserService from '../service/UserService';
class MainHeader extends Component {
    constructor(props){
      super(props);
      this.changeLanguage = this.changeLanguage.bind(this);
      //i18next.changeLanguage('en');
      this.state = {
        reportCount: {}
      }
      const tokenJSON = JSON.parse(sessionStorage.getItem("token"));
      if (tokenJSON.user.is_staff) {
        this.userService = new UserService();
        this.userService.getUserReportCount().then(response => {

          this.state.reportCount.to_send = response.to_send
          this.state.reportCount.to_correct = response.to_correct
          this.state.reportCount.reviewed = response.reviewed

        }).catch(err=> {
          console.log(err)
        })
      }

    }

    changeLanguage(lang,e){
        let elem = document.getElementsByClassName('current');

        [].forEach.call(elem, function(el) {
            el.classList.remove("current");
        });

        e.target.classList.add('current');
        i18next.changeLanguage(lang);

    }

    render() {
        const tokenJSON = JSON.parse(sessionStorage.getItem("token"));
        return (
            <div className="main_header">
                <div className="col-12 row">
                    <div className="col-md-1">
                        <Translation>{t => <span>{t('WELCOME')} </span>}
                        </Translation>{tokenJSON.user?.username}
                    </div>
                    <div className="col-md-4">
                      <div hidden = {!tokenJSON.user.is_staff} className="row">
                          <div className="col-md-3" style={{'marginRight': '5px'}}>
                              <div className="box-report-info bg-blue">{<Translation>{ t => <span>{t('REPORTS_TO_SEND') } : {this.state.reportCount.to_send}</span>}</Translation>}  </div>
                          </div>
                          <div className="col-md-3" style={{'marginRight': '5px'}}>
                              <div className="box-report-info bg-green">{<Translation>{ t => <span>{t('REPORTS_TO_CORRECT')} </span>}</Translation>} : {this.state.reportCount.to_correct}</div>
                          </div>
                          <div className="col-md-3" >
                              <div className="box-report-info bg-yellow">{<Translation>{ t => <span>{t('REVIEWED_REPORTS')} </span>}</Translation>} : {this.state.reportCount.reviewed}</div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                    </div>
                    <div className="col-md-1" id="lang-selector">
                      <span>
                        <a href="#" onClick={(e) => this.changeLanguage('en',e)}
                            className={ (localStorage.getItem("i18nextLng") == "en") ? "current" : ""}>EN
                        </a> |
                        <a href="#"  onClick={(e) => this.changeLanguage('es',e)}
                            className={ (localStorage.getItem("i18nextLng") == "es") ? "current" : ""}>ES
                        </a> |
                        <a href="#"   onClick={(e) => this.changeLanguage('fr',e)}
                              className={ (localStorage.getItem("i18nextLng") == "fr") ? "current" : ""}>FR
                        </a>
                      </span>
                    </div>
                    <div className="col-md-1">
                        <img className="logo" alt="CCP Logo" src={logo}/>
                    </div>
                </div>
            </div>
            )
        }
    }

export default MainHeader
