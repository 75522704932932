import React, { Component } from 'react';
import Navitem from './Navitem';
import { Translation } from 'react-i18next';


class Navbar extends Component {

    constructor(props)
    {
        super(props);
        this.state={
           NavItemActive:'',
           isStaff: this.props.is_staff,
           isAdmin: this.props.is_admin
        }
    }
    activeitem=(x)=>
    {
       /* if(this.state.NavItemActive.length > 0){
            document.getElementById(this.state.NavItemActive).classList.remove('active');
        }
        this.setState({NavItemActive:x},()=>{
          document.getElementById(this.state.NavItemActive).classList.add('active');

        });*/
    };

    render() {

      if(this.state.isStaff){
        if (this.state.isAdmin){
          return (
              <nav>
                <ul>
                    <Navitem item={<Translation>{t => t('INQUIRIES')}</Translation>} tolink="/inquiries"  activec={this.activeitem}></Navitem>
                    <Navitem item={<Translation>{t => t('ORDERS')}</Translation>} tolink="/orders" activec={this.activeitem}></Navitem>
                    <Navitem item={<Translation>{t => t('ADMIN')}</Translation>} tolink="/admin" activec={this.activeitem}></Navitem>
                    <Navitem item={<Translation>{t => t('LOGOUT')}</Translation>} tolink="/logout" activec={this.activeitem}></Navitem>
                </ul>
              </nav>
              )
        } else {
          return (
            <nav>
              <ul>
                  <Navitem item={<Translation>{t => t('INQUIRIES')}</Translation>} tolink="/inquiries"  activec={this.activeitem}></Navitem>
                  <Navitem item={<Translation>{t => t('LOGOUT')}</Translation>} tolink="/logout" activec={this.activeitem}></Navitem>
              </ul>
            </nav>
          )
        }

      } else {
        return (
            <nav>
              <ul>
                <Navitem item={<Translation>{t => t('MY_ORDERS')}</Translation>} tolink="/orders" activec={this.activeitem}></Navitem>
                <Navitem hidden item={<Translation>{t => t('COMPANIES')}</Translation>} tolink="/companies" activec={this.activeitem}></Navitem>
                <Navitem item="Logout" tolink="/logout" activec={this.activeitem}></Navitem>
              </ul>
            </nav>
            )
      }

        }
    }

    export default Navbar
