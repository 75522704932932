import React, { Component} from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import InquiryService from '../service/InquiryService';
import Address from '../components/Address.js';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { Translation } from 'react-i18next';
import DateComponent from '../components/DateComponent';
import { Toast } from 'primereact/toast';
class InquiriesDetailsIdentificationRegistrations extends Component {
  constructor(props){
    super(props);
    this.state = {
      registrations: this.props.registrations,
      lastAnnualReport: this.props.last_annual_report,
      comments: this.props.comments,
      regTypes: [],
      regStatuses:[],
      countries: [],
      toast: this.props.toast,
      isCoface : this.props.order_legal_name.toLowerCase().includes("coface") ? true : false
    }
    this.inquiryService = new InquiryService();
    this.addNewRegistration = this.addNewRegistration.bind(this);
    this.renderRegistrations = this.renderRegistrations.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.handleRegistrationDateChange = this.handleRegistrationDateChange.bind(this);
    this.handleRegistrationChange = this.handleRegistrationChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleRegistrationAddressChange = this.handleRegistrationAddressChange.bind(this);
    this.handleDeleteRegisrtration = this.handleDeleteRegisrtration.bind(this);
    this.inquiryService.getRegTypes().then(data=>{
      this.setState({regTypes: data})
    })
    this.inquiryService.getRegStatuses().then(data=>{
      this.setState({regStatuses: data})
    })
    this.inquiryService.getCountries().then(data => {
      this.setState({countries: data})
    })
    console.log("PO",this.props,this.state.isCoface)
  }

  handleDeleteRegisrtration(indx, event){
    let registrations = this.state.registrations;
    registrations.splice(indx,  1);
    this.setState({registrations: registrations }, function(){
      this.dataRefresh();
    })
  }

  handleAddressChange(field, address_data, label){
    var reg_idx = label - 1
    var event = {}
    event.target = {}
    event.target.value = address_data
    this.handleRegistrationChange(reg_idx, field, event)
  }

  dataRefresh(){
    // function that reads the state and updates the session storage
    const registrationInfo = sessionStorage.getItem('ses_registration_info_' + this.props.report_id);
    const registrationInfoJSON = JSON.parse(registrationInfo)
    var newRegistrations = []
    this.state.registrations.map((registration,i)=>{
      newRegistrations.push(registration)
    })
    registrationInfoJSON.registrations = newRegistrations
    registrationInfoJSON.last_annual_report = this.state.lastAnnualReport
    registrationInfoJSON.comments = this.state.comments
    sessionStorage.setItem('ses_registration_info_' + this.props.report_id, JSON.stringify(registrationInfoJSON));
  }

  addNewRegistration(event){
    event.preventDefault();
    const registration_arr = this.state.registrations;    
   
    if(registration_arr.length == 0 || registration_arr[registration_arr.length - 1].reg_type){
      registration_arr.push({});
      this.setState({registrations: registration_arr}, function(){
        this.dataRefresh();
      });
    } else {
      this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please a Type of Registration"});
        
    }
  }

  handleRegistrationDateChange(reg_idx, field, event){
    if (reg_idx == -1){
      this.setState({lastAnnualReport: event.target.value}, function(){
        this.dataRefresh();
      })

    } else{
      var registration_arr = this.state.registrations;
      registration_arr[reg_idx][field] = event.target.value
      this.setState({registrations: registration_arr }, function(){
        this.dataRefresh();
      });
    }



  }

  handleRegistrationAddressChange(reg_idx, field, event){
    const value = event.target.value
    var registration_arr = this.state.registrations;
    if(!registration_arr[reg_idx]["address"]){
      registration_arr[reg_idx]["address"] = {}
    }
    registration_arr[reg_idx]["address"][field] = value
    this.setState({registrations: registration_arr }, function(){
      this.dataRefresh();
    });

  }

  handleRegistrationChange(reg_idx, field, event){
    const value = event.target.value
    var registration_arr = this.state.registrations;

    registration_arr[reg_idx][field] = value

    this.setState({registrations: registration_arr });
    this.dataRefresh();
  }

  renderRegistrations_inp(){
    const options = [{value : true, label: <Translation>{ t =>t('DOMESTIC')}</Translation>}, {value : false, label: <Translation>{ t =>t('FOREIGN')}</Translation>}];

    if (this.state.registrations.length == 0){
      return <div></div>
    }
    return(
      <div className="form-group" >
        <div className="col-12">
            <div className="row" style = {{marginTop:"10px"}}>
              <div className = "col-1">
                <span className="form-field-bold">{<Translation>{ t =>t('TYPE')}</Translation>}</span>
              </div>

              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                  <div className="row" >
                       <div className="col-sm-9">
                         <Dropdown value = {registration.reg_type} options={this.state.regTypes}
                               onChange={(e)=>this.handleRegistrationChange(i, "reg_type", e)} optionLabel="name" placeholder="Select a Type" filter filterBy="name" />
                      </div>
                      <div className = "col-sm-1">
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                          onClick = {(e) => this.handleDeleteRegisrtration(i, e)} value = {null}  />
                      </div>
                  </div>


                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"10px"}}>
              <div className = "col-1">
                <span className="form-field-bold">{<Translation>{ t =>t('NUMBER')}</Translation>}</span>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationChange(i, "reg_value", e)} className="form-control form-control-sm" value = {registration.reg_value} />
                  </div>
                ))
              }

            </div>
            <div className="row" style = {{marginTop:"10px"}}>
              <div className = "col-1">
                <span className="form-field-bold">{<Translation>{ t =>t('REGISTRATION_DATE')}</Translation>}</span>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                      <DateComponent date = {registration.reg_date} update_date = {(e) => this.handleRegistrationDateChange(i, "reg_date", e)}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <div className = "col-1">
                <span className="form-field-bold">{<Translation>{ t =>t('ISSUING_ADMINISTRATION')}</Translation>}</span>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationChange(i, "iss_admin", e)} className="form-control form-control-sm" value = {registration.iss_admin}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <div className = "col-1">
                <span className="form-field-bold">{<Translation>{ t =>t('FOREIGN') + "/" + t('DOMESTIC')}</Translation>}</span>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <SelectButton value={registration.type} options={options}  onChange={(e) => this.handleRegistrationChange(i, "type", e)}
                            optionLabel="label" style = {{ "marginLeft" : "10px", "fontSize": "7px"}}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <div className = "col-1">
                <span className="form-field-bold">{<Translation>{ t =>t('FORMER_NUMBER')}</Translation>}</span>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationChange(i, "former_nbr", e)}  className="form-control form-control-sm" value = {registration.former_nbr}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <div className = "col-1">
                <span className="form-field-bold">{<Translation>{ t =>t('REGISTRATION_STATUS')}</Translation>}</span>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                  <div className="row" >
                       <div className="col-sm-9">
                          <Dropdown value={registration.reg_status} options={this.state.regStatuses}  onChange={(e) => this.handleRegistrationChange(i, "reg_status", e)}
                            filter filterBy="name" optionLabel="name" />
                      </div>
                      <div className = "col-sm-1">
                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.handleRegistrationChange(i, "reg_status", e)} value = {null}  />
                      </div>
                  </div>


                  </div>
                ))
              }

            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <div className = "col-2">
                <span className="form-field-bold">{<Translation>{ t =>t('LEGAL_ADDRESS')}</Translation>}</span>
              </div>
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <div className = "col-1">
                <label>{<Translation>{ t =>t('STREET')}</Translation>}</label>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <textarea type = "text" onChange={(e)=>this.handleRegistrationAddressChange(i, "street", e)}  className="form-control form-control-sm" value = {registration.address?.street}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"5px"}}>
              <div className = "col-1">
                <label>{<Translation>{ t =>t('POSTCODE') + "/" + t("PO_BOX")}</Translation>} </label>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationAddressChange(i, "postal_code", e)}  className="form-control form-control-sm" value = {registration.address?.postal_code}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"5px"}}>
              <div className = "col-1">
                <label>{<Translation>{ t =>t('CITY') }</Translation>}</label>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationAddressChange(i, "city", e)}  className="form-control form-control-sm" value = {registration.address?.city}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"5px"}}>
              <div className = "col-1">
                <label>{<Translation>{ t =>t('STATE') }</Translation>}</label>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationAddressChange(i, "state", e)}  className="form-control form-control-sm" value = {registration.address?.state}/>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{marginTop:"5px"}}>
              <div className ="col-1">
                <label>{<Translation>{ t =>t('COUNTRY') }</Translation>}</label>
              </div>
              {
                this.state.registrations.map((registration, i) =>(
                  <div className="col-2" key={i}>
                    <Dropdown value={registration.address?.country} options={this.state.countries}  onChange={(e) => this.handleRegistrationAddressChange(i, "country", e)} filter filterBy="name" optionLabel="name"  />
                  </div>
                ))
              }
            </div>
        </div>
    </div>
    )
  }

  renderRegistrations(){
    if (this.state.registrations.length == 0){
      return <div></div>
    }
    return(
      <div className="form-group" style={{margin:"25px"}}>
        <div className = "row">
          <div className="col-2">
          <div className="row" style = {{marginTop:"10px"}}>
              <span className="form-field-bold">{<Translation>{ t =>t('NUMBER')}</Translation>}</span>
            </div>
            <div className="row" style = {{marginTop:"10px"}}>
              <span className="form-field-bold">{<Translation>{ t =>t('REGISTRATION_DATE')}</Translation>}</span>
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <span className="form-field-bold">{<Translation>{ t =>t('ISSUING_ADMINISTRATION')}</Translation>}</span>
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <span className="form-field-bold">{<Translation>{ t =>t('FOREIGN')+"/"+ t("DOMESTIC")}</Translation>}</span>
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <span className="form-field-bold">{<Translation>{ t =>t('FORMER_NUMBER')}</Translation>}</span>
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <span className="form-field-bold">{<Translation>{ t =>t('REGISTRATION_STATUS')}</Translation>}</span>
            </div>
            <div className="row" style = {{marginTop:"12px"}}>
              <span className="form-field-bold">{<Translation>{ t =>t('LEGAL_ADDRESS')}</Translation>}</span>
            </div>
          </div>
          {
            this.state.registrations.map((registration, i) =>(
              <div className="col-3" style = {{marginLeft:"10px"}}>
                  <div className = "row" style = {{marginTop:"5px"}}>
                    <select className="form-control form-control-sm" onChange={(e)=>this.handleRegistrationChange(i, "reg_type", e)} id="country-options" name="countryOptions">
                      {
                        this.state.regTypes.map((type, i)=>(
                          <option key={i} value = {type.name} selected={registration.reg_type.name == type.name} className="form-control form-control-sm">{type.name} </option>
                        ))
                      }
                    </select>
                  </div>
                  <div className = "row" style = {{marginTop:"5px"}}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationChange(i, "reg_value", e)} className="form-control form-control-sm" value = {registration.reg_value}/>
                  </div>
                  <div className = "row" style = {{marginTop:"5px",width: '98%'}} >
                    <Calendar name="reg_date" placeholder="date" onChange={(e)=>this.handleRegistrationDateChange(i, "reg_date", e)}
                        value={new Date(registration.reg_date   + 'T12:00:00+00:00')} monthNavigator yearNavigator yearRange="1900:2030" showIcon showButtonBar />
                  </div>
                  <div className = "row" style = {{marginTop:"5px"}}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationChange(i, "iss_admin", e)} className="form-control form-control-sm" value = {registration.iss_admin}/>
                  </div>
                  <div className = "row" style = {{marginTop:"5px"}}>
                    <input type = "radio" id = "domestic" name = "domestic" value = "true" checked={'true' == registration.type} onChange={(e)=>this.handleRegistrationChange(i, "type", e)}/>
                    <label htmlFor = "domestic" style={{marginLeft:"2px"}}> <strong> {<Translation>{ t => t("DOMESTIC")}</Translation>} </strong> </label>
                    <input type = "radio" id = "foreign" name = "foreign" style={{marginLeft:"10px"}} value = "false" checked={'false' == registration.type}
                    onChange={e => this.handleRegistrationChange(i, "type", e)}/>
                    <label htmlFor = "foreign" style={{marginLeft:"2px"}}> <strong> {<Translation>{ t =>t('FOREIGN')}</Translation>} </strong> </label>
                  </div>
                  <div className = "row" style = {{marginTop:"5px"}}>
                  </div>
                  <div className = "row" style = {{marginTop:"5px"}}>
                    <input type = "text" onChange={(e)=>this.handleRegistrationChange(i, "former_nbr", e)}  className="form-control form-control-sm" value = {registration.former_nbr}/>
                  </div>
                  <div className = "row" style = {{marginTop:"5px"}}>
                    <select className="form-control form-control-sm" onChange={(e)=>this.handleRegistrationChange(i, "reg_status", e)} id="reg_status-options" name="reg_statusOptions">
                      {
                        this.state.regStatuses.map((status, i)=>(
                          <option key={i} value = {status.name} selected={registration.reg_status.name == status.name} className="form-control form-control-sm">{status.name} </option>
                        ))
                      }
                    </select>
                  </div>
                  <div className = "row" style = {{marginTop:"20px"}}>
                    <Address AddressChange={this.handleAddressChange} label={i+1} field_name = "address"  address = {registration.address}/>
                  </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
  render(){
    return(
      <div className="col-12">
        <Toast ref={this.state.toast} />
      	<div className="row">
      		<div className="col-8">
      			<div className="form-group">
      				<span className="form-title">
      					{<Translation>{ t =>t('REGISTRATION_DATA')}</Translation>}
      				</span>
      				<Button icon="pi pi-plus" onClick={this.addNewRegistration} className="p-button-square p-button-secondary" />
                 { this.state.isCoface && this.state.registrations.length > 0 &&
                  <div class="coface" style={{padding:"5px",width:"300px"}}>You are processing a coface inquiry please check for country on legal address</div>
                }
      				{this.renderRegistrations_inp()}
      				<br></br>
      			</div>
      			<div className="form-group">
      				<div className="row" >
      					<div className="col-sm-2">
      						<label className="form-field-bold">{<Translation>{ t =>t('LAST_ANNUAL_REPORT_DATE')}</Translation>}</label>
      					</div>
      					<div className="col-sm-9">
      						<div className="row">
                    <div className="col-sm-9">
                        <DateComponent date = {this.state.lastAnnualReport} update_date = {(e) => this.handleRegistrationDateChange(-1, "last_annual_report", e)}/>
                    </div>
      						</div>
      					</div>
      				</div>
      				<div className="row" >
      					<div className="col-sm-2">
      						<label className="form-field-bold">{<Translation>{ t =>t('COMMENTS')}</Translation>}</label>
      					</div>
      					<div className="col-4">
      						<div className="row">
      							<textarea value={this.state.comments} rows="2" className="form-control form-text-comments" onChange = {(e) => this.setState({comments: e.target.value},function(){this.dataRefresh()})}/>
      						</div>
      					</div>
      				</div>
      			</div>
      		</div>
      	</div>
      </div>
    )
  }
}

export default InquiriesDetailsIdentificationRegistrations
