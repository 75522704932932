import React, { Component } from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import StatisticsOverview from './StatisticsOverview.js';
import StatisticsDetails from './StatisticsDetails.js';

class Statistics extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      showLoading : true,
    };
    this.setLoading = this.setLoading.bind(this);
  }
setLoading(val){
  this.setState({showLoading : val});
}
render(){
    return (
      <div>
        <div className="condiv">
          <div className = "row">
            <div className="col-12">
            <div className={this.state.showLoading? 'visible loadingDiv' : 'hidden'}>
              <div className="textLoading">Loading Statistics Please wait...</div>
            </div>
            <TabView>    
              <TabPanel header="Overview">
                  <StatisticsOverview setLoading={this.setLoading}/>
              </TabPanel>
              <TabPanel header="Statistics">
                  <StatisticsDetails setLoading={this.setLoading}/>
              </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Statistics
