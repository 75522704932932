import React, { Component } from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import CompanyService from '../service/CompanyService';
import UserService from '../service/UserService';
import InquiryService from '../service/InquiryService';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Users from './Users.js';
import ApiKeys from './ApiKeys';
import CompanyDetails from './CompanyDetails.js';
import Logs from './Logs.js';
import Statistics from './Statistics.js';
import UserDetails from './UserDetails.js';
import CompanyGroups from './CompanyGroups.js';
import AdminCompanies from './AdminCompanies.js';
import ReportsDownloadLogs from './ReportsDownloadLogs.js';
import ApiDownloadLogs from './ApiDownloadLogs.js';
//import { Toast } from 'primereact/toast';
// Users || APi Keys || Customers || Logs || Statistics
class AdminAdmin extends Component {
  constructor(props){
    super(props);
    this._isMounted = false;
    this.state = {
      toast: this.props.toast,
      loadingCompanies: true,
      activeIndex: 0,
      tabs:[],
    }
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.inquiryService = new InquiryService();
    this.onTabChange = this.onTabChange.bind(this);

    this.handleAddNewCustomer = this.handleAddNewCustomer.bind(this);
    this.handleAddCompanyTab = this.handleAddCompanyTab.bind(this);
    this.handleAddUserDetailsTab = this.handleAddUserDetailsTab.bind(this);
    this.handleCloseTab = this.handleCloseTab.bind(this);


  }

  handleCloseTab(tab_id){
      const tab_toClose = tab_id.split("_")
      const id = tab_toClose[1];
      const tab_items = this.state.tabs;
      tab_items.map((tab,i) => {
          if(tab.id == id){
              tab_items.splice(i,1);
          }
      });
     this.setState({tabs:tab_items});
     this.setState({activeTabIndex : (tab_items.length - 1) });

  }

  onTabChange(e){
    let tab_index = e.index;
    const tab_items = this.state.tabs;
    if(tab_items.length == 1){
        tab_index = 0;
    }
    this.setState({ activeTabIndex: tab_index});
  }

  componentDidMount(){
    // APi Keys
    const tabs_arr = this.state.tabs;
    this._isMounted = true;
    const api_keys_tab =  {
        id : 1,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> ApiKeys
                </React.Fragment>,
        content: this._isMounted ? this.renderApiKeysTabContent() : ""
    };
    tabs_arr.push(api_keys_tab);


    // customers
    const companies_tab =  {
        id : 1,
        sort_title : "Customers",
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Customers
                </React.Fragment>,
        content: this.renderCompaniesTabContent()
    };
    tabs_arr.push(companies_tab);

    // users
    const users_tab =  {
        id : 0,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Users
                </React.Fragment>,
        content: this.renderUsersTabContent()
    };
    tabs_arr.push(users_tab);


    // logs
    const logs_tab =  {
        id : 3,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Logs
                </React.Fragment>,
        content: this.renderLogsTabContent()
    };
    tabs_arr.push(logs_tab);

    //Statistics
    const statistics_tab =  {
      id : 4,
      title: <React.Fragment>
                      <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Statistics
              </React.Fragment>,
      content: this.renderStatisticsTabContent()
    };
    tabs_arr.push(statistics_tab);
    this.setState({tabs : tabs_arr});

    // Invoices
    const report_download_logs =  {
      id : 4,
      title: <React.Fragment>
                      <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Report Download Logs
              </React.Fragment>,
      content: this.renderReportsDownloadLogs()
    };
    tabs_arr.push(report_download_logs);
    this.setState({tabs : tabs_arr});

    //API Download
    const api_download_logs =  {
      id : 5,
      title: <React.Fragment>
                      <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Api Logs
              </React.Fragment>,
      content: this.renderApiDownloadLogs()
    };
    tabs_arr.push(api_download_logs);
    this.setState({tabs : tabs_arr});

    // Groups
    this.userService.getCCPCompanyGroups().then(data =>{
      this.setState({
        groups: data
      })
      const groups_tab =  {
          id : 9,
          title: <React.Fragment>
                          <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> CCP Groups
                  </React.Fragment>,
          content: this.renderGroupsTabContent()
      };
      const tabs_arr = this.state.tabs;
      tabs_arr.push(groups_tab);
      this.setState({tabs : tabs_arr});
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  renderGroupsTabContent(){
    return(
      <div>
        <CompanyGroups company_groups = {this.state.groups} toast = {this.state.toast} />
      </div>
    )
  }
  renderUsersTabContent(){
    return(
      <div>
        <Users addUserTab={this.handleAddUserDetailsTab} />
      </div>
    )
  }

  renderApiKeysTabContent(){
    return (
      <div>
        <ApiKeys toast = {this.state.toast} />
      </div>
    )


  }


  renderLogsTabContent(){
    return (
      <div>
        <Logs toast = {this.state.toast} />
      </div>
    )
  }

  renderReportsDownloadLogs(){
    return (
      <ReportsDownloadLogs toast = {this.state.toast} />
    )
  }

  renderApiDownloadLogs(){
    return (
      <ApiDownloadLogs toast = {this.state.toast} />
    )
  }

  renderStatisticsTabContent(){
      return(
        <Statistics />
      )
  }

  renderUserDetailsContent(userInfo , tab_count = this.state.tabs.length){
    return(
      <div><UserDetails addCompanyTab={this.handleAddCompanyTab}  user = {userInfo} toast = {this.state.toast}  onTabChange={this.onTabChange} activeTabIndex = {this.state.activeTabIndex} tabCount = {tab_count} closeTab={this.handleCloseTab} /></div>
    )
  }

  companyNameBodyTemaplte(rowData){
    return (
        <React.Fragment>
            {rowData.legal_name}
        </React.Fragment>
    );
  }
  handleAddNewCustomer(event){
    let companyData = {}
    companyData["legal_name"] = "new customer"
    companyData["type"] = "CUST"
    companyData["customer_info"] = {}
    companyData["customer_info"]["default_rating"] = true
    companyData["legal_address"] = {}
    companyData["legal_address"]["country"] = {}
    companyData["legal_address"]["country"]["name"] = "United States"

    this.handleAddCompanyTab(companyData);
  }




  renderCompaniesTabContent(){
    return(
      <div>
        <AdminCompanies addCompanyTab={this.handleAddCompanyTab} />
      </div>
    )
  }

  handleAddCompanyTab(companyData){
    let tab = this.state.tabs.slice();
    const closeTab = this.handleCloseTab;
    let count = this.state.tabs.length;
    //console.log(localStorage.setItem();
    function handleClose(e) {
        e.preventDefault();
        closeTab(e.currentTarget.id);
    }
    tab.push({
        id : count,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {companyData.legal_name} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                </React.Fragment>,
       content: this.renderNewCompanyContent(companyData,count)
    })
   // count = count + 1;
    sessionStorage.setItem('activeTabIndex',count);

    this.setState({tabs:tab})

    this.setState({activeTabIndex : count });
  }

  handleAddUserDetailsTab(userData){
    let tab = this.state.tabs.slice();
    const closeTab = this.handleCloseTab;
    let count = this.state.tabs.length;

    function handleClose(e) {
        e.preventDefault();
        closeTab(e.currentTarget.id);
    }
    tab.push({
        id : count,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {userData.username} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                </React.Fragment>,
       content: this.renderUserDetailsContent(userData)
    })
   // count = count + 1;
    sessionStorage.setItem('activeTabIndex',count);

    this.setState({tabs:tab})

    this.setState({activeTabIndex : count });
  }



  renderNewCompanyContent(companyInfo, tab_count = this.state.tabs.length){
    return(
      <div><CompanyDetails companyInfo = {companyInfo} onTabChange={this.onTabChange} activeTabIndex = {this.state.activeTabIndex} tabCount = {tab_count} closeTab={this.handleCloseTab} toast = {this.state.toast} /></div>
    )
  }

  render() {
      return (


          <div className="condiv">
            <div className="col-12">

                <TabView activeIndex={this.state.activeTabIndex}  onTabChange={this.onTabChange}>
                    {
                      this.state.tabs.map((tab,i) => (
                        <TabPanel key={i} header={tab.title} headerStyle={tab.headerStyle}>{tab.content}</TabPanel>
                      ))
                    }
                </TabView>
            </div>
          </div>
          )
      }
}

export default AdminAdmin;