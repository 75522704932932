import React, { Component } from 'react';
import UserService from '../service/UserService';
import CompanyService from '../service/CompanyService';
import InquiryService from '../service/InquiryService';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

class OrderAssign extends Component {
  constructor(props){
    super(props)
    console.log(props)
    this.state = {
      availableResources: [],
      availableCompanies: [],
      orderId: this.props.orderInfo.id,
      assignedTo: this.props.orderInfo.assigned_to,
      nameOnOrder: this.props.orderInfo.company_name,
      isStaff: this.props.is_staff,
      status: this.props.orderInfo.status,
      companyToAssign: '',
      companyIdToAssign: 0,
      resourceToAssign: 0,
      reportId: this.props.orderInfo.report,
      report: '',
      buttonLabel: 'Assign Resource'
    }
    if (this.state.status === "PENDING"){
      this.state.buttonLabel = " Accept & Assign"
    }
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.inquiryService = new InquiryService();
    this.companyService.getClients().then(data => {
      this.setState({
        availableCompanies: data
      })
    })
    this.userService.getUsers().then(data => {
      this.setState({
        availableResources: data
      })
    });
    this.renderResourceSubHeader = this.renderResourceSubHeader.bind(this);
    this.renderCompanySubHeader = this.renderCompanySubHeader.bind(this);
    this.companyNameBodyTemplate = this.companyNameBodyTemplate.bind(this);
    this.renderAvailableCompanies = this.renderAvailableCompanies.bind(this);
    this.handleClickOnCompany = this.handleClickOnCompany.bind(this);
    this.handleAssignedResourceChange = this.handleAssignedResourceChange.bind(this);
    this.assignOrder = this.assignOrder.bind(this);
    this.assignCompanyToOrder = this.assignCompanyToOrder.bind(this);
    this.getReport = this.getReport.bind(this);
    this.renderGoToReport = this.renderGoToReport.bind(this);
    this.goToReport = this.goToReport.bind(this);
  }

  getReport(){
    this.inquiryService.getReport(this.state.reportId).then(data=>{
      let report = data[0]

      this.inquiryService.getCompany(report.company).then(data=>{
        report.company = data[0]
        this.setState({report:report})
      })
      .catch(err=>{
        console.log(err)
        console.log(err.response.data)
      })

    }).catch(err=>{
      console.log(err)
      console.log(err.response.data)
    })
  }
  componentDidMount() {
    this.getReport()
    console.log(this.state.report)
  }

  assignCompanyToOrder(){
    if (this.state.companyIdToAssign == 0){
      alert("No Company has been selected !")
      return
    }
    this.inquiryService.assignOrderCompany(this.state.orderId, this.state.companyIdToAssign)
    .then(data => {
      alert("Order Has Been Assigned Succesfully !")
    }).catch(err => {
      console.log(err)
    })
  }

  assignOrder(){
    if (this.state.resourceToAssign == 0){
      alert("No Resource has been selected !")
      return
    }
    if (this.state.status === "PENDING"){
      // accept order
      this.inquiryService.acceptOrder(this.state.orderId).then(data =>{
        alert("Order Has Been Accepted and a report has been generated")
        this.inquiryService.assignOrderResource(this.state.orderId, this.state.resourceToAssign)
          .then(data => {
            alert("Order Has Been Assigned Succesfully !")
          }).catch(err=>{
            alert(err.response.data.detail)
            return
          })
          this.state.availableResources.map((resource)=>{
            if(resource.id == this.state.resourceToAssign){
              this.setState({assignedTo:resource})
          }})

      }).catch(err => {
        console.log(err.response.data.detail)
        alert(err.response.data.detail)
      })
    } else{
      this.inquiryService.assignOrderResource(this.state.orderId, this.state.resourceToAssign)
        .then(data => {
          alert("Order Has Been Assigned Succesfully !")
        }).catch(err=>{
          alert(err.response.data.detail)
          return
        })
        this.state.availableResources.map((resource)=>{
          if(resource.id == this.state.resourceToAssign){
            this.setState({assignedTo:resource})
        }})
    }
}

  renderResourceSubHeader(){
    if(this.state.assignedTo){
      return <h5>Already Assigned to : {this.state.assignedTo.username}</h5>
    } else {
      return <h5>No Resource Assigned</h5>
    }
  }

  renderHeader(){
    return(<span></span>)
  }

  companyNameBodyTemplate(rowData){
    let company_id = rowData.report;
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a id={company_id} href="#" onClick={() => {this.handleClickOnCompany(rowData)}} >{rowData.legal_name}</a></span>
        </React.Fragment>
    );
  }

  handleClickOnCompany(rowData){
    this.setState({companyToAssign:rowData.legal_name, companyIdToAssign:rowData.id})
  }

  handleAddCompanyTab(e){
    this.props.addCompanyTab();
    e.preventDefault();
  }
  renderAvailableCompanies(){
    const header = this.renderHeader();
    return(
      <div className="condiv">
        <div className="col-sm-9" style={{'margin': '5px'}}>
          <Button onClick={(e) => this.handleAddCompanyTab(e)} label="New Company" className="p-button-sm" icon="pi pi-plus"/>
        </div>
        <div className="col-12" id="dataTable">
          <DataTable ref={(el) => this.dt = el}
              value={this.state.availableCompanies}
              header={header}
              className="p-datatable-striped"
              dataKey="id"
              rowHover
              //  selection={this.state.selectedName}
              // onSelectionChange={e => this.setState({selectedName: e.value})}
              paginator rows={10}
              emptyMessage="No orders found"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[10,25,50]}>
              <Column sortField="legal_name" filterField="legal_name" field="legal_name" header="Legal Name" body={this.companyNameBodyTemplate} sortable filter filterPlaceholder="Search by name"/>
          </DataTable>
        </div>
      </div>
    )
  }

  renderCompanySubHeader(){
    if(this.state.status === "PENDING"){
      return <h5>No Company Assigned</h5>
    } else{
      if(this.state.report){
        return <h5>Already assigned to : {this.state.report.company.legal_name} </h5>
      }

    }
  }
  handleAssignedResourceChange(e){
    this.setState({resourceToAssign:e.target.value})
  }

  goToReport(e){
    this.props.goToReport(this.props.orderInfo);
    e.preventDefault();
  }

  renderGoToReport(){
    if(this.state.report){
      return(
        <Button onClick={this.goToReport} label="Go To Report" className="p-button-sm" icon="pi pi-external-link"/>
      )
    }
  }
  render() {

    return (
      <div className = "row">
        <div className = "col-6">
          <h2>Companies List</h2>
          <h5>Name On Order : {this.state.nameOnOrder}</h5>
          click on a company to assign it to this order
          {this.renderAvailableCompanies()}
        </div>
        <div className = "col-6">
        <br></br>
        <br></br>
          <h2>Assign Resource</h2>
          {this.renderResourceSubHeader()}
          <div className="col-sm-9">
            <select onChange={this.handleAssignedResourceChange} id="experience-options" name="experienceOptions">
            <option key={0} value={0}> </option>
            {

              this.state.availableResources.filter(resource => resource.is_staff).map((resource, i)=>(
                <option key={i} value = {resource.id}>{resource.username} </option>
              ))
            }
            </select>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h2>Assign Company</h2>
          {this.renderCompanySubHeader()}
          <input type="text" name="assign_to_company" id="assign_to_company" value={this.state.companyToAssign} />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className="col-sm-9" style={{'margin': '5px'}}>
            <Button onClick={this.assignOrder} label={this.state.buttonLabel} className="p-button-sm" icon="pi pi-check"/>
            <Button onClick={this.assignCompanyToOrder} label="Assign Company" className="p-button-sm" icon="pi pi-check"/>
            {this.renderGoToReport()}
          </div>
        </div>
      </div>
    )
  }
}

export default OrderAssign
