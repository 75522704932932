import React, { Component } from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import InquiryService from '../service/InquiryService';
import {formatDate} from '../components/Utils';
import {getWeekRange} from '../components/Utils';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

class StatisticsDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      by_clients : {},
      by_staffs : {},
      start_delivery_date: null,
      end_delivery_date: null
    };

    this.filterStatisticsClick = this.filterStatisticsClick.bind(this);
    this.filterStatistics = this.filterStatistics.bind(this);
    this.dateFilterChange = this.dateFilterChange.bind(this);
    this.inquiryService = new InquiryService();

}

componentDidMount(){
  let week_range = getWeekRange(new Date());
  let monday =  week_range.monday.split("-")
  let start_delivery_date = new Date(monday[0], monday[1] - 1, monday[2]);
  let friday = week_range.friday.split("-");
  let end_delivery_date = new Date(friday[0], friday[1] - 1, friday[2]);
  this.setState({start_delivery_date : start_delivery_date, end_delivery_date: end_delivery_date}, function(){
    this.filterStatistics(this.state.start_delivery_date, this.state.end_delivery_date);
  });

}
filterStatisticsClick(){
   this.filterStatistics(this.state.start_delivery_date,this.state.end_delivery_date);
}
filterStatistics(start_delivery_date,end_delivery_date){
  this.props.setLoading(true)
  this.inquiryService.getStatisticsDetails({start_delivery_date : formatDate(start_delivery_date), end_delivery_date: formatDate(end_delivery_date)}).then(data =>{
    this.setState({by_staffs : data.by_staffs})
    this.setState({by_clients : data.by_clients})
    this.props.setLoading(false)
  });
}
dateFilterChange(field,event){
    if(field == 'start_delivery_date')
      this.setState({ start_delivery_date : formatDate(event.target.value)});
    else
      this.setState({ end_delivery_date : formatDate(event.target.value)});
}
getStats(statValue){
  if (!statValue){
    return 0
  }
  return statValue
}

getLateReportsPercentage(stats){
  if (this.getStats(stats.completed) == 0){
    return '-'
  }
  return parseFloat(( this.getStats(stats.late) / this.getStats(stats.completed)) *100).toFixed(2) + ' %'
}

getTotals(staff){
  let totals = {}
  totals["total_completed"] = 0
  totals["total_in_advance"] = 0
  totals["total_on_time"] = 0
  totals["total_late"] = 0
  totals["total_late_percentage"] = 0
  totals["total_unavailable"] = 0
  totals["total_partial"] = 0
  totals["total_full"] = 0
  if(staff){
    Object.keys(this.state.by_staffs).map(key => {
      let stats = this.state.by_staffs[key]
      totals["total_completed"] += this.getStats(stats.completed)
      totals["total_in_advance"] += this.getStats(stats.in_advance)
      totals["total_on_time"] += this.getStats(stats.on_time)
      totals["total_late"] += this.getStats(stats.late)
      totals["total_unavailable"] += this.getStats(stats.unavailable)
      totals["total_partial"] += this.getStats(stats.partial)
      totals["total_full"] += this.getStats(stats.full)
    })
  } else {
    Object.keys(this.state.by_clients).map(key => {
      let stats = this.state.by_clients[key]
      totals["total_completed"] += this.getStats(stats.completed)
      totals["total_in_advance"] += this.getStats(stats.in_advance)
      totals["total_on_time"] += this.getStats(stats.on_time)
      totals["total_late"] += this.getStats(stats.late)
      totals["total_unavailable"] += this.getStats(stats.unavailable)
      totals["total_partial"] += this.getStats(stats.partial)
      totals["total_full"] += this.getStats(stats.full)
    })
  }
  if(totals["total_completed"] == 0){
    totals["total_late_percentage"] = "-"
  } else {
    totals["total_late_percentage"] = parseFloat((totals["total_late"] / totals["total_completed"])).toFixed(2) + " %"
  }


  return totals

}

render(){
    let client_totals = this.getTotals(false)
    let totals = this.getTotals(true)

    return (
      <div>
        <div className="condiv">
          <div className = "row">
            <div className="col-12">
                <TabView>
                      <TabPanel header="By Staff">

                      <table id="statTable" width="100%" border="1" align="center" cellSpacing="1" cellPadding="2">
                      <tbody>
                      <tr>
                          <th className="bold" style={{'borderBottom':'1px solid #ddd','padding':'15px'}}>Statistics: </th>
                          <th className="bold"  colSpan= "17">
                                Delivery Date From : <Calendar onChange={(e) => this.dateFilterChange("start_delivery_date", e)}    name="delivery_date" placeholder="date" type="text" value={this.state.start_delivery_date} class="form-control form-control-sm" showIcon/>  Delivery Date To : <Calendar  onChange={(e) => this.dateFilterChange("end_delivery_date", e)} name="delivery_date_to" placeholder="date" type="text" value={this.state.end_delivery_date} class="form-control form-control-sm" showIcon/>
                                <Button icon="pi pi-search" style={{'marginLeft':'10px'}} onClick={this.filterStatisticsClick}  className="p-button-square p-button-secondary" />
                            </th>
                      </tr>
                      <tr>
                          <td  width="15%" bgcolor="#ddd"></td>
                          <td  width="10%" bgcolor="#ddd" align="center"><strong>Reports</strong></td>
                          <td  width="30%"colSpan="4" bgcolor="#ddd" align="center"><strong>Punctuality</strong></td>
                          <td  width="30%"colSpan="3" bgcolor="#ddd" align="center"><strong>Financial Data</strong></td>

                      </tr>
                      <tr>
                          <td colSpan="1" bgcolor="#ddd"></td>
                          <td bgcolor="#ddd" align="center">Completed</td>
                          <td bgcolor="#ddd" align="center">In advance</td>
                          <td bgcolor="#ddd" align="center">On Time</td>
                          <td bgcolor="#ddd" align="center">Late</td>
                          <td bgcolor="#ddd" align="center">% late reports</td>
                          <td bgcolor="#ddd" align="center">Full FS</td>
                          <td bgcolor="#ddd" align="center">Partial Figures</td>
                          <td bgcolor="#ddd" align="center">Unavailable</td>

                      </tr>
                      {Object.keys(this.state.by_staffs).map((key,i) => {
                        let stats = this.state.by_staffs[key]
                        return (

                          <tr className={(i%2 == 0)? 'odd' : 'even'} key={key}>
                              <td style={{'borderBottom':'1px solid #ddd','padding':'5px'}}>{key}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.completed)}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.in_advance)}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.on_time)}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.late)}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getLateReportsPercentage(stats)}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.full)}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.partial)}</td>
                              <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.unavailable)}</td>
                          </tr>
                      )})
                      }
                      <tr className="even bold" style={{'borderTop': '1px solid #ddd'}}>
                            <td bgcolor="#ddd" style={{'borderTop': '1px solid','borderBottom':'1px solid #ddd','padding':'5px'}}>Total</td>
                            <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals.total_completed}</td>
                            <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals.total_in_advance}</td>
                            <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{totals.total_on_time}</td>
                            <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{totals.total_late}</td>
                            <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{totals.total_late_percentage}</td>
                            <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{totals.total_full}</td>
                            <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{totals.total_partial}</td>
                            <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{totals.total_unavailable}</td>
                      </tr>
                      </tbody>
                      </table>
                      </TabPanel>
                      <TabPanel header="By Client">          
                        <table id="statTable" width="100%" border="1" align="center" cellspacing="1" cellpadding="2">
                        <tbody>
                        <tr>
                            <th className="bold" style={{'borderBottom':'1px solid #ddd','padding':'15px'}}>Statistics: </th>
                            <th className="bold"  colSpan= "17">
                                  Delivery Date From : <Calendar onChange={(e) => this.dateFilterChange("start_delivery_date", e)}    name="delivery_date" placeholder="date" type="text" value={this.state.start_delivery_date} class="form-control form-control-sm" showIcon/>  Delivery Date To : <Calendar  onChange={(e) => this.dateFilterChange("end_delivery_date", e)} name="delivery_date_to" placeholder="date" type="text" value={this.state.end_delivery_date} class="form-control form-control-sm" showIcon/>
                                  <Button icon="pi pi-search" style={{'marginLeft':'10px'}} onClick={this.filterStatisticsClick}  className="p-button-square p-button-secondary" />
                              </th>
                        </tr>
                        <tr>
                            <td  width="15%" bgcolor="#ddd"></td>
                            <td  width="10%" bgcolor="#ddd" align="center"><strong>Reports</strong></td>
                            <td  width="30%"colSpan="4" bgcolor="#ddd" align="center"><strong>Punctuality</strong></td>
                            <td  width="30%"colSpan="3" bgcolor="#ddd" align="center"><strong>Financial Data</strong></td>

                        </tr>
                        <tr>
                            <td colSpan="1" bgcolor="#ddd"></td>
                            <td bgcolor="#ddd" align="center">Completed</td>
                            <td bgcolor="#ddd" align="center">In advance</td>
                            <td bgcolor="#ddd" align="center">On Time</td>
                            <td bgcolor="#ddd" align="center">Late</td>
                            <td bgcolor="#ddd" align="center">% late reports</td>
                            <td bgcolor="#ddd" align="center">Full FS</td>
                            <td bgcolor="#ddd" align="center">Partial Figures</td>
                            <td bgcolor="#ddd" align="center">Unavailable</td>

                        </tr>
                        {Object.keys(this.state.by_clients).map((key,i) => {
                          let stats = this.state.by_clients[key]
                          return (

                            <tr className={(i%2 == 0)? 'odd' : 'even'} key={key}>
                                <td style={{'borderBottom':'1px solid #ddd','padding':'5px'}}>{key}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.completed)}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.in_advance)}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.on_time)}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.late)}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getLateReportsPercentage(stats)}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.full)}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.partial)}</td>
                                <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.unavailable)}</td>
                            </tr>
                        )})
                        }

                          <tr className="even bold" style={{'borderTop': '1px solid #ddd'}}>
                                <td bgcolor="#ddd" style={{'borderTop': '1px solid','borderBottom':'1px solid #ddd','padding':'5px'}}>Total</td>
                                <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_completed}</td>
                                <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_in_advance}</td>
                                <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_on_time}</td>
                                <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_late}</td>
                                <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_late_percentage}</td>
                                <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_full}</td>
                                <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_partial}</td>
                                <td align='center'style={{'borderBottom':'1px solid #ddd'}}>{client_totals.total_unavailable}</td>
                          </tr>
                          </tbody>
                        </table>
                      </TabPanel>
                </TabView>
            </div>
          </div>
        </div>
      </div>
   )
  }
}

export default StatisticsDetails
