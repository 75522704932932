import React, { Component } from 'react';
import InquiryService from '../service/InquiryService';
import UserService from '../service/UserService';
import CompanyService from '../service/CompanyService';
import { MultiSelect } from 'primereact/multiselect';
import { Translation } from 'react-i18next';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

class OrderSearch extends Component {

  constructor(props){
    super(props)
    let ses_token = JSON.parse(sessionStorage.getItem("token"));
    this.state = {
      users: [],
      countries: [],
      clients: [],
      suppliers: [],
      delays: [],
      statuses: [],
      groups: [],
      searchloading: false,
      toast: this.props.toast,
      selectedCountries: [],
      selectedAnalysts: [],
      selectedClients: [],
      selectedSuppliers: [],
      selectedStatuses: [],
      selectedDelays: [],
      selectedGroup: null,
      qsMyReports: false,
      qsPriorities: false,
      qsLateReports: false,
      showQuickSearch: this.props.show_quick_search ? this.props.show_quick_search : false,
      selectedInvoiceGroups: [],
      is_admin: ses_token.user.group === 'Admin',
      searchObject: {},
      detailedSearchField: "Company Name",
      exportLoading: false
    }
    this.inquiryService = new InquiryService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.updateDetailedSearchFilter = this.updateDetailedSearchFilter.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.loadLazyData = this.loadLazyData.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.validateSearch = this.validateSearch.bind(this);
    this.companyService.getCustomersSummary().then(data =>{

      data.sort((a, b) => {
        const nameA = a.legal_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.legal_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      
      this.setState({
        clients: data
      })
    })

    this.companyService.getSuppliers().then(data =>{
      data.sort((a, b) => {
        const nameA = a.supplier_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.supplier_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      this.setState({
        suppliers: data
      })
    })



    
    this.userService.getCCPCompanyGroups().then(data =>{
      this.setState({
        groups: data
      })
    });

    this.userService.getStaffSummary().then(data => {
      let admin = data.filter(user => user.user == null)
      let staff = data.filter(user=> user.user != null).filter(user=> user.user['user_deleted'] == 0)
      
      let admin_staff = admin.concat(staff)
      let users_staff = []
      admin_staff.forEach(user => {
        users_staff.push({'id' : user.id , 'username' : user.username})
      });

      users_staff.sort((a, b) => {
        const nameA = a.username.toUpperCase(); // ignore upper and lowercase
        const nameB = b.username.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      this.setState({
        users: users_staff
      })
    }); 
    
    this.inquiryService.getOrderStatusList().then(data =>{
      data.map(field => {
        this.state.statuses.push({label: field[1], value: field[0]})
      })
    })

    const cachedState = sessionStorage.getItem('ses_report_search');

    if (cachedState === null) {
        // banks not set, so we set them from the state of the component
        this.dataRefresh();
    } else {
      let cachedStateJson = JSON.parse(cachedState)
      this.state.selectedAnalysts = cachedStateJson.selectedAnalysts
      this.state.selectedCountries = cachedStateJson.selectedCountries
      this.state.selectedClients = cachedStateJson.selectedClients
      this.state.selectedSuppliers = cachedStateJson.selectedSuppliers
      this.state.selectedStatuses = cachedStateJson.selectedStatuses
      this.state.selectedDelays = cachedStateJson.selectedDelays
      this.state.selectedGroup = cachedStateJson.selectedGroup
      this.state.qsMyReports = cachedStateJson.qsMyReports
      this.state.qsPriorities = cachedStateJson.qsPriorities
      this.state.qsLateReports = cachedStateJson.qsLateReports
      this.state.detailedSearchField = cachedStateJson.detailedSearchField
      this.state.detailedSearchValue = cachedStateJson.detailedSearchValue
    }
  }
  componentDidMount() {
      this.inquiryService.getCountries().then(data =>{
        this.setState({countries:data})
      });
      this.inquiryService.getDelays().then(data =>{
        this.setState({ delays: data})
      })
  }
  dataRefresh(){
    let obj = {}
    obj.selectedCountries = this.state.selectedCountries
    obj.selectedAnalysts = this.state.selectedAnalysts
    obj.selectedClients = this.state.selectedClients
    obj.selectedSuppliers = this.state.selectedSuppliers
    obj.selectedStatuses = this.state.selectedStatuses
    obj.selectedDelays = this.state.selectedDelays
    obj.selectedGroup = this.state.selectedGroup
    obj.qsMyReports = this.state.qsMyReports
    obj.qsPriorities = this.state.qsPriorities
    obj.qsLateReports = this.state.qsLateReports
    obj.detailedSearchValue = this.state.detailedSearchValue
    obj.detailedSearchField = this.state.detailedSearchField
    sessionStorage.setItem('ses_report_search', JSON.stringify(obj));
  }

  loadLazyData(lazyParams){
    this.setState({searchloading: true})
    let search = this.state.searchObject;
    search["lazy"] = {first: lazyParams.first, rows: lazyParams.rows, sortField: lazyParams.sortField, sortOrder: lazyParams.sortOrder}
    this.inquiryService.submitSearch(search).then(data => {
      this.props.toast.current.show({severity:'info', summary:'Loaded !', detail: "Page loaded"});
      this.setState({inquiries: data.orders, searchloading: false}, function(){
        this.props.readResults(this.state.inquiries, data.total_records)
      })
    }).catch(err =>{
      this.props.toast.current.show({severity:'error', summary:'Filter Required !', detail: JSON.stringify(err.response.data)});
      this.setState({searchloading: false})
    })
  }


  handleCancel(){
    let ses_token = JSON.parse(sessionStorage.getItem('token'));
    this.setState({
      selectedCountries: [],
      selectedAnalysts: [],
      selectedClients: [],
      selectedSuppliers: [],
      selectedStatuses: [],
      selectedDelays: [],
      selectedInvoiceGroups: [],
      qsMyReports: false,
      qsPriorities: false,
      qsLateReports: false,
      detailedSearchField: "Company Name",
      detailedSearchValue: null,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      dueDateTo: null,
      dueDateFrom: null,
      orderDateFrom: null,
      orderDateTo: null,
      selectedGroup: null
    }, function(){
      this.dataRefresh();
    })
  }

  XOR(a,b){
    // xor function
    return (a || b) && !(a && b);
  }

  validateSearch(){
    return (this.state.selectedClients.length == 0 && this.state.selectedCountries.length == 0 && this.state.selectedAnalysts.length == 0
        && this.state.selectedSuppliers.length == 0 && this.state.selectedStatuses.length == 0 && this.state.selectedDelays.length == 0
        && this.state.selectedInvoiceGroups.length == 0
        && this.state.dueDateFrom == null && this.state.dueDateTo == null && this.state.orderDateFrom == null
        && this.state.orderDateTo == null && this.state.deliveryDateFrom == null && this.state.deliveryDateTo == null
        && (this.state.detailedSearchField == null || this.state.detailedSearchValue == null)
        && this.state.selectedGroup == null && this.state.qsMyReports == false && this.state.qsLateReports == false
        && this.state.qsPriorities == false)
  }

  handleSubmitSearch(lazy){
    if (this.validateSearch()){
      this.props.toast.current.show({severity:'error', summary:'Filter Required !', detail: "At Least one Filter needs to be set."});
      return;
    }
    if (this.XOR(this.state.dueDateFrom , this.state.dueDateTo )){
      this.props.toast.current.show({severity:'error', summary:'Filter Required !', detail: "Due Date Filter Not Set Correctly."});
      return;
    }
    if (this.XOR(this.state.orderDateFrom , this.state.orderDateTo )){
      this.props.toast.current.show({severity:'error', summary:'Filter Required !', detail: "Order Date Filter Not Set Correctly."});
      return;
    }
    if (this.XOR(this.state.deliveryDateFrom , this.state.deliveryDateTo )){
      this.props.toast.current.show({severity:'error', summary:'Filter Required !', detail: "Delivery Date Filter Not Set Correctly."});
      return;
    }
    let search = {}
    search["statuses"] = this.state.selectedStatuses
    search["delays"] = this.state.selectedDelays
    search["countries"] = this.state.selectedCountries
    search["analysts"] = this.state.selectedAnalysts
    search["suppliers"] = this.state.selectedSuppliers
    search["clients"] = this.state.selectedClients
    search["invoiceGroups"] = this.state.selectedInvoiceGroups
    if (this.state.detailedSearchField && this.state.detailedSearchValue){
      search["search"] = {field: this.state.detailedSearchField, value: this.state.detailedSearchValue}
    }
    if (this.state.dueDateFrom && this.state.dueDateTo){
      search["due_dates"] = {from: this.state.dueDateFrom, to : this.state.dueDateTo}
    }

    if (this.state.orderDateFrom && this.state.orderDateTo){
      search["order_dates"] = {from: this.state.orderDateFrom, to : this.state.orderDateTo}
    }

    if (this.state.deliveryDateFrom && this.state.deliveryDateTo){
      search["delivery_dates"] = {from: this.state.deliveryDateFrom, to : this.state.deliveryDateTo}
    }

    if(this.state.showQuickSearch){
      let quickSearch = {}
      quickSearch["group"] = this.state.selectedGroup
      quickSearch["priorities"] = this.state.qsPriorities
      quickSearch["my_reports"] = this.state.qsMyReports
      quickSearch["late_reports"] = this.state.qsLateReports

      search["quick_search"] = quickSearch
    }

    search["lazy"] = lazy

    this.setState({searchloading: true, searchObject: search})
    let state = this.state;
    this.inquiryService.submitSearch(search).then(data => {
      this.state.toast.current.show({severity:'success', summary:'Loaded !', detail: "Number of loaded inquiries : " + data.total_records});
      this.setState({inquiries: data.orders, searchloading: false}, function(){
        this.props.readResults(this.state.inquiries, data.total_records)
      })
    }).catch(err =>{
      if(err.response?.data){
        this.state.toast.current.show({severity:'error', summary:'Filter Required !', detail: JSON.stringify(err.response.data)});
        this.setState({searchloading: false})
      }
    })
  }

  exportCSV(){
    if(this.validateSearch()){
      this.props.toast.current.show({severity:'error', summary:'Filter Required !', detail: "At Least one Filter needs to be set."});
      return;
    }
    this.setState({exportLoading: true})
    let search = {}
    search["statuses"] = this.state.selectedStatuses
    search["delays"] = this.state.selectedDelays
    search["countries"] = this.state.selectedCountries
    search["analysts"] = this.state.selectedAnalysts
    search["suppliers"] = this.state.selectedSuppliers
    search["clients"] = this.state.selectedClients
    search["invoiceGroups"] = this.state.selectedInvoiceGroups
    if (this.state.detailedSearchField && this.state.detailedSearchValue){
      search["search"] = {field: this.state.detailedSearchField, value: this.state.detailedSearchValue}
    }
    if (this.state.dueDateFrom && this.state.dueDateTo){
      search["due_dates"] = {from: this.state.dueDateFrom, to : this.state.dueDateTo}
    }

    if (this.state.orderDateFrom && this.state.orderDateTo){
      search["order_dates"] = {from: this.state.orderDateFrom, to : this.state.orderDateTo}
    }

    if (this.state.deliveryDateFrom && this.state.deliveryDateTo){
      search["delivery_dates"] = {from: this.state.deliveryDateFrom, to : this.state.deliveryDateTo}
    }

    if(this.state.showQuickSearch){
      let quickSearch = {}
      quickSearch["group"] = this.state.selectedGroup
      quickSearch["priorities"] = this.state.qsPriorities
      quickSearch["my_reports"] = this.state.qsMyReports
      quickSearch["late_reports"] = this.state.qsLateReports

      search["quick_search"] = quickSearch
    }

    this.inquiryService.submitCsvExport(search).then(response => {
      const blob = new Blob([response], { type: 'text/plain' })
      // Create an anchor element and dispatch a click event on it
      // to trigger a download
      const a = document.createElement('a')
      a.download = 'orders_Export.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
      this.setState({exportLoading: false})
      this.props.toast.current.show({severity:'info', summary:'Loaded !', detail: "Data loaded"});
    }).catch(err => {
      console.log(err);
      this.props.toast.current.show({severity:'error', summary:'Filter Required !', detail: "Something Wrong Happened!"});
      this.setState({exportLoading: false})
    })
  }

  updateDetailedSearchFilter(field, event){
    let state = this.state
    state[field] = event.target.value
    this.setState(state, function(){
      this.dataRefresh();
    })
  }

  renderQuickSearch(){
      return(
          <div className="component-box dark-blue-bg">
              <div className="row"> <div className="col-sm box-title">Quick <Translation>{ t => <span>{t('SEARCH')} </span>}</Translation></div></div>
              <div className="row">
                  <div className="col-sm">{<Translation>{ t => <span>{t('MYREPORTS')} </span>}</Translation>}</div>
                  <div className="col-sm"><input checked = {this.state.qsMyReports} onChange={() => {this.setState({qsMyReports: !this.state.qsMyReports})}} type="checkbox" name=""/></div>
              </div>
              <div className="row">
                  <div className="col-sm">{<Translation>{ t => <span>{t('PRIORITIES')} </span>}</Translation>} </div>
                  <div className="col-sm"><input checked = {this.state.qsPriorities} onChange={() => {this.setState({qsPriorities: !this.state.qsPriorities})}} type="checkbox" name=""/></div>
              </div>
              <div className="row">
                  <div className="col-sm">{<Translation>{ t => <span>{t('LATEREPORTS')} </span>}</Translation>}</div>
                  <div className="col-sm"><input checked = {this.state.qsLateReports} onChange={() => {this.setState({qsLateReports: !this.state.qsLateReports})}} type="checkbox" name=""/></div>
              </div>
              <div className="row">
                <div className="col-sm">
                    <MultiSelect value={this.state.selectedGroup} options={this.state.groups} optionLabel="name"
                          placeholder="Select a Group" filter onChange = {(e) => this.updateDetailedSearchFilter("selectedGroup", e)} />
                </div>
              </div>
          </div>
      )
  }

  renderDetailedSearch(){
    return(
      <div>
        <div className="component-box light-blue-bg">
          <div className="row">
            <div className="col-sm box-title">
              {<Translation>{ t => <span>{t('SEARCH')} </span>}</Translation>}
            </div>
          </div>
          <div className="row" id="searchFields">
            <div className="col-sm-2">
              <div className = "row">
                <input type = "text" value = {this.state.detailedSearchValue || ''} className="form-control form-control-sm"
                    onChange = {(e) => {this.setState({detailedSearchValue: e.target.value})}}
                    style = {{'width': '75%','height': '30px'}}/>
              </div>
              <div className = "row">
                <Dropdown value = {this.state.detailedSearchField} options =  {['Company Name', 'Cristal ID', 'Client Reference', 'Registration ID', 'Officer']}
                    onChange = {(e) => {this.setState({detailedSearchField: e.target.value})}}
                    placeholder = "Select a search field" style = {{'width': '75%'}} showClear  />
              </div>
              <div className = "row">
                <MultiSelect value={this.state.selectedCountries} options={this.state.countries} optionLabel="name"
                    placeholder="Select Countries" filter="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedCountries", e)} />
              </div>
            </div>
            <div style = {{'borderLeft': '2px solid #C0C0C0'}}></div>
            <div className="col-sm-2">
              <div className = "row">
                <MultiSelect value={this.state.selectedAnalysts} options={this.state.users} optionLabel="username"
                    placeholder="Select Analysts" filter="true" selectAll="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedAnalysts", e)}/>
              </div>
              <div className = "row">
                <MultiSelect value={this.state.selectedClients} options={this.state.clients} optionLabel="legal_name"
                    placeholder="Select Clients" filter="true" selectAll="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedClients", e)} />
              </div>
              <div className = "row">
                <MultiSelect value={this.state.selectedSuppliers} options={this.state.suppliers} optionLabel="supplier_name"
                    placeholder="Select Suppliers" filter="true" selectAll="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedSuppliers", e)} />
              </div>
            </div>
            <div style = {{'borderLeft': '2px solid #C0C0C0'}}></div>
            <div className="col-sm-2">
              <div className = "row">
                <MultiSelect value={this.state.selectedStatuses} options={this.state.statuses} onChange = {(e) => this.updateDetailedSearchFilter("selectedStatuses", e)}
                      placeholder="Select Stasuses" filter="true" optionlabel = "supplier_name" selectAll="true"/>
              </div>
              <div className = "row">
                <MultiSelect value={this.state.selectedDelays} options={this.state.delays} optionLabel="name"
                    placeholder="Select Delays" filter="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedDelays", e)} />
              </div>
              <div className = "row" hidden={!this.state.is_admin}>
                <div className = "col-12">
                  <div className = "row">
                    <label>Invoice Search</label>
                  </div>
                  <div className = "row">
                    <MultiSelect value={this.state.selectedInvoiceGroups} options={this.state.groups} optionLabel="name"
                        placeholder="Select Groups" filter="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedInvoiceGroups", e)} />
                  </div>
                </div>

              </div>
            </div>
            <div style = {{'borderLeft': '2px solid #C0C0C0'}}></div>
            <div className="col-md-5">
              <div className="row">
                  <div className="col-md-4">
                      <span>{<Translation>{ t => <span>{t('ORDEREDFROM')} </span>}</Translation>}</span>
                  </div>
                  <div className="col-md-8">
                      <div className="row">
                          <div className="col-md-12">
                               <div className="row">
                                  <div className="col-5">
                                          <Calendar id="icon" value={this.state.orderDateFrom} onChange={(e) => this.setState({ orderDateFrom: e.value })}
                                              showIcon monthNavigator showButtonBar yearNavigator yearRange="1900:2030"/>
                                  </div>
                                  <div className="col-0">to</div>
                                  <div className="col-5">
                                          <Calendar id="icon" value={this.state.orderDateTo} onChange={(e) => this.setState({ orderDateTo: e.value })}
                                              showIcon monthNavigator  showButtonBar yearNavigator yearRange="1900:2030"/>
                                      </div>
                                  </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-4">
                      <span>{<Translation>{ t => <span>{t('DUEDATEFROM')} </span>}</Translation>}</span>
                  </div>
                  <div className="col-md-8">
                      <div className="row">
                          <div className="col-md-12">
                               <div className="row">
                                  <div className="col-5">
                                          <Calendar id="icon" value={this.state.dueDateFrom} onChange={(e) => this.setState({ dueDateFrom: e.value })} showIcon monthNavigator showButtonBar />
                                  </div>
                                  <div className="col-0">to</div>
                                  <div className="col-5">
                                          <Calendar id="icon" value={this.state.dueDateTo} onChange={(e) => this.setState({ dueDateTo: e.value })} showIcon monthNavigator showButtonBar/>
                                      </div>
                                  </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-4">
                      <span>{<Translation>{ t => <span>{t('DELIVERYDATEFROM')} </span>}</Translation>}</span>
                  </div>
                  <div className="col-md-8">
                      <div className="row">
                          <div className="col-md-12">
                               <div className="row">
                                  <div className="col-5">
                                          <Calendar id="icon" value={this.state.deliveryDateFrom} onChange={(e) => this.setState({ deliveryDateFrom: e.value })} showIcon monthNavigator showButtonBar/>
                                  </div>
                                  <div className="col-0">to</div>
                                  <div className="col-5">
                                          <Calendar id="icon" value={this.state.deliveryDateTo} onChange={(e) => this.setState({ deliveryDateTo: e.value })} showIcon monthNavigator showButtonBar/>
                                      </div>
                                  </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className = "form-hr"> </div>
        <div className="light-blue-bg">
          <div className = "row">
            <div className="col-sm-1">
                <Button loading = {this.state.searchloading} id="search-btn" label={<Translation>{ t => <span>{t('SUBMIT')} </span>}</Translation>}
                      icon="pi pi-search" onClick = {() => this.handleSubmitSearch({first: 0, rows: 50})} />
            </div>
            <div className="col-sm-2">
                <Button id="cancel-btn" label={<Translation>{ t => <span>{t('CLEAR_ALL')} </span>}</Translation>}
                      icon="pi pi-times" onClick = {this.handleCancel} />
            </div>
            <div className="col-md-2" style = {{'borderLeft': '2px solid #FFF'}}></div>
            <div className="col-sm-5">
            </div>
            <div hidden = {!this.state.is_admin} className="col-md-0" style = {{'borderLeft': '2px solid #FFF'}}>
                <Button id="cancel-btn" label={<Translation>{ t => <span>{t('EXPORT CSV')} </span>}</Translation>}
                      icon="pi pi-file" onClick = {(e) => this.exportCSV()} loading = {this.state.exportLoading} />
            </div>
          </div>
        </div>
        </div>
      )
  }


  render(){
    const quickSearch = this.renderQuickSearch();
    const  detailedSearch = this.renderDetailedSearch();
    if(this.state.showQuickSearch){
      return (
        <div className="col-12">
            <Toast ref={this.state.toast} />
            <div className="row">
                <div className="col-2" id="container-quicksearch">
                    {quickSearch}
                </div>
                <div className="col-10" id="container-detailedSearch">
                    {detailedSearch}
                </div>
            </div>
        </div>
      )
    } else {
      return (

        <div className="col-sm-12" style={{'margin': '5px'}}>
          <Toast ref={this.state.toast} />
          {detailedSearch}
        </div>
      )
    }

  }
}

export default OrderSearch
