import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
//import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import InquiriesDetailsFinancialsBanks from './InquiriesDetailsFinancialsBanks.js'
import InquiriesDetailsFinancialsInformation from './InquiriesDetailsFinancialsInformation.js'
import InquiriesDetailsFinancialsLegalRecords from './InquiriesDetailsFinancialsLegalRecords.js'
import InquiriesDetailsFinancialsPressReleases from './InquiriesDetailsFinancialsPressReleases.js'

import { Translation } from 'react-i18next';
import SourcesComponent from '../components/SourcesComponent';
class InquiriesDetailsFinancials extends Component {
    constructor(props){
        super(props);
        this.state = {
           activeIndex : null,
           financials: this.props.report.financials,
           banksInfo : this.props.report.banks_info,
           legalRecords: this.props.report.legal_fillings,
           press: this.props.report.press,
           toast: this.props.toast
        }
        this.handleUpdateBanksInfo = this.handleUpdateBanksInfo.bind(this);

    }
    componentDidMount(){
      // banks
      const banksInfo = sessionStorage.getItem('ses_banks_info_' + this.props.report.id);
      if (banksInfo === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_banks_info_' + this.props.report.id, JSON.stringify(this.state.banksInfo));
      } else{
          this.setState({banks_info : JSON.parse(banksInfo)})
      }

      // financials
      const financials = sessionStorage.getItem('ses_financials_' + this.props.report.id);
      if (financials === null) {
          // financials not set, so we set them from the state of the component
          sessionStorage.setItem('ses_financials_' + this.props.report.id, JSON.stringify(this.state.financials));
      } else{
          this.setState({financials : JSON.parse(financials)})
      }
      // legal records
      const legalRecords = sessionStorage.getItem('ses_legal_records_' + this.props.report.id);
      if (legalRecords === null) {
          // financials not set, so we set them from the state of the component
          sessionStorage.setItem('ses_legal_records_' + this.props.report.id, JSON.stringify(this.state.legalRecords));
      } else{
          this.setState({legalRecords : JSON.parse(legalRecords)})
      }
      // press releases
      const pressReleases = sessionStorage.getItem('ses_press_' + this.props.report.id);
      if (pressReleases === null) {
          // financials not set, so we set them from the state of the component
          sessionStorage.setItem('ses_press_' + this.props.report.id, JSON.stringify(this.state.press));
      } else{
          this.setState({press : JSON.parse(pressReleases)})
      }
     }

     dataRefresh(){
       const banksInfo = sessionStorage.getItem('ses_banks_info_' + this.props.report.id);
       const banksInfoJSON = JSON.parse(banksInfo)
       var newBanksInfo = this.state.banksInfo;
       newBanksInfo.banks = banksInfoJSON.banks
       sessionStorage.setItem('ses_banks_info_' + this.props.report.id, JSON.stringify(newBanksInfo));
     }

     handleUpdateBanksInfo(field, event){
       let banksInfo = this.state.banksInfo;
       banksInfo[field] = event.target.value;
       this.setState({banksInfo: banksInfo}, function(){
         this.dataRefresh();
       })
     }


    render(){

        return(
          <div>
            <Toast ref={this.state.toast} />
            <Accordion multiple activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
              <AccordionTab header={<Translation>{ t => <span>{t('BANKS')} </span>}</Translation>}>
                <InquiriesDetailsFinancialsBanks banks = {this.state.banksInfo.banks} report_id = {this.props.report.id} toast={this.state.toast} />

                <br></br>
                <div className="form-hr"/>
                <br></br>

                <div className = "row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-9">
                      <textarea className="form-control form-text-comments" onChange={(e) => this.handleUpdateBanksInfo("comments", e)} name="banks-info-comments" rows="3" value={this.state.banksInfo.comments}></textarea>
                  </div>
                </div>
                <br></br>
                <div className = "row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('SOURCES')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-9">
                        <SourcesComponent sources = {this.state.banksInfo.sources} update_date = {(e) => this.handleUpdateBanksInfo("sources", e)}/>
                  </div>
                </div>

              </AccordionTab>

              <AccordionTab header={<Translation>{ t => <span>{t('FINANCIAL_INFORMATION')} </span>}</Translation>}>
                <InquiriesDetailsFinancialsInformation financials = {this.state.financials} report_id = {this.props.report.id}
                    order_info = {this.props.order_info} toast = {this.state.toast}/>
              </AccordionTab>
              <AccordionTab header={<Translation>{ t => <span>{t('LEGAL_RECORDS')} </span>}</Translation>}>
                  <InquiriesDetailsFinancialsLegalRecords legal_records = {this.state.legalRecords} report_id = {this.props.report.id} />
              </AccordionTab>
              <AccordionTab header={<Translation>{ t => <span>{t('PRESS_RELEASES')} </span>}</Translation>}>
                  <InquiriesDetailsFinancialsPressReleases press_releases = {this.state.press?.press_releases} report_id = {this.props.report.id} toast = {this.state.toast} />
              </AccordionTab>
        </Accordion>
        </div>
        )
    }

}
export default InquiriesDetailsFinancials
