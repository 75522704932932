import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';
import { Translation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';

class CountryOverviews extends Component {
  constructor(props){
    super(props);
    this.state = {
      countryOverviews : [],
      loading: true,
      countries: [],
      selectedCountryOverview: {
        id: 0,
        country: {
          "name": "United States"
        }
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    }
    this.countryNameBodyTemaplte = this.countryNameBodyTemaplte.bind(this);
    this.handleCountryClick = this.handleCountryClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inquiryService = new InquiryService();
    this.inquiryService.getCountries().then(data =>{
      this.setState({countries:data})
    })
    this.inquiryService.getCountryOverviews().then(data =>{
      this.setState({
        countryOverviews: data, loading: false
      })
    })
  }

  handleSaveClick(){
    if(this.state.selectedCountryOverview.id == 0){
      this.inquiryService.createCountryOverview(this.state.selectedCountryOverview).then(data => {
        let countryOverviews = this.state.countryOverviews
        countryOverviews.push(data)
        this.setState({countryOverviews:countryOverviews,selectedCountryOverview: data,button:{label:'Save',icon: 'pi pi-check'}})
        this.cancel();
        alert("countryOverviews Created !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    } else{
      this.inquiryService.updateCountryOverview(this.state.selectedCountryOverview.country.id, this.state.selectedCountryOverview).then(data => {
        alert("selectedCountryOverview Updated !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    }
  }

  handleCountryOverviewChange(field, event){
    var selectedCountryOverview  = this.state.selectedCountryOverview
    selectedCountryOverview[field] = event.target.value
    this.setState({selectedCountryOverview: selectedCountryOverview})
  }


  handleCountryClick(rowData){
    this.setState({
      selectedCountryOverview: rowData,
      button:{
        label:'Save',
        icon: 'pi pi-check'
      }
    })
  }

  cancel(){
    this.setState({
      selectedCountryOverview: {
        id: 0,
        country: null,
        form_of_government: '',
        economic_risk: '',
        overview: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }

  countryNameBodyTemaplte(rowData){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleCountryClick(rowData)} > {rowData.country.name}</a>
        </React.Fragment>
    );
  }

  render(){
    return(
      <div>
        <div className="condiv">
          <div className="row">
            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.countryOverviews}
                  loading={this.state.loading}
                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No Country Overviews found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="name" filterField="country.name" field="country.name" header="Name" body={this.countryNameBodyTemaplte} sortable filter filterPlaceholder="Search by Country Name"/>
              </DataTable>
            </div>
            <div className = "col-6">
              <div className="col-sm-9" style={{'margin': '5px'}}>
                <div className="form-group">
                  <div className="row" >
                    <div className="col-sm-3">
                        <label className="form-field-bold">Country</label>
                    </div>
                    <div className="col-sm-5">
                      <Dropdown value = {this.state.selectedCountryOverview.country} options={this.state.countries} name="languages"
                        onChange={(e)=>this.handleCountryOverviewChange("country", e)} placeholder="Select a Country" optionLabel = "name"/>
                    </div>
                  </div>
                  <div className="row" >
                    <div className="col-sm-3">
                        <label className="form-field-bold">{<Translation>{ t => <span>{t('FORM_OF_GOVERNMENT')} </span>}</Translation>}</label>
                    </div>
                    <div className="col-sm-5">
                      <input type = "text" id = "form-of-government" onChange={(e)=>this.handleCountryOverviewChange("form_of_government", e)}  name = "formOfGovernment" value={this.state.selectedCountryOverview.form_of_government} className="form-control form-control-sm"/>
                    </div>
                  </div>
                  <div className="row" >
                    <div className="col-sm-3">
                        <label className="form-field-bold">Economic Risk</label>
                    </div>
                    <div className="col-sm-5">
                      <input type = "text" id = "economic_risk" onChange={(e)=>this.handleCountryOverviewChange("economic_risk", e)}  name = "economic_risk" value={this.state.selectedCountryOverview.economic_risk} className="form-control form-control-sm"/>
                    </div>
                  </div>
                  <div className="row" >
                    <div className="col-sm-3">
                        <label className="form-field-bold">Overview</label>
                    </div>
                    <div className="col-sm-5">
                      <textarea class="form-control form-text-comments" name="overview" onChange={(e)=>this.handleCountryOverviewChange("overview", e)}  rows="3" value={this.state.selectedCountryOverview.overview}></textarea>
                    </div>
                  </div>
                </div>
                <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default CountryOverviews;
