import React, { Component } from 'react';
import { Translation } from 'react-i18next';


class InquiriesPreview extends Component {
  constructor(props){
    super(props);
    this.state = {
     report : this.props.report,
     orderInfo : this.props.orderInfo
    }
  }
    showDate(date){
      if(!date){
        return ''
      }
      if (date.type == 'YEAR') {
        return date.year
      } else if (date.type == 'MONTH'){
        return date.month + "-" + date.year
      } else {
        return date.day + "-" + date.month + "-" + date.year
      }
    }

    showPhone(phones){
      let string = '';
      if (phones){
        phones.forEach(phone => {
          string += phone.country_code + "-" + phone.number +","
        })
        return string
      } else {
        return ''
      }

    }
    render(){
        return(

            <div className="col-12" id="reportPreview">
                <div className="row">
                    <div className="col-3 strong"><Translation>{ t => t('CLIENT')}</Translation></div>
                    <div className="col-9">{this.state.orderInfo.ordered_by.user?.company.legal_name}</div>
                </div>
                <div className="row">
                    <div className="col-3 strong"><Translation>{ t => t('ATTENTION')}</Translation></div>
                    <div className="col-9">{this.state.orderInfo.ordered_by.user?.first_name + " "+this.state.orderInfo.ordered_by.user?.last_name}</div>
                </div>
                <div className="row">
                    <div className="col-3 strong"><Translation>{ t => t('REFERENCE')}</Translation></div>
                    <div className="col-9">{this.state.orderInfo.reference}</div>
                </div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4" align="center">
                      <Translation>{ t => <span className="strong"> {t('REQUIRED_CREDIT')} </span>}</Translation><br/>
                      {this.state.orderInfo.required_credit?.value+ " " + this.state.orderInfo.required_credit?.currency.code}
                    </div>
                    <div className="col-4" align="center">
                      <Translation>{ t => <span className="strong"> {t('ADVICE_CREDIT')} </span>}</Translation><br/>
                      {this.state.report.credit_evaluation.advised_credit?.value+ " " + this.state.report.credit_evaluation.advised_credit?.currency.code}
                    </div>
                    <div className="col-4" align="center">
                      <Translation>{ t => <span className="strong"> {t('RATING')} </span>}</Translation><br/>
                      {this.state.report.credit_evaluation.rating}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                     <Translation>{ t => <span className="strong"> {t('COMMENTS_ON_THE_RATING')} </span>}</Translation><br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;{this.state.report.credit_evaluation.comments_on_rating}
                     </div>
                </div>
                {/*<div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong">{this.state.report.company_info.headquarters?.country.name}</div></div>*/}
                <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('IDENTIFICATION')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('NAME_IN_THE_ORDER')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.orderInfo.company_name}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('REGISTERED_NAME')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.company_info.registred_name}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('TRADE_NAMES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.company_info.trade_names}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('PREVIOUS_NAMES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.company_info.previous_names}</div>
                </div>


                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('REGISTRATION_DATA')} </span>}</Translation> </div>
                    <div className="col-8">
                        {this.state.report.registration_info.registrations.map((registration, i) =>(
                            <div className="form-hr">
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TYPE')} </span>}</Translation> : {registration.reg_type?.name}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NUMBER')} </span>}</Translation> : {registration.reg_value}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('REGISTRATION_DATE')} </span>}</Translation> : {this.showDate(registration.reg_date)}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ISSUING_ADMINISTRATION')} </span>}</Translation> : {registration.iss_admin}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('FOREIGN') + "/" + t('DOMESTIC')} </span>}</Translation> : {(registration.type == true) ? <Translation>{ t => t('FOREIGN')}</Translation> : <Translation>{ t => t('DOMESTIC')}</Translation>}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('FORMER_NUMBER')} </span>}</Translation> : {registration.former_nbr}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('REGISTRATION_STATUS')} </span>}</Translation> : {registration.reg_status?.name}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('REGISTRATION_ADDRESS')} </span>}</Translation></div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STREET')} </span>}</Translation> : {registration.address?.street}</div>
                              <div className="noBorder">{<Translation>{ t =><span className="strong"> {t('POSTCODE') + "/" + t("PO_BOX")} </span>}</Translation>} : {registration.address?.postal_code}</div>
                              <div className="noBorder">{<Translation>{ t =><span className="strong"> {t('CITY')} </span>}</Translation>} : {registration.address?.city}</div>
                              <div className="noBorder">{<Translation>{ t =><span className="strong"> {t('STATE')} </span>}</Translation>} : {registration.address?.state}</div>
                              <div className="noBorder">{<Translation>{ t =><span className="strong"> {t('COUNTRY')} </span>}</Translation>} : {registration.address?.country?.name}</div>

                            </div>
                        ))
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('START_DATE')} </span>}</Translation> </div>
                    <div className="col-8">{this.showDate(this.state.report.registration_info.start_date)}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('LEGAL_FORM')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.registration_info.legal_form?.name}</div>
                </div>

               <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('REGISTRATION_CHANGES')} </span>}</Translation> </div>
                    <div className="col-8">
                        {this.state.report.registration_changes.changes.map((change, i) =>(
                            <div className="form-hr">
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('DATE')} </span>}</Translation> : {this.showDate(change.change_date)}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TYPE_OF_CHANGE')} </span>}</Translation> : {change.registration_Change_type}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('DETAIL_OF_CHANGE')} </span>}</Translation> : {change.registration_Change_detail}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('LOCATIONS')} </span>}</Translation> </div>
                    <div className="col-8">
                      <div className="form-hr">
                          <div className="noBorder" align="center"><Translation>{ t => <span className="strong"> {t('HEADQUARTERS')} </span>}</Translation></div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STREET')} </span>}</Translation> : {this.state.report.company_info.headquarters?.street}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('POSTCODE') + "/"+ t('PO_BOX')} </span>}</Translation> : {this.state.report.company_info.headquarters?.postal_code}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CITY')} </span>}</Translation> : {this.state.report.company_info.headquarters?.city}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STATE') + "/" + t('REGION')} </span>}</Translation> : {this.state.report.company_info.headquarters?.state}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {this.state.report.company_info.headquarters?.country?.name}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('SURFACEAREA')} </span>}</Translation> : {this.state.report.company_info.headquarters?.surface_are_of_hq}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PROPERTY')} </span>}</Translation> : {this.state.report.company_info.headquarters?.property}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PREMISES_COMPRISE_OF')} </span>}</Translation> : {this.state.report.company_info.headquarters?.premises_comprise_of}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {this.state.report.company_info.headquarters?.comments}</div>
                      </div>
                      <div className="form-hr">
                          <div className="noBorder" align="center"><Translation>{ t => <span className="strong"> {t('MAILING')} </span>}</Translation></div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STREET')} </span>}</Translation> : {this.state.report.company_info.mailing_address?.street}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('POSTCODE') + "/"+ t('PO_BOX')} </span>}</Translation> : {this.state.report.company_info.mailing_address?.postal_code}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CITY')} </span>}</Translation> : {this.state.report.company_info.mailing_address?.city}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STATE') + "/" + t('REGION')} </span>}</Translation> : {this.state.report.company_info.mailing_address?.state}</div>
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {this.state.report.company_info.mailing_address?.country?.name}</div>
                      </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('BRANCHES')} </span>}</Translation> </div>
                    <div className="col-8">
                      {this.state.report.business.branches.map((branch, i) =>(
                            <div className="form-hr">
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ADDRESS')} </span>}</Translation> : {branch.address.full_address}</div>
                              <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {branch.address.country.name}</div>

                            </div>
                        ))}
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {this.state.report.business.branches_comments}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12"><Translation>{ t => <span className="strong"> {t('CONTACT_DETAILS')} </span>}</Translation> </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('PHONE_NUMBERS')} </span>}</Translation> </div>
                    <div className="col-8">{this.showPhone(this.state.report.contact_info.telephones)}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('FAX_NUMBERS')} </span>}</Translation> </div>
                    <div className="col-8">{this.showPhone(this.state.report.contact_info.faxes)}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('EMAILS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.contact_info.emails}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('WEBSITES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.contact_info.websites}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('SOCIAL_MEDIA_LINKS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.contact_info.social_media}</div>
                </div>
                <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('ACTIVITY_TAB')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('HISTORY')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.history}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('LINE_OF_BUSINESS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.line_of_business}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('SIC_CODES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.sics.map((sic,i) =>(
                      <span>{sic.sic_code.code},</span>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('PRODUCTS') + "/" + t('SERVICES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.main_product}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('TYPE_OF_BUSINESS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.business_types}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('BRANDS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.brands.map((brand,i) => (
                      <span>{brand.name},</span>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('PROPRIETARY_TRADEMARKS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.trademarks.map((trademark,i) => (
                      <span>{trademark.name},</span>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('AWARDS_AND_CERTIFICATIONS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.awards_and_certification.map((award,i) => (
                       <div className="form-hr">
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {award.name}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('DATE')} </span>}</Translation> : {this.showDate(award.date)}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ORGANIZATION')} </span>}</Translation> : {award.organization}</div>
                       </div>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12"><Translation>{ t => <span className="strong"> {t('MEMBERSHIPS_IN_ASSOCIATIONS')} </span>}</Translation> </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('STAFF_NUMBER')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.staff_number}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('SIZE_RANGE')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.size_range}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMMENTS_ON_STAFF') +"/"+ t('STAFF_COMPOSITION')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.comments_on_staff}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('SOURCES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.sources}</div>
                </div>
                <div className="row">
                    <div className="col-12"><Translation>{ t => <span className="strong"> {t('CLIENTS')} </span>}</Translation> </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('TYPES_OF_CLIENTS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.clients.clients_type}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMMENTS_ON_CLIENTS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.clients.comments_on_clients}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('EXPORTS_TO')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.clients.exports_to}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('CLIENTS')} </span>}</Translation> </div>
                    <div className="col-8">
                      {this.state.report.clients.clients.map((client,i) => (
                      <div className="form-hr">
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {client.name}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {client.country.name}</div>
                     </div>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12"><Translation>{ t => <span className="strong"> {t('SUPPLIERS')} </span>}</Translation> </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMMENTS_ON_SUPPLIERS')} </span>}</Translation> </div>
                    <div className="col-8">
                      {/*this.state.report.suppliers.comments_on_suppliers.map((comments_supplier,i) => (
                        <div className="form-hr">
                          <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {comments_supplier.name}</div>

                      </div>
                      )) */}
                      <div className="noBorder">{this.state.report.suppliers.comments_details}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('IMPORTS_FROM')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.suppliers.improts_from}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('SUPPLIERS')} </span>}</Translation> </div>
                    <div className="col-8">
                      { this.state.report.suppliers.suppliers.map((supplier,i) => (
                      <div className="form-hr">
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {supplier.supplier_name}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMPANY_ADDRESS')} </span>}</Translation>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('STREET')} </span>}</Translation>:  {supplier.address.street}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('CITY')} </span>}</Translation>:  {supplier.address.city}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('POSTAL_CODE')} </span>}</Translation>:  {supplier.address.postal_code}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation>:  {supplier.address.country.name}
                            </div>
                        </div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMPANY_DATA')} </span>}</Translation>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('REGISTRATION_ID')} </span>}</Translation>:  {supplier.registration_id}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('CONTACT_NAME')} </span>}</Translation>:  {supplier.contact_name}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('CONTACT_POSITION')} </span>}</Translation>:  {supplier.contact_position}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('PHONE_NUMBER')} </span>}</Translation>:  {supplier.contact_phone_number}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('SUPPLIER_SINCE')} </span>}</Translation>:  {this.showDate(supplier.supplier_since)}
                            </div>
                        </div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TRADE_REFERENCE')} </span>}</Translation>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('PAYMENT_TERMS')} </span>}</Translation>:  {supplier.payment_terms}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('NUMBER_OF_DAYS_GRANTED')} </span>}</Translation>: {supplier.number_of_days_granted}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('MAX_CREDIT_LINE')} </span>}</Translation>:  {supplier.max_credit_line}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('MONTHLY_PURCHASE_AMOUNT')} </span>}</Translation>:  {supplier.monthly_purchase_amount}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('LAST_PURCHASE_AMOUNT')} </span>}</Translation>:  {supplier.last_purchase_amount}
                            </div>
                            <div className="noBorder" style={{"marginLeft" : "10px"}}>
                               <Translation>{ t => <span className="strong"> {t('LAST_PURCHASE_DATE')} </span>}</Translation>:  {this.showDate(supplier.last_purchase_date)}
                            </div>
                            <div className="noBorder">
                               <Translation>{ t => <span className="strong"> {t('SUPPLIERS_OPINION')} </span>}</Translation>:  {supplier.supplier_opinion}
                            </div>
                            <div className="noBorder">
                               <Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation>:  {supplier.comments}
                            </div>
                        </div>

                     </div>
                      ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('PAYMENT_BEHAVIOUR')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.payment_behavior.payments.map((payment,i) => (
                       <div className="form-hr">
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('MONTH')} </span>}</Translation> : {payment.month}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('YEAR')} </span>}</Translation> : {payment.year}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('DBT')} </span>}</Translation> : {payment.dbt}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('BALANCE')} </span>}</Translation> : {payment.balance}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('BALANCE_CURRENCY')} </span>}</Translation> : {payment.balance_currency.code}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ONTIME')} </span>}</Translation> : {payment.on_time}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ONE_MONTH_LATE')} </span>}</Translation> : {payment.one_month_late}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TWO_MONTHS_LATE')} </span>}</Translation> : {payment.two_months_late}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('THREE_MONTHS_LATE')} </span>}</Translation> : {payment.three_months_late}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('MORE_THAN_THREE_MONTHS_LATE')} </span>}</Translation> : {payment.more_than_three_months_late}</div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('CREDIT_BUREA')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.payment_behavior.credit_bureau_information}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMPETITORS')} </span>}</Translation> </div>
                    <div className="col-8">
                      {this.state.report.business.competitors.map((competitor,i) => (
                      <div className="form-hr">
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {competitor.name}</div>
                     </div>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMPETITORS')} </span>}</Translation> </div>
                    <div className="col-8">
                      {this.state.report.insurances.insurances.map((insurance,i) => (
                      <div className="form-hr">
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {insurance.name}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {insurance.comments}</div>
                     </div>
                    ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('INDUSTRY_SITUATION')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.business.industry_situation}</div>
                </div>
                {/*SHAREHOLDERS */}
                <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('SHAREHOLDERS_TAB')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMPANY')} </span>}</Translation> </div>
                    <div className="col-8">
                        {this.state.report.shareholding.shareholders.map((shareholder,i) => (
                            (shareholder.shareholder_type == "company") &&
                                <div className="form-hr">
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TYPE')} </span>}</Translation> : {shareholder.type}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('REGISTRATION')} </span>}</Translation> : {shareholder.registration_id}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PERCENTAGE')} </span>}</Translation> : {shareholder.shares_percentage}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STOCK_EXCHANGE')} </span>}</Translation> : {shareholder.stock_exchange}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TICKER')} </span>}</Translation> : {shareholder.ticker}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {shareholder.shareholder_name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STREET_ADDRESS')} </span>}</Translation> : {shareholder.address.street}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CITY')} </span>}</Translation> : {shareholder.address.city}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STATE') + "/" + t('REGION')} </span>}</Translation> : {shareholder.address.state}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('POSTAL_CODE')} </span>}</Translation> : {shareholder.address.postal_code}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {shareholder.address.country.name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PHONE_NUMBER')} </span>}</Translation> : {this.showPhone(shareholder.contact_info?.telephones)}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('WEBSITE')} </span>}</Translation> : {shareholder.contact_info?.websites}</div>

                                </div>
                        ))}
                        {this.state.report.shareholding.shareholders.map((shareholder,i) => (
                            (shareholder.shareholder_type != "company") &&
                                <div className="form-hr">
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {shareholder.shareholder_name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TYPE')} </span>}</Translation> : {shareholder.type}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PERCENTAGE')} </span>}</Translation> : {shareholder.shares_percentage}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STREET_ADDRESS')} </span>}</Translation> : {shareholder.address.street}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CITY')} </span>}</Translation> : {shareholder.address.city}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STATE') + "/" + t('REGION')} </span>}</Translation> : {shareholder.address.state}</div>

                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {shareholder.address.country.name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('DATE_OF_BIRTH')} </span>}</Translation> : {this.showDate(shareholder.date_of_birth)}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ID_NUMBER')} </span>}</Translation> : {shareholder.registration_id}</div>

                                </div>
                        ))}
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {this.state.report.shareholding.comments}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('SOURCES')} </span>}</Translation> : {this.state.report.shareholding.sources}</div>


                    </div>
                </div>
                {/*MANAGEMENT */}
                 <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('MANAGEMENT')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMPANY')} </span>}</Translation> </div>
                    <div className="col-8">
                        {this.state.report.management.managers.map((manager,i) => (
                            (manager.type == "company") &&
                                <div className="form-hr">
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('FULL_NAME')} </span>}</Translation> : {manager.full_name}</div>

                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {manager.address.country.name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('POSITION')} </span>}</Translation> : {manager.position}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('FULL_ADDRESS')} </span>}</Translation> : {manager.address?.full_address}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('REGISTRATION_ID')} </span>}</Translation> : {manager.registration_id}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PHONE_NUMBER')} </span>}</Translation> : {this.showPhone(manager.contact_info?.telephones)}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('WEBSITE')} </span>}</Translation> : {manager.contact_info?.websites}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {manager.comments}</div>

                                </div>
                        ))}
                        {this.state.report.management.managers.map((manager,i) => (
                            (manager.type != "company") &&
                                <div className="form-hr">
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('FULL_NAME')} </span>}</Translation> : {manager.full_name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {manager.address.country.name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('POSITION')} </span>}</Translation> : {manager.position}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('FULL_ADDRESS')} </span>}</Translation> : {manager.address?.full_address}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ID_NUMBER')} </span>}</Translation> : {manager.registration_id}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PHONE_NUMBER')} </span>}</Translation> : {this.showPhone(manager.contact_info?.telephones)}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('DATE_OF_BIRTH')} </span>}</Translation> : {this.showDate(manager.date_of_birth)}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {manager.comments}</div>
                               </div>
                        ))}
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {this.state.report.management.comments}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('SOURCES')} </span>}</Translation> : {this.state.report.management.sources}</div>
                        </div>
                </div>
                {/*AFFILIATIONS */}
                <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('AFFILIATIONS')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMPANY')} </span>}</Translation> </div>
                    <div className="col-8">
                        {this.state.report.affiliations.affiliated_companies.map((affiliate,i) => (
                            <div className="form-hr">
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMPANY_NAME')} </span>}</Translation> : {affiliate.company_name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {affiliate.address.country.name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('TYPE')} </span>}</Translation> : {affiliate.type.name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STREET_ADDRESS')} </span>}</Translation> : {affiliate.address.street}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CITY')} </span>}</Translation> : {affiliate.address.city}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('STATE') +"/"+t("REGION")} </span>}</Translation> : {affiliate.address.state}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CITY')} </span>}</Translation> : {affiliate.address.city}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('REGISTRATIONS')} </span>}</Translation> : {affiliate.registration_id}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('PHONE_NUMBER')} </span>}</Translation> : {this.showPhone(affiliate.contact_info?.telephones)}</div>
                            </div>
                        ))}

                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {this.state.report.affiliations.comments}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('SOURCES')} </span>}</Translation> : {this.state.report.affiliations.sources}</div>
                        </div>
                </div>
                {/*FINANCIALS */}
                <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('FINANCIALS') + " / " + t('LEGALRECORDS')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('BANKS')} </span>}</Translation> </div>
                    <div className="col-8">
                        {this.state.report.banks_info.banks.map((bank,i) => (
                            <div className="form-hr">
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('NAME')} </span>}</Translation> : {bank.name}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('ACCOUNT_NUMBER')} </span>}</Translation> : {bank.account_number}</div>
                                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COUNTRY')} </span>}</Translation> : {bank.country?.name}</div>
                                         <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {bank.comments}</div>
                        </div>
                        ))}

                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {this.state.report.banks_info.comments}</div>
                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('SOURCES')} </span>}</Translation> : {this.state.report.banks_info.sources}</div>
                        </div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('ADDITIONAL_COMMENTS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.banks_info.comments}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('SOURCES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.banks_info.sources}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('CURRENCY_CHANGES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials.currency_change}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('LISTEDSTOCKEXCHANGE')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials.is_stock_listed}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('STOCK_EXCHANGE')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials.stock_exchange}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('TICKER')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials.ticker}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('CAPITAL_PAIDUP')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials.paid_up_capital}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('CAPITAL')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials?.capital?.value + " " +this.state.report.financials?.capital?.currency.code}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('NUMBER_OF_SHARES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials.nbr_of_shares}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMMENTS_ON_CAPITAL')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.financials.comments_on_Capital}</div>
                </div>
                {/*LEGAL */}
                <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('LEGALRECORDS')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('UCC_FILES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.legal_fillings.ucc_file}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('OFAC_LIST')} </span>}</Translation> </div>
                    <div className="col-8">{ (true === this.state.report.legal_fillings.ofac_list) ? <Translation>{ t => t('YES')}</Translation> : <Translation>{ t => t('NO')}</Translation>} { " " +this.state.report.legal_fillings.ofac_list_comment}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('BANKCRUPTCY')} </span>}</Translation> </div>
                    <div className="col-8">{ (true === this.state.report.legal_fillings.bankruptcy ) ? <Translation>{ t => t('YES')}</Translation> : <Translation>{ t => t('NO')}</Translation>} { " " +this.state.report.legal_fillings.bankruptcy_comment}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('LAWSUIT_S')} </span>}</Translation> </div>
                    <div className="col-8">{ (true === this.state.report.legal_fillings.lawsuits) ? <Translation>{ t => t('YES')}</Translation> : <Translation>{ t => t('NO')}</Translation>} {  " " +this.state.report.legal_fillings.lawsuits_comment}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COLLECTIONS')} </span>}</Translation> </div>
                    <div className="col-8">{ (true === this.state.report.legal_fillings.collections) ? <Translation>{ t => t('YES')}</Translation> : <Translation>{ t => t('NO')}</Translation>} {  " " +this.state.report.legal_fillings.collections_comment}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('LIENS')} </span>}</Translation> </div>
                    <div className="col-8">{ (true === this.state.report.legal_fillings.liens) ? <Translation>{ t => t('YES')}</Translation> : <Translation>{ t => t('NO')}</Translation>} {  " " +this.state.report.legal_fillings.liens_comments}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('OUTSTANDING_DEBT')} </span>}</Translation> </div>
                    <div className="col-8">{ (true === this.state.report.legal_fillings.out_standing_debts) ? <Translation>{ t => t('YES')}</Translation> : <Translation>{ t => t('NO')}</Translation>} {  " " +this.state.report.legal_fillings.out_standing_debts_comment}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('ADDITIONAL_COMMENTS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.legal_fillings.comments}</div>
                </div>
                 {/*SUMMARY */}
                <div className="row">&nbsp;</div>
                <div className="row" align="center"><div className="col-12 noBorder strong"><Translation>{ t => <span className="strong"> {t('SUMMARY')} </span>}</Translation><br/></div></div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMPANY_OPERATIONS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.operative_status}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('SIZE')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.size}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('EXPERIENCE')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.experience}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('GROUP')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.group}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('TRADE_REFERENCES')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.trade_references}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('PAYMENTS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.payments}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('PROFITABILITY')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.profitability}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('INDEBTINESS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.indebtness}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('LEGAL_RECORDS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.legal_records}</div>
                </div>{/*}
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COUNTRY_RISK')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.country_risk}</div>
                        </div>*/}
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('GENERAL_OVERVIEW')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.general_overview}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('CREDIT_RISK_ANALYSIS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.credit_risk_analysis}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.comments}</div>
                </div>
                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('COMMENTS_ON_THE_RATING')} </span>}</Translation> </div>
                    <div className="col-8">{this.state.report.credit_evaluation.comments_on_rating}</div>
                </div>
                 {/*INTERVIEW */}

                <div className="row">
                    <div className="col-4"><Translation>{ t => <span className="strong"> {t('INTERVIEW')} </span>}</Translation> </div>
                    <div className="col-8">
                        {this.state.report.interviews.interviews.map((interview,i) => (
                            <div className="form-hr">
                                <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CONTACT_NAME')} </span>}</Translation> : {interview.contact_name}</div>
                                <div className="noBorder"><Translation>{ t => <span className="strong"> {t('CONTACT_POSITION')} </span>}</Translation> : {interview.contact_position}</div>

                             </div>
                        ))}

                        <div className="noBorder"><Translation>{ t => <span className="strong"> {t('COMMENTS')} </span>}</Translation> : {this.state.report.interviews.comments}</div>

                        </div>
                </div>
            </div>

        )
    }

}
export default InquiriesPreview
