import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import InquiryService from '../service/InquiryService';

import InquiriesDetailsSummaryCountryOverView from './InquiriesDetailsSummaryCountryOverView.js';
import InquiriesDetailsSummarySummary from './InquiriesDetailsSummarySummary.js';
import InquiriesDetailsSummaryInterview from './InquiriesDetailsSummaryInterview.js';
import { Translation } from 'react-i18next';
class InquiriesDetailsSummary extends Component {
    constructor(props){
      super(props);
      this.state = {
        activeIndex : null,
        countryOverView: {},
        creditEvaluation: this.props.report.credit_evaluation,
        interviews: this.props.report.interviews,
        customerInfo: this.props.customer_info,
        toast: this.props.toast
      }
      this.inquiryService = new InquiryService();
      this.inquiryService.getCountryOverview(this.props.orderInfo.country.id).then(data => {
       // console.log(data)
        this.setState({countryOverView:data[0]})
      }).catch(err =>{
        console.log(err)
      })
    }

    componentDidMount(){
      const creditEvaluation = sessionStorage.getItem('ses_credit_evaluation_' + this.props.report.id);
      if (creditEvaluation === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_credit_evaluation_' + this.props.report.id, JSON.stringify(this.state.creditEvaluation));
      } else{
          this.setState({creditEvaluation : JSON.parse(creditEvaluation)})
      }

      const interviews = sessionStorage.getItem('ses_interviews_' + this.props.report.id);

      if (interviews === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_interviews_' + this.props.report.id, JSON.stringify(this.state.interviews));
      } else{
          this.setState({interviews : JSON.parse(interviews)})
      }

    }
    render(){
        return(
          <Accordion multiple activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
            <AccordionTab header={<Translation>{ t => t('SUMMARY')}</Translation>}>
                <InquiriesDetailsSummarySummary credit_evaluation = {this.state.creditEvaluation} report_id = {this.props.report.id}
                  required_credit = {this.props.orderInfo.required_credit} customer_info = {this.state.customerInfo} maximum_required_credit = {this.props.orderInfo.required_credit == null}/>
            </AccordionTab>
            <AccordionTab header={<Translation>{ t => t('INTERVIEW')}</Translation>}>
                <InquiriesDetailsSummaryInterview interviews = {this.state.interviews} report_id = {this.props.report.id} toast = {this.state.toast}  />
            </AccordionTab>
            <AccordionTab header={<Translation>{ t => t('COUNTRY_OVERVIEW')}</Translation>}>
                <InquiriesDetailsSummaryCountryOverView country_overview={this.state.countryOverView} />
            </AccordionTab>
        </Accordion>
        )
    }

}
export default InquiriesDetailsSummary
