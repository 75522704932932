import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import CompanyService from '../service/CompanyService';
import { Button } from 'primereact/button';
import { Translation } from 'react-i18next';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {formatDate} from '../components/Utils';

class ReportsDownloadLogs extends Component {

  constructor(props){
    super(props)
    this.state = {
      toast: this.props.toast,
      search: {},
      customers: [],
      submitLocked: false,
      searchloading: true,
      selectedCustomers: [],
      logs: []
    }
    this.companyService = new CompanyService();
    this.updateSearchParams = this.updateSearchParams.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
  }

  componentDidMount(){
    this.state.toast.current.show({severity:'info', summary:'Loading !', detail: "Loading Customers !"});
    this.companyService.getReportCustomersInfo().then(data => {
      this.setState({customers: data, submitLocked: false, searchloading: false}, function(){
        this.state.toast.current.show({severity:'success', summary:'Loaded !', detail: "Customers Loaded !"});
      })
    })
  }

  exportCSV(selectionOnly){
    this.dt.exportCSV({ selectionOnly });
  }

  handleSubmitSearch(){
    this.setState({searchloading: true})
    console.log(this.state.search)
    this.companyService.generateReportDownloadsLogs(this.state.search).then(response => {
      this.setState({searchloading: false, logs: response})
    }).catch(err => {
      if (err.response){
        this.state.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(err.response.data)});
      } else {
        this.state.toast.current.show({severity:'error', summary:'Failed !', detail: "There has been an error generating the report !"});
      }

      this.setState({searchloading: false})
    })

  }

  updateSearchParams(field, event){
    let search = this.state.search
    search[field] = (field.includes("date")) ? formatDate(event.target.value) : event.target.value;
    this.setState({search: search})
  }

  render(){
    return (
      <div>
        <Toast ref={this.state.toast} />
        <div className = "row">
          <div className = "col-1">
            <label className="form-field-bold"> From Date </label>
          </div>
          <div className = "col-1">
            <Calendar value={this.state.search.from_date} onChange={(e) => this.updateSearchParams("from_date", e)} showIcon monthNavigator showButtonBar></Calendar>
          </div>
          <div className = "col-1">
            <label className="form-field-bold"> To Date </label>
          </div>
          <div className = "col-1">
            <Calendar value={this.state.search.to_date}  onChange={(e) => this.updateSearchParams("to_date", e)} showIcon monthNavigator showButtonBar></Calendar>
          </div>
          <div className = "col-1">
            <div className="row">
              <label className="form-field-bold"> Customers</label>
            </div>
            <div className="row">
              *leave empty to select all
            </div>
          </div>
          <div className = "col-2">
            <MultiSelect value={this.state.search.selectedCustomers} options={this.state.customers} optionLabel="legal_name"
                placeholder="Select Clients" filter="true" onChange = {(e) => this.updateSearchParams("selectedCustomers", e)} />
          </div>
          <div className = "col-1">
            <Button loading = {this.state.searchloading} disabled = {this.state.submitLocked}  loadingIcon="pi pi-spin pi-sun" id="search-btn"
                  label={<Translation>{ t => <span>{t('GENERATE')} </span>}</Translation>}
                  icon="pi pi-briefcase" onClick = {() => this.handleSubmitSearch()} className="p-button-danger" />
          </div>
          <div className = "col-2">
            <Button type="button" label="Export CSV" icon="pi pi-file" onClick={() => this.exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
          </div>

        </div>
        <div className = "row">
          <DataTable ref={(el) => this.dt = el}
              value={this.state.logs}
              loading={this.state.searchloading}
              className="p-datatable-striped"
              dataKey="id"
              paginator rows={10}
              emptyMessage="No Logs found"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[10,25,50]}>

              <Column field="log_date" header="Date" body={this.logDateBodyTemplate} sortable />
              <Column sortField="log_action"         field="log_action"        header="Action"     sortable />
              <Column sortField="log_details"        field="log_details"       header="Details"    sortable />
              <Column sortField="ip_address"         field="ip_address"        header="IP Address" sortable />
              <Column sortField="user_log.username"  field="user_log.username" header="User"       sortable  />

          </DataTable>
        </div>
      </div>
    )
  }
}

export default ReportsDownloadLogs;
