import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { TabView,TabPanel } from 'primereact/tabview';
import Address from '../components/Address.js'
import CompanyService from '../service/CompanyService';
import InquiryService from '../service/InquiryService';
import UserService from '../service/UserService';
//import CompanyDetailsBusiness from '../contents/CompanyDetailsBusiness';
//import CompanyDetailsFinancials from '../contents/CompanyDetailsFinancials';
import { SelectButton } from 'primereact/selectbutton';
import { Translation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

class CompanyDetails extends Component {


  constructor(props){
    super(props);
    this.state = {
      toast: this.props.toast,
      companyInfo : {...this.props.companyInfo},
      delays: [],
      countries: [],
      currencies: [],
      selectedCustomerDelay: null,
      selectedCustomerDelayIndex: 0,
      users: [],
      selectedFile: null,
      showLogo : this.props.companyInfo.customer_info?.logo,
      logo_url : this.props.companyInfo.logo_url
    }
    this.companyService = new CompanyService();
    this.inquiryService = new InquiryService();
    this.userService = new UserService();

    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleCustomerInfoChange = this.handleCustomerInfoChange.bind(this);
    this.addNewCustomerDelay = this.addNewCustomerDelay.bind(this);
    this.saveCustomerDelay = this.saveCustomerDelay.bind(this);
    this.delayNameBodyTemaplte = this.delayNameBodyTemaplte.bind(this);
    this.handleDelayClick = this.handleDelayClick.bind(this);
    this.handleCustomerDelayChange = this.handleCustomerDelayChange.bind(this);
    this.parseCompanyInfo = this.parseCompanyInfo.bind(this);
    this.deleteCustomerDelay = this.deleteCustomerDelay.bind(this);
    this.UploadLogo = this.UploadLogo(this);
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
    this.upload = React.createRef();
    this.inquiryService.getDelays().then(data => {
      this.setState({delays: data});
    })

    this.inquiryService.getCurrencies().then(data =>{
      this.setState({currencies:data})
    })
    this.userService.getClientSummary().then(data => {
      this.setState({users: data})
    })
  }


  componentDidMount(){
   
    this.inquiryService.getCountries().then(data =>{
      this.setState({countries:data})
    });
    let companyInfo = {...this.state.companyInfo}
    this.setState({companyInfo: this.parseCompanyInfo(companyInfo)})
    if(this.props.tab_index){
      this.state.toast.current.show({severity:'info', summary:'Information !', detail: "Company Will be automatically assigned to the report if saved successfully"});
    }
   
    if(companyInfo.logo_url){
      this.setState({"logo_url" : companyInfo.logo_url})
    } else{
      this.setState({"logo_url" : companyInfo.company_logo})
    }
    if(companyInfo.customer_info['customer_delays'].length == 0){
      this.refetchCustomerDelay();
    }
  }
  refetchCustomerDelay(){
    this.companyService.getCustomerDelays(this.state.companyInfo.id).then(response => {
      let company_info = this.state.companyInfo
      company_info.customer_info.customer_delays = response
      this.setState({companyInfo: this.parseCompanyInfo(company_info), selectedCustomerDelay: null, selectedCustomerDelayIndex: 0})
    }).catch(err => {
      console.log(err);
    })
  }
  parseCompanyInfo(companyInfo){
    if (companyInfo.type == "CUST"){
      let customer_delays = companyInfo.customer_info.customer_delays
      let new_customer_delays = []
      for(let indx in customer_delays){
        let customer_delay = customer_delays[indx]
        let customer_delay_countries = customer_delay["delay_countries"]
        if(customer_delay_countries.length == 0 || !customer_delay_countries[0]["country"]){
          new_customer_delays.push(customer_delay)
          continue;
        }
        let new_customer_delay_countries = []
        for (let country_indx in customer_delay_countries){
          new_customer_delay_countries.push(customer_delay_countries[country_indx]["country"])
        }
        customer_delay.delay_countries = new_customer_delay_countries

        new_customer_delays.push(customer_delay)
      }
      companyInfo.customer_info.customer_delays = new_customer_delays
      return companyInfo
    } else {
      return companyInfo
    }
    
  }
  handleFileUploadChange(event){
    if (!event.files) {
      this.setState({selectedFile: null})
      return;
    }
    for (let file in event.files){
      this.setState({selectedFile: event.files[file]})
    }
  }
 UploadLogo(event){
    //this.state.toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Auto Mode'});
    //this.state.toast.current.show({severity:'info', summary:'Success !', detail: "File Uploaded with Auto Mode"});
    if (!event.files) {
      return;
    }
    var bodyFormData = new FormData();
    for (let i in event.files){
       bodyFormData.append("file_" + i, event.files[i]);
    }
    this.companyService.uploadCompanyLogo(this.state.companyInfo.id, bodyFormData)
      .then(response => {
       /*this.setState({groupFinancialFiles: response}, () => {
          this.state.toast.current.show({severity:'success', summary:'Imported', detail: ""});
          this.groupUplaod.current.clear();
        })
       */
        console.log(response)
      })
      .catch(err => {
         this.state.toast.current.show({severity:'error', summary:'Failed', detail: JSON.stringify(err.response.data)});
      })

 }


  handleDelayClick(rowData, props){
    const indx =  props.rowIndex
    this.setState({
      selectedCustomerDelay: rowData,
      selectedCustomerDelayIndex: indx
    })
  }
  delayNameBodyTemaplte(rowData, props){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleDelayClick(rowData, props)} > {rowData.delay.name}</a>
        </React.Fragment>
    );
  }

  handleAddressChange(address_field, address_data){
    let companyInfo = this.state.companyInfo
    companyInfo[address_field] = address_data;
  }

  handleCustomerDelayChange(field, event){
    let selectedCustomerDelay = this.state.selectedCustomerDelay
    selectedCustomerDelay[field] = event.target.value
    this.setState({selectedCustomerDelay: selectedCustomerDelay})
  }

  deleteCustomerDelay(){
    if(!this.state.selectedCustomerDelay.id){
      this.setState({selectedCustomerDelay: null})
      this.state.toast.current.show({severity:'info', summary:'Canceled !', detail: "Operation Canceled !"});
      return;
    }
    this.companyService.deleteCompanyCustomerDelay(this.state.companyInfo.id, this.state.selectedCustomerDelay.id).then(response => {
      let companyInfo = this.state.companyInfo
      let customer_delays = companyInfo.customer_info.customer_delays
      customer_delays.splice(this.state.selectedCustomerDelayIndex,  1);
      companyInfo.customer_info.customer_delays = customer_delays;
      this.setState({companyInfo: companyInfo, selectedCustomerDelay: null, selectedCustomerDelayIndex:0})
      this.state.toast.current.show({severity:'success', summary:'Deleted !', detail: "Customer Delay Has Been Deleted !"});
    }).catch(error => {
      console.log(error);
      this.state.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(error.response.data)});
    })
  }

  saveCustomerDelay(){
    let selectedCustomerDelay = this.state.selectedCustomerDelay
    let new_delay_countries = [];
    for (let indx in selectedCustomerDelay.delay_countries){
      let delay_country = selectedCustomerDelay.delay_countries[indx]
      new_delay_countries.push({country: delay_country})
    }
    selectedCustomerDelay.delay_countries = new_delay_countries

    if(!this.state.selectedCustomerDelay.id){

      this.companyService.createCompanyCustomerDelay(this.state.companyInfo.id, selectedCustomerDelay).then(response => {
        let company_info = this.state.companyInfo
        company_info.customer_info.customer_delays = response
        this.setState({companyInfo: this.parseCompanyInfo(company_info), selectedCustomerDelay: null, selectedCustomerDelayIndex: 0})
        this.state.toast.current.show({severity:'success', summary:'Created !', detail: "Customer Delay Has Been Created !"});
      }).catch(err => {
        console.log(err);
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response?.data)});
      })
    } else {
      this.companyService.updateCompanyCustomerDelay(this.state.companyInfo.id, selectedCustomerDelay.id, selectedCustomerDelay).then(response => {
        let company_info = this.state.companyInfo
        company_info.customer_info.customer_delays = response
        this.setState({companyInfo: this.parseCompanyInfo(company_info), selectedCustomerDelay: null, selectedCustomerDelayIndex: 0})
        this.state.toast.current.show({severity:'success', summary:'Updated !', detail: "Customer Delay Has Been Updated !"});
      }).catch(err => {
        console.log(err);
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    }

  }

  delete(event){
  //  event.currentTarget.id = this.props.tab_id;
    this.companyService.deleteCompany(this.state.companyInfo.id).then(
      response => {
       // event.currentTarget.id = this.props.tab_id;
        //this.props.closeTab(event, this.state.companyInfo.legal_name);
         let AdminCustomers = JSON.parse(sessionStorage.getItem("AdminCustomers"));
         sessionStorage.setItem("AdminCustomers", JSON.stringify(AdminCustomers.filter(cust=>cust.id != this.state.companyInfo.id)))
         this.state.toast.current.show({severity:'success', summary:'Deleted !', detail: "Company Has Been Deleted !"});
         
         setTimeout(() => {
          this.props.closeTab(this.state.companyInfo.legal_name+"_"+this.props.tabCount)
          this.props.onTabChange({index : this.props.activeTabIndex})
        }, 1000);
         
      }
    ). catch(
      err => {
        console.log(err);
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err)});
      }
    )
  }
  save(){
    const afterSave = this.props.after_save
    var bodyFormData = new FormData();
    this.state.companyInfo['customer_info']['customer_delays'] = null;
    bodyFormData.append('data', JSON.stringify({data:this.state.companyInfo}));
    
  

    if(this.state.selectedFile != null)
      bodyFormData.append('file', this.state.selectedFile);
  
  
    if (this.state.companyInfo.type === "CLNT"){
      this.state.companyInfo['registration_info'] = JSON.parse(sessionStorage.getItem('ses_comp_registration_info_' + this.state.companyInfo.id));
      this.state.companyInfo['regitsration_changes'] = JSON.parse(sessionStorage.getItem('ses_comp_regitsration_changes_' + this.state.companyInfo.id));
      this.state.companyInfo['affiliations'] = JSON.parse(sessionStorage.getItem('ses_comp_affiliations_' + this.state.companyInfo.id));
      this.state.companyInfo['banks_info'] = JSON.parse(sessionStorage.getItem('ses_comp_banks_info_' + this.state.companyInfo.id));
      this.state.companyInfo['clients'] = JSON.parse(sessionStorage.getItem('ses_comp_clients_' + this.state.companyInfo.id));
      this.state.companyInfo['contact_info'] = JSON.parse(sessionStorage.getItem('ses_comp_contact_info_' + this.state.companyInfo.id));
      this.state.companyInfo['legal_fillings'] = JSON.parse(sessionStorage.getItem('ses_comp_legal_fillings_' + this.state.companyInfo.id));
      this.state.companyInfo['financials'] = JSON.parse(sessionStorage.getItem('ses_comp_financials_' + this.state.companyInfo.id));
      this.state.companyInfo['payment_behavior'] = JSON.parse(sessionStorage.getItem('ses_comp_payment_behavior_' + this.state.companyInfo.id));
      this.state.companyInfo['suppliers'] = JSON.parse(sessionStorage.getItem('ses_comp_suppliers_' + this.state.companyInfo.id));
      this.state.companyInfo['shareholding'] = JSON.parse(sessionStorage.getItem('ses_comp_shareholding_' + this.state.companyInfo.id));
      this.state.companyInfo['management'] = JSON.parse(sessionStorage.getItem('ses_comp_management_' + this.state.companyInfo.id));
      this.state.companyInfo['business'] = JSON.parse(sessionStorage.getItem('ses_comp_business_' + this.state.companyInfo.id));
      this.state.companyInfo['insurances'] = JSON.parse(sessionStorage.getItem('ses_comp_insurances_' + this.state.companyInfo.id));
      this.state.companyInfo['press'] = JSON.parse(sessionStorage.getItem('ses_comp_press_releases_' + this.state.companyInfo.id));
      this.state.companyInfo['customer_info'] = null
    } else {
      this.state.companyInfo['registration_info'] = null
      this.state.companyInfo['regitsration_changes'] = null
      this.state.companyInfo['affiliations'] = null
      this.state.companyInfo['banks_info'] = null
      this.state.companyInfo['clients'] = null
      this.state.companyInfo['contact_info'] = null
      this.state.companyInfo['legal_fillings'] = null
      this.state.companyInfo['financials'] = null
      this.state.companyInfo['payment_behavior'] = null
      this.state.companyInfo['suppliers'] = null
      this.state.companyInfo['shareholding'] = null
      this.state.companyInfo['management'] = null
      this.state.companyInfo['business'] = null
      this.state.companyInfo['insurances'] = null
      this.state.companyInfo['press'] = null
      this.state.companyInfo['customer_info'] = null
      /*let customer_delays = this.state.companyInfo.customer_info.customer_delays
      let new_customer_delays = []
      for(let indx in customer_delays){
        let customer_delay = customer_delays[indx]
        let customer_delay_countries = customer_delay["delay_countries"]
        let new_customer_delay_countries = []
        for (let country_indx in customer_delay_countries){
          new_customer_delay_countries.push({"country": customer_delay_countries[country_indx]})
        }
        customer_delay.delay_countries = new_customer_delay_countries

        new_customer_delays.push(customer_delay)
      }
      this.state.companyInfo.customer_info.customer_delays = new_customer_delays*/
    }
    if( "id" in this.state.companyInfo){

      this.companyService.updateCompany(this.state.companyInfo.id, bodyFormData).then(data=>{
        //clear sessions
        sessionStorage.removeItem("ses_comp_registration_info_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_affiliations_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_banks_info_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_clients_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_contact_info_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_customer_info_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_legal_fillings_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_financials_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_payment_behavior_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_suppliers_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_shareholders_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_registration_changes_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_company_info_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_business_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_shareholding_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_management_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_insurances_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_sources_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_history_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_legal_records_" + this.state.companyInfo.id);
        sessionStorage.removeItem("ses_comp_press_releases_" + this.state.companyInfo.id);

        this.state.toast.current.show({severity:'success', summary:'Updated !', detail: "The company Has Been Updated Succesfully!"});
        let companyInfo = this.parseCompanyInfo(data);

        this.setState({companyInfo: companyInfo, selectedCustomerDelay: null, selectedCustomerDelayIndex: 0})
         //Remove the sessionStorage TODO : update the sessionStorage
        sessionStorage.removeItem('AdminCustomers');    
      }).catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
        return;
      })
    } else{
      this.companyService.createCompany(this.state.companyInfo)
      .then(data =>{
        this.state.toast.current.show({severity:'success', summary:'Created !', detail: "The company Has Been Created Succesfully!"});
        this.setState({companyInfo: this.parseCompanyInfo(data), selectedCustomerDelay: null, selectedCustomerDelayIndex: 0})
        //Remove the sessionStorage TODO : update the sessionStorage
        sessionStorage.removeItem('AdminCustomers');      
        if (this.props.tab_index){
          afterSave(this.props.order_id, this.state.companyInfo.id, this.props.tab_index)
        }

      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
        this.setState({companyInfo: this.parseCompanyInfo(this.state.companyInfo)});
      })
    }

  }

  handleFormChange(field, event){
    let companyInfo = this.state.companyInfo
    companyInfo[field] = event.target.value
    this.setState({companyInfo:companyInfo})
  }

  handleCustomerInfoChange(field, event){
    let companyInfo = this.state.companyInfo
    if(field == "logo"){
      this.setState({showLogo: event.target.value})
    }
    if (field == "download_formats"){
      const download_format = event.target.value
      if (download_format.length == 0){
        companyInfo["customer_info"][field] = ""
      } else {
        companyInfo["customer_info"][field] = event.target.value.join(",")
      }

    } else {
      companyInfo["customer_info"][field] = event.target.value
    }
    this.setState({companyInfo:companyInfo})
  }

  renderAdditionalInfo(){
    const options = [{value : 1, label: "Active"}, {value : 0, label: "Inactive"}];
    const yesNo = [{value : true, label: "Yes"}, {value : false, label: "No"}];
    const negativeOptions = [{value : true, label: "Negative Report"}, {value : false, label: "Email"}];
    const languages = [{value : "English", label: "EN"}, {value : "French", label: "FR"}, , {value : "Spanish", label: "ES"}]
    if (this.state.companyInfo.type == "CLNT"){
      return (<div></div>)
      /*return (
        <div className="col-11"  style={{'marginLeft': '10px'}}>
          <TabView>
            <TabPanel header="BUSINESS">
                <CompanyDetailsBusiness company_info={this.state.companyInfo} />
            </TabPanel>
            <TabPanel header="FINANCIALS/LEGALRECORDS">
               <CompanyDetailsFinancials company_info={this.state.companyInfo} />
            </TabPanel>
          </TabView>
        </div>
      )*/
    } else if (this.state.companyInfo.type == "CUST"){
      return (
        <div className="col-11"  style={{'margin': '10px'}}>

          <br></br>
          <div className="form-hr"/>
          <div className="row">
            <span className="form-title" style={{marginRight:"25px"}}>
              Customer Info
            </span>
          </div>
          <br></br>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('CUSTOMER_STATUS')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-7">
                          <SelectButton value={this.state.companyInfo.customer_info.customer_status} options={options}
                              onChange={(e) => this.handleCustomerInfoChange("customer_status", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                      </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('CUSTOMER_EMAILS')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-2">
                          <input type="text" value={this.state.companyInfo.customer_info.emails} className="form-control form-control-sm"
                              onChange={(e) => this.handleCustomerInfoChange("emails", e)} style = {{ "marginLeft" : "10px"}}/>
                      </div>
                      <div className="col-sm-2">
                        <strong>Separate Emails by a comma (,)</strong>
                    </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('DEFAULT_LANGUAGE')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-7">
                        <Dropdown value = {this.state.companyInfo.customer_info.default_language} options={languages} name="languages"
                          onChange={(e)=>this.handleCustomerInfoChange("default_language", e)} placeholder="Select a Language" optionLabel = "label"/>
                      </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('DEFAULT_CURRENCY')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-7">
                        <Dropdown value = {this.state.companyInfo.customer_info.default_currency} options={this.state.currencies} name="currency"
                          onChange={(e)=>this.handleCustomerInfoChange("default_currency", e)} placeholder="Select a Currency" optionLabel = "name"/>
                      </div>
              </div>
          </div>
          <div hidden className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('DEFAULT_RATING')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-7">
                        <SelectButton  value={this.state.companyInfo.customer_info.default_rating} options={options}
                            onChange={(e) => this.handleCustomerInfoChange("default_rating", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                      </div>
              </div>
          </div>
          <div className="form-group">
            <div className="row">
                    <div className="col-sm-4">
                        <label className="form-field-bold">{<Translation>{ t => <span>{t('DATABASE_ACCESS')} </span>}</Translation>}</label>
                    </div>
                    <div className="col-sm-2">
                        <SelectButton value={this.state.companyInfo.customer_info.database_access} options={yesNo}
                            onChange={(e) => this.handleCustomerInfoChange("database_access", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                    </div>
            </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('DOWNLOAD_FORMATS')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-7">
                        <MultiSelect value={this.state.companyInfo.customer_info.download_formats?.split(",")} options={['XML', 'WORD']}
                          onChange={(e) => this.handleCustomerInfoChange("download_formats", e)}  placeholder="Select Formats" display="chip" selectAll="true"/>
                      </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('CUSTOMER_COMMENTS')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-4">
                        <textarea className="form-control form-control-sm" row = "3" onChange={(e)=>this.handleCustomerInfoChange("customer_comments", e)}
                          value = {this.state.companyInfo.customer_info.customer_comments}/>
                      </div>
              </div>
          </div>
          <br></br>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('NEGATIVE_REPORT')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-2">
                          <SelectButton value={this.state.companyInfo.customer_info.negative} options={negativeOptions}
                              onChange={(e) => this.handleCustomerInfoChange("negative", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                      </div>
                      <div className="col-1">
                            <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                              onClick = {(e) => this.handleCustomerInfoChange("negative", e)} value = {null}  />
                      </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('EXPERIAN')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-2">
                          <SelectButton value={this.state.companyInfo.customer_info.experian} options={yesNo}
                              onChange={(e) => this.handleCustomerInfoChange("experian", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                      </div>
                      <div className="col-1">
                            <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                              onClick = {(e) => this.handleCustomerInfoChange("experian", e)} value = {null}  />
                      </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('LOGO')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-2">
                          <SelectButton value={this.state.companyInfo.customer_info.logo} options={yesNo}
                              onChange={(e) => this.handleCustomerInfoChange("logo", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                      </div>
                      <div className="col-sm-1">
                            <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                              onClick = {(e) => this.handleCustomerInfoChange("logo", e)} value = {null}  />
                      </div>
              </div>
          </div>
          <div className="form-group" hidden={!this.state.showLogo}>
              <div className="row">
                      <div className="col-sm-4"></div>
                      <div className="col-sm-3">
                      <img hidden={this.state.logo_url == "" || this.state.logo_url == null} width="150" src={process.env.REACT_APP_HOST + "/wand2/media/"+this.state.logo_url}/>
                      <br/>
                      <FileUpload customUpload uploadHandler={this.handleFileUploadChange} chooseLabel="Add Logo"
                        onRemove = {this.handleFileUploadChange} name="file" maxFileSize={1000000} auto/>
                      </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                      <div className="col-sm-4">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('DEFAULT_USER')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-2">
                        <Dropdown value = {this.state.companyInfo.customer_info.default_user} options={this.state.users} name="defaultUser"
                          onChange={(e)=>this.handleCustomerInfoChange("default_user", e)} placeholder="Select a Default User"
                          optionLabel = "username" optionValue="id" filter filterBy ="username"/>
                      </div>
              </div>
          </div>
          <br></br>
          <div className="form-hr"/>
          <div className="row">
            <span className="form-title" style={{marginRight:"25px"}}>
              Customer Delays
            </span>
          </div>
          <br></br>
          {this.renderCustomerDelays()}
        </div>
      )
    } else {
      return (<div></div>)
    }
  }

  addNewCustomerDelay(){
    let companyInfo = this.state.companyInfo
    let selectedCustomerDelay = {
      delay:{
        name: ''
      }
    }
    this.setState({selectedCustomerDelay: selectedCustomerDelay})
  }
  renderCustomerDelays(){
    if(!this.state.companyInfo.id){
      return(<div></div>)
    }
    return(
      <div>
        <div className="condiv">
          <div className="row">

            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.companyInfo.customer_info.customer_delays}

                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No Delays found for this company"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="name" filterField="name" field="name" header="Name" body={this.delayNameBodyTemaplte} sortable filter filterPlaceholder="Search by company Name"/>
              </DataTable>
            </div>
            <div className = "col-4">
              <div className="col-sm-6">
                <Button onClick={this.addNewCustomerDelay} label="Add Customer Delay" className="p-button-sm" icon="pi pi-plus" />
              </div>
              <br></br>
              <br></br>
              <div hidden = {this.state.selectedCustomerDelay == null} >
                <div className = "row">
                  <div className="col-sm-12">
                    <Dropdown value = {this.state.selectedCustomerDelay?.delay} options={this.state.delays} name="delays"
                      onChange={(e)=>this.handleCustomerDelayChange("delay", e)} placeholder="Select a Delay" optionLabel = "name" style={{width: '150%'}}/>
                  </div>
                </div>
                <br></br>
                <div className = "row">
                  <div className="col-sm-12" >
                      <MultiSelect value={this.state.selectedCustomerDelay?.delay_countries} options={this.state.countries} optionLabel="name"
                        onChange={(e) => this.handleCustomerDelayChange("delay_countries", e)}  placeholder="Select Countries" display="chip" filter="true" maxSelectedLabels="20" style={{width: '150%'}}/>
                  </div>
                </div>

                <br></br>
                <div className = "row">
                  <div className="col-sm-6" >
                    <input id = "nbr_of_days" type="number" placeholder="Number of Business Days" value={this.state.selectedCustomerDelay?.nbr_of_days}
                        className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}} onChange={(e)=>this.handleCustomerDelayChange("nbr_of_days", e)} style={{width: '150%'}}/>
                  </div>

                </div>
                <br></br>

                <Button onClick={this.saveCustomerDelay} label="Save" className="p-button-sm" icon="pi pi-check" />
                <Button onClick={this.deleteCustomerDelay} label="Delete" className="p-button-sm" icon="pi pi-trash" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  render() {
    return (
      <div className = "row">
        <Toast ref={this.state.toast} />
        <div className = "col-3">
          <div className="form-group">
            <div className="row">
                <div className="col-sm-4">
                    <label hidden = {this.state.companyInfo.type === "CUST"} className="form-field-bold">Legal Name</label>
                    <label hidden = {this.state.companyInfo.type != "CUST"} className="form-field-bold">Name</label>
                </div>
                <div className="col-sm-5">
                    <input ref="legal_name" onChange={(e)=>this.handleFormChange("legal_name", e)} autoFocus className="form-control form-control-sm" name="legal_name" type="text" value={this.state.companyInfo?.legal_name}/>
                </div>
            </div>
            <div hidden = {this.state.companyInfo.type === "CUST"} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Previous Names</label>
                </div>
                <div className="col-sm-5">
                    <input ref="previous_names" onChange={(e)=>this.handleFormChange("previous_names", e)} autoFocus className="form-control form-control-sm" name="previous_names" type="text" value={this.state.companyInfo.previous_names}/>
                </div>
            </div>
            <div hidden = {this.state.companyInfo.type === "CUST"} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Trade Names</label>
                </div>
                <div className="col-sm-5">
                    <input ref="trade_names" onChange={(e)=>this.handleFormChange("trade_names", e)} autoFocus className="form-control form-control-sm" name="trade_names" type="text" value={this.state.companyInfo.trade_names}/>
                </div>
            </div>
            <div hidden = {this.state.companyInfo.type === "CUST"} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Registration ID</label>
                </div>
                <div className="col-sm-5">
                    <input ref="registration_id" onChange={(e)=>this.handleFormChange("registration_id", e)} autoFocus className="form-control form-control-sm" name="registration_id" type="text" value={this.state.companyInfo.registration_id}/>
                </div>
            </div>
            <div hidden = {this.state.companyInfo.type === "CUST"} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Cristal Number</label>
                </div>
                <div className="col-sm-5">
                    <input ref="registration_id" autoFocus className="form-control form-control-sm" name="registration_id" type="text"
                      value={this.state.companyInfo.cristal_number} disabled/>
                </div>
            </div>
          </div>
        </div>
        <div className = "col-sm-3">
          <Address AddressChange={this.handleAddressChange} field_name = "legal_address" label = "Legal Address" address = {this.state.companyInfo.legal_address} />
        </div>
        <div hidden = {this.state.companyInfo.type === "CUST"} className = "col-sm-3">
          <Address  AddressChange={this.handleAddressChange} field_name = "mailing_address" label = "Mailing Address" address = {this.state.companyInfo.mailing_address} />
        </div>
        <div  hidden = {this.state.companyInfo.type === "CUST"}  className = "col-sm-3">
          <Address  AddressChange={this.handleAddressChange} field_name = "operative_address" label = "Operative Address" address = {this.state.companyInfo.operative_address} />
        </div>

        {this.renderAdditionalInfo()}
        <div className="col-12" style={{'marginLeft': '10px','marginTop':'20px'}}>
          <Button onClick={this.save} label="Save" className="p-button-sm" icon="pi pi-check" />
          <Button onClick={this.delete} hidden={!this.state.companyInfo.id} label="Delete" className="p-button-sm" icon="pi pi-trash" />
        </div>

      </div>
    )
  }
}

export default CompanyDetails
