import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import InquiryService from '../service/InquiryService';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import InquiriesDetailsPaymentBehavior from './inquiriesDetailsPaymentBehavior.js';
import { Translation } from 'react-i18next';
import { Chips } from 'primereact/chips';
import { ToggleButton } from 'primereact/togglebutton';
import DateComponent from '../components/DateComponent';
import SourcesComponent from '../components/SourcesComponent';
import FormattedNumberComponent from '../components/FormattedNumberComponent';
import { Toast } from 'primereact/toast';
class InquiriesDetailsBusinesssActivity extends Component {
    constructor(props){
        super(props);
        this.state = {
            history: [],
            business : this.props.business,
            clients : this.props.clients,
            sources: [],
            suppliers: this.props.suppliers,
            paymentBehavior : this.props.payment_behavior,
            payment_behavior_payments: [],
            sics: [],
            insurances : this.props.insurances,
            countries: [],
            selectedImportCountries : null,
            selectedExportCountries : null,
            businessTypes: [],
            selectedBusinessTypes: [],
            commentsOnSuppliers: [],
            commentsOnClients: [],
            selectedCommentsOnClients: [],
            selectedCommentsOnSuppliers: [],
            currencies: [],
            toast: this.props.toast
        }
        if (this.state.clients.exports_to){
          this.state.exports_to = this.state.clients.exports_to.split(",")
        }
        if (this.state.suppliers.imports_from){
          this.state.imports_from = this.state.suppliers.imports_from.split(",")
        }
        this.inquiryService = new InquiryService();
       
    


        this.inquiryService.getBusinessTypes().then(data =>{
          this.setState({businessTypes:data})
          if(this.state.business.business_types){
             this.state.business.business_types.split(",").forEach(type => {
               if(type != null && type != ""){
                 let busType = data.find(busiType => busiType.name == type)
                 this.state.selectedBusinessTypes.push(busType)
               }

            })
          }
        })
        this.inquiryService.getCommentsOnClients().then(data =>{
          this.setState({commentsOnClients:data})
          if(this.state.clients.comments_on_clients){
             this.state.clients.comments_on_clients.split(",").forEach(comment => {
               if (!comment.includes(".")){
                 comment = comment + "."
               }
               if(comment != null && comment != ""){
                 let commentOnclient = data.find(com => com.name == comment)
                 this.state.selectedCommentsOnClients.push(commentOnclient)
               }

            })
          }
        })
        this.inquiryService.getCommentsOnSuppliers().then(data =>{
          this.setState({commentsOnSuppliers:data})
          if(this.state.suppliers.comments_on_suppliers){
             this.state.suppliers.comments_on_suppliers.split(",").forEach(comment => {
               if (!comment.includes(".")){
                 comment = comment + "."
               }
               if(comment != null && comment != ""){
                 let commentOnSupplier = data.find(com => com.name == comment)
                 this.state.selectedCommentsOnSuppliers.push(commentOnSupplier)
               }

            })
          }
        })

        this.inquiryService.getSics().then(data =>{
          this.setState({sics:data})
          sessionStorage.setItem('ses_sics',JSON.stringify(data))
        })

  
        this.handleClickAward = this.handleClickAward.bind(this);
        ///this.handleClientChange = this.handleClientChange.bind(this);
        this.handleClickSic = this.handleClickSic.bind(this);
        //this.handleSicChange = this.handleSicChange.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.handleAddBrand = this.handleAddBrand.bind(this);
        //this.handleBrandChange = this.handleBrandChange.bind(this);
        //this.handleTrademarkChange = this.handleTrademarkChange.bind(this);
        this.handleAddTrademark = this.handleAddTrademark.bind(this);
        this.handleAddCompetitor = this.handleAddCompetitor.bind(this);
        this.handleAddClient = this.handleAddClient.bind(this);
        this.handleAddSupplier = this.handleAddSupplier.bind(this);
        this.deleteClient = this.deleteClient.bind(this);
        this.handleDeleteBrand = this.handleDeleteBrand.bind(this);
        this.handleDeleteTrademark = this.handleDeleteTrademark.bind(this);
        this.handleDeleteAward = this.handleDeleteAward.bind(this);
        this.handleDeleteCompetitor = this.handleDeleteCompetitor.bind(this);
        this.handleDeleteInsurance = this.handleDeleteInsurance.bind(this);
        this.handleAddInsurance = this.handleAddInsurance.bind(this);
        this.handleActivityChange = this.handleActivityChange.bind(this);
        this.nullifyLastPurchaseAmount = this.nullifyLastPurchaseAmount.bind(this);


        this.dataRefresh = this.dataRefresh.bind(this);
        this.handleSicChange = this.handleSicChange.bind(this);
        this.handleBusinessChange = this.handleBusinessChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleSupplierChange = this.handleSupplierChange.bind(this);
        this.handleDeleteSic = this.handleDeleteSic.bind(this);

        
        this.sources = [
            {"value": "interview" , "label": "Interview"},  
            {"value": "website" , "label": "Company Website"},
            {"value": "press" , "label": "Press"},
            {"value": "report" , "label": "Annual Report"},
            {"value": "public" , "label": "Publicly accesible source"},
            {"value": "private" , "label": "Private Source"},
            {"value": "other" , "label": "Other"}
        ];
    }


    dataRefresh(){
      sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(this.state.business));
      sessionStorage.setItem('ses_suppliers_' + this.props.report.id, JSON.stringify(this.state.suppliers));
      sessionStorage.setItem('ses_clients_' + this.props.report.id, JSON.stringify(this.state.clients));
      sessionStorage.setItem('ses_history_' + this.props.report.id, JSON.stringify(this.state.history));
      sessionStorage.setItem('ses_insurances_' + this.props.report.id, JSON.stringify(this.state.insurances));
    }
    componentDidMount(){
      if(!sessionStorage.getItem('ses_countries')){
        this.inquiryService.getCountries().then(data =>{
          this.setState({countries:data})
          sessionStorage.setItem('ses_countries',JSON.stringify(data))
        });
      }else{
       
          this.setState({countries : JSON.parse(sessionStorage.getItem('ses_countries')) })  
          
      }
      if(!sessionStorage.getItem('ses_currency')){
        this.inquiryService.getCurrencies().then(data =>{
          this.setState({currencies: data })
          sessionStorage.setItem('ses_currency',JSON.stringify(data))
        })
      }else{
          this.setState({currencies : JSON.parse(sessionStorage.getItem('ses_currency')) })  
      }

    }
    nullifyLastPurchaseAmount(i, event){
      event.preventDefault();
      let suppliers = this.state.suppliers
      suppliers["suppliers"][i]["last_purchase_amount"] = null
      this.setState({suppliers:suppliers}, function(){
        this.dataRefresh();
      })
    }

    deleteClient(client_indx, event){
      event.preventDefault();
      var clients = this.state.clients;
      clients.clients.splice(client_indx,  1);
      this.setState({clients: clients }, function(){
        this.dataRefresh();
      })
    }

    deleteSupplier(supplier_indx, event){
      event.preventDefault();
      var suppliers = this.state.suppliers;
      suppliers.suppliers.splice(supplier_indx,  1);
      this.setState({suppliers: suppliers }, function(){
        this.dataRefresh();
      })
    }

    injectBusinessSubItems(){
        const business_arr = this.state.business;

        //inject sub-array contents
        business_arr.awards_and_certification = this.state.awards_and_certification;
        business_arr.sics = this.state.sics;
        business_arr.sources = this.state.sources.join(",");
        business_arr.brands = this.state.brands.join(",")
        this.setState({business: business_arr });
        localStorage.setItem('ses_business_'+this.props.orderInfo.report, JSON.stringify(business_arr));

    }

    handleAddSupplier(e){
      e.preventDefault();
      let suppliers = this.state.suppliers

      const suppliers_arr = suppliers.suppliers
      
        if(suppliers_arr.length == 0 || suppliers_arr[suppliers_arr.length - 1].supplier_name != "New supplier"){
          suppliers.suppliers.push({
            "supplier_name": "New supplier",
            "address": {
              "country":{
                "name": "United States"
              }
            }
          })
          this.setState({suppliers: suppliers}, function(){
            this.dataRefresh();
          })
        } else {
            this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter supplier name"});            
        }

    }

    handleSupplierChange(field, indx, parentField, childField, event){
      let suppliers = this.state.suppliers
      if(parentField){
        if(childField){
          if(!suppliers[field][indx][parentField]){
            suppliers[field][indx][parentField] = {}
          }
          suppliers[field][indx][parentField][childField] = event.target.value
        } else{
          suppliers[field][indx][parentField] = event.target.value
        }

      } else{
        if (field == "imports_from"){
          suppliers[field] = event.target.value.join(",")
          this.setState({imports_from: event.target.value})
        } else if (field == "does_not_import"){
          if(event.target.value){
            this.state.imports_from = [];
          }
          suppliers[field] = event.target.value
        } else if (field == "comments_on_suppliers"){
          const selectedCommentsOnSuppliers = event.target.value
          if(selectedCommentsOnSuppliers.length == 0){
            suppliers[field] = null
            this.state.selectedCommentsOnSuppliers = []
          } else {
            suppliers[field] = event.target.value.map(comment => comment.name).join(",")
            this.state.selectedCommentsOnSuppliers = selectedCommentsOnSuppliers
          }
        }
         else {
          suppliers[field] = event.target.value
        }

      }
      this.setState({suppliers:suppliers}, function(){
        this.dataRefresh();
      })
    }

    handleAddClient(e){
        e.preventDefault();
        let clients = this.state.clients
        const clients_arr = clients.clients
       
        if(clients_arr.length == 0 || clients_arr[clients_arr.length - 1].client_legal_name){
          clients.clients.push({"name": "","country": {"name":"United States"}})
          this.setState({clients: clients}, function(){
            this.dataRefresh();
          });
        } else {
            this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter client name"});            
        }

  

    }

    handleClientChange(field, indx, childField, event){
      let clients = this.state.clients
      if(childField){
        clients[field][indx][childField] = event.target.value
      } else{
        if(field == "exports_to"){
          clients[field] = event.target.value.join(",")
          this.setState({exports_to: event.target.value})
        } else if (field == "clients_type"){
          const clients_type = event.target.value
          if (clients_type.length == 0){
            clients[field] = null
          } else {
            clients[field] = event.target.value.join(",")
          }
        } else if (field == "comments_on_clients"){
          const selectedCommentsOnClients = event.target.value
          if(selectedCommentsOnClients.length == 0){
            clients[field] = null
            this.state.selectedCommentsOnClients = []
          } else {
            clients[field] = event.target.value.map(comment => comment.name).join(",")
            this.state.selectedCommentsOnClients = selectedCommentsOnClients
          }
        } else if(field == "does_not_export"){
          if(event.target.value){
            this.state.exports_to = [];
          }

          clients[field] = event.target.value
        }else{
          clients[field] = event.target.value
        }

      }
      this.setState({clients:clients}, function(){
        this.dataRefresh();
        console.log(this.state.selectedCommentsOnClients)
      })
    }

    handleBusinessChange(field, indx, childField, event){
      let business = this.state.business
      if(childField){
        business[field][indx][childField] = event.target.value

      } else {
        if(field == "business_types"){
          const business_types = event.target.value
          if (business_types.length == 0){
            this.state.selectedBusinessTypes = []
            business[field] = ''
          } else {
            let busTypes = ''
            this.state.selectedBusinessTypes = business_types
            business_types.forEach(type => {
              busTypes += type.name + ","
            })
            business[field] = busTypes
          }
        } else {
          business[field] = event.target.value
        }
      }

      this.setState({business:business}, function(){
        console.log(this.state.business)
        this.dataRefresh();
      })
    }
    handleSicChange(indx, event){
      let business = this.state.business
      business["sics"][indx]["sic_code"] = event.target.value
      this.setState({business:business}, function(){
        this.dataRefresh();
      })
    }

    handleDeleteSic(indx){
      let business = this.state.business
      let new_sics = []
      for(let idx in business.sics){
        if(indx != idx){
          new_sics.push(business.sics[idx])
        }
      }
      business.sics = new_sics;
      this.setState({business:business}, function(){
        this.dataRefresh();
      })
    }

    handleBranchChange(indx, field, event){
      let business = this.state.business
      business["branches"][indx][field] = event.target.value
      this.setState({business:business}, function(){
        this.dataRefresh();
      })
    }

    handleActivityChange(id, field, event,type){
        const field_arr = field.split(".");
        const value = event.target.value;
        let item_arr = [];
        let new_arr = [];
        let stateVar = "";
        let isArr = true;
        let hasName = true;

        if(type == "suppliers"){
            item_arr = this.state.suppliers_suppliers;
            stateVar = "suppliers_suppliers";
        }else if(type == "payment_behavior"){
            isArr = false;
            item_arr = this.state.payment_behavior;
            stateVar = "payment_behavior";
        }else if(type == "insurance"){
            item_arr = this.state.insurances;
            stateVar = "insurances";
        }else if(type == "brand"){
            item_arr = this.state.brands;
            stateVar = "brand";
            hasName = false;
        }else if(type == "trademark"){
            item_arr = this.state.trademarks;
            stateVar = "trademark";
            hasName = false;
        }else if(type == "history"){
            item_arr = this.state.history;
            stateVar = "history";
            isArr = false;
        }else if(type == "business"){
            item_arr = this.state.business;
            stateVar = "business";
            isArr = false;
        }else if(type == "sic"){
            item_arr = this.state.sics;
            stateVar = "sics";
        }else if(type == "award"){
            item_arr = this.state.awards_and_certification;
            stateVar = "awards_and_certification";
        }else if(type == "clients"){
            item_arr = this.state.clients;
            stateVar = "clients";
            isArr = false;
        }else if(type == "clients_clients"){
            item_arr = this.state.clients_clients;
            stateVar = "clients_clients";
        }


        if(isArr == true){
            item_arr.map((item,i) => {
                if(i == id){
                    if(hasName == true){
                        if(field_arr.length == 1) {
                            item[field_arr[0]] = value;
                        }else if(field_arr.length == 2){
                            item[field_arr[0]][field_arr[1]] = value;
                        }else if(field_arr.length == 3){
                            item[field_arr[0]][field_arr[1]][field_arr[2]] = value;
                        }
                    }else{
                        item_arr[i] = value;
                    }
                }
                new_arr.push(item);
            });
        }else{
            if(field_arr.length == 1) {
                item_arr[field_arr[0]] = value;
            }else if(field_arr.length == 2){
                item_arr[field_arr[0]][field_arr[1]] = value;
            }else if(field_arr.length == 3){
                item_arr[field_arr[0]][field_arr[1]][field_arr[2]] = value;
            }
            new_arr = item_arr;
        }
       // console.log('ses_'+stateVar+'_'+this.props.orderInfo.report);
        //console.log( JSON.parse(new_arr));
       // console.log(new_arr);

        this.setState({ stateVar : new_arr });


        sessionStorage.setItem('ses_'+stateVar+'_'+this.props.orderInfo.report, JSON.stringify(new_arr));
        this.dataRefresh();
    }


    handleClickAward(e){
          e.preventDefault();
          let business = this.state.business

          const awards_arr = business.awards_and_certification

        if(awards_arr.length == 0 || awards_arr[awards_arr.length - 1].name){
          business.awards_and_certification.push({"name": "",});
          this.setState({business: business}, function(){
            this.dataRefresh();
            //sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
          });
        } else {
            this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter awards and certification"});            
        }
    }


    handleDeleteTrademark(indx, event){
      let business = this.state.business;
      business.trademarks.splice(indx,  1);
      this.setState({business: business }, function(){
        this.dataRefresh();
      })
    }
    handleDeleteBrand(indx, event){
      let business = this.state.business;
      business.brands.splice(indx,  1);
      this.setState({business: business }, function(){
        this.dataRefresh();
      })
    }
    handleDeleteAward(indx, event){
      let business = this.state.business;
      business.awards_and_certification.splice(indx,  1);
      this.setState({business: business }, function(){
        this.dataRefresh();
      })
    }
    handleAddBrand(e){
      e.preventDefault();
      let business = this.state.business
      const brand_arr = business.brands

      if(brand_arr.length == 0 || brand_arr[brand_arr.length - 1].name){
        business.brands.push({"name": "",});
        this.setState({business: business}, function(){
          this.dataRefresh();
          //sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
        });
      } else {
          this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter brand"});            
      }



      

    }
    handleAddTrademark(e){
        e.preventDefault();
        let business = this.state.business
        const trademarks_arr = business.trademarks

        if(trademarks_arr.length == 0 || trademarks_arr[trademarks_arr.length - 1].name){
          business.trademarks.push({"name": "",});
          this.setState({business: business}, function(){
            this.dataRefresh();
            //sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
          });
        } else {
            this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter trademark"});            
        }

    }
    handleAddCompetitor(e){
        e.preventDefault();
        let business = this.state.business

        const competitors_arr = business.competitors

        if(competitors_arr.length == 0 || competitors_arr[competitors_arr.length - 1].name){
          business.competitors.push({"name": "",});
          this.setState({business: business}, function(){
            this.dataRefresh();
            //sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
          });
        } else {
            this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter competitor"});            
        }


    }
    handleDeleteCompetitor(indx, event){
      let business = this.state.business;
      business.competitors.splice(indx,  1);
      this.setState({business: business }, function(){
        this.dataRefresh();
      })
    }
    handleClickSic(e){
      e.preventDefault();

      let business = this.state.business
      const sic_arr = business.sics

      console.log(sic_arr)

      if(sic_arr.length == 0 || sic_arr[sic_arr.length - 1].sic_code.code){
        business.sics.push({"sic_code": {}});
        this.setState({business: business}, function(){
          this.dataRefresh();
        });
      } else {
          this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please select SIC Code"});            
      }

   }


    onSourceChange(e){

        const select_sources = [...this.state.sources];

        if (e.checked)
            select_sources.push(e.value);
        else
            select_sources.splice(select_sources.indexOf(e.value), 1);

        this.setState({ sources: select_sources });

        localStorage.setItem('ses_sources_'+this.props.orderInfo.report, JSON.stringify(select_sources));
        this.injectBusinessSubItems();
    }
    toggleCreditBurea(){
        const currentState = this.state.ActiveCreditBurea;
        this.setState({ ActiveCreditBurea: !currentState });
    }

    handleDeleteInsurance(indx, event){
      const insurances_arr = this.state.insurances;
      insurances_arr.insurances.splice(indx,  1);
      this.setState({insurances: insurances_arr }, function(){
        this.dataRefresh();
      })
    }

    handleAddInsurance(e){
        e.preventDefault();
        const insurances_arr = this.state.insurances;
        const insurances_arr_insurances = insurances_arr.insurances;

        if(insurances_arr_insurances.length == 0 || insurances_arr_insurances[insurances_arr_insurances.length - 1].name){
          insurances_arr.insurances.push({"name": "","comments":""});
          this.setState({insurances: insurances_arr}, function(){
            sessionStorage.setItem('ses_insurances_'+this.props.report.id, JSON.stringify(insurances_arr));
          });
        } else {
            this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter insurance"});            
        }

        
    }
    handleInsuranceChange(insurance_indx, field, event){
      const insurances_arr = this.state.insurances;
      insurances_arr.insurances[insurance_indx][field] = event.target.value;
      this.setState({insurances: insurances_arr}, function(){
        sessionStorage.setItem('ses_insurances_'+this.props.report.id, JSON.stringify(insurances_arr));
      });
    }
    render(){
        const sizes = [
          {label: 'N.A', value: 'NA'},
          {label: 'Small - 0 to 49 employees', value: 'small'},
          {label: 'Medium - 50 to 499 employees', value: 'medium'},
          {label: 'Large - Over 500 Employees', value: 'large'}
        ]
        return(
            <div className="row">
              <Toast ref={this.state.toast} />
                <div className="col-12">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('HISTORY')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <textarea autoFocus onChange={(e) => this.handleBusinessChange("history",0, null, e)} className="form-control form-text-comments" name="history"  value={this.state.business.history} rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('LINE_OF_BUSINESS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                        <div className="col-sm-6" >
                                            <textarea onChange={(e) => this.handleBusinessChange("line_of_business",0, null, e)}  type="text" name="line_of_business" value={this.state.business.line_of_business} className="form-control form-control-sm"/>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <label className="form-field-bold">{<Translation>{ t => <span>{t('SIC_CODES')} </span>}</Translation>}</label>
                                  </div>
                                  <div className="col-sm-6">
                                    <Button icon="pi pi-plus"  onClick={this.handleClickSic} className="p-button-square p-button-secondary ccp-add-btn" />
                                  </div>


                                </div>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <label style={{'color':'red'}}> <strong>Caution: wholesale/retail/manufacturing…</strong></label>
                                  </div>

                                </div>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                        <div className="col-sm-6" >
                                                <div className="row">
                                                {
                                                    this.state.business.sics.map((sic,i) => (
                                                        <div className="col-sm-3" key ={"sic-"+i}>
                                                              <Dropdown options = {this.state.sics} optionLabel = "code" value = {sic.sic_code} onChange = {(e) => this.handleSicChange(i, e)} filter filterBy="code"/>
                                                              <strong>{sic.sic_code.description}</strong>
                                                              <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.handleDeleteSic(i)}  />
                                                        </div>
                                                    ))
                                                }
                                                </div>

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('PRODUCTS') + "/" + t('SERVICES')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                        <div className="col-sm-6" >
                                            <textarea onChange={(e) => this.handleBusinessChange("main_product",0, null, e)} type="text" name="main_product" value={this.state.business.main_product} className="form-control form-control-sm"/>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('TYPE_OF_BUSINESS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                        <div className="col-sm-6" >
                                            <MultiSelect value = {this.state.selectedBusinessTypes}  options={this.state.businessTypes}
                                                  onChange={(e) => this.handleBusinessChange("business_types", 0, null, e)}
                                                  optionLabel="name" placeholder="Select Business Types" filter filterBy="name"/>

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('BRANDS')} </span>}</Translation>}</label>   <Button icon="pi pi-plus"  onClick={this.handleAddBrand} className="p-button-square p-button-secondary ccp-add-btn" />
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    {
                                            this.state.business.brands.map((brand,i) => (
                                            <div className="col-sm-3" key={brand+'-'+i} >
                                              <div className="row" >
                                                   <div className="col-sm-9">
                                                     <input autoFocus onChange={(e) => this.handleBusinessChange("brands", i, "name", e)}
                                                      type="text" name="brand" value={brand.name} className="form-control form-control-sm"/>
                                                  </div>
                                                  <div className = "col-sm-1">
                                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                                      onClick = {(e) => this.handleDeleteBrand(i, e)} value = {null}  />
                                                  </div>
                                              </div>

                                            </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('PROPRIETARY_TRADEMARKS')} </span>}</Translation>}</label>   <Button icon="pi pi-plus"  onClick={this.handleAddTrademark} className="p-button-square p-button-secondary ccp-add-btn" />
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    {
                                            this.state.business.trademarks.map((trade,i) => (
                                            <div className="col-sm-3" key={trade+'-'+i} >
                                              <div className="row" >
                                                   <div className="col-sm-9">
                                                     <input autoFocus onChange={(e) => this.handleBusinessChange("trademarks", i, "name", e)}
                                                      type="text" name={"trademark"} value={trade.name} className="form-control form-control-sm"/>
                                                  </div>
                                                  <div className = "col-sm-1">
                                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                                      onClick = {(e) => this.handleDeleteTrademark(i, e)} value = {null}  />
                                                  </div>
                                              </div>

                                            </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('AWARDS_AND_CERTIFICATIONS')} </span>}</Translation>}</label> <Button icon="pi pi-plus"  onClick={this.handleClickAward} className="p-button-square p-button-secondary ccp-add-btn" />
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    {
                                        this.state.business.awards_and_certification.map((award,i) => (
                                            <div className="col-sm-3" key={award+'-'+i} >
                                                <input onChange={(e) => this.handleBusinessChange("awards_and_certification", i, "name", e)} placeholder="award name" type="text" name="name" value={award.name} className="form-control form-control-sm"/>
                                                <div className="spacer"/>
                                                <DateComponent date = {award.date} update_date = {(e) => this.handleBusinessChange("awards_and_certification", i,"date", e)}/>
                                                  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                                    onClick = {(e) => this.handleDeleteAward(i, e)} value = {null}  />
                                                <div className="spacer"/>
                                                <textarea rows = "2" onChange={(e) => this.handleBusinessChange("awards_and_certification", i, "organization", e)}  placeholder="organization" type="text" name="award.organization" value={award.organization} className="form-control form-control-sm"/>
                                                <div className="spacer"/>
                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('MEMBERSHIPS_IN_ASSOCIATIONS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                  <div className="col-sm-6" >
                                    <textarea onChange={(e) => this.handleBusinessChange("membership_in_associations", 0, null, e)}  type="text" name="membership_in_associations"
                                              value={this.state.business.membership_in_associations} className="form-control form-control-sm"/>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('STAFF_NUMBER')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                      <FormattedNumberComponent name = "value" type='input'
                                        update_value={(e)=>this.handleBusinessChange("staff_number", 0, null, e)}
                                        value={this.state.business.staff_number} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('GROUP_STAFF_NUMBER')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                      <FormattedNumberComponent name = "value" type='input'
                                        update_value={(e)=>this.handleBusinessChange("consolidated_staff", 0, null, e)}
                                        value={this.state.business.consolidated_staff} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('SIZE_RANGE')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                        <Dropdown value={this.state.business.size_range} options={sizes}
                                          onChange={(e) => this.handleBusinessChange("size_range", 0, null, e)} optionLabel="label" placeholder="Select Size Range"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTS_ON_STAFF') +"/"+ t('STAFF_COMPOSITION')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                         <textarea onChange={(e) => this.handleBusinessChange("comments_on_staff", 0, null, e)}  className="form-control form-text-comments" name="comments_on_staff"  value={this.state.business.comments_on_staff} rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('STAFF_SOURCES')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                      <SourcesComponent sources = {this.state.business.sources} update_date = {(e) => this.handleBusinessChange("sources", 0, null, e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-hr-sm"/>
                    <div className="form-group">

                            <span className="form-title">{<Translation>{ t => <span>{t('CLIENTS')} </span>}</Translation>}</span>

                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('TYPES_OF_CLIENTS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                        <MultiSelect value={this.state.clients.clients_type?.split(",")}
                                          options={['B2C', 'B2B', 'Government', 'Others']} onChange={(e) => this.handleClientChange("clients_type", 0, null, e)}
                                          placeholder="Select Types" display="chip" filter="true" selectAll="true"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTS_ON_CLIENTS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                  <div className="col-sm-3" >
                                    <MultiSelect value = {this.state.selectedCommentsOnClients}  options={this.state.commentsOnClients}
                                      onChange={(e) => this.handleClientChange("comments_on_clients", 0, null, e)} optionLabel="name"
                                        placeholder="Select Comments Here" filterBy="name" style = {{width: '100%'}} filter="true" selectAll="true"/>
                                    </div>
                                    <div className="col-sm-6" >
                                     <textarea onChange={(e) => this.handleClientChange("comment_details", 0, null, e)}  className="form-control form-text-comments" name="comments"  value={this.state.clients.comment_details}  rows="1"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('EXPORTS_TO')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-12" >
                                      <Chips disabled={this.state.clients.does_not_export} value={this.state.exports_to} onChange={(e) => this.handleClientChange("exports_to", 0, null, e)} separator="," />
                                    </div>
                                </div>
                                <br/>
                                <div className = "row">
                                  <div className="col-sm-6" >
                                    <ToggleButton checked={this.state.clients.does_not_export} onChange={(e) => this.handleClientChange("does_not_export", 0, null, e)}
                                      onLabel="Company Does Not Export" offLabel="Company Does Not Export"  onIcon="pi pi-times" offIcon="pi pi-check"/>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('ADD_CLIENT')} </span>}</Translation>}</label> <Button icon="pi pi-plus"  onClick={this.handleAddClient} className="p-button-square p-button-secondary ccp-add-btn" />
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    {
                                        this.state.clients.clients.map((client,i) => (
                                            <div className="col-sm-3" key={client+'-'+i} >
                                                <input onChange={(e) => this.handleClientChange("clients",i ,"client_legal_name", e)} placeholder="Client's name (Legal Name)" type="text" name="name" value={client.client_legal_name} className="form-control form-control-sm"/>
                                                <div className="spacer"/>

                                                <Dropdown value = {client.country}  options={this.state.countries}
                                                  onChange={(e) => this.handleClientChange("clients", i, "country", e)} optionLabel="name"
                                                    placeholder="Select a country Here" filter filterBy="name"/>
                                                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deleteClient(i, e)} value = {null}  />
                                                <div className="spacer"/>
                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-hr-sm"/>
                    <div className="form-group">
                            <span className="form-title">{<Translation>{ t => <span>{t('SUPPLIERS')} </span>}</Translation>}</span>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTS_ON_SUPPLIERS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                      <MultiSelect value = {this.state.selectedCommentsOnSuppliers}  options={this.state.commentsOnSuppliers}
                                        onChange={(e) => this.handleSupplierChange("comments_on_suppliers", 0, null, null, e)} optionLabel="name"
                                          placeholder="Select Comments Here" filterBy="name" style = {{width: '100%'}} filter="true" selectAll="true"/>

                                        <div className="spacer"/>
                                        <textarea autoFocus onChange={(e) => this.handleSupplierChange("comments_details", 0 , null, null, e)}  className="form-control form-text-comments" name="suppliers_comments_details"  value={this.state.suppliers.comments_details} rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('IMPORTS_FROM')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6" >
                                      <Chips disabled={this.state.suppliers.does_not_import} value={this.state.imports_from} onChange={(e) => this.handleSupplierChange("imports_from", 0, null, null, e)} separator="," />
                                    </div>
                                </div>
                                <br/>
                                <div className = "row">
                                  <div className="col-sm-6" >
                                    <ToggleButton checked={this.state.suppliers.does_not_import} onChange={(e) => this.handleSupplierChange("does_not_import", 0, null, null, e)}
                                      onLabel="Company Does Not Import" offLabel="Company Does Not Import"  onIcon="pi pi-times" offIcon="pi pi-check"/>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-12">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('ADD_SUPPLIER')} </span>}</Translation>}</label> <Button icon="pi pi-plus"  onClick={this.handleAddSupplier} className="p-button-square p-button-secondary ccp-add-btn" />
                            </div>
                            <div className="col-sm-9">
                            <Accordion activeIndex="0">
                                {
                                    this.state.suppliers.suppliers?.map((supplier,i) => (
                                        <AccordionTab key={"key-"+i} header={
                                                        <React.Fragment>
                                                            <div className="col-12" style={{'width':'400px'}}>
                                                                <div className="row">
                                                                    <div className="col-5">
                                                                        <input type="text" autoFocus onChange={(e) => this.handleSupplierChange("suppliers", i , "supplier_name", null, e)}
                                                                          placeholder="legal name" name="company.legal.name" value={supplier.supplier_name} className="form-control form-control-sm"/>
                                                                    </div>
                                                                    <div className="col-5">
                                                                      <Dropdown value = {supplier.address.country}  options={this.state.countries}
                                                                        onChange={(e) => this.handleSupplierChange("suppliers", i, "address", "country", e)} optionLabel="name"
                                                                          placeholder="Select a country Here" filter filterBy="name"/>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </React.Fragment>}>

                                                <div className="spacer"/>
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <label className="form-field-bold">{<Translation>{ t => <span>{t('COMPANY_ADDRESS')} </span>}</Translation>}</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <label className="form-field-bold">{<Translation>{ t => <span>{t('COMPANY_DATA')} </span>}</Translation>}</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <label className="form-field-bold">{<Translation>{ t => <span>{t('TRADE_REFERENCE')} </span>}</Translation>}</label>
                                                        </div>

                                                    </div>

                                                </div>
                                                <br></br>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <div className="row">
                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('STREET')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input onChange={(e) => this.handleSupplierChange("suppliers", i , "address", "street", e)} type = "text" className="form-control form-control-sm" value = {supplier.address.street} />
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('REGISTRATION_ID')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input onChange={(e) => this.handleSupplierChange("suppliers", i , "registration_id", null, e)} type = "text" className="form-control form-control-sm" value = {supplier.registration_id} />
                                                                </div>

                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('PAYMENT_TERMS')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input onChange={(e) => this.handleSupplierChange("suppliers", i , "payment_terms", null, e)} type = "text" className="form-control form-control-sm" value = {supplier.payment_terms}/>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('CITY')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input onChange={(e) => this.handleSupplierChange("suppliers", i , "address", "city", e)}  type = "text" className="form-control form-control-sm"  value = {supplier.address.city}/>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('CONTACT_NAME')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input type = "text" className="form-control form-control-sm" value= {supplier.contact_name} onChange={(e) => this.handleSupplierChange("suppliers", i , "contact_name", null, e)}  />
                                                                </div>

                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('NUMBER_OF_DAYS_GRANTED')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input type = "number" onChange={(e) => this.handleSupplierChange("suppliers", i , "number_of_days_granted", null, e)} className="form-control form-control-sm" value = {supplier.number_of_days_granted}/>
                                                                </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-2">
                                                                <label>{<Translation>{ t => <span>{t('POSTAL_CODE')} </span>}</Translation>}</label>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <input onChange={(e) => this.handleSupplierChange("suppliers", i , "address", "postal_code", e)}  type = "text" className="form-control form-control-sm" value = {supplier.address.postal_code} />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <label>{<Translation>{ t => <span>{t('CONTACT_POSITION')} </span>}</Translation>}</label>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <input type = "text" className="form-control form-control-sm" value= {supplier.contact_position} onChange={(e) => this.handleSupplierChange("suppliers", i , "contact_position", null, e)}  />
                                                            </div>

                                                            <div className="col-sm-2">
                                                                <label>{<Translation>{ t => <span>{t('MAX_CREDIT_LINE')} </span>}</Translation>}</label>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <FormattedNumberComponent name = "value" type='input' update_value={(e) => this.handleSupplierChange("suppliers", i , "max_credit_line", null, e)}
                                                                  value = {supplier.max_credit_line} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group">
                                                      <div className="row">
                                                              <div className="col-sm-2">
                                                                  <label>Country</label>
                                                              </div>
                                                              <div className="col-sm-2">
                                                                <Dropdown value = {supplier.address.country}  options={this.state.countries} onChange={(e) => this.handleSupplierChange("suppliers", i, "address", "country", e)}
                                                                  optionLabel="name" placeholder="Select a country Here" filter filterBy="name"/>
                                                              </div>
                                                              <div className="col-sm-2">
                                                                  <label>{<Translation>{ t => <span>{t('PHONE_NUMBER')} </span>}</Translation>}</label>
                                                              </div>
                                                              <div className="col-sm-2">
                                                                  <input type = "text" className="form-control form-control-sm" value= {supplier.contact_phone_number} onChange={(e) => this.handleSupplierChange("suppliers", i , "contact_phone_number", null, e)} />
                                                              </div>

                                                              <div className="col-sm-2">
                                                                  <label>{<Translation>{ t => <span>{t('MONTHLY_PURCHASE_AMOUNT')} </span>}</Translation>}</label>
                                                              </div>
                                                              <div className="col-sm-2">
                                                                <FormattedNumberComponent name = "value" type='input' update_value={(e) => this.handleSupplierChange("suppliers", i , "monthly_purchase_amount", null, e)}
                                                                  value = {supplier.monthly_purchase_amount} />
                                                              </div>
                                                      </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                                <div className="col-sm-2">

                                                                </div>
                                                                <div className="col-sm-2">
                                                                  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deleteSupplier(i, e)} />
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('SUPPLIER_SINCE')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <DateComponent date = {supplier.supplier_since} update_date = {(e) => this.handleSupplierChange('suppliers', i, "supplier_since", null, e)}/>
                                                                </div>

                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('LAST_PURCHASE_AMOUNT')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                  <div className = "row" >
                                                                    <div className = "col-sm-6">
                                                                      <FormattedNumberComponent name = "value" type='input' update_value={(e) => this.handleSupplierChange("suppliers", i , "last_purchase_amount", "value", e)}
                                                                        value={supplier.last_purchase_amount?.value} />
                                                                    </div>
                                                                    <div className = "col-sm-6">
                                                                      <Dropdown value = {supplier.last_purchase_amount?.currency} options={this.state.currencies} onChange={(e)=>this.handleSupplierChange("suppliers",i, "last_purchase_amount", "currency", e)} name = "currency" optionLabel="code" placeholder="Select a Currency" filter/>
                                                                    </div>
                                                                  </div>
                                                                  <div className = "row" >
                                                                    <div className = "col-4">
                                                                    </div>
                                                                    <div className = "col-4">
                                                                      <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                                                                        onClick={(e) => this.nullifyLastPurchaseAmount(i, e)} style={{'top': '2px'}} />
                                                                    </div>
                                                                    <div className = "col-4">
                                                                    </div>

                                                                  </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                                <div className="col-sm-2">

                                                                </div>
                                                                <div className="col-sm-2">

                                                                </div>
                                                                <div className="col-sm-2">
                                                                  <label>Products Supplied</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                  <textarea className="form-control form-text-comments" rows="2"
                                                                    value={supplier.products_supplied} onChange={(e) => this.handleSupplierChange("suppliers", i , "products_supplied", null, e)}  />
                                                                </div>

                                                                <div className="col-sm-2">
                                                                    <label>Last purchase date</label>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <DateComponent date = {supplier.last_purchase_date} update_date = {(e) => this.handleSupplierChange('suppliers', i, "last_purchase_date", null, e)}/>
                                                                </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                                <div className="col-sm-2"></div>
                                                                <div className="col-sm-3"></div>
                                                                <div className="col-sm-2">
                                                                    <label>{<Translation>{ t => <span>{t('SUPPLIERS_OPINION')} </span>}</Translation>}</label>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                <input type="text" placeholder="suppliers opinion"  onChange={(e) => this.handleSupplierChange("suppliers", i, "supplier_opinion", null, e)} value={supplier.supplier_opinion} className="form-control form-control-sm"/>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                                <textarea onChange={(e) => this.handleSupplierChange("suppliers", i, "comments", null, e)} className="form-control form-text-comments" name={"supplier_comments"} value={supplier.comments} rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                        </AccordionTab>
                                    ))
                                }
                            </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="spacer"/>
                    <InquiriesDetailsPaymentBehavior payment_behavior = {this.state.paymentBehavior} report_id = {this.props.report.id} />
                    <div className="spacer"/>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold"><Translation>{ t => <span className="strong"> {t('COMPETITORS')} </span>}</Translation> </label>   <Button icon="pi pi-plus"  onClick={this.handleAddCompetitor} className="p-button-square p-button-secondary ccp-add-btn" />
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    {
                                            this.state.business.competitors.map((competitor,i) => (
                                            <div className="col-sm-3" key={competitor+'-'+i} >
                                              <div className="row" >
                                                   <div className="col-sm-9">
                                                     <input autoFocus onChange={(e) => this.handleBusinessChange("competitors", i, "name", e)}
                                                      type="text" name={"competitor"} value={competitor.name} className="form-control form-control-sm"/>
                                                  </div>
                                                  <div className = "col-sm-1">
                                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                                      onClick = {(e) => this.handleDeleteCompetitor(i, e)} value = {null}  />
                                                  </div>
                                              </div>

                                            </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >

                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('INSURANCE')} </span>}</Translation>}</label> <Button icon="pi pi-plus"  onClick={this.handleAddInsurance} className="float-right p-button-square p-button-secondary ccp-add-btn" />
                            </div>
                            <div className="col-sm-9">
                                <div className="row">
                                    {
                                        this.state.insurances?.insurances.map((insurance,i) => (
                                            <div className="col-sm-3" key={i} >

                                                <input onChange={(e) => this.handleInsuranceChange(i, "name", e)} placeholder="insurance name" type="text" name={"insurance.name"} value={insurance.name} className="form-control form-control-sm"/>
                                                <div className="spacer"/>
                                                <textarea onChange={(e) => this.handleInsuranceChange(i, "comments", e)}  className="form-control form-text-comments" name={"insurance.comments"} value={insurance.comments} rows="3"></textarea>
                                                <div className="spacer"/>
                                                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                                  onClick = {(e) => this.handleDeleteInsurance(i, e)} value = {null}  />
                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row" >
                            <div className="col-sm-3">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('INDUSTRY_SITUATION')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-9">

                                <div className="row">
                                        <div className="col-sm-6" >
                                            <textarea onChange={(e) => this.handleBusinessChange("industry_situation", 0, null, e)} type="text" name="industry.situation" value={this.state.business.industry_situation} className="form-control form-text-comments" rows="3"></textarea>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default InquiriesDetailsBusinesssActivity