import React, { Component } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import InquiryService from '../service/InquiryService';
import {formatDate} from '../components/Utils';
import { Toast } from 'primereact/toast';
import { Translation } from 'react-i18next';

class OrderComments extends Component {
  constructor(props){
    super(props);
    const tokenJSON = JSON.parse(sessionStorage.getItem("token"))
    this.state = {
      newComment: '',
      orderInfo: this.props.orderInfo,
      user: tokenJSON.user,
      customerEmails: this.props.customer_email,
      communications: [],
      selectedFile: null,
      toast: this.props.toast
    }
    this.inquiryService = new InquiryService();
    this.save = this.save.bind(this);
    this.saveAndSendToClient = this.saveAndSendToClient.bind(this);
    this.saveAndSendToAnalyst = this.saveAndSendToAnalyst.bind(this);
    this.sendCommunicationEmail = this.sendCommunicationEmail.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.inquiryService.getOrderComunications(this.state.orderInfo.id)
    .then(data => {
      this.setState({communications: data})

    })
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
  }

  handleFileUploadChange(event){
    if (!event.files) {
      this.setState({selectedFile: null})
      return;
    }
    for (let file in event.files){
      this.setState({selectedFile: event.files[file]})
    }
  }

  parseFileName(fileName){
    let parts = fileName ?  fileName.split("/") : ["null"];
    return parts[parts.length - 1] !== "null" ? parts[parts.length - 1] : "" ;
  }

  sendCommunicationEmail(communication, mailingList){
    let data = {}
    data['to'] = mailingList
    data['subject'] = 'New Communication on Order : ' + this.state.orderInfo.reference
    data['message'] = communication.comment
    this.inquiryService.sendEmail(data).then(response => {
      this.props.toast.current.show({severity:'success', summary:'Sent !', detail: "Email Sent successfully"});
    }).catch(err => {
      this.props.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(err.response.data)});
    })
  }

  saveAndSendToClient(){
    if(this.state.newComment.length == 0){
      this.props.toast.current.show({severity:'error', summary:'Canceled !', detail: "Please add a communication text"});
      return;
    }
    if(!this.state.customerEmails){
      this.props.toast.current.show({severity:'error', summary:'Canceled !', detail: "Customer does not have an email list"});
      return;
    }
    var bodyFormData = new FormData();

    let communication = {}
    communication.comment = this.state.newComment
    communication.order = this.state.orderInfo
    communication.owner = {username: this.state.user.username}

    bodyFormData.append('file', this.state.selectedFile);
    bodyFormData.append('data', JSON.stringify(communication));
    this.inquiryService.createOrderCommunication(this.state.orderInfo.id, bodyFormData).then(
      data=>{
        //update order comment notification
        this.inquiryService.updateNewCommentOrder(this.state.orderInfo.id);

        let communications = this.state.communications
        communications.push(data)
        this.setState({communications: communications, selectedFile: null, newComment: ''})
        this.props.toast.current.show({severity:'success', summary:'Created !', detail: "Comment Has Been Added"});
        this.sendCommunicationEmail(data, this.state.customerEmails.split(","))
      }
    ).catch(err=>{
      this.props.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(err.response.data)});
    })
  }

  saveAndSendToAnalyst(){
    if(this.state.newComment.length == 0){
      this.props.toast.current.show({severity:'error', summary:'Canceled !', detail: "Please add a communication text"});
      return;
    }
    if(!this.state.orderInfo.assigned_to){
      this.props.toast.current.show({severity:'error', summary:'Canceled !', detail: "Order is not assigned to an Analyst"});
      return;
    }
    if(!this.state.orderInfo.assigned_to.user){
      this.props.toast.current.show({severity:'error', summary:'Canceled !', detail: "Assigned Analyst does not have a user profile. Please Contact the admin"});
      return;
    }
    if(!this.state.orderInfo.assigned_to.user.email){
      this.props.toast.current.show({severity:'error', summary:'Canceled !',
          detail: "Assigned Analyst does not have an email address. Please Contact the admin"});
      return;
    }
    var bodyFormData = new FormData();

    let communication = {}
    communication.comment = this.state.newComment
    communication.order = this.state.orderInfo
    communication.owner = {username: this.state.user.username}

    bodyFormData.append('file', this.state.selectedFile);
    bodyFormData.append('data', JSON.stringify(communication));
    this.inquiryService.createOrderCommunication(this.state.orderInfo.id, bodyFormData).then(
      data=>{
        //update order comment notification
        this.inquiryService.updateNewCommentOrder(this.state.orderInfo.id);

        let communications = this.state.communications
        communications.push(data)
        this.setState({communications: communications, selectedFile: null, newComment: ''})
        this.props.toast.current.show({severity:'success', summary:'Created !', detail: "Comment Has Been Added"});
        this.sendCommunicationEmail(data, [this.state.orderInfo.assigned_to.user.email])
      }
    ).catch(err=>{
      this.props.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(err.response.data)});
    })
  }

  sendEmail(data){
    let sendTo = ["info@cristalcredit.com"];
    let subject = "New Communication On Order " + this.state.orderInfo.client_reference
    let msg = "New Communication from user : " + data.owner.username + " on " + formatDate(data.date) + " \n"
    msg += data.owner.username + " is saying : \n"
    msg += data.comment + " \n"
    //if (data.file != "/null"){
    if(data.file.indexOf("null") < 0){
      msg += "Link to the uploaded File : " + process.env.REACT_APP_HOST + "/wand2/order/" + data.id + "/communications/upload" + "\n"
    }
   
    this.inquiryService.sendEmail({subject: subject,
                                  message : msg,
                                  to : sendTo })
  }

  save(){
    if(this.state.newComment.length == 0){
      this.props.toast.current.show({severity:'error', summary:'Canceled !', detail: "Please add a communication text"});
      return;
    }

    var bodyFormData = new FormData();

    let communication = {}
    communication.comment = this.state.newComment
    communication.order = this.state.orderInfo
    communication.owner = {username: this.state.user.username}

    bodyFormData.append('file', this.state.selectedFile);
    bodyFormData.append('data', JSON.stringify(communication));
    this.inquiryService.createOrderCommunication(this.state.orderInfo.id, bodyFormData).then(
      data=>{
        //update order comment notification
        this.inquiryService.updateNewCommentOrder(this.state.orderInfo.id);
        if(!this.state.user.is_staff){
          this.sendEmail(data)
        }
        let communications = this.state.communications
        communications.push(data)
        this.setState({communications: communications, selectedFile: null, newComment: ''})
        this.props.toast.current.show({severity:'success', summary:'Created !', detail: "Comment Has Been Added"});
      }
    ).catch(err=>{
      this.props.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(err.response.data)});
    })
  }
  render(){
    return(
      <div className = "row">
        <Toast ref={this.state.toast} />
        <div className = "col-5">
          <div className="form-group">
            <span className="form-title"><Translation>{ t => t('ADD_COMMENT')}</Translation></span>
            <br></br>
            <br></br>
            <div className="row" >
                <div className="col-sm-3">
                    <label className="form-field-bold"><Translation>{ t => t('COMMUNICATION')}</Translation></label>
                </div>
                <div className="col-sm-9">
                    <textarea className="form-control form-control-sm" rows = "3"
                      value = {this.state.newComment} onChange={(e)=>{this.setState({newComment: e.target.value})}} style = {{'resize': 'horizontal'}}/>
                </div>
            </div>
            <br></br>
            <div className="row" >
                <div className="col-sm-3">
                    <label className="form-field-bold"><Translation>{ t => t('FILE')}</Translation></label>
                </div>
                <div className="col-sm-9">
                    <FileUpload customUpload uploadHandler={this.handleFileUploadChange} chooseLabel="Add File"
                        onRemove = {this.handleFileUploadChange} name="file" maxFileSize="1000000" auto/>
                </div>
            </div>
          </div>
          <div className="row" >
                <div className="col-sm-3">

                </div>
                <div className="col-sm-3">
                    <Button onClick={this.save} label={<Translation>{ t => t('ADD_COMMENT')}</Translation>} className="p-button-sm" icon="pi pi-plus" mode="basic"/>
                </div>
                <div className="col-sm-3">
                    <Button hidden={true || !this.state.user.is_staff} onClick={this.saveAndSendToClient} label={<Translation>{ t => t('ADD_COMMENT_CLIENT')}</Translation>}
                      className="p-button-sm" icon="pi pi-check" mode="basic"/>
                </div>
                <div className="col-sm-3">
                    <Button hidden={true} onClick={this.saveAndSendToAnalyst} label={<Translation>{ t => t('ADD_COMMENT_ANALYST')}</Translation>} className="p-button-sm" icon="pi pi-check" mode="basic"/>
                </div>
          </div>

        </div>
        <div className = "col-7">
          <br></br>
          {
            this.state.communications.map((communication, i) => {
              return(
                <div>
                <div className = "row">
                  <br></br>
                  <div className="form-group" style = {{'width': '-webkit-fill-available'}}>
                    <span className="form-title"><Translation>{ t => t('COMMENT_FROM')}</Translation> <strong> {communication.owner.username}</strong></span>
                    <br></br>
                    <div className="row" >
                        <div className="col-sm-3">
                            <label className="form-field-bold"><Translation>{ t => t('DATE')}</Translation></label>
                        </div>
                        <div className="col-sm-9">
                            <input type = "text" className="form-control form-control-sm" value={communication.date} readOnly />
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-sm-3">
                            <label className="form-field-bold"><Translation>{ t => t('COMMENT')}</Translation></label>
                        </div>
                        <div className="col-sm-9">
                            <textarea type = "text" className="form-control form-control-sm" value={communication.comment} readOnly style = {{'resize': 'auto'}} />
                        </div>
                    </div>
                    <div className = "row">
                      <div className="col-sm-3">
                          <label className="form-field-bold"><Translation>{ t => t('UPLOADED_FILE')}</Translation></label>
                      </div>
                      <div className="col-sm-3">
                      </div>
                      <div className="col-sm-6"  >
                        <a href={process.env.REACT_APP_HOST + "/wand2/order/" + communication.id + "/communications/upload"}>
                          <span style = {{'color': 'green'}}><strong>{this.parseFileName(communication.file)}</strong></span>
                        </a>
                      </div>
                    </div>
                  </div>

                </div>
                <br></br>
                </div>
              )

          })
          }

        </div>
      </div>
    )
  }
}

export default OrderComments;
