import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

class FormattedNumberComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      //value: this.props.value,
      type: 'input'
    }
    if(this.props.type){
      this.state.type = this.props.type
    }
    this.valueChangeHandler = this.valueChangeHandler.bind(this);
  }

  valueChangeHandler(values, sourceInfo){
    let { formattedValue, value } = values;
    if (value == ''){
      value = null
    }
    this.props.update_value({target: {value:value}});
    //this.setState({value: value})
  }



  render(){
    return (
      <NumberFormat
        value={this.props.value}
        displayType={this.state.type}
        className="form-control form-control-sm"
        thousandSeparator={true}
        onValueChange={this.valueChangeHandler}
      />
    )
  }


}

export default FormattedNumberComponent;
