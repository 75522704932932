import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';

class Countries extends Component {
  constructor(props){
    super(props);
    this.state = {
      countries: [],
      loading: true,
      selectedCountry: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    }
    this.countryNameBodyTemaplte = this.countryNameBodyTemaplte.bind(this);
    this.handleCountryClick = this.handleCountryClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCountryNameChange = this.handleCountryNameChange.bind(this);
    this.handleCountryIsoChange = this.handleCountryIsoChange.bind(this);
    this.delete = this.delete.bind(this);
    this.inquiryService = new InquiryService();
  }

  componentDidMount(){
    this.inquiryService.getCountries().then(data =>{
    this.setState({
      countries: data, loading: false
      })
    })
  }

  handleCountryNameChange(event){
    var selectedCountry  = this.state.selectedCountry
    selectedCountry.name = event.target.value
    this.setState({selectedCountry: selectedCountry})
  }

  handleCountryIsoChange(event){
    var selectedCountry  = this.state.selectedCountry
    selectedCountry.country_iso = event.target.value
    this.setState({selectedCountry: selectedCountry})
  }

  handleSaveClick(){
    if(this.state.selectedCountry.id == 0){
      this.inquiryService.createCountry(this.state.selectedCountry).then(data => {
        let countries = this.state.countries
        countries.push(data)
        this.setState({countries:countries,selectedCountry: data,button:{label:'Save',icon: 'pi pi-check'}})
        alert("Country Created !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    } else{
      this.inquiryService.updateCountry(this.state.selectedCountry.id, this.state.selectedCountry).then(data => {
        console.log(data);
        alert("Country Updated !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    }
  }

  delete(){
    if(this.state.selectedCountry){
      this.inquiryService.deleteCountry(this.state.selectedCountry.id).then(resp => {
        alert("deleted")
        this.inquiryService.getCountries().then(data =>{
        this.setState({
          countries: data, loading: false
          })
        })
      }).catch(err => {
        console.log(err.response)
        alert("error")
      })
    }

  }
  cancel(){
    this.setState({
      selectedCountry: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }
  handleCountryClick(rowData){
    this.setState({
      selectedCountry: rowData,
      button:{
        label:'Save',
        icon: 'pi pi-check'
      }
    })
  }
  countryNameBodyTemaplte(rowData){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleCountryClick(rowData)} > {rowData.name}</a>
        </React.Fragment>
    );
  }

  render(){
    return (
      <div>
        <div className="condiv">
          <div className="row">
            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.countries}
                  loading={this.state.loading}
                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No Countries found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="name" filterField="name" field="name" header="Name" body={this.countryNameBodyTemaplte} sortable filter filterPlaceholder="Search by country Name"/>
                  <Column field="country_iso" header="ISO" />
              </DataTable>
            </div>
            <div className = "col-4">
              <div className="col-sm-9" style={{'margin': '5px'}}>
                <input id = "name" onChange={this.handleCountryNameChange} type="text" placeholder="country name" value={this.state.selectedCountry.name} className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}}/>
                <input id = "name" onChange={this.handleCountryIsoChange} type="text" placeholder="country iso" value={this.state.selectedCountry.country_iso} className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}}/>
                <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
                <Button hidden={this.state.selectedCountry.id == 0} onClick={this.delete} label="Delete" className="p-button-sm" icon="pi pi-trash" className="p-button-danger" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Countries
