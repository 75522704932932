import React, { Component } from 'react';
import MainHeader from '../components/MainHeader'
import { TabView,TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';

import AdminAdmin from './AdminAdmin.js';
import AdminAttributes from './AdminAttributes.js'
import AdminLists from './AdminLists.js';

class Admin extends Component {
  constructor(props){
    super(props)
    console.log(props, props);
    this.state = {
      toast: this.props.toast,
      activeIndex: 0,
      tabs:[],
      users: [],
      companies:[],
      clients:[],
      delays: [],
      suppliers:[],
      countries:[],
      countryOverviews: [],
      commentsOnSuppliers:[],
      commentsOnClients:[],
      legalForms: [],
      regStatuses: [],
      managerPositions: [],
      affiliationTypes: [],
      businessTypes: [],
      currencies:[],
      apiKeys: []
    }
    this.onTabChange = this.onTabChange.bind(this);
  }
  handleCloseTab(tab_id){
      const tab_toClose = tab_id.split("_")
      const id = tab_toClose[1];
      const tab_items = this.state.tabs;
      tab_items.map((tab,i) => {
          if(tab.id == id){
              tab_items.splice(i,1);
          }
      });
     this.setState({tabs:tab_items});
     this.setState({activeTabIndex : (tab_items.length - 1) });

  }
  renderAdministrationTabContent(){
    return (
      <div>
        <AdminAdmin toast = {this.state.toast}/>
      </div>
    )
  }

  renderAttributesTabContent(){
    return (
      <div>
        <AdminAttributes toast = {this.state.toast}/>
      </div>
    )
  }

  renderListsTabContent(){
    return (
      <div>
        <AdminLists toast = {this.state.toast}/>
      </div>
    )
  }
  componentDidMount(){
    const tabs_arr = this.state.tabs;
    //admin admin
    const admin_admin_tab = {
      id :1,
      sort_title: 'Administration',
      title: <React.Fragment>
                      <i className="pi pi-users" style={{'fontSize': '1em'}}></i> Administration
              </React.Fragment>,
      content: this.renderAdministrationTabContent()
    }

    // admin attributes
    const admin_attributes_tab = {
      id :2,
      sort_title: 'Attributes',
      title: <React.Fragment>
                      <i className="pi pi-star-o" style={{'fontSize': '1em'}}></i> Attributes
              </React.Fragment>,
      content: this.renderAttributesTabContent()
    }

    // admin lists
    const admin_lists_tab = {
      id :3,
      sort_title: 'Lists',
      title: <React.Fragment>
                      <i className="pi pi-list" style={{'fontSize': '1em'}}></i> Lists
              </React.Fragment>,
      content: this.renderListsTabContent()
    }
    tabs_arr.push(admin_admin_tab);
    tabs_arr.push(admin_attributes_tab);
    tabs_arr.push(admin_lists_tab);
    this.setState({tabs : tabs_arr});

  }



  onTabChange(e){
    let tab_index = e.index;
    const tab_items = this.state.tabs;
    if(tab_items.length == 1){
        tab_index = 0;
    }
    this.setState({ activeTabIndex: tab_index});
  }


  render() {
      return (


          <div className="condiv">
             <MainHeader/>
               <br></br>
                <div className="col-12">
                  <Toast ref={this.state.toast} />
                  <TabView activeIndex={this.state.activeTabIndex}  onTabChange={this.onTabChange}>
                      {

                          this.state.tabs.map((tab,i) => (
                              <TabPanel key={i} header={tab.title} headerStyle={tab.headerStyle}>{tab.content}</TabPanel>
                          ))
                      }

                  </TabView>

                  </div>



          </div>
          )
      }
    }

export default Admin
