import React, { Component } from 'react';
//import { Accordion, AccordionTab } from 'primereact/accordion';
//import { Button } from 'primereact/button';
//import {Checkbox} from 'primereact/checkbox';
//import { Calendar } from 'primereact/calendar';
//import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import InquiriesDetailsShareholdersInformation from './InquiriesDetailsShareholdersInformation.js';
import InquiriesDetailsShareholdersManagement from './InquiriesDetailsShareholdersManagement.js';
import InquiriesDetailsShareholdersAffiliations from './InquiriesDetailsShareholdersAffiliations.js';

import { Translation } from 'react-i18next';
import SourcesComponent from '../components/SourcesComponent';
class InquiriesDetailsShareholders extends Component {
    constructor(props){
        super(props);
        this.state = {
            shareholding : this.props.shareholding,
            management : this.props.management,
            affiliations : this.props.affiliations,
            toast: this.props.toast
        }
        this.handleAffiliationsChange = this.handleAffiliationsChange.bind(this);
        this.handleManagementChange = this.handleManagementChange.bind(this);
        this.handleShareholdingChange = this.handleShareholdingChange.bind(this);
    }
    componentDidMount(){

      const shareholding = sessionStorage.getItem('ses_shareholding_' + this.props.report.id);
      if (shareholding === null) {
          sessionStorage.setItem('ses_shareholding_' + this.props.report.id, JSON.stringify(this.state.shareholding));
      } else{
          this.setState({shareholding : JSON.parse(shareholding)})
      }

      const management = sessionStorage.getItem('ses_management_' + this.props.report.id);
      if (management === null) {
          sessionStorage.setItem('ses_management_' + this.props.report.id, JSON.stringify(this.state.management));
      } else{
          this.setState({management : JSON.parse(management)})
      }

      const affiliations = sessionStorage.getItem('ses_affiliations_' + this.props.report.id);
      if (affiliations === null) {
          sessionStorage.setItem('ses_affiliations_' + this.props.report.id, JSON.stringify(this.state.affiliations));
      } else{
          this.setState({affiliations : JSON.parse(affiliations)})
      }
    }

    dataRefresh(){
      const affiliations = sessionStorage.getItem('ses_affiliations_' + this.props.report.id);
      const affiliationsJSON = JSON.parse(affiliations)
      var affiliations_arr = this.state.affiliations;
      affiliations_arr.affiliated_companies = affiliationsJSON.affiliated_companies
      sessionStorage.setItem('ses_affiliations_' + this.props.report.id, JSON.stringify(affiliations_arr));

      const management = sessionStorage.getItem('ses_management_' + this.props.report.id);
      const managementJSON = JSON.parse(management)
      var managementState = this.state.management;
      managementState.managers = managementJSON.managers
      sessionStorage.setItem('ses_management_' + this.props.report.id, JSON.stringify(managementState));

      const shareholding = sessionStorage.getItem('ses_shareholding_' + this.props.report.id);
      const shareholdingJSON = JSON.parse(shareholding)
      var shareholdingState = this.state.shareholding;
      shareholdingState.shareholders = shareholdingJSON.shareholders
      sessionStorage.setItem('ses_shareholding_' + this.props.report.id, JSON.stringify(shareholdingState));
    }


    handleAffiliationsChange(field, e){
        var affiliations_arr = this.state.affiliations;
        affiliations_arr[field] = e.target.value;
        console.log("e")
        this.setState({affiliations: affiliations_arr}, function(){
          this.dataRefresh();
        })
    }

    handleShareholdingChange(field, e){
      var shareholding = this.state.shareholding;
      if(!shareholding){
        shareholding = {}
      }
      shareholding[field] = e.target.value;
      this.setState({shareholding: shareholding}, function(){
        this.dataRefresh();
      })
    }

    handleManagementChange(field, e){
      var management = this.state.management;
      if(!management){
        management = {}
      }
      management[field] = e.target.value;
      this.setState({management: management}, function(){
        this.dataRefresh();
      })
    }

    render(){
     return(
            <div className="row">
                <Toast ref={this.state.toast} />
                <div className="col-12">

                    <div className="form-group">
                        <InquiriesDetailsShareholdersInformation shareholders = {this.state.shareholding?.shareholders} report_id = {this.props.report.id} toast = {this.state.toast}/>
                        <div className="lg-spacer"/>

                        <div className="row">
                             <div className="col-sm-2">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-5">
                              <textarea onChange={(e) => this.handleShareholdingChange("comments", e)}
                                className="form-control form-text-comments" name="comments"  value={this.state.shareholding?.comments}  rows="3"></textarea>
                            </div>
                        </div>
                        <div className="row">
                             <div className="col-sm-2">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('SOURCES')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-5">
                                <SourcesComponent sources = {this.state.shareholding?.sources} update_date = {(e) => this.handleShareholdingChange("sources", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-hr-sm" />
                    <div className="form-group">
                        <InquiriesDetailsShareholdersManagement managers = {this.state.management?.managers} report_id = {this.props.report.id} toast = {this.state.toast}/>
                        <div className="lg-spacer"/>

                        <div  className="row">
                             <div className="col-sm-2">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-5">
                              <textarea onChange={(e) => this.handleManagementChange("comments", e)}
                                className="form-control form-text-comments" name="comments"  value={this.state.management?.comments}  rows="3"></textarea>
                            </div>
                        </div>
                        <div className="row">
                             <div className="col-sm-2">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('SOURCES')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-5">
                                <SourcesComponent sources = {this.state.management?.sources} update_date = {(e) => this.handleManagementChange("sources", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-hr-sm" />

                    <div className="form-group">
                        <InquiriesDetailsShareholdersAffiliations affiliated_companies = {this.state.affiliations.affiliated_companies} report_id = {this.props.report.id} toast = {this.state.toast} />
                        <div className="lg-spacer"/>

                        <div className="row">
                             <div className="col-sm-2">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-5">
                                <textarea onChange={(e) => this.handleAffiliationsChange("comments", e)}  className="form-control form-text-comments" name="comments"  value={this.state.affiliations.comments}  rows="3"></textarea>
                            </div>
                        </div>
                        <div className="row">
                             <div className="col-sm-2">
                                <label className="form-field-bold">{<Translation>{ t => <span>{t('SOURCES')} </span>}</Translation>}</label>
                            </div>
                            <div className="col-sm-5">
                                <SourcesComponent sources = {this.state.affiliations?.sources} update_date = {(e) => this.handleAffiliationsChange("sources", e)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }

}
export default InquiriesDetailsShareholders
