import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';


class RegistrationStatus extends Component {
  constructor(props){
    super(props);
    this.state = {
      regStatus: [],
      loading: false,
      selectedStatus: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    }

    this.statusNameBodyTemplate = this.statusNameBodyTemplate.bind(this);
    this.handleStatusClick = this.handleStatusClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleStatusNameChange = this.handleStatusNameChange.bind(this);
    this.inquiryService = new InquiryService();

  }

  componentDidMount(){
    this.inquiryService.getRegStatuses().then(data => {
      this.setState({
        regStatuses: data, loading: false
      })
    })
  }

  handleSaveClick(){
    if (this.state.selectedStatus.id == 0){
      this.inquiryService.createRegStatus(this.state.selectedStatus).then(data => {
        let regStatus = this.state.regStatus
        regStatus.push(data)
        this.setState({regStatus:regStatus, selectedStatus: data,button:{label:'Save',icon: 'pi pi-check'}})
        alert("Registration STatus Created !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    } else {
      this.inquiryService.updateRegStatus(this.state.selectedStatus.id, this.state.selectedStatus).then(data => {
        alert("Registration STatus Updated !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    }
  }

  cancel(){
    this.setState({
      selectedStatus: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }


  handleStatusClick(rowData){
    this.setState({
      selectedStatus: rowData,
      button:{
        label:'Save',
        icon: 'pi pi-check'
      }
    })
  }

  statusNameBodyTemplate(rowData){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleStatusClick(rowData)} > {rowData.name}</a>
        </React.Fragment>
    );
  }

  handleStatusNameChange(event){
    var selectedStatus  = this.state.selectedStatus
    selectedStatus.name = event.target.value
    this.setState({selectedStatus: selectedStatus})
  }

  render(){
    return (
      <div>
        <div className="condiv">
          <div className="row">
            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.regStatus}
                  loading = {this.state.loading}
                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No Registration Status found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="name" filterField="name" field="name" header="Name" body={this.statusNameBodyTemplate} sortable filter filterPlaceholder="Search by Status"/>
              </DataTable>
            </div>
            <div className = "col-4">
              <div className="col-sm-9" style={{'margin': '5px'}}>
                <input id = "name" onChange={this.handleStatusNameChange} type="text" placeholder="Registration Status" value={this.state.selectedStatus.name} className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}}/>
                <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}


export default RegistrationStatus;
