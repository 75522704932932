import React, { Component } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Translation } from 'react-i18next';

class SourcesComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedSources: [],
      sources: this.props.sources
    }

    this.sources = [
        {"value": "interview" , "label": "Interview"},
        {"value": "website" , "label": "Company Website"},
        {"value": "press" , "label": "Press"},
        {"value": "report" , "label": "Annual Report"},
        {"value": "official", "label": "Official Sources"},
        {"value": "public" , "label": "Publicly Accessible Sources"},
        {"value": "private" , "label": "Private Sources"},
        {"value": "other" , "label": "Other"}
    ];
    if(this.state.sources){
      this.state.selectedSources = this.state.sources.split(",")
    }
    this.handleSourceChange = this.handleSourceChange.bind(this);
  }

  handleSourceChange(event){
    console.log(event.target.value)
    this.setState({selectedSources: event.target.value}, function(){
      this.refreshData();
    })
  }

  refreshData(){
    if(this.state.selectedSources.length == 0){
       this.props.update_date({target: {value: null}})
    } else {
      this.props.update_date({target: {value: this.state.selectedSources.join(",")}})
    }

  }

  render(){
    return (
      <div>
        <MultiSelect value = {this.state.selectedSources}  options={this.sources} onChange={this.handleSourceChange}
          optionLabel="label" placeholder="Select Sources" />
      </div>

    )

  }
}

export default SourcesComponent;
