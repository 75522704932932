import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Translation } from 'react-i18next';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {formatDate,formatDateDisplay} from '../components/Utils';
import UserService from '../service/UserService';
import CompanyService from '../service/CompanyService';
class ApiDownloadLogs extends Component {

  constructor(props){
    super(props)
    this.state = {
      toast: this.props.toast,
      search: {from_date:"2022-05-01", to_date : "2022-05-30"},
      submitLocked: false,
      searchloading: false,
      selectedUsers: [],
      logs: [],
      users:[],
      apiCompanies: [],
      totalRecords: 0,
      first: 0, 
      rows: JSON.parse(sessionStorage.getItem('AdminApiLogs_rows')) || 100, 
    }
   
    this.userService = new UserService();
    this.companyService = new CompanyService();

    this.updateSearchParams = this.updateSearchParams.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    //this.exportCSV = this.exportCSV.bind(this);
  }
  onPageChange(e) {
    this.setState({
      first: e.first,
      rows: e.rows,
    })
    sessionStorage.setItem('AdminApiLogs_rows', JSON.stringify(e.rows))
  }
  async componentDidMount(){
    this.state.toast.current.show({severity:'info', summary:'Loading !', detail: "Loading Users !"});
  }

  exportCSV(selectionOnly){

    this.dt.exportCSV({ selectionOnly });
  }

  handleSubmitSearch(){
    const { rows, first } = this.state;
    this.setState({searchloading: true})
   
   this.companyService.generateApiReportDownloadsLogsWithPagination(rows, first,this.state.search).then(data => {
      this.setState({
        logs: data.results, 
        totalRecords: data.count,
        searchloading: false
      })
    }).catch(err => {
      if (err.response){
        this.state.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(err.response.data)});
      } else {
        this.state.toast.current.show({severity:'error', summary:'Failed !', detail: "There has been an error generating the report !"});
      }

      this.setState({searchloading: false})
    })
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("prevState",prevState , this.state.first)
    if (prevState.first !== this.state.first) {
      
      this.setState({
        searchloading: true
      })
      console.log("Loading",this.state.searchloading)
      const { rows, first } = this.state;
      this.companyService.generateApiReportDownloadsLogsWithPagination(rows, first,this.state.search).then(data => {
        this.setState({
          logs: data.results, 
          totalRecords: data.count,
          searchloading: false
        })
        console.log("Loading",this.state.searchloading)
      });
    }
  } 
  updateSearchParams(field, event){
    let search = this.state.search
    search[field] = (field.includes("date")) ? formatDate(event.target.value) : event.target.value;
    this.setState({search: search})
  }
  logDateBodyTemplate(rowData){
    return (
      <>
        <span>{formatDateDisplay(rowData.log_date)} </span>
      </>
    );
  }

  render(){
    return (
      <div>
        <Toast ref={this.state.toast} />
        <div className = "row">
          <div className = "col-1">
            <label className="form-field-bold"> From Date </label>
          </div>
          <div className = "col-1">
            <Calendar value={this.state.search.from_date} onChange={(e) => this.updateSearchParams("from_date", e)} showIcon monthNavigator showButtonBar></Calendar>
          </div>
          <div className = "col-1">
            <label className="form-field-bold"> To Date </label>
          </div>
          <div className = "col-1">
            <Calendar value={this.state.search.to_date}  onChange={(e) => this.updateSearchParams("to_date", e)} showIcon monthNavigator showButtonBar></Calendar>
          </div>
          <div className = "col-1">
            <div className="row">
              <label className="form-field-bold"> Customers</label>
            </div>
           {/*  <div className="row">
              *leave empty to select all
            </div> */}
          </div>
          <div className = "col-2">
            {/*<MultiSelect value={this.state.search.selectedUsers} options={this.state.users} optionLabel="username"
                placeholder="Select Clients" filter="true" onChange = {(e) => this.updateSearchParams("selectedUsers", e)} />
    */}
              <input type="text" onChange={(e)=>this.updateSearchParams("company_name", e)} name="email" value={this.state.search.company_name} className="form-control form-control-sm"/>
          </div>
          <div className = "col-1">
            <Button loading = {this.state.searchloading} disabled = {this.state.submitLocked}  loadingIcon="pi pi-spin pi-sun" id="search-btn"
                  label={<Translation>{ t => <span>{t('GENERATE')} </span>}</Translation>}
                  icon="pi pi-briefcase" onClick = {() => this.handleSubmitSearch()} className="p-button-danger" />
          </div>
          <div className = "col-2">
            <Button type="button" label="Export CSV" icon="pi pi-file" onClick={() => this.exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
          </div>

        </div>
        <div className = "row">
         <DataTable value={this.state.logs} paginator
              lazy
              className="p-datatable-striped"
              ref={(el) => this.dt = el}
              loading={this.state.searchloading}
              first={this.state.first}
              rows={this.state.rows}
              totalRecords={this.state.totalRecords}
              onPage={this.onPageChange}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[50,100,200]}>

              <Column field="log_date" header="Date" body={this.logDateBodyTemplate} sortable />
              <Column sortField="log_action"         field="log_action"        header="Action"     sortable />
              <Column sortField="company"         field="company"              header="Company" sortable />
              <Column sortField="log_details"        field="log_details"       header="Details"    sortable />
              <Column sortField="ip_address"         field="ip_address"        header="IP Address" sortable />
              <Column sortField="user_log.username"  field="user_log.username" header="User"       sortable  />
  </DataTable> 
        </div>
      </div>
    )
  }
}

export default ApiDownloadLogs;
