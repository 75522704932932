import axios from 'axios';

let axiosInstances = {};

const getInstance = (pathname,requestOption) => {
    if (axiosInstances[pathname] && !axiosInstances[pathname].controller.signal.aborted) {
        //console.log(axiosInstances[pathname],"abort me");
        return axiosInstances[pathname].instance;
    }
    else {
       
        const customAxiosInstance = createInstance(requestOption);
        axiosInstances[pathname] = customAxiosInstance;
        //console.log("new",axiosInstances[pathname]);
        return axiosInstances[pathname].instance;
    }
}
const createInstance = (requestOption) => {
    const controller = new AbortController();
    const customAxiosInstance = {
        controller,
        instance: axios.create({
            signal: controller.signal,
            headers: requestOption.headers
        })
    }
    return customAxiosInstance;
}

export const cancelPreviousPageRequests = (previousPath) => {
    //console.log("CancelPrevious",previousPath,axiosInstances[previousPath]);
    axiosInstances[previousPath]?.controller.abort();
}

export const api = async (url, method, data = null , requestOption) => {
    const instance = getInstance(window.location.pathname,requestOption);
    const res = await instance({
        method,
        url,
        data,
    });
    return res;
}