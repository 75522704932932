import React, { Component } from 'react';
import CompanyService from '../service/CompanyService';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
class AdminCompanies extends Component {
  constructor(props){
    super(props);
    this.state = {
      companies: [],
      totalRecords: 0,
      first: 0, 
      rows: JSON.parse(sessionStorage.getItem('AdminCustomers_rows')) || 10, 
      loading: true,
      legal_name : '',
      address :"",
      country: ""
    }

    this.companyService = new CompanyService();
    this.companyNameBodyTemplate = this.companyNameBodyTemplate.bind(this);
    this.handleCompanyClick = this.handleCompanyClick.bind(this);
    //this.filterClient = this.filterClient.bind(this);
     this.searchClient = this.searchClient.bind(this);
    this.searchFieldChange= this.searchFieldChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount(){
    const { rows, first } = this.state;
    this.companyService.getCustomersWithPagination(rows, first).then(data => {
      this.setState({
        companies: data.results, 
        totalRecords: data.count,
        loading: false
      })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.first !== this.state.first) {
      this.setState({
        loading: true
      })

      const { rows, first } = this.state;
      this.companyService.getCustomersWithPagination(rows, first).then(data => {
        this.setState({
          companies: data.results, 
          totalRecords: data.count,
          loading: false
        })
      });
    }
  }
  searchClient(data = []){

    const { rows, first } = this.state;
    let searchData = {legal_name: this.state.legal_name , address : this.state.address , country : this.state.country}
    if(data.legal_name == ""){  searchData.legal_name = ''; }
    if(data.address == ""){ searchData.address = ''; }
    if(data.country == ""){ searchData.country = '';  }
    this.companyService.getCustomersWithPaginationSearch(rows, first,searchData).then(data => {
    let companies = data.results;   
    try {
      if(companies.length > 0){
        let sorted = companies.sort((a, b) =>  new Date(b.last_update_date) - new Date(a.last_update_date));
        let unique = [];
        var distinct = [];

        for( let i = 0; i < sorted.length; i++ ){
            if( !unique[sorted[i].legal_name]){
              distinct.push(sorted[i]);
              unique[sorted[i].legal_name] = 1;
            }
          }
        }
     
        this.setState({
          companies: distinct, 
          totalRecords: distinct.length,
          loading: false
        })
        console.log(distinct);
    
   
    } catch(err){
      console.log(err)
   }
  });
}

  handleAddNewCustomer(event){
    let companyData = {}
    companyData["legal_name"] = "new customer"
    companyData["type"] = "CUST"
    companyData["customer_info"] = {}
    companyData["customer_info"]["default_rating"] = true
    companyData["legal_address"] = {}
    companyData["legal_address"]["country"] = {}
    companyData["legal_address"]["country"]["name"] = "United States"

    this.props.addCompanyTab(companyData);
  }

  handleCompanyClick(event, rowData){
    this.props.addCompanyTab(rowData);
  }

  companyNameBodyTemplate(rowData){
    return (
      <React.Fragment>
        <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a href="#" onClick={(e) => this.handleCompanyClick(e, rowData)}>{rowData.legal_name}</a></span>
      </React.Fragment>
    );
  }

  companyAddressBodyTemplate(rowData){
    return (
      <React.Fragment>
        {rowData.legal_address.street}<br/>
        {rowData.legal_address.city},  {rowData.legal_address.state} {rowData.legal_address.postal_code}<br/>
        {rowData.legal_address.comments}
      </React.Fragment>
    );
  }

  companyEmailsBodyTemplate(rowData){
    return (
      <React.Fragment>
        <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.customer_info?.emails}</span>
      </React.Fragment>
    );
  }

  companyCountryBodyTemplate(rowData){
    return (
      <React.Fragment>
        <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.legal_address.country.name}</span>
      </React.Fragment>
    );
  }
  companyTelephonesBodyTemplate(rowData){
    return (
      <React.Fragment>
        <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.contact_info?.telephones}</span>
      </React.Fragment>
    );
  }

  onPageChange(e) {
    this.setState({
      first: e.first,
      rows: e.rows,
    })
    sessionStorage.setItem('AdminCustomers_rows', JSON.stringify(e.rows))
  }
  searchFieldChange(e,field){
    let val = document.getElementById(field).value ? document.getElementById(field).value : ''

    if(field == "legal_name"){
       this.setState({ legal_name : val})
       if(val == ''){  this.searchClient({legal_name:val}); }
    }
    if(field == "address"){
      this.setState({ address : val})
      if(val == ''){  this.searchClient({address:val}); }
    }

   if(field == "country"){
    this.setState({ country : val})
    if(val == ''){ this.searchClient({country:val}); }
 }
  
  }
  clientFilter(){
    return (
      <React.Fragment>
        <InputText id="legal_name" type="search" value={this.state.legal_name}  onChange={(e) => this.searchFieldChange(e,'legal_name') } placeholder="Company Name" />
        <i className="pi pi-search" style={{'fontSize': '2em','margin':'6px 5px 1px','position':'absolute'}} onClick={(e) =>  this.searchClient()}></i>
      </React.Fragment>
    );
  }
  addressFilter(){
    return (
      <React.Fragment>
        <InputText id="address" type="search" value={this.state.address} onChange={(e) => this.searchFieldChange(e,'address') } placeholder="Address" />
        <i className="pi pi-search" style={{'fontSize': '2em','margin':'6px 5px 1px','position':'absolute'}} onClick={(e) =>  this.searchClient()}></i>
      </React.Fragment>
    );
  }
  countryFilter(){
    return (
      <React.Fragment>
        <InputText id="country" type="search" value={this.state.country} onChange={(e) => this.searchFieldChange(e,'country') } placeholder="Country" />
        <i className="pi pi-search" style={{'fontSize': '2em','margin':'6px 5px 1px','position':'absolute'}} onClick={(e) =>  this.searchClient()}></i>
      </React.Fragment>
    );
  }
  render(){
    const clientFilter =  this.clientFilter()
    const addressFilter =  this.addressFilter()
    const countryFilter =  this.countryFilter()
    return (
      <div>
        <div className="condiv">
          <div className="col-sm-9" style={{'margin': '5px'}}>
            <Button onClick={(e) => this.handleAddNewCustomer(e)} label="New Customer" className="p-button-sm" icon="pi pi-plus"/>
          </div>
          <div className="col-12" id="dataTable">
            <DataTable 
                lazy
                ref={(el) => this.dt = el}
                value={this.state.companies}
                loading={this.state.loading}
                className="p-datatable-striped"
                dataKey="id"
                rowHover
                 // selection={this.state.selectedName}
                // onSelectionChange={e => this.setState({selectedName: e.value})}
                paginator 
                first={this.state.first}
                rows={this.state.rows}
                totalRecords={this.state.totalRecords}
                onPage={this.onPageChange}
                emptyMessage="No Customers found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}>
                <Column sortField="legal_name" filterField="legal_name" field="legal_name" header="Company" body={this.companyNameBodyTemplate} sortable filter filterElement={clientFilter} filterPlaceholder="Company Name" filterMatchMode="contains"/>
                <Column sortField="status" filterField="status" field="status" header="Address" body={this.companyAddressBodyTemplate} sortable filter filterElement={addressFilter} filterPlaceholder="Address"/>
                <Column sortField="legal_address.country.name" filterField="legal_address.country.name" field="legal_address.country.name" sortable filter filterElement={countryFilter} filterPlaceholder = "Company Country" header="Country" body={this.companyCountryBodyTemplate} />
                <Column header="Telephones" body={this.companyTelephonesBodyTemplate} />
                <Column header="Email" body={this.companyEmailsBodyTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminCompanies
