import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import OrderComments from './OrderComments.js';
import { Translation } from 'react-i18next';
import { Toast } from 'primereact/toast';

class InquiriesDetailsCommunications extends Component {
  constructor(props){
    super(props);
    this.state = {
      orderInfo: this.props.orderInfo,
      toast: this.props.toast
    }
  }
    render(){

        return(
          <div>
            <Toast ref={this.state.toast} />
            <Accordion activeIndex="0">
              <AccordionTab header={<Translation>{ t => <span>{t('COMMUNICATIONS')} </span>}</Translation>}>
                  <OrderComments toast = {this.state.toast} orderInfo = {this.state.orderInfo} customer_email = {this.props.customer_info.emails} />
              </AccordionTab>
            </Accordion>
          </div>
        )
    }

}
export default InquiriesDetailsCommunications
