import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import UserService from '../service/UserService';
import { Toast } from 'primereact/toast';

class CompanyGroups extends Component {
  constructor(props){
    super(props);
    this.state = {
      groups: this.props.company_groups,
      selectedGroup: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      },
      toast: this.props.toast,
    }

    this.companyGroupNameBodyTemplate = this.companyGroupNameBodyTemplate.bind(this);
    this.handleGroupClick = this.handleGroupClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.userService = new UserService();
  }

  handleSaveClick(){
    if(this.state.selectedGroup.id == 0){
      this.userService.createCCPCompanyGroup(this.state.selectedGroup).then(data => {
        let groups = this.state.groups
        groups.push(data)
        this.setState({groups:groups, selectedGroup: data,button:{
          label:'Save',
          icon: 'pi pi-check'
        }})
        this.state.toast.current.show({severity:'success', summary:'Created !', detail: "CCP Group Created"});
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    } else {
      this.userService.updateCCPCompanyGroup(this.state.selectedGroup.id, this.state.selectedGroup).then(data => {
        this.state.toast.current.show({severity:'success', summary:'Updated !', detail: "CCP Group Updated !"});
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    }
  }

  cancel(){
    this.setState({
      selectedGroup: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }

  handleGroupClick(rowData){
    this.setState({
      selectedGroup: rowData,
      button:{
        label:'Save',
        icon: 'pi pi-check'
      }
    })
  }

  companyGroupNameBodyTemplate(rowData){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleGroupClick(rowData)} > {rowData.name}</a>
        </React.Fragment>
    );
  }

  handleGroupChange(event){
    var selectedGroup  = this.state.selectedGroup
    selectedGroup.name = event.target.value
    this.setState({selectedGroup: selectedGroup})
  }

  render(){
    return (
      <div>
        <div className="condiv">
          <div className="row">
            <Toast ref = {this.state.toast} />
            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.groups}

                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No Affiliation Types found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="name" filterField="name" field="name" header="Name" body={this.companyGroupNameBodyTemplate} sortable filter filterPlaceholder="Search by Position"/>
              </DataTable>
            </div>
            <div className = "col-4">
              <div className="col-sm-9" style={{'margin': '5px'}}>
                <input id = "name" onChange={this.handleGroupChange} type="text" placeholder="CCP group" value={this.state.selectedGroup.name} className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}}/>
                <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default CompanyGroups;
