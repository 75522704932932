import React, { Component } from 'react';
import CompanyService from '../service/CompanyService';
import UserService from '../service/UserService';
import InquiryService from '../service/InquiryService';
import { TabView,TabPanel } from 'primereact/tabview';
import CommentsOnSuppliers from './CommentsOnSuppliers.js';
import CommentsOnClients from './CommentsOnClients.js';
import BusinessTypes from './BusinessTypes.js';
import ManagerPositions from './ManagerPositions.js';
import LegalForms from './LegalForms.js';
import RegistrationStatus from './RegistrationStatus.js';
import AffiliationTypes from './AffiliationTypes.js';

class AdminAttributes extends Component {
  constructor(props){
    super(props);
    this.state = {
      toast: this.props.toast,
      activeIndex: 0,
      tabs:[],
    }
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.inquiryService = new InquiryService();
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount(){
    //comments on clients
    const tabs_arr = this.state.tabs;
    const comments_on_clients_tab =  {
        id : 3,
        sort_title : 'Comments On Clients',
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Comments On Clients
                </React.Fragment>,
        content: this.renderCommentsOnClientsTabContent()
    };
    tabs_arr.push(comments_on_clients_tab);

    // business types
    const business_types_tab =  {
        id : 3,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Business Types
                </React.Fragment>,
        content: this.renderBusinessTypesTabContent()
    };


    tabs_arr.push(business_types_tab);




    //comments on suppliers
    const comments_on_suppliers_tab =  {
        id : 3,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Comments On Suppliers
                </React.Fragment>,
        content: this.renderCommentsOnSuppliersTabContent()
    };
    tabs_arr.push(comments_on_suppliers_tab);


    //legal forms
    const legal_forms_tab =  {
        id : 3,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Legal Forms
                </React.Fragment>,
        content: this.renderLegalFormsTabContent()
    };
    tabs_arr.push(legal_forms_tab);


    // reg status
    const reg_statuses_tab =  {
        id : 3,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Registration Statuses
                </React.Fragment>,
        content: this.renderRegStatusesTabContent()
    };
    tabs_arr.push(reg_statuses_tab);

    //manager positions
    const manager_positions_tab =  {
        id : 3,
        sort_title : 'Manager Positions',
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Manager Positions
                </React.Fragment>,
        content: this.renderManagerPositionsTabContent()
    };
    tabs_arr.push(manager_positions_tab);


    //affiliation types
    const affiliation_types_tab =  {
        id : 3,
        sort_title : 'Affiliation Types',
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Affialiation Types
                </React.Fragment>,
        content: this.renderAffiliationTypesTabContent()
    };
    tabs_arr.push(affiliation_types_tab);

    this.setState({tabs: tabs_arr})

  }

  renderAffiliationTypesTabContent(){
    return (
      <div>
        <AffiliationTypes />
      </div>
    )
  }
  renderRegStatusesTabContent(){
    return (
      <div>
        <RegistrationStatus registration_statuses = {this.state.regStatuses} />
      </div>
    )
  }

  renderLegalFormsTabContent(){
    return (
      <div>
        <LegalForms legal_forms = {this.state.legalForms} />
      </div>
    )
  }

  renderManagerPositionsTabContent(){
    return (
      <div>
        <ManagerPositions manager_positions = {this.state.managerPositions} />
      </div>
    )
  }


  renderBusinessTypesTabContent(){
    return (
      <div>
        <BusinessTypes business_types = {this.state.businessTypes} />
      </div>
    )
  }

  renderCommentsOnSuppliersTabContent(){
    return (
      <div>
        <CommentsOnSuppliers comments_on_suppliers = {this.state.commentsOnSuppliers} />
      </div>
    )
  }

  renderCommentsOnClientsTabContent(){
    return (
      <div>
        <CommentsOnClients comments_on_clients = {this.state.commentsOnClients} />
      </div>
    )
  }

  handleCloseTab(tab_id){
      const tab_toClose = tab_id.split("_")
      const id = tab_toClose[1];
      const tab_items = this.state.tabs;
      tab_items.map((tab,i) => {
          if(tab.id == id){
              tab_items.splice(i,1);
          }
      });
     this.setState({tabs:tab_items});
     this.setState({activeTabIndex : (tab_items.length - 1) });

  }

  onTabChange(e){
    let tab_index = e.index;
    const tab_items = this.state.tabs;
    if(tab_items.length == 1){
        tab_index = 0;
    }
    this.setState({ activeTabIndex: tab_index});
  }


  render() {
      return (


          <div className="condiv">
            <div className="col-12">
                <TabView activeIndex={this.state.activeTabIndex}  onTabChange={this.onTabChange}>
                    {
                      this.state.tabs.map((tab,i) => (
                        <TabPanel key={i} header={tab.title} headerStyle={tab.headerStyle}>{tab.content}</TabPanel>
                      ))
                    }
                </TabView>
            </div>
          </div>
          )
      }

}

export default AdminAttributes;
