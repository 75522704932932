import React, {useRef,useEffect} from 'react';
import './App.css';
import {Route, useLocation }  from "react-router-dom";
import Navbar from './components/Navbar';
import Inquiries from './contents/Inquiries';
import Orders from './contents/Orders';
import Admin from './contents/Admin';
import Login from './components/Login';
import Logout from './components/Logout';
import Companies from './contents/Companies';
import useToken from './components/useToken';
import { cancelPreviousPageRequests } from './service/baseService';

let previousPath = '';
let currentPath = '';
function App() {
  const location = useLocation(); 
  
  useEffect(() => {
    if (!previousPath && !currentPath) {
      previousPath = location.pathname;
      currentPath = location.pathname;
    } else {
      previousPath = currentPath;
      currentPath = location.pathname;
    }
    console.log(previousPath,currentPath)
    if (previousPath !== currentPath) {
      console.log("Need to cancel")
      cancelPreviousPageRequests(previousPath);
    }
    
  }, [location.pathname]);
  
  const { token, setToken } = useToken();

  const toast = useRef(null);

  if(!token) {
    return <Login setToken={setToken} />
  }
  const tokenJSON = JSON.parse(sessionStorage.getItem("token"));
  return (

    <div className="App">
        <Navbar is_staff = {tokenJSON.user.is_staff} is_admin = {tokenJSON.user.group == 'Admin'} />
        <Route path="/inquiries">
          <Inquiries toast = {toast} />
        </Route>

        <Route path="/companies">
          <Companies toast = {toast} />
        </Route>

        <Route path="/orders">
          <Orders toast = {toast}/>
        </Route>
        <Route path="/admin">
          <Admin toast = {toast} />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>
      </div>
    );
  }
  export default App;
