import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';

class AffiliationTypes extends Component {
  constructor(props){
    super(props);
    this.state = {
      affiliations: [],
      loading: true,
      selectedType: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    }
    this.affiliationNameBodyTemplate = this.affiliationNameBodyTemplate.bind(this);
    this.handleAffiliationClick = this.handleAffiliationClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleAffiliationTypeChange = this.handleAffiliationTypeChange.bind(this);
    this.inquiryService = new InquiryService();
  }

  componentDidMount(){
    this.inquiryService.getAffiliationTypes().then(data => {
      this.setState({
        affiliations: data, loading: false
      })
    })
  }

  handleSaveClick(){
    if(this.state.selectedType.id == 0){
      this.inquiryService.createAffiliationType(this.state.selectedType).then(data => {
        let affiliations = this.state.affiliations
        affiliations.push(data)
        this.setState({affiliations:affiliations, selectedType: data,button:{
          label:'Save',
          icon: 'pi pi-check'
        }})
        alert("Affiliation Type Created !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    } else {
        this.inquiryService.updateAffiliationTypes(this.state.selectedType.id, this.state.selectedType).then(data => {
          alert("Affiliation Type Updated !");
        }).catch(err=>{
          var alertBody = ""
          for(var index in err.response.data){
            if(err.response.data[index][0]){
              alertBody += index + "=>" + err.response.data[index][0] + "\n"
            } else{
              for(var childIndex in err.response.data[index]){
                alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
              }
            }

          }
          alert(alertBody);
        })
    }
  }

  cancel(){
    this.setState({
      selectedType: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }

    handleAffiliationClick(rowData){
      this.setState({
        selectedType: rowData,
        button:{
          label:'Save',
          icon: 'pi pi-check'
        }
      })
    }

    affiliationNameBodyTemplate(rowData){
      return (
          <React.Fragment>
              <a href="#" onClick={()=>this.handleAffiliationClick(rowData)} > {rowData.name}</a>
          </React.Fragment>
      );
    }

    handleAffiliationTypeChange(event){
      var selectedType  = this.state.selectedType
      selectedType.name = event.target.value
      this.setState({selectedType: selectedType})
    }

    render(){
      return (
        <div>
          <div className="condiv">
            <div className="row">
              <div className="col-6" id="dataTable">
                <DataTable ref={(el) => this.dt = el}
                    value={this.state.affiliations}
                    loading = {this.state.loading}
                    className="p-datatable-striped"
                    dataKey="id"
                    rowHover
                    //  selection={this.state.selectedName}
                    // onSelectionChange={e => this.setState({selectedName: e.value})}
                    paginator rows={10}
                    emptyMessage="No Affiliation Types found"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10,25,50]}>
                    <Column sortField="name" filterField="name" field="name" header="Name" body={this.affiliationNameBodyTemplate} sortable filter filterPlaceholder="Search by Position"/>
                </DataTable>
              </div>
              <div className = "col-4">
                <div className="col-sm-9" style={{'margin': '5px'}}>
                  <input id = "name" onChange={this.handleAffiliationTypeChange} type="text" placeholder="affiliation type" value={this.state.selectedType.name} className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}}/>
                  <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                  <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    }

}

export default AffiliationTypes;
