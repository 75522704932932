import React, { Component } from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import {SplitButton} from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';
//import {Link} from "react-router-dom";
//import { render, Document, Text } from 'redocx'; encountered issues so comment it out will find a different library
import InquiriesDetailsBusiness from '../contents/InquiriesDetailsBusiness';
import InquiriesDetailsFinancials from '../contents/InquiriesDetailsFinancials';
import InquiriesDetailsSummary from '../contents/InquiriesDetailsSummary';
import InquiriesDetailsCommunications from '../contents/InquiriesDetailsCommunications';
import InquiriesDetailsAdminNotesSideBar from '../contents/InquiriesDetailsAdminNotesSideBar';
import InquiriesPreview from '../contents/InquiriesPreview';
import { Dropdown } from 'primereact/dropdown';
import {formatToCurrency} from '../components/Utils';
import { Translation } from 'react-i18next';

import InquiryService from '../service/InquiryService';
import CompanyService from '../service/CompanyService';
import ExperianService from '../service/ExperianService';

class InquiriesDetails extends Component {
    constructor(props) {
        const tokenJSON = JSON.parse(sessionStorage.getItem("token"));

        super(props)
        this.state = {
            report :  null,
            orderInfo : null,
            notif_msg : null,
            notif_class : "hidden",
            msg_header : "",
            alert_class : "success",
            company: {},
            display: false,
            toast: this.props.toast,
            readOnly: !tokenJSON.user.is_staff,
            displayReportImport: false,
            displayReportImportFromCompany: false,
            displayReportImportFromExperian: false,
            searchValue: '',
            searchField: '',
            searchReports: [],
            searchCompanies: [],
            searchExperian: [],
            preview : false,
            activeTab: 0,
            customerInfo: [],
            previousComment : "",
            searchStatusTxt : "",
            experianData : {},
            importBtnDisable : false
        }
        this.handleSaveReport = this.handleSaveReport.bind(this);
        this.HandleCancelReport = this.HandleCancelReport.bind(this);
        this.HandleGenerateXML = this.HandleGenerateXML.bind(this);
        this.handleGenerateDoc = this.handleGenerateDoc.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onHidePreview = this.onHidePreview.bind(this);
        this.onHidePreviewOrder = this.onHidePreviewOrder.bind(this);
        this.handlePreviewOrder = this.handlePreviewOrder.bind(this);
        this.clearToast = this.clearToast.bind(this);
        this.sendUpdateCompany = this.sendUpdateCompany.bind(this);
        this.importFromReport = this.importFromReport.bind(this);
        this.cleanReport = this.cleanReport.bind(this);
        this.updateReport = this.updateReport.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.renderTabButtons = this.renderTabButtons.bind(this);
        this.renderActionButtons = this.renderActionButtons.bind(this);
        this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
        this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.submitCompanySearch = this.submitCompanySearch.bind(this);
        this.importReport = this.importReport.bind(this);
        this.importReportBodyTemplate = this.importReportBodyTemplate.bind(this);
        this.importCompanyBodyTemplate = this.importCompanyBodyTemplate.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.activeTabIndex = this.activeTabIndex.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.createNewCompany = this.createNewCompany.bind(this);
        this.toggleSideBar = this.toggleSideBar.bind(this);
        this.setReportToCompleted = this.setReportToCompleted.bind(this);
        this.openEmail = this.openEmail.bind(this);
        this.updatePreviousReportComments = this.updatePreviousReportComments.bind(this);
        this.initToken = this.initToken.bind(this);
        this.previewExperianHTMLBodyTemplate = this.previewExperianHTMLBodyTemplate.bind(this);
        this.importExperianBodyTemplate = this.importExperianBodyTemplate.bind(this);
        this.importExperianAddressTemplate =  this.importExperianAddressTemplate.bind(this);
     //   this.handleSubmit = this.handleSubmit.bind(this);
        this.inquiryService = new InquiryService();
        this.companyService = new CompanyService();
        this.experianService = new ExperianService();
        this.toast = React.createRef();
        //const ses_company = sessionStorage.getItem('ses_company_'+this.props.orderInfo.report);
        const ses_registration_changes = sessionStorage.getItem('ses_registration_changes_'+this.props.orderInfo.report);
        
        this.handleSearchExperian = this.handleSearchExperian.bind(this);
        this.submitSearchExperian = this.submitSearchExperian.bind(this);
        //this.importReportFromExperian = this.importReportFromExperian.bind(this);
        this.inquiryService.getOrder(this.props.orderInfo.id).then(data => {
          this.setState({orderInfo: data, customerInfo: data.ordered_by.user.company.customer_info})
        })
    }

    componentDidMount(){
       //   sessionStorage.removeItem("experianImport");
         /*jslint eqeq: true*/
         if(this.props.orderInfo.report){
           this.inquiryService.getReport(this.props.orderInfo.report).then(data => {
               if(data[0].comments_from_previous_report == null){
                  data[0].comments_from_previous_report = ""
               } 
               this.setState({report : data[0]});
           });

         } else {
           this.inquiryService.createReport(this.props.orderInfo.id).then(data =>{
             this.setState({report : data.report});
           })
         }

         this.inquiryService.getOrderProvidedFiles(this.props.orderInfo.id).then(data => {
           this.setState({providedFiles: data})
         })
    }
    parseFileName(fileName){
      let parts = fileName.split("/");
      return parts[parts.length - 1] !== "null" ? parts[parts.length - 1] : "" ;
    }
    renderClientInfo(){
        if(this.state.readOnly){
          return (<div></div>)
        }
        return(
            <div className="component-box light-blue-bg">
                 <div className="row">
                     <div className="col-sm box-title">
                        <Tooltip target=".client-info-tooltip">
                            <span className="form-field-bold">{<Translation>{ t => <span>{t('CLIENTSREQUIREMENTS')} </span>}</Translation>}</span><br/>
                            English<br/>
                            Word<br/>
                            EUR<br/>
                            Experian: Yes<br/>
                            Business overview : Yes<br/>
                            No logo<br/>
                        </Tooltip>
                        <span className="client-info-tooltip" data-pr-position="left">{<Translation>{ t => <span>{t('CLIENTSNAME')} </span>}</Translation>}</span>
                      </div>

                    </div>
                <div className="row">
                    <div className="col-12">
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('NAME')} </span>}</Translation>} : {this.state.orderInfo?.ordered_by.user.company.legal_name}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('CRISTAL_NUMBER')} </span>}</Translation>} : {this.state.orderInfo?.ordered_by.user.company.cristal_number}</label>
                                            </div>
                                            <div hidden className="form-group">
                                                <label>{<Translation>{ t => <span>{t('REGISTRATION_NUMBER')} </span>}</Translation>} : {this.state.orderInfo?.ordered_by.user.company.registration_id}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation>} : {this.state.orderInfo?.ordered_by.user.company.legal_address.country.name}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('CLIENT_REFERENCE')} </span>}</Translation>} : {this.state.orderInfo?.client_reference}</label>
                                            </div>
                                            <div hidden className="form-group">
                                                <label>{<Translation>{ t => <span>{t('PHONE_NUMBER')} </span>}</Translation>}: {this.state.orderInfo?.ordered_by.user.company.contact_info?.telephones}</label>
                                            </div>
                                        </div>
                                    <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('COMMENTS_FROM_GC')} </span>}</Translation>} : {this.state.orderInfo?.gc_comments}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('DUEDATE')} </span>}</Translation>} : {this.state.orderInfo?.due_date}</label>
                                            </div>
                                            <div hidden className="form-group">
                                                <label>{<Translation>{ t => <span>{t('WEBSITE')} </span>}</Translation>} : {this.state.orderInfo?.ordered_by.user.company.contact_info?.websites}</label>
                                            </div>
                                            <div hidden className="form-group">
                                                <label>{<Translation>{ t => <span>{t('CONTACTNAME')} </span>}</Translation>} :</label>
                                            </div>
                                            <div hidden className="form-group">
                                                <label>{<Translation>{ t => <span>{t('POSITION')} </span>}</Translation>} :</label>
                                            </div>
                                            <div hidden className="form-group">
                                                <label>{<Translation>{ t => <span>{t('EMAIL')} </span>}</Translation>} :</label>
                                            </div>
                                    </div>
                                    <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('FILES_PROVIDED')} </span>}</Translation>} :</label>
                                                <div className="col-sm-4">
                                                  <ul>
                                                    {
                                                      this.state.providedFiles?.map((file, i) => (
                                                        <li key={i}>
                                                          <a href={process.env.REACT_APP_HOST + "/wand2/order/provided_file/upload/" + file.id}>
                                                            <span style = {{'color': 'green'}}><strong>{this.parseFileName(file.file)}</strong></span>
                                                          </a>
                                                        </li>
                                                      ))
                                                    }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div hidden className="form-group">
                                                <label>{<Translation>{ t => <span>{t('REQUIRED_CREDIT')} </span>}</Translation>} : {this.state.orderInfo?.required_credit?.value} {this.state.orderInfo?.required_credit?.currency.code}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('INQUIRING_PARTY')} </span>}</Translation>}: {this.state.orderInfo?.inquiring_party_name}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('DELAY')} </span>}</Translation>} : {this.state.orderInfo?.delay?.name} </label>
                                            </div>
                                    </div>
                                    <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('DEFAULT_LANGUAGE')} </span>}</Translation>} : {this.state.customerInfo.default_language}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('DEFAULT_FORMAT')} </span>}</Translation>} : {this.state.customerInfo.download_formats}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('NEGATIVE_REPORT')} </span>}</Translation>} : {this.state.customerInfo.negative ? "Negative Report" : "Email" }</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('EXPERIAN')} </span>}</Translation>} : {this.state.customerInfo.experian ? "Yes" : "No"}</label>
                                            </div>
                                            <div className="form-group">
                                                <label>{<Translation>{ t => <span>{t('LOGO')} </span>}</Translation>} : {this.state.customerInfo.logo ? "Yes" : "No"}</label>
                                            </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-3"></div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4">
                                    <div className="col-sm-12">
                                            <div className="form-group">
                                               <span className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTSFROMCLIENTS')} </span>}</Translation>}</span>
                                               <textarea className="form-control form-text-comments" rows="3" defaultValue={this.state.customerInfo.customer_comments}></textarea>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                <div className="col-sm-12">
                                            <div className="form-group">
                                               <span className="form-field-bold">{<Translation>{ t => <span>{t('SPECIALCOMMENT')} </span>}</Translation>}</span>
                                               <textarea className="form-control form-text-comments" rows="3"  defaultValue={this.props.orderInfo.comments}></textarea>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                               <span className="form-field-bold">{<Translation>{ t => t('COMMENTSFROMPREVIOUSREPORT')}</Translation>}</span>
                                               <textarea className="form-control form-text-comments" rows="3" defaultValue={this.state.report.comments_from_previous_report}></textarea>
                                     </div>
                                </div>
                                <div className="col-1"><br/><br/>
                                  <Button hidden label="Delete" icon="pi pi-trash"  className="p-button-sm" iconPos="right" />
                                </div>

                            </div>
                        </div>
                </div>
            </div>
        )
    }

    saveReport(){
      var jsonData = this.state.report;
      const registration_info = sessionStorage.getItem("ses_registration_info_" + this.state.report.id);
      if (registration_info != null){
        jsonData.registration_info = JSON.parse(registration_info)
      }

      const registration_changes = sessionStorage.getItem("ses_registration_changes_" + this.state.report.id);
      if (registration_changes != null){
        jsonData.registration_changes = JSON.parse(registration_changes)
      }

      const contact_info = sessionStorage.getItem("ses_contact_info_" + this.state.report.id);
      if (contact_info != null){
        jsonData.contact_info = JSON.parse(contact_info)
      }

      const shareholding = sessionStorage.getItem("ses_shareholding_" + this.state.report.id);
      if (shareholding != null){
        jsonData.shareholding = JSON.parse(shareholding)
      }

      const management = sessionStorage.getItem("ses_management_" + this.state.report.id);
      if (management != null){
        jsonData.management = JSON.parse(management)
      }

      const affiliations = sessionStorage.getItem("ses_affiliations_" + this.state.report.id);
      if (affiliations != null){
        jsonData.affiliations = JSON.parse(affiliations)
      }

      const banksInfo = sessionStorage.getItem("ses_banks_info_" + this.state.report.id);
      if (banksInfo != null){
        jsonData.banks_info = JSON.parse(banksInfo)
      }

      const pressReleases = sessionStorage.getItem("ses_press_" + this.state.report.id);
      if (pressReleases != null){
        jsonData.press = JSON.parse(pressReleases)
      }

      const interviews = sessionStorage.getItem("ses_interviews_" + this.state.report.id);
      if (interviews != null){
        jsonData.interviews = JSON.parse(interviews)
      }
      const business = sessionStorage.getItem("ses_business_" + this.state.report.id);
      if (business != null){
        jsonData.business = JSON.parse(business)
      }
      const clients = sessionStorage.getItem("ses_clients_" + this.state.report.id);
      if (clients != null){
        jsonData.clients = JSON.parse(clients)
      }
      const suppliers = sessionStorage.getItem("ses_suppliers_" + this.state.report.id);
      if (suppliers != null){
        jsonData.suppliers = JSON.parse(suppliers)
      }
      const financials = sessionStorage.getItem("ses_financials_" + this.state.report.id);
      if (financials != null){
        jsonData.financials = JSON.parse(financials)
      }
      jsonData.financials.financial_report_files = null
      jsonData.financials.group_financial_report_files = null

      const companyInfo = sessionStorage.getItem("ses_company_info_" + this.state.report.id);
      if (companyInfo != null){
        jsonData.company_info = JSON.parse(companyInfo)
      }

      const creditEvaluation = sessionStorage.getItem("ses_credit_evaluation_" + this.state.report.id);
      if (creditEvaluation != null){
        jsonData.credit_evaluation = JSON.parse(creditEvaluation)
      }


      const paymentBehavior = sessionStorage.getItem("ses_payment_behavior_"+ this.state.report.id);
      if (paymentBehavior != null){
        jsonData.payment_behavior = JSON.parse(paymentBehavior)
      }

      const insurances = sessionStorage.getItem("ses_insurances_"+ this.state.report.id);
      if (insurances != null){
        jsonData.insurances = JSON.parse(insurances)
      }
      const legal_fillings = sessionStorage.getItem('ses_legal_records_'+ this.state.report.id);
      if(legal_fillings !=null){
        jsonData.legal_fillings = JSON.parse(legal_fillings);
      }
      //console.log(jsonData)
      this.inquiryService.updateReport(this.state.orderInfo.id, jsonData).then(data => {
        this.toast.current.show({severity:'success', summary:'Updated', detail:'Report Data Saved.'});
        this.setState({notif_msg : "", notif_class: "", alert_class: "", msg_header: "", display: false});
       }).catch(err =>{
         if(!err.response){
           //console.log(err);
           return;
         }
         let error_obj = Object.keys(err.response.data);
         let data_obj = Object.values(err.response.data);

         let error_msg = "";

          error_obj.map((field,i) => {
                    error_msg = error_msg + field +" : "+  JSON.stringify(data_obj[i]) + "<Br/>\r\n"
          });

          this.setState({notif_msg : error_msg.replace(",","<br/>")});
          this.setState({notif_class : ""});
          this.setState({alert_class : "danger"});
          this.setState({msg_header : "Failed Saving!"})
          this.setState({display:true});

          });
    }

    handleSaveReport(){
      let clearToast = this.clearToast;
      let saveReport = this.saveReport
      function proceed(){
        clearToast();
        saveReport();
      }
      if (this.state.orderInfo.status === "COMPLETED"){
        this.toast.current.show({ severity: 'warn', sticky: true, content: (
            <div className="p-flex p-flex-column" style={{flex: '1'}}>
                <div className="p-text-center">
                    <i className="pi pi-exclamation-triangle" style={{fontSize: '3rem'}}></i>
                    <h4>Order is Completed</h4>
                    <p> This will change the status to IN-PROGRESS  </p>
                    <p>Confirm to proceed</p>
                </div>
                <div className="p-grid p-fluid">
                    <div className="p-col-6">
                        <Button onClick={proceed} type="button" label="Yes" className="p-button-success" />
                    </div>
                    <div className="p-col-6">
                        <Button onClick={this.clearToast} type="button" label="No" className="p-button-secondary" />
                    </div>
                </div>
            </div>
        ) });
      } else {
        proceed();
      }
    }
    HandleCancelReport = () =>{
      let report = this.state.report
     // console.log(report)
      report.address_comments = null
      report.address_same_as_order = null
      report.contact_info_comments = null
      report.contact_info_same_as_order = null
      report.name_comments = null
      report.name_same_as_order = null
      //affiliations
      report.affiliations.affiliated_companies = []
      report.affiliations.comments = null
      report.affiliations.sources = null
      // banks
      report.banks_info.banks = []
      report.banks_info.comments = null
      report.banks_info.sources = null
      //business
      report.business.awards_and_certification = []
      report.business.branches = []
      report.business.branches_comments = null
      report.business.brands = []
      report.business.business_activity = null
      report.business.business_types = null
      report.business.comments_on_staff = null
      report.business.competitors = []
      report.business.consolidated_staff = null
      report.business.date_created = null
      report.business.history = null
      report.business.industry_situation = null
      report.business.line_of_business = null
      report.business.main_product = null
      report.business.membership_in_associations = null
      report.business.sics = []
      report.business.size_range = null
      report.business.sources = null
      report.business.staff_number = null
      report.business.trademarks = []
      // clients
      report.clients.clients = []
      report.clients.clients_type = null
      report.clients.comment_details = null
      report.clients.comments_on_clients = null
      report.clients.does_not_export = null
      report.clients.exports_to = null
      // company info
      report.company_info.headquarters = null
      report.company_info.mailing_address = null
      report.company_info.previous_names = null
      report.company_info.registred_name = null
      report.company_info.trade_names = null
      // contact Info
      report.contact_info.comments = null
      report.contact_info.emails = null
      report.contact_info.faxes = []
      report.contact_info.social_media = null
      report.contact_info.telephones = []
      report.contact_info.websites = null
      // credit evaluation
      report.credit_evaluation.advised_credit = null
      report.credit_evaluation.coface_alarm = null
      report.credit_evaluation.comments = null
      report.credit_evaluation.comments_on_rating = null
      report.credit_evaluation.credit_risk_analysis = null
      report.credit_evaluation.experience = null
      report.credit_evaluation.general_overview = null
      report.credit_evaluation.group = null
      report.credit_evaluation.indebtness = null
      report.credit_evaluation.legal_records = null
      report.credit_evaluation.operative_status = null
      report.credit_evaluation.payments = null
      report.credit_evaluation.profitability = null
      report.credit_evaluation.rating = null
      report.credit_evaluation.size = null
      report.credit_evaluation.trade_references = null
      // financials
      report.financials.availability = null
      report.financials.capital = null
      report.financials.comments = null
      report.financials.comments_on_Capital = null
      report.financials.currency = null
      report.financials.currency_change = null
      report.financials.financial_report_file = null
      report.financials.financial_report_files = []
      report.financials.group_availability = null
      report.financials.group_financial_report_files = []
      report.financials.group_financials = []
      report.financials.group_report_comments = null
      report.financials.is_stock_listed = null
      report.financials.nbr_of_shares = null
      report.financials.no_interview_reason = null
      report.financials.not_available_reason = null
      report.financials.paid_up_capital = null
      report.financials.quarterly_financials = []
      report.financials.report_type = null
      report.financials.sources = null
      report.financials.stock_exchange = null
      report.financials.ticker = null
      report.financials.unit = null
      report.financials.yearly_financials = []
      // insurances
      report.insurances.comments = null
      report.insurances.insurances = []
      // interviews
      report.interviews.comments = null
      report.interviews.interviews = []
      report.interviews.previous_interview = null
      //legal fillings
      report.legal_fillings.bankruptcy = null
      report.legal_fillings.bankruptcy_comment = null
      report.legal_fillings.collections = null
      report.legal_fillings.collections_comment = null
      report.legal_fillings.comments = null
      report.legal_fillings.lawsuits = null
      report.legal_fillings.lawsuits_comment = null
      report.legal_fillings.liens = null
      report.legal_fillings.liens_comments = null
      report.legal_fillings.note_of_protest_comments = null
      report.legal_fillings.notes_of_protest = null
      report.legal_fillings.ofac_list = null
      report.legal_fillings.ofac_list_comment = null
      report.legal_fillings.out_standing_debts = null
      report.legal_fillings.out_standing_debts_comment = null
      report.legal_fillings.returned_checks = null
      report.legal_fillings.returned_checks_comments = null
      report.legal_fillings.ucc_file_old = null
      report.legal_fillings.ucc_files = null
      report.legal_fillings.ucc_files_comments = null
      // management
      report.management.comments = null
      report.management.sources = null
      report.management.managers = []
      // payment bheavior
      report.payment_behavior.cash_payment = null
      report.payment_behavior.credit_bureau_information = null
      report.payment_behavior.credit_payments = null
      report.payment_behavior.maximum_credit_line = null
      report.payment_behavior.payment_means = null
      report.payment_behavior.payment_terms = null
      report.payment_behavior.payments = []
      // press
      report.press.comments = null
      report.press.press_releases = []
      // registration changes
      report.registration_changes.comments = null
      report.registration_changes.changes = []
      // registration info
      report.registration_info.comments = null
      report.registration_info.last_annual_report = null
      report.registration_info.legal_form = null
      report.registration_info.registrations = []
      report.registration_info.start_date = null
      // shareholding
      report.shareholding.comments = null
      report.shareholding.sources = null
      report.shareholding.shareholders = []
      // suppliers
      report.suppliers.comments_details = null
      report.suppliers.comments_on_suppliers = null
      report.suppliers.does_not_import = null
      report.suppliers.imports_from = null
      report.suppliers.suppliers = []
     // console.log(report)
      this.updateReport(report)
      /*this.inquiryService.getReport(this.props.orderInfo.report).then(data => {
          //this.setState({report : data[0]});
          this.updateReport(data[0])
      });*/
    }

    getReportUpdateData = () =>{
      var jsonData = this.state.report;
      const ses_company = JSON.parse(sessionStorage.getItem("ses_company_" + this.state.report.id));

      jsonData.company_name = this.props.orderInfo.company_name;
      jsonData.company_info = ses_company;

      const registration_info = sessionStorage.getItem("ses_registration_info_" + this.state.report.id);
      if (registration_info != null){
        jsonData.registration_info = JSON.parse(registration_info)
      }

      const registration_changes = sessionStorage.getItem("ses_registration_changes_" + this.state.report.id);
      if (registration_changes != null){
        jsonData.registration_changes = JSON.parse(registration_changes)
      }

      const contact_info = sessionStorage.getItem("ses_contact_info_" + this.state.report.id);
      if (contact_info != null){
        jsonData.contact_info = JSON.parse(contact_info)
      }

      const shareholders = sessionStorage.getItem("ses_shareholders_" + this.state.report.id);
      if (shareholders != null){
        jsonData.shareholders = JSON.parse(shareholders)
      }

      const managers = sessionStorage.getItem("ses_managers_" + this.state.report.id);
      if (managers != null){
        jsonData.managers = JSON.parse(managers)
      }

      const affiliations = sessionStorage.getItem("ses_affiliations_" + this.state.report.id);
      if (affiliations != null){
        jsonData.affiliations = JSON.parse(affiliations)
      }

      const banksInfo = sessionStorage.getItem("ses_banks_info_" + this.state.report.id);
      if (banksInfo != null){
        jsonData.banks_info = JSON.parse(banksInfo)
      }

      const pressReleases = sessionStorage.getItem("ses_press_releases_" + this.state.report.id);
      if (pressReleases != null){
        jsonData.press_releases = JSON.parse(pressReleases)
      }

      const interview = sessionStorage.getItem("ses_interview_" + this.state.report.id);
      if (interview != null){
        jsonData.interview = JSON.parse(interview)
      }
      const business = sessionStorage.getItem("ses_business_" + this.state.report.id);
      if (business != null){
        jsonData.business = JSON.parse(business)
      }
      const clients = sessionStorage.getItem("ses_clients_" + this.state.report.id);
      if (clients != null){
        jsonData.clients = JSON.parse(clients)
      }
      const suppliers = sessionStorage.getItem("ses_suppliers_" + this.state.report.id);
      if (suppliers != null){
        jsonData.suppliers = JSON.parse(suppliers)
      }
      const financials = sessionStorage.getItem("ses_financials_" + this.state.report.id);
      if (financials != null){
        jsonData.financials = JSON.parse(financials)
        jsonData.financials.group_financial_report_files = null
        jsonData.financials.financial_report_files = null
      }
      const companyInfo = sessionStorage.getItem("ses_company_info_" + this.state.report.id);
      if (companyInfo != null){
        jsonData.company_info = JSON.parse(companyInfo)
      }

      const creditEvaluation = sessionStorage.getItem("ses_credit_evaluation_" + this.state.report.id);
      if (creditEvaluation != null){
        jsonData.credit_evaluation = JSON.parse(creditEvaluation)
      }

      //add username for logging
      const token = JSON.parse(sessionStorage.getItem('token'));

      jsonData.user = token.user.username;

      return jsonData
    }
    HandleGenerateXML = () =>{
      let jsonData = this.getReportUpdateData();
      //console.log(jsonData)
      this.inquiryService.updateGenerateReport(this.state.orderInfo.id,jsonData).then(data => {
         this.setState({notif_msg : data});
         this.setState({notif_class : ""});
         this.toast.current.show({severity:'success', summary:'Generated', detail:'XML report Generated'});
       }).catch(err =>{
         this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: JSON.stringify(err.response.data)});
       });
    }
    handleGenerateDoc = () => {
      let jsonData = this.getReportUpdateData();
      //console.log(jsonData)
      this.inquiryService.updateGenerateWordReport(this.state.orderInfo.id, jsonData).then(data => {
        this.setState({notif_msg : data});
        this.setState({notif_class : ""});
        this.toast.current.show({severity:'success', summary:'Generated', detail:'Word report Generated'});
      }).catch(err => {
        //console.log(err);
        this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: JSON.stringify(err.response.data)});
      })
    }
    onHide(){
      this.setState({display : false});
      //this.setState({displayClass: 'visible'});
    }

    askForReview(event){
      this.inquiryService.askFoReview(this.state.orderInfo.id).then(response => {
        this.toast.current.show({severity:'success', summary:'Updated', detail:'Order Status Updated'});
      }).catch(err =>{
        this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: err.response.data});
      })
    }

    setToWAiting(event){
      this.inquiryService.setToWaiting(this.state.orderInfo.id).then(response => {
        this.toast.current.show({severity:'success', summary:'Updated', detail:'Order Status Updated'});
      }).catch(err =>{
        this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: err.response.data});
      })
    }
    setReportToCompleted(){
      this.clearToast();
      this.inquiryService.complete(this.state.orderInfo.id).then(response => {
        this.toast.current.show({severity:'success', summary:'Updated', detail:'Order Completed'});
      }).catch(err =>{
        this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: err.response.data});
      })
    }

    complete(event){
      this.toast.current.show({ severity: 'warn', sticky: true, content: (
          <div className="p-flex p-flex-column" style={{flex: '1'}}>
              <div className="p-text-center">
                  <i className="pi pi-exclamation-triangle" style={{fontSize: '3rem'}}></i>
                  <h4>Are you sure ?</h4>
                  <p>This action will set the status of the report to Completed and will set the delivery date to today </p>
                  <p>Confirm to proceed</p>
              </div>
              <div className="p-grid p-fluid">
                  <div className="p-col-6">
                      <Button onClick={this.setReportToCompleted} type="button" label="Yes" className="p-button-success" />
                  </div>
                  <div className="p-col-6">
                      <Button onClick={this.clearToast} type="button" label="No" className="p-button-secondary" />
                  </div>
              </div>
          </div>
      ) });
    }

    clearToast(){
      if(this.toast.current){
        this.toast.current.clear();
      }

    }

    sendUpdateCompany(){
      this.clearToast();
      this.inquiryService.updateCompanyFromReport(this.state.orderInfo.id).then(response => {
        this.toast.current.show({severity:'success', summary:'Updated', detail:'Company Updated'});
      }).catch(err =>{
        this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: err.response.data});
      })
    }

    updateCompany(event){

      this.toast.current.show({ severity: 'warn', sticky: true, content: (
          <div className="p-flex p-flex-column" style={{flex: '1'}}>
              <div className="p-text-center">
                  <i className="pi pi-exclamation-triangle" style={{fontSize: '3rem'}}></i>
                  <h4>Are you sure?</h4>
                  <p> This will Overwrite All Company Information </p>
                  <p>Confirm to proceed</p>
              </div>
              <div className="p-grid p-fluid">
                  <div className="p-col-6">
                      <Button onClick={this.sendUpdateCompany} type="button" label="Yes" className="p-button-success" />
                  </div>
                  <div className="p-col-6">
                      <Button onClick={this.clearToast} type="button" label="No" className="p-button-secondary" />
                  </div>
              </div>
          </div>
      ) });
    }

    cleanReport(data){
      data["id"] = this.state.report.id
      //CRedit Evaluation
      data["credit_evaluation"]["id"] = this.state.report.credit_evaluation.id
      //Company Info
      data["company_info"]["id"] = this.state.report.company_info.id
      //Contact Info
      data["contact_info"]["id"] = this.state.report.contact_info.id
      //Legal fillings
      data["legal_fillings"]["id"] = this.state.report.legal_fillings.id
      //Payment Behavior
      data["payment_behavior"]["id"] = this.state.report.payment_behavior.id
      let payments = data["payment_behavior"]["payments"]
      for (let index in payments){
        let payment = data["payment_behavior"]["payments"][index];
        delete payment.id;
      }
      //Banks Info
      data["banks_info"]["id"] = this.state.report.banks_info.id
      let banks = data["banks_info"]["banks"]
      for (let index in banks){
        let bank = data["banks_info"]["banks"][index];
        delete bank.id;
      }
      //Registration Info
      data["registration_info"]["id"] = this.state.report.registration_info.id
      let registrations = data["registration_info"]["registrations"]
      for (let index in registrations){
        let registration = data["registration_info"]["registrations"][index];
        delete registration.id;
      }
      //Financials
      data["financials"]["id"] = this.state.report.financials.id
      let yearly_financials = data["financials"]["yearly_financials"]
      for (let yearly_financial in yearly_financials){
        let yearly_financial_data = data["financials"]["yearly_financials"][yearly_financial]
        delete yearly_financial_data.id;
      }
      let quarterly_financials = data["financials"]["quarterly_financials"]
      for (let quarterly_financial in quarterly_financials){
        let quarterly_financial_data = data["financials"]["quarterly_financials"][quarterly_financial]
        delete quarterly_financial_data.id;
      }
      let group_financials = data["financials"]["group_financials"]
      for (let group_financial in group_financials){
        let group_financial_data = data["financials"]["group_financials"][group_financial]
        delete group_financial_data.id;
      }
      //Clients
      data["clients"]["id"] = this.state.report.clients.id
      let clients = data["clients"]["clients"]
      for (let index in clients){
        let client = data["clients"]["clients"][index];
        delete client.id;
      }
      //Suppliers
      data["suppliers"]["id"] = this.state.report.suppliers.id
      let suppliers = data["suppliers"]["suppliers"]
      for (let index in suppliers){
        let supplier = data["suppliers"]["suppliers"][index];
        delete supplier.id;
      }
      //Affiliations
      data["affiliations"]["id"] = this.state.report.affiliations.id
      let affiliated_companies = data["affiliations"]["affiliated_companies"]
      for (let index in affiliated_companies){
        let affiliated_company = data["affiliations"]["affiliated_companies"][index];
        delete affiliated_company.id;
      }
      // Business
      data["business"]["id"] = this.state.report.business.id
      let sics = data["business"]["sics"]
      for (let index in sics){
        let sic = data["business"]["sics"][index];
        delete sic.id;
      }
      let branches = data["business"]["branches"]
      for (let index in branches){
        let branch = data["business"]["branches"][index];
        delete branch.id;
      }
      let awards_and_certification = data["business"]["awards_and_certification"]
      for (let index in awards_and_certification){
        let award_and_certification = data["business"]["awards_and_certification"][index];
        delete award_and_certification.id;
      }
      let adlicenses = data["business"]["adlicenses"]
      for (let index in adlicenses){
        let adlicense = data["business"]["adlicenses"][index];
        delete adlicense.id;
      }
      let brands = data["business"]["brands"]
      for (let index in brands){
        let brand = data["business"]["brands"][index];
        delete brand.id;
      }
      let trademarks = data["business"]["trademarks"]
      for (let index in trademarks){
        let trademark = data["business"]["trademarks"][index];
        delete trademark.id;
      }
      data["management"]["id"] = this.state.report.management.id
      //Managers
      for (let manager in data["management"]["managers"]){
        let manager_data = data["management"]["managers"][manager];
        delete manager_data.id;
      }
      // Shareholders
      data["shareholding"]["id"] = this.state.report.shareholding.id
      for (let shareholder in data["shareholding"]["shareholders"]){
        let shareholder_data = data["shareholding"]["shareholders"][shareholder];
        delete shareholder_data.id;
      }
      // INsurances
      data["insurances"]["id"] = this.state.report.insurances.id
      for (let insurance in data["insurances"]["insurances"]){
        let insurance_data = data["insurances"]["insurances"][insurance];
        delete insurance_data.id;
      }
      // Registration Changes
      data["registration_changes"]["id"] = this.state.report.registration_changes.id
      for (let registration_change in data["registration_changes"]["changes"]){
        let registration_change_data = data["registration_changes"]["changes"][registration_change];
        delete registration_change_data.id;
      }

      // Press Releases
      data["press"]["id"] = this.state.report.press.id
      for (let press_release in data["press"]["press_releases"]){
        let press_release_data = data["press"]["press_releases"][press_release];
        delete press_release_data.id;
      }
      // Interview
      data["interviews"]["id"] = this.state.report.interviews.id
      for (let interview in data["interviews"]["interviews"]){
        let interview_data = data["interviews"]["interviews"][interview];
        delete interview_data.id;
      }

      return data
    }

    importFromReport(event){
      this.setState({displayReportImport: true})
    }

    importFromCompany(event){
      this.setState({displayReportImportFromCompany: true})
    }
    importFromExperian(event){
      this.setState({displayReportImportFromExperian:true});
      this.initToken();
    }
    initToken(){
      //console.log("Experian Token");
      this.experianService.initRefreshToken("experian_api").then(response => {
        sessionStorage.setItem("experian_tokens" , JSON.stringify(response));
        this.toast.current.show({severity:'sucess', summary:'Token Fetch Successfully!', detail: ''});
      }).catch(err =>{
       // console.log(err);
          //this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: err.response.data});
      })
    }
    updateReport(report){

      sessionStorage.removeItem("ses_managers_" + this.state.report.id);
      sessionStorage.removeItem("ses_banks_info_" + this.state.report.id);
      sessionStorage.removeItem("ses_credit_evaluation_" + this.state.report.id);
      sessionStorage.removeItem("ses_shareholding_" + this.state.report.id);
      sessionStorage.removeItem("ses_management_" + this.state.report.id);
      sessionStorage.removeItem("ses_affiliations_" + this.state.report.id);
      sessionStorage.removeItem("ses_contact_info_" + this.state.report.id);
      sessionStorage.removeItem("ses_registration_changes_" + this.state.report.id);
      sessionStorage.removeItem("ses_business_" + this.state.report.id);
      sessionStorage.removeItem("ses_clients_" + this.state.report.id);
      sessionStorage.removeItem("ses_suppliers_" + this.state.report.id);
      sessionStorage.removeItem("ses_registration_info_" + this.state.report.id);
      sessionStorage.removeItem("ses_financials_" + this.state.report.id);
      sessionStorage.removeItem("ses_company_info_" + this.state.report.id);
      sessionStorage.removeItem("ses_interviews_" + this.state.report.id);
      sessionStorage.removeItem("ses_press_" + this.state.report.id);
      sessionStorage.removeItem("ses_legal_records_" + this.state.report.id);
      sessionStorage.removeItem("ses_payment_behavior_" + this.state.report.id);
      
      this.setState({report: report, displayReportImport: false})
      this.handleTabChange({index: 2})
      this.handleTabChange({index: 0})
    }


    importFromCompany2(event){
      let clean = this.cleanReport;
      if (!this.state.orderInfo.company){
        this.toast.current.show({severity:'error', summary:'Can not Proceed', detail: "No Company Assigned To This Report"});
      } else if (this.state.orderInfo.status === "COMPLETED"){
        this.toast.current.show({severity:'error', summary:'Can not Proceed', detail: "Order Status is Completed !"});
      } else {
        this.inquiryService.getCompanyReport(this.state.orderInfo.id)
        .then(data => {
          this.updateReport(clean(data))
          this.toast.current.show({severity:'success', summary:'Imported', detail: ""});
        })
        .catch(err => {
          this.toast.current.show({severity:'error', summary:'Import Failed !', detail: err.response.data});
        })
      }
    }
    onHidePreview(){
      this.setState({preview : false});
    }
    onHidePreviewOrder(){
      this.setState({previewOrder: false})
    }
    handlePreviewOrder(){
      this.setState({previewOrder: true})
    }
    handlePreview = () =>{
      this.setState({preview:true});
    }
    activeTabIndex(index){
      this.setState({activeTab : index});
    }
    handleTabChange(e){

      if(e.index != 4){
        sessionStorage.setItem("active_tab", e.index)
        this.setState({ activeTab: e.index })
      } else {
        this.toggleSideBar();
      }

    }
    renderTabButtons(){
      let sesToken = JSON.parse(sessionStorage.getItem("token"));
      return (
        <TabView activeIndex={this.state.activeTab} onTabChange={this.handleTabChange} >
          <TabPanel header={<Translation>{ t => <span>{t('BUSINESS')} </span>}</Translation>}>
              {this.renderClientInfo()}<br/>
              <InquiriesDetailsBusiness report={this.state.report} orderInfo ={this.state.orderInfo} toast = {this.state.toast} />
          </TabPanel>
          <TabPanel header={<Translation>{ t => <span>{t('FINANCIALS') + " / " + t('LEGALRECORDS')} </span>}</Translation>}>
               {this.renderClientInfo()}<br/>
              <InquiriesDetailsFinancials report={this.state.report}  order_info ={this.state.orderInfo} toast = {this.state.toast}/>
          </TabPanel>
          <TabPanel header={<Translation>{ t => <span>{t('SUMMARY')} </span>}</Translation>}>
               {this.renderClientInfo()}<br/>
              <InquiriesDetailsSummary report={this.state.report}  orderInfo ={this.state.orderInfo} customer_info = {this.state.customerInfo} toast = {this.state.toast}/>
          </TabPanel>
          <TabPanel header={<Translation>{ t => t('COMMUNICATIONS')}</Translation>}>
              {this.renderClientInfo()}<br/>
              <InquiriesDetailsCommunications toast = {this.state.toast}  orderInfo ={this.state.orderInfo} customer_info = {this.state.customerInfo} />
          </TabPanel>

          <TabPanel header={<Translation>{ t => t('ADMINNOTES')}</Translation>} onClick={this.toggleSideBar}>

          </TabPanel>


        </TabView>
      )

    }

    toggleSideBar(){
      this.setState({admin_note_sidebar:!this.state.admin_note_sidebar})
    }

    openEmail(e){
      let subject = 'Reference:%20' + this.state.orderInfo.client_reference + ' - ' + this.state.orderInfo.company_name + ' Report'
      let email = this.state.customerInfo.emails ? this.state.customerInfo.emails : ''
      window.open('mailto:' + email + '?subject=' + subject + '&body=Body%20goes%20here');
    }

    renderActionButtons(){
      const saveItems = [
        {
          label: 'Set to waiting',
          icon: 'pi pi-clock',
          command: (e) => {
              this.setToWAiting(e);
          }
        },
        {
          label: 'Ask for Review',
          icon: 'pi pi-compass',
          command: (e) => {
              this.askForReview(e);
          }
        },
        {
          label: 'Complete',
          icon: 'pi pi-forward',
          command: (e) => {
              this.complete(e);
          }
        },
        {
          label: 'Update Or Create Company',
          icon: 'pi pi-download',
          command: (e) => {
              this.updateCompany(e)
          }
        }
      ];

      const importItems2 = [
        {
          label: 'Import from Report',
          icon: 'pi pi-upload',
          command: (e) => {
              this.importFromReport(e);
          }
        }
      ];

      const importItems = [
        {
          label: 'Assign to Company',
          icon: 'pi pi-upload',
          command: (e) => {
              this.importFromCompany(e);
          }
        },
        {
          label: 'Import from Report',
          icon: 'pi pi-upload',
          command: (e) => {
              this.importFromReport(e);
          }
        },
        {
          label: 'Import from Experian ' ,
          icon: 'pi pi-upload',
          command: (e) => {
              this.importFromExperian(e);
          }
        }
      ];
      const exportItems = [
        {
          label: 'XML',
          icon: 'pi pi-file-excel',
          command: (e) => {
              this.HandleGenerateXML(e);
          }
        },
        {
          label: 'DOC',
          icon: 'pi pi-file',
          command: (e) => {
              this.handleGenerateDoc(e);
          }
        },
        {
          label: 'Outlook',
          icon: 'pi pi-inbox',
          command: (e) => {
            this.openEmail(e);
          }
        }
      ];
      if (this.state.readOnly){
        return(
          <div className="col-sm-12 action-buttons">
            <Button hidden icon="pi pi-undo"  label="Empty Report" onClick={this.HandleCancelReport} />
            &nbsp;
            <Button icon="pi pi-file-excel" label="XML" onClick={this.HandleGenerateXML} />
            &nbsp;
            <Button  icon="pi pi-file" label="Doc" onClick={this.handleGenerateDoc} />
            &nbsp;
            <Button  icon="pi pi-eye" label="View" onClick={this.handlePreview} />
          </div>
        )
      }else {
        return (
          <div className="col-sm-12">
            <Button hidden icon="pi pi-undo"  label="Empty Report" onClick={this.HandleCancelReport} />
            &nbsp;
            <SplitButton label="Export" icon="pi pi-download" model={exportItems}></SplitButton>
            &nbsp;
            <Button  icon="pi pi-eye" label="View" onClick={this.handlePreview} />
            &nbsp;
            <Button  icon="pi pi-eye" label="View Order" onClick={this.handlePreviewOrder} />
            &nbsp;
            <SplitButton label="Import" icon="pi pi-upload" model={importItems}></SplitButton>
            &nbsp;
            <SplitButton label="Save" icon="pi pi-save" onClick={this.handleSaveReport} model={saveItems}></SplitButton>
            &nbsp;

          </div>
        )
      }

    }

    handleSearchFieldChange(e){
      this.setState({searchField: e.target.value})
    }

    handleSearchValueChange(e){
      this.setState({searchValue: e.target.value})
    }
    submitSearch(){
      let body = {}
      body.field = this.state.searchField;
      body.value = this.state.searchValue;
      this.inquiryService.reportSearch(body).then(data => {
        this.setState({searchReports: data})
      })
    }
    handleSearchExperian(e){
      var field = e.target.name;
      var val = e.target.value;
      Object.assign(this.state.searchExperian, {[field]: val});
      
    }
    submitCompanySearch(){
      let body = {}
      body.field = this.state.searchField;
      body.value = this.state.searchValue;
      this.companyService.searchForCompany(body).then(data => {
        this.setState({searchCompanies: data})
      })
    }
    submitSearchExperian(){
        //console.log("fields",this.state.searchExperian);
        let body = {}
        body.name = this.state.searchExperian['name'];
        body.city = this.state.searchExperian['city'];
        body.state = this.state.searchExperian['state'];
       

        this.experianService.searchExperianBusiness(body).then(data=>{
          //console.log('false',data);
           if(data.success == false){
            // console.log('false');
           }
           this.setState({searchExperianResults: data.results})
            
         }).catch(err=>{
            this.clearToast();
            //console.log("ERR",err);
           // this.toast.current.show({severity:'error', summary:'Error Searching Try Again', detail: err.response.data.errors[0].message});
         })



    }
    updatePreviousReportComments(old_report_id, previous_comments){
      this.inquiryService.updatePreviousComment(this.state.orderInfo.id, {report_id: old_report_id}).then(data => {
        let report = this.state.report
        report.comments_from_previous_report = previous_comments
        this.setState({report: report})
        this.toast.current.show({severity:'success', summary:'Loaded', detail: "Comments from Previous Report loaded"});
      }).catch(err => {
       // console.log(err);
      })
    }

    importReport(orderInfo, event){
      let clean = this.cleanReport;
      this.inquiryService.getReport(orderInfo.report).then(data => {
          let old_report_id = data[0]["id"]
          let previous_comments = data[0]["credit_evaluation"]["comments"]

          this.updateReport(clean(data[0]));
          this.toast.current.show({severity:'success', summary:'Imported', detail: ""});
          this.updatePreviousReportComments(old_report_id, previous_comments);

      });
    }
    importReportFromCompany(companyInfo, event){
      let clean = this.cleanReport;
      this.inquiryService.getCompanyReport(companyInfo.id).then(data => {
        //this.updateReport(clean(data))
        //this.toast.current.show({severity:'success', summary:'Imported', detail: ""});
        this.inquiryService.assignOrderCompany(this.state.orderInfo.id, companyInfo.id).then(data => {
          this.toast.current.show({severity:'success', summary:'Assigned', detail: ""});
        }).catch(err => {
          this.toast.current.show({severity:'error', summary:'Assignement Failed', detail: JSON.stringify(err.response.data)});
        })
      }).catch(err => {
        //console.log(err)
        this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.response.data)});
      })
    }
    importReportBodyTemplate(rowData){
      return (
          <React.Fragment>
              <Button icon="pi pi-upload" label = "Import"  onClick={(e) => this.importReport(rowData, e)} />
          </React.Fragment>
      );
    }
    importCompanyBodyTemplate(rowData){
      return (
          <React.Fragment>
              <Button icon="pi pi-upload" label = "Import"  onClick={(e) => this.importReportFromCompany(rowData, e)} />
          </React.Fragment>
      );
    }
    
   /*** EXPERIAN LEGAL RECORDS */
   async fetchUCCFiles(companyInfo){
    let searchData = {}
    let jsonData = this.getReportUpdateData();
    searchData.bin = companyInfo.bin;
    searchData.uccFilingsDetail = true;
    searchData.uccFilingsSummary = true;

    this.setState({searchStatusTxt : "Importing UCC Files please wait.."})
    this.setState({importBtnDisable : false});
    let newreport = this.state.report;
    let legal_fillings = newreport.legal_fillings;

    if(sessionStorage.getItem('ses_legal_records_'+this.state.report.id) !== null){
        legal_fillings = JSON.parse(sessionStorage.getItem('ses_legal_records_'+this.state.report.id))
    }

    try {
        const resultOk = await this.experianService.fetchExperianLegalRecords("uccfilings",searchData).then( data=>{
        delete searchData.uccFilingsDetail;
        delete searchData.uccFilingsSummary;
        let uccDetails = data.results.uccFilingsDetail;
        uccDetails = uccDetails.sort((a, b) => new Date(b.dateFiled) - new Date(a.dateFiled))
       if(uccDetails.length == 0){
        sessionStorage.removeItem('ExperianData_uccfillings_'+this.state.report.id);
        //this.fetchBankruptcies(companyInfo,data.results.businessHeader.bin);
        this.fetchLegalCollectionSummary(companyInfo); 
        return ;
      }

       //Check if UCC Boolean is selected
       if(legal_fillings.ucc_files !== null){
          // replace or not?
       } else {
          legal_fillings.ucc_files = data.results.uccFilingIndicator;
       }
      
       console.log("uclen", uccDetails.length);
       let cntr = 0;
       let uccTxt = "";
       uccDetails.forEach(ucc=>{
          if(cntr < 5){
            uccTxt += "Original Filing Date  : " + ucc.dateFiled +"\n";
            uccTxt += "Original Filing Number : " + ucc.documentNumber+"\n";
            if (Object.hasOwn(ucc, "originalUCCFilingsInfo")) {
               uccTxt += "Original Filing Statex : " + ucc.originalUCCFilingsInfo.filingState +"\n";
            }
            if (Object.hasOwn(ucc, "securedParty")) {
                uccTxt += "SECURED PARTY # : " + ucc.securedParty  +"\n";
            }
            uccTxt += "Jurisdiction : " + ucc.filingLocation +"\n\n";
            //Juris,Original Filing Date, Original Filing Number, Original Filing State
          } else {
            return;
          }
          cntr++;
       })
      

       legal_fillings.ucc_files_comments = uccTxt;
       sessionStorage.setItem('ses_legal_records_'+this.state.report.id, JSON.stringify(legal_fillings));
       sessionStorage.setItem('ExperianData_uccfillings_'+this.state.report.id,  JSON.stringify(data.results))
       newreport.legal_fillings = legal_fillings;
       newreport.ucc_files_comments = uccTxt;
       this.setState({report : newreport});
      // this.fetchBankruptcies(companyInfo,data.results.businessHeader.bin);
       this.fetchLegalCollectionSummary(companyInfo); 
      });
    } catch (err) {
      console.log("ERR",err);
     // this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.message) + '\nTry Importing Again.'});
      this.setState({searchStatusTxt : ""});
      this.fetchLegalCollectionSummary(companyInfo); 
      //return 0;
      //this.fetchUCCFiles()
     
    }
   }
  async fetchBankruptcies(companyInfo){
    let searchData = {}
    searchData.bin = companyInfo.bin;
    searchData.bankruptcyDetail =  true;
    
    let newreport = this.state.report;
    let legal_fillings = newreport.legal_fillings;

    this.setState({searchStatusTxt : "Importing Bankruptcies please wait.."})
      try {
        const resultOk = await this.experianService.fetchExperianLegalRecords("bankruptcies",searchData).then( data=>{
        delete searchData.bankruptcyDetail;
        let bankruptcyDetails = data.results.bankruptcyDetail;
        bankruptcyDetails = bankruptcyDetails.sort((a, b) => new Date(b.dateFiled) - new Date(a.dateFiled))
        let cntr = 0;
        let bankruptcyTxt = "";
        
        if(bankruptcyDetails.length == 0){
          this.fetchCollections(companyInfo)
          return ;
        }

        if(legal_fillings.bankruptcy !== null){
          // replace or not?
        } else {
          legal_fillings.bankruptcy = data.results.bankruptcyIndicator;
        }
       
        bankruptcyDetails.forEach(bankruptcy=>{
          if(cntr < 5){
            bankruptcyTxt += "Date Filed  : " + bankruptcy.dateFiled +"\n";
            bankruptcyTxt += "Document # : " + bankruptcy.documentNumber+"\n";
            bankruptcyTxt += "Jurisdiction : " + bankruptcy.filingLocation +"\n\n";
          } else {
            return;
          }
          cntr++;
        })
        legal_fillings.bankruptcy_comment = bankruptcyTxt;
        newreport.legal_fillings = legal_fillings;
        this.setState({report : newreport});
        console.log(data.results,"bank");
        sessionStorage.setItem('ses_legal_records_'+this.state.report.id, JSON.stringify(legal_fillings));
        sessionStorage.setItem('ExperianData_bankruptcies_'+this.state.report.id , JSON.stringify(data.results));
        this.setState({searchStatusTxt : ""});
        this.fetchCollections(companyInfo)
      
      });
    } catch (err) {
     
      //this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.message) + '\nTry Importing Again.'});
      this.setState({searchStatusTxt : ""});
      this.fetchCollections(companyInfo);
      return 0;
    }
   }
   async fetchCollections(companyInfo){
    let searchData = {}
    searchData.bin = companyInfo.bin;
    searchData.collectionsDetail = true;

    let newreport = this.state.report;
    let legal_fillings = newreport.legal_fillings;


    this.setState({searchStatusTxt : "Importing Collections please wait.."})
      try {
        const resultOk = await this.experianService.fetchExperianLegalRecords("collections",searchData).then( data=>{
        delete searchData.collectionsDetail;
       
        let collectionsDetails = data.results.collectionsDetail;
        collectionsDetails = collectionsDetails.sort((a, b) => new Date(b.datePlacedForCollection) - new Date(a.datePlacedForCollection))
       console.log(collectionsDetails,"<<<")
        let cntr = 0;
        let collectionsTxt = "";

        if(collectionsDetails.length == 0){
          this.fetchLiens(companyInfo)
          this.setState({searchStatusTxt : ""});
          return ;
        } 

        if(legal_fillings.collections !== null){
          // replace or not?
        } else {
          legal_fillings.collections = data.results.collectionsIndicator;
        }
        collectionsDetails.forEach(collections=>{
          if(cntr < 5){
            collectionsTxt += "Account Status : " + collections.accountStatus +"\n";
            collectionsTxt += "Collection Date : " + collections.datePlacedForCollection +"\n";
            collectionsTxt += "Amount Collection : " + collections.amountPlacedForCollection +"\n";
            collectionsTxt += "Collection Agency: " + collections.collectionAgencyInfo.name +"\n";
            collectionsTxt += "Agency Phone: " + collections.collectionAgencyInfo.phoneNumber +"\n\n";
          } else {
            return;
          }
          cntr++;
        })
        legal_fillings.collections_comment = collectionsTxt;
        newreport.legal_fillings = legal_fillings;
        this.setState({report : newreport});
        console.log(data.results,"collections");
        sessionStorage.setItem('ses_legal_records_'+this.state.report.id, JSON.stringify(legal_fillings));
        sessionStorage.setItem('ExperianData_collections_'+this.state.report.id,  JSON.stringify(data.results));
        this.setState({searchStatusTxt : ""});
        this.fetchLiens(companyInfo)
      });
    } catch (err) {
      //this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.message) + '\nTry Importing Again.'});
      this.setState({searchStatusTxt : ""});
      this.fetchLiens(companyInfo)
      return 0;

    }
   }
   async fetchLiens(companyInfo){
    let searchData = {}

    searchData.lienSummary =  true;
    searchData.lienDetail = true;
    searchData.bin = companyInfo.bin;
    let newreport = this.state.report;
    let legal_fillings = newreport.legal_fillings;
    
    this.setState({searchStatusTxt : "Importing Liens please wait.."})
      try {
        const resultOk = await this.experianService.fetchExperianLegalRecords("liens",searchData).then( data=>{
        delete searchData.lienSummary;
        delete searchData.lienDetail;
        
        console.log("liens", data.results);
    //  this.fetchJudgement(companyInfo,bin)
        let lienDetails = data.results.lienDetail;
        lienDetails = lienDetails.sort((a, b) => new Date(b.dateFiled) - new Date(a.dateFiled))
        let cntr = 0;
        let lienTxt = "";

       /* if(lienDetails.length == 0){
          this.fetchLiens(companyInfo)
          this.setState({searchStatusTxt : ""});
          return ;
        } */

        if(legal_fillings.liens !== null){
          // replace or not?
        } else {
          legal_fillings.liens = data.results.lienIndicator;
        }

        lienDetails.forEach(liens=>{
          if(cntr < 5){
            lienTxt += "Date Filed : " + liens.dateFiled +"\n";
            lienTxt += "Document # : " + liens.documentNumber +"\n";
            lienTxt += "Filing Location : " + liens.filingLocation+"\n";
            lienTxt += "Legal Type : " + liens.legalType+"\n";
            lienTxt += "Filed by : " + liens.owner +"\n";
            lienTxt += "Status  : " + liens.legalAction +"\n\n";
          } else {
            return;
          }
          cntr++;
        })

        legal_fillings.liens_comments = lienTxt;
        newreport.legal_fillings = legal_fillings;
        this.setState({report : newreport});
        sessionStorage.setItem('ses_legal_records_'+this.state.report.id, JSON.stringify(legal_fillings));
        sessionStorage.setItem('ExperianData_liens_'+this.state.report.id,  JSON.stringify(data.results));
        this.setState({searchStatusTxt : ""});
        this.fetchTrades(companyInfo)
      });
    } catch (err) {
      //this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.message) + '\nTry Importing Again.'});
      this.setState({searchStatusTxt : ""});
      this.fetchTrades(companyInfo);
      return 0;

    }
   }
   async fetchJudgement(companyInfo){
    let searchData = {}
    searchData.bin = companyInfo.bin;
    searchData.judgmentSummary =  true;
    searchData.judgmentDetail = true;
    this.setState({searchStatusTxt : "Importing Judgement please wait.."})
      try {
        const resultOk = await this.experianService.fetchExperianLegalRecords("judgments",searchData).then( data=>{
        delete searchData.judgmentSummary;
        delete searchData.judgmentDetail;
        console.log("judgements", data.results);
        sessionStorage.setItem('ExperianData_judgments_'+this.state.report.id,  JSON.stringify(data.results));
        this.fetchTrades(companyInfo)
      });
    } catch (err) {
      this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.message) + '\nTry Importing Again.'});
      this.setState({searchStatusTxt : ""});
      this.fetchJudgement(companyInfo);
      return 0;

    }
   }
   paymentTrends(trendData,is_monthly = 1){
    var trend_arr = [];
    var date = "";
    var date_monthly_arr = [];
    
    var updateMonth = false;
    var prev_month = "";
    trendData.forEach(monthly => {
        date = monthly.date.split("-");
       
        var year = date[0];
        var day = date[2]; 
        var month = date[1];
     
        if(!date_monthly_arr.includes(year+"-"+date[1])){
          month = parseInt(month) + 2;
          updateMonth = null;
          prev_month = date[1]
        } else {
          var new_trend = trend_arr.filter(trend => trend.year == year && trend.month == date[1]);
          updateMonth = true;
        }
     
        
        date_monthly_arr.push(year+"-"+date[1]);
       
        trend_arr.push({ 
          update_month : updateMonth,
          prev_month : prev_month,
          month: is_monthly ? month : date[1],
          year : date[0],
          dbt : monthly.dbt, 
          balance: monthly.totalAccountBalance.amount,
          on_time: monthly.currentPercentage,
          one_month_late: monthly.dbt30,
          two_months_late: monthly.dbt60,
          three_months_late: monthly.dbt90,
          more_than_three_months_late: monthly.dbt91Plus,
          is_monthly : is_monthly,
          balance_currency: {
              "id": 1,
              "country": {
                  "id": 244,
                  "name": "United States",
                  "country_iso": "US"
              },
              "code": "USD",
              "name": "United States Dollars"
          }
        })
        
    });
  
    var new_trend = []
    var rtrend ;
    var ntrend =[];
    var filtered_trend = [];

    if(is_monthly){
        trend_arr.forEach( trend=>{

          if(trend.update_month){
              trend.month = (parseInt(trend.month) - 1).toString();
              rtrend = new_trend.filter(nt => nt.prev_month == trend.prev_month)
              ntrend = rtrend[0]
              ntrend.month = rtrend[0].prev_month.padStart(2, '0');
            
             //If there are 2 entries for january one is for Dec and one is actual Jan data
              if(trend.month == "0"){
                trend.month = ntrend.month
              }
          } else {
              trend.month = (parseInt(trend.month) - 3).toString().padStart(2, '0');
              var currentTime =  new Date();
              var year = currentTime.getFullYear()
              //January data but 01-01-year but for december
              if(trend.month == "00" && trend.year == year){
                trend.month = "12";
                trend.year = (trend.year - 1).toString().padStart(2, '0');
              }
          } 
          ntrend = trend;
           filtered_trend.push(ntrend)
           new_trend.push(trend)
        })
        
      return filtered_trend;
    } else {
     return trend_arr
    }
   
 }
   async fetchLegalCollectionSummary(companyInfo){
      let searchData = {}
      searchData.bin = companyInfo.bin; 
      searchData.legalFilingsCollectionsSummary =  true;
      searchData.legalFilingsSummary = true;
      try { 
        const resultOk = await this.experianService.fetchExperianLegalRecords("legalcollectionsummaries",searchData).then( data=>{
            console.log("CollectionSummary" , data);
          // this.previewHTMLReportFromExperian(companyInfo);
            let collectionSummary = { "collectionCount": data.results.legalFilingsCollectionsSummary.collectionCount , 
                                      "collectionBalance" : data.results.legalFilingsCollectionsSummary.collectionBalance}
                                  
            sessionStorage.setItem("ses_summary_collection_"+this.state.report.id, JSON.stringify(collectionSummary));
            
            this.fetchBankruptcies(companyInfo);
        });
      } catch(err){
        this.fetchBankruptcies(companyInfo);
      }
   }
   async fetchTrades(companyInfo,bin){
    let searchData = {}
    let summaryCollection = JSON.parse(sessionStorage.getItem("ses_summary_collection_"+this.state.report.id));
    searchData.bin = companyInfo.bin;
    searchData.tradePaymentSummary =  true;
    searchData.tradePaymentTotals = true;
    searchData.tradePaymentExperiences  = true;
    searchData.tradePaymentTrends = true;
    this.setState({searchStatusTxt : "Importing Trades please wait.."})
      try {
        const resultOk = await this.experianService.fetchExperianLegalRecords("trades",searchData).then( data=>{
        delete searchData.tradePaymentSummary;
        delete searchData.tradePaymentTotals;
        delete searchData.tradePaymentExperiences;
        delete searchData.tradePaymentTrends;
        console.log("trades", data.results);
      
       //Set payment behavior
        let ses_payments  = JSON.parse(sessionStorage.getItem("ses_payment_behavior_"+this.state.report.id))
        let tradePaymentTrends = data.results.tradePaymentTrends;
        let monthlyTrends = tradePaymentTrends.monthlyTrends;
        let quarterlyTrends = tradePaymentTrends.quarterlyTrends;
        let paymentSummary = data.results.tradePaymentSummary;
        let tradePaymentExperiencestradeAdditional = data.results.tradePaymentExperiences.tradeAdditional 
        let report = this.state.report;
        //tradePaymentExperiencestradeAdditional.sort((a, b) => a.terms - b.terms);
        tradePaymentExperiencestradeAdditional = tradePaymentExperiencestradeAdditional.sort((a, b) => a.terms - b.terms);
        tradePaymentExperiencestradeAdditional = tradePaymentExperiencestradeAdditional.filter((trade) => trade.terms != "0000000");
        console.log(tradePaymentExperiencestradeAdditional.filter((value,index,array) =>  array.indexOf(value) === index ), "<><>");
        console.log(tradePaymentExperiencestradeAdditional);

       let paymentSummaryTxt ="Payment Performance\n";
       paymentSummaryTxt += "Current DBT: " + paymentSummary.currentDbt +"\n";
       //paymentSummaryTxt += "Predicted DB\n";
       paymentSummaryTxt += "Monthly Average DBT: " + paymentSummary.monthlyAverageDbt +"\n";
       paymentSummaryTxt += "Highest DBT Previous 6 Months: " + paymentSummary.highestDbt6Months+"\n";
       paymentSummaryTxt += "Highest DBT Previous 5 Quarters : " + paymentSummary.highestDbt5Quarters+"\n";
       // += "Payment Trend Indication:\n";
       paymentSummaryTxt += "\nTrade and Collection Balance\n";
       paymentSummaryTxt += "Total trade collection ("+ (paymentSummary.allTradelineCount + summaryCollection.collectionCount) +"):  "+formatToCurrency(paymentSummary.allTradelineBalance + summaryCollection.collectionBalance)+" \n";
       paymentSummaryTxt += "   All trades("+paymentSummary.allTradelineCount+"): " + formatToCurrency(paymentSummary.allTradelineBalance) +"\n";
       paymentSummaryTxt += "   All collections ("+summaryCollection.collectionCount+"): " + formatToCurrency(summaryCollection.collectionBalance) +"\n";
       paymentSummaryTxt += "Continuous trade ("+ paymentSummary.currentTradelineCount+ "): " + formatToCurrency(paymentSummary.currentAccountBalance) +"\n";

       //paymentSummaryTxt += "6 month average: \n";
       paymentSummaryTxt += "Highest credit amount extended: : " + formatToCurrency(paymentSummary.singleHighCredit) +"\n";
      // paymentSummaryTxt += "Most frequent industry purchasing terms: \n";
       ses_payments.credit_bureau_information = paymentSummaryTxt;
       ses_payments.payments = this.paymentTrends(monthlyTrends).concat(this.paymentTrends(quarterlyTrends,0));
       console.log("ses_payments",ses_payments);
       sessionStorage.setItem('ExperianData_trades_'+this.state.report.id,  JSON.stringify(data.results));
       sessionStorage.setItem("ses_payment_behavior_"+this.state.report.id,JSON.stringify(ses_payments));
     
       report.payment_behavior.payments = ses_payments;
       this.setState({report : report});
       
        this.getReportUpdateData();
        this.previewHTMLReportFromExperian(companyInfo);
          //this.fetchLegalCollectionSummary(companyInfo);
      });
    } catch (err) {
      //track payment trends error
     // this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.message) + '\nTry Importing Again.'});
    //  this.setState({searchStatusTxt : ""});
     // this.fetchTrades(companyInfo,bin);
     //console.log("Err",err.message);
     this.getReportUpdateData();
     this.previewHTMLReportFromExperian(companyInfo);
    }
   }
   
    previewHTMLReportFromExperian(companyInfo){
      this.setState({searchStatusTxt : "Fetching HTML Report please wait.."})
      this.experianService.fetchHMTL(companyInfo).then(data=>{ 
      //  this.setState({htmlpreview:true});
      //  this.setState({htmlpreviewContent: data.results});
        //this.props.experian_html(companyInfo,data.results, this.props.tab_index);
        this.toast.current.show({severity:'success', summary:'HTML Report Fetch and Display in Tab', detail: ""});
        this.setState({searchStatusTxt : ""});
        this.setState({importBtnDisable: false})
       
        const blob = new Blob([data.results], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = companyInfo.businessName+".html";
        link.href = url;
        link.click();
        this.setState({displayReportImportFromExperian : false})
      }).catch(err=>{
          // this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(err.message) + '\nTry Importing Again.'});
          this.setState({searchStatusTxt : ""});
          this.previewHTMLReportFromExperian(companyInfo);
          //return 0;
      })
      return 0;
    }
   

    previewExperianHTMLBodyTemplate(rowData){
      return (
        <React.Fragment>
            <Button icon="pi pi-eye" label = "View HTML"  onClick={(e) => this.previewHTMLReportFromExperian(rowData, e)} />
        </React.Fragment>
    );
    }
    importReportFromExperian(companyInfo, event){
      this.setState({importBtnDisable: true})
      sessionStorage.setItem("experianImport" , true);
      this.fetchUCCFiles(companyInfo);
     }
    importExperianBodyTemplate(rowData){
      return (
          <React.Fragment>
              <Button icon="pi pi-upload" label = "Import"  onClick={(e) => this.importReportFromExperian(rowData, e)} disabled={this.state.importBtnDisable}/>
          </React.Fragment>
      );
    }
    importExperianAddressTemplate(rowData){
      return (
        <React.Fragment>
            {rowData.address['street'] + "," + rowData.address['city'] +" " + rowData.address['state'] + " " + rowData.address['zip']}
        </React.Fragment>
    );
    }
    createNewCompany(){
      this.setState({displayReportImportFromCompany: false})
      this.props.new_company(this.state.orderInfo.id, this.props.tab_index);
    }
    renderImportFromCompany(){
      const fields = [
        {label: 'Company Name', value: 'legal_name'},
        {label: 'Cristal Number', value: 'cristal_number'}
      ];
      return(
        <div>
          <div className="form-group">
            <div className="row">
                <div className="col-sm-1">
                    <label className="form-field-bold">Field</label>
                </div>
                <div className="col-sm-1">
                  <Dropdown value={this.state.searchField} options={fields} onChange={this.handleSearchFieldChange} optionLabel="label" placeholder="Select a Field"/>
                </div>
                <div className="col-sm-1">
                  <label className="form-field-bold">Value</label>
                </div>
                <div className="col-sm-1">
                  <input type = "text" value={this.searchValue} onChange={this.handleSearchValueChange} ccreateNewCompanylassName="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                  <Button icon="pi pi-search" label = "Search"  onClick={this.submitCompanySearch} />
                </div>
                <div className="col-sm-2">
                  <Button icon="pi pi-plus" label = "Create New Company"  onClick={this.createNewCompany} />
                </div>
            </div>
          </div>
          <div className="col-12" id="dataTable">
            <DataTable ref={(el) => this.dt = el}
                value={this.state.searchCompanies}
                className="p-datatable-striped"
                dataKey="id"
                rowHover
                //  selection={this.state.selectedName}
                // onSelectionChange={e => this.setState({selectedName: e.value})}
                paginator rows={10}
                emptyMessage="No orders found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10,25,50]}
                globalFilter={this.state.globalFilter}>
                <Column field="legal_name" header={<Translation>{ t => <span>{t('company')} </span>}</Translation>}/>
                <Column field="trade_names" header={<Translation>{ t => <span>{t('trade_names')} </span>}</Translation>}/>
                <Column field="previous_names" header={<Translation>{ t => <span>{t('previous_names')} </span>}</Translation>}/>
                <Column field="legal_address.country.name"  header={<Translation>{ t => <span>{t('country')} </span>}</Translation>} />
                <Column field="legal_address.street"  header={<Translation>{ t => <span>{t('address')} </span>}</Translation>} />
                <Column field="cristal_number" header={<Translation>{ t => <span>{t('CRISTAL_NUMBER')}  </span>}</Translation>}/>
                <Column field = "" body = {this.importCompanyBodyTemplate}  />
            </DataTable>
          </div>
        </div>
      )
    }
    renderImportFromExperian(){
      const disableBtn = this.state.disableBtn;
      return(
        <div>
          <div className="form-group col-12">
          <div className='row'>
                 <div className='col-4'><h6>Search Experian <span className='required'>*</span> are required</h6></div>
                 <div className='col-8'><strong>{this.state.searchStatusTxt}</strong></div>
            </div>
            <div className="row searchHeaders">
                <div className="col-sm-1">
                    <label className="form-field-bold">Business Name:<span className='required'>*</span></label>
                </div>
                <div className="col-sm-2">
                    <input type = "text" value={this.searchValue} name="name" onChange={this.handleSearchExperian} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">City:<span className='required'>*</span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="city"   onChange={this.handleSearchExperian} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">State:<span className='required'>*</span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="state" onChange={this.handleSearchExperian} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                     {disableBtn
                       ?  <Button icon="pi pi-search" label = "Search" disabled/>
                       : <Button icon="pi pi-search" label = "Search"  onClick={this.submitSearchExperian} />
                       } 
                </div>
            </div>
          </div>
          <div className="col-12" id="dataTable">
          <DataTable ref={(el) => this.dt = el}
                value={this.state.searchExperianResults}
                className="p-datatable-striped"
                rowHover
                paginator rows={10}
                emptyMessage="No Company Found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10,25,50]}
                globalFilter={this.state.globalFilter}>
                <Column field="businessName" header={<Translation>{ t => <span>{t('business_name')} </span>}</Translation>}/>
                <Column field="bin" header={<Translation>{ t => <span>{t('BIN')} </span>}</Translation>}/>
                <Column field="phone" header={<Translation>{ t => <span>{t('PHONE')} </span>}</Translation>}/>
                <Column field="address" body = {this.importExperianAddressTemplate} header={<Translation>{ t => <span>{t('ADDRESS')} </span>}</Translation>}/>
                <Column body = {this.importExperianBodyTemplate}  />
            </DataTable>
          </div>
        </div>
      )
    }
    renderImportFromReport(){
      const fields = [
        {label: 'Legal Name', value: 'company_name'},
        {label: 'WAND2 Reference', value: 'reference'},
        {label: 'Client Reference', value: 'client_reference'}
      ];
      return(
        <div>
          <div className="form-group">
            <div className="row">
                <div className="col-sm-1">
                    <label className="form-field-bold">Field</label>
                </div>
                <div className="col-sm-1">
                  <Dropdown value={this.state.searchField} options={fields} onChange={this.handleSearchFieldChange} optionLabel="label" placeholder="Select a Field"/>
                </div>
                <div className="col-sm-1">
                  <label className="form-field-bold">Value</label>
                </div>
                <div className="col-sm-1">
                  <input type = "text" value={this.searchValue} onChange={this.handleSearchValueChange} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                  <Button icon="pi pi-search" label = "Search"  onClick={this.submitSearch} />
                </div>
            </div>
          </div>
          <div className="col-12" id="dataTable">
            <DataTable ref={(el) => this.dt = el}
                value={this.state.searchReports}
                className="p-datatable-striped"
                dataKey="id"
                rowHover
                //  selection={this.state.selectedName}
                // onSelectionChange={e => this.setState({selectedName: e.value})}
                paginator rows={10}
                emptyMessage="No orders found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10,25,50]}
                globalFilter={this.state.globalFilter}>
                <Column field="registered_name" header={<Translation>{ t => <span>{t('LEGALNAME')} </span>}</Translation>}/>
                <Column field="reference"  header={<Translation>{ t => <span>{t('REFERENCE')} </span>}</Translation>} />
                <Column field="client"  header={<Translation>{ t => <span>{t('CLIENT NAME')} </span>}</Translation>} />
                <Column field="created_at" header={<Translation>{ t => <span>{t('ORDERDATE')}  </span>}</Translation>}/>
                <Column field="status" header={<Translation>{ t => <span>{t('STATUS')} </span>}</Translation>}/>
                <Column field="assigned_to.username"   header={<Translation>{ t => <span>{t('INCHARGE')} </span>}</Translation>}  />
                <Column field = "" body = {this.importReportBodyTemplate}  />
            </DataTable>
          </div>
        </div>
      )
    }

    readAddress(address){
      if(!address){
        return '';
      }
      let str = '';
      if(address.full_address){
        str += address.full_address + '\n';
      } else {
        str += address.street + '\n';
        str += address.city +  "," + address.state + " " + address.postal_code + '\n';
      }
      str += "Country : " + address.country.name + '\n';
      str += 'Comments On Address ' +  (  address.comments ? ': '+ address.comments : '');
      return str;
    }

    renderOrderPreview(){
      return (
        <div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Order Date</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.order_date}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Client Reference</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.client_reference}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Legal Name</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.company_name}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Trade Name</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.trade_name}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Address</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold"><pre>{this.readAddress(this.state.orderInfo?.address)}</pre></label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Phone</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.phone}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Email</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.email}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Website</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.website}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Registration Number</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.registration_number}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Inquiring Party Name</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.inquiring_party_name}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Comments</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.comments}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Delay</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">{this.state.orderInfo?.delay.name}</label>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Required Credit</label>
            </div>
            <div className="col-sm-4">
                <label className="form-field-bold">
                  {this.state.orderInfo?.required_credit ? this.state.orderInfo?.required_credit.value + ' ' + this.state.orderInfo?.required_credit.currency.code : 'MAXIMUM'}
                </label>
            </div>
          </div>
        </div>
      )
    }

    render() {
      let sesToken = JSON.parse(sessionStorage.getItem("token"));
      if(this.state.report && this.state.orderInfo){
        return (

           <div id="inquiry_details_info">
                <Toast ref={this.toast} />
               <div className="col-12" style={{'position': 'sticky', 'top': '50px', 'zIndex': '10'}}>
                   <div className="row" >
                        <div className="col-sm-7" >
                        <Dialog header={this.state.msg_header} visible={this.state.display} onHide={() => this.onHide() } breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '30vw'}}>
                         { <div dangerouslySetInnerHTML={{ __html: this.state.notif_msg }} /> }
                        </Dialog>
                        <Dialog header = "Import From Report" visible = {this.state.displayReportImport} onHide={() => {this.setState({displayReportImport: false})}}>
                            {this.renderImportFromReport()}
                        </Dialog>
                        <Dialog header = "Import From Company" visible = {this.state.displayReportImportFromCompany} onHide={() => {this.setState({displayReportImportFromCompany: false})}}>
                            {this.renderImportFromCompany()}
                        </Dialog>
                        <Dialog header = "Import From Experian" visible = {this.state.displayReportImportFromExperian} onHide={() => {this.setState({displayReportImportFromExperian: false})}}>
                            {this.renderImportFromExperian()}
                        </Dialog>

                          <div className={"alert alert-"+this.state.alert_class+ " " + this.state.notif_class} role="alert">{ <div dangerouslySetInnerHTML={{ __html: this.state.notif_msg }} /> }</div>
                        </div>
                        <div className="col-sm-5" >
                          {this.renderActionButtons()}

                        </div>
                    </div>
               </div>
               <div className="spacer"/>
               {this.renderTabButtons()}
               <Dialog visible={this.state.preview} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} onHide={() => this.onHidePreview() }>
                 <InquiriesPreview report={this.state.report} orderInfo ={this.state.orderInfo} />
               </Dialog>
               <Dialog visible={this.state.previewOrder} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} onHide={() => this.onHidePreviewOrder() }>
                  {this.renderOrderPreview()}
               </Dialog>
               <Sidebar visible={this.state.admin_note_sidebar} position="right" dismissable={false} modal={false} onHide={() => {this.setState({admin_note_sidebar:false})}} style={{overflowY: 'scroll', maxHeight: '100%'}}>
                 <InquiriesDetailsAdminNotesSideBar readOnly={this.state.readOnly} activeTab={this.activeTabIndex.bind(this)}
                   user_group = {sesToken.user.group} user_name = {sesToken.user.username}  orderInfo ={this.state.orderInfo}  />
               </Sidebar>

           </div>

        )
      }else{
        return(<div></div>)
      }
    }
    }

export default InquiriesDetails
