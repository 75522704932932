import React, { Component } from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import InquiryService from '../service/InquiryService';
import {formatDate} from '../components/Utils';

class StatisticsOverview extends Component {
  constructor(props){
    super(props);
    this.state = {
      overview_by_staffs : {},
      overview_by_clients : {},
      staffs : [],
      clients: [],
    };
    this.inquiryService = new InquiryService();

    

  }
  componentDidMount(){
    this.props.setLoading(true)
    this.inquiryService.getOverviewStatistics().then(data =>{
      this.setState({overview_by_staffs : data.by_staffs});
      this.setState({overview_by_clients : data.by_clients});
      this.props.setLoading(false)
    });
  }
  getStats(statValue){
    if (!statValue){
      return 0
    }
    return statValue
  }

  getTotalNEW(stats){
    return this.getStats(stats.lateNEW) + this.getStats(stats.duetodayNEW) + this.getStats(stats.dueD1NEW) + this.getStats(stats.dueD2NEW) + this.getStats(stats.dueD3NEW) + this.getStats(stats.dueD4NEW)
            + this.getStats(stats.dueD5NEW) + this.getStats(stats.dueD6NEW)
  }

  getTotalINP(stats){
    return this.getStats(stats.lateINP) + this.getStats(stats.duetodayINP) + this.getStats(stats.dueD1INP) + this.getStats(stats.dueD2INP) + this.getStats(stats.dueD3INP) + this.getStats(stats.dueD4INP)
            + this.getStats(stats.dueD5INP) + this.getStats(stats.dueD6INP)
  }

  getTotals(staff){
    let totals = {}
    totals["totalNEW"] = 0
    totals["totalINP"] = 0
    totals["totalLateNEW"] = 0
    totals["totalLateINP"] = 0
    totals["totalDueTodayNEW"] = 0
    totals["totalDueTodayINP"] = 0
    totals["totalDueD1NEW"] = 0
    totals["totalDueD1INP"] = 0
    totals["totalDueD2NEW"] = 0
    totals["totalDueD2INP"] = 0
    totals["totalDueD3NEW"] = 0
    totals["totalDueD3INP"] = 0
    totals["totalDueD4NEW"] = 0
    totals["totalDueD4INP"] = 0
    totals["totalDueD5NEW"] = 0
    totals["totalDueD5INP"] = 0
    totals["totalDueD6NEW"] = 0
    totals["totalDueD6INP"] = 0
    if (staff) {
      Object.keys(this.state.overview_by_staffs).map(key => {
        let stats = this.state.overview_by_staffs[key]
        totals["totalNEW"] += this.getTotalNEW(stats)
        totals["totalINP"] += this.getTotalINP(stats)
        totals["totalLateNEW"] += this.getStats(stats.lateNEW)
        totals["totalLateINP"] += this.getStats(stats.lateINP)
        totals["totalDueTodayNEW"] += this.getStats(stats.duetodayNEW)
        totals["totalDueTodayINP"] += this.getStats(stats.duetodayINP)
        totals["totalDueD1NEW"] += this.getStats(stats.dueD1NEW)
        totals["totalDueD1INP"] += this.getStats(stats.dueD1INP)
        totals["totalDueD2NEW"] += this.getStats(stats.dueD2NEW)
        totals["totalDueD2INP"] += this.getStats(stats.dueD2INP)
        totals["totalDueD3NEW"] += this.getStats(stats.dueD3NEW)
        totals["totalDueD3INP"] += this.getStats(stats.dueD3INP)
        totals["totalDueD4NEW"] += this.getStats(stats.dueD4NEW)
        totals["totalDueD4INP"] += this.getStats(stats.dueD4INP)
        totals["totalDueD5NEW"] += this.getStats(stats.dueD5NEW)
        totals["totalDueD5INP"] += this.getStats(stats.dueD5INP)
        totals["totalDueD6NEW"] += this.getStats(stats.dueD6NEW)
        totals["totalDueD6INP"] += this.getStats(stats.dueD6INP)
      })
    } else {
      Object.keys(this.state.overview_by_clients).map(key => {
        let stats = this.state.overview_by_clients[key]
        totals["totalNEW"] += this.getTotalNEW(stats)
        totals["totalINP"] += this.getTotalINP(stats)
        totals["totalLateNEW"] += this.getStats(stats.lateNEW)
        totals["totalLateINP"] += this.getStats(stats.lateINP)
        totals["totalDueTodayNEW"] += this.getStats(stats.duetodayNEW)
        totals["totalDueTodayINP"] += this.getStats(stats.duetodayINP)
        totals["totalDueD1NEW"] += this.getStats(stats.dueD1NEW)
        totals["totalDueD1INP"] += this.getStats(stats.dueD1INP)
        totals["totalDueD2NEW"] += this.getStats(stats.dueD2NEW)
        totals["totalDueD2INP"] += this.getStats(stats.dueD2INP)
        totals["totalDueD3NEW"] += this.getStats(stats.dueD3NEW)
        totals["totalDueD3INP"] += this.getStats(stats.dueD3INP)
        totals["totalDueD4NEW"] += this.getStats(stats.dueD4NEW)
        totals["totalDueD4INP"] += this.getStats(stats.dueD4INP)
        totals["totalDueD5NEW"] += this.getStats(stats.dueD5NEW)
        totals["totalDueD5INP"] += this.getStats(stats.dueD5INP)
        totals["totalDueD6NEW"] += this.getStats(stats.dueD6NEW)
        totals["totalDueD6INP"] += this.getStats(stats.dueD6INP)
      })
    }

    return totals;
  }


render(){
    let totals = this.getTotals(true);
    let client_totals = this.getTotals(false);
    return (
      <div>
        <div className="condiv">
          <div className = "row">
           
            <div className="col-12">
                <TabView>
                      <TabPanel header="By Staff">
                      <table id="statTable" width="100%" border="1" align="center" cellSpacing="1" cellPadding="2">
                                <tbody>
                                <tr>
                                    <th colSpan="1"></th>
                                    <th colSpan="2" align="center">Late reports</th>
                                    <th colSpan="2" align="center">Due Date Today(d)</th>
                                    <th colSpan="2" align="center">Due date d+1</th>
                                    <th colSpan="2" align="center">Due date d+2</th>
                                    <th colSpan="2" align="center">Due date d+3</th>
                                    <th colSpan="2" align="center">Due date d+4</th>
                                    <th colSpan="2" align="center">Due date d+5</th>
                                    <th colSpan="2" align="center">Due date d+6</th>
                                    <th colSpan="2" align="center">Total</th>

                                </tr>

                                <tr>
                                    <th colSpan="1" width="15%"></th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                </tr>
                                {Object.keys(this.state.overview_by_staffs).map((key,i) => {
                                  let stats = this.state.overview_by_staffs[key]
                                  return (

                                    <tr className={(i%2 == 0)? 'odd' : 'even'} key = {key}>
                                        <td style={{'borderBottom':'1px solid #ddd','padding':'5px'}}>{key}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.lateNEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.lateINP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.duetodayNEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.duetodayINP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD1NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD1INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD2NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD2INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD3NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD3INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD4NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD4INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD5NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD5INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD6NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD6INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getTotalNEW(stats)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getTotalINP(stats)}</td>
                                    </tr>
                                )})
                                }
                                <tr className="even bold">
                                    <td style={{'borderBottom':'1px solid #ddd','padding':'5px'}}>Total</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalLateNEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalLateINP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueTodayNEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueTodayINP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD1NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD1INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD2NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD2INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD3NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD3INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD4NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD4INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD5NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD5INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD6NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalDueD6INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalNEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{totals["totalINP"]}</td>
                                </tr>
                                </tbody>
                            </table>
                      </TabPanel>
                      <TabPanel header="By Client">

                            <table id="statTable" width="100%" border="1" align="center" cellspacing="1" cellpadding="2">
                                <tr>
                                    <th colSpan="1"></th>
                                    <th colSpan="2" align="center">Late reports</th>
                                    <th colSpan="2" align="center">Due Date Today(d)</th>
                                    <th colSpan="2" align="center">Due date d+1</th>
                                    <th colspan="2" align="center">Due date d+2</th>
                                    <th colSpan="2" align="center">Due date d+3</th>
                                    <th colSpan="2" align="center">Due date d+4</th>
                                    <th colSpan="2" align="center">Due date d+5</th>
                                    <th colSpan="2" align="center">Due date d+6</th>
                                    <th colSpan="2" align="center">Total</th>

                                </tr>

                                <tr>
                                    <th colSpan="1" width="15%"></th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                    <th width="5%" align="center">New</th>
                                    <th width="5%" align="center">In progress</th>
                                </tr>
                                {Object.keys(this.state.overview_by_clients).map((key,i) => {
                                  let stats = this.state.overview_by_clients[key]
                                  return (
                                    <tr className={(i%2 == 0)? 'odd' : 'even'} key={key}>
                                        <td style={{'borderBottom':'1px solid #ddd','padding':'5px'}}>{key}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.lateNEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.lateINP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.duetodayNEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.duetodayINP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD1NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD1INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD2NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD2INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD3NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD3INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD4NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD4INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD5NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD5INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD6NEW)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getStats(stats.dueD6INP)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getTotalNEW(stats)}</td>
                                        <td align='center' style={{'borderBottom':'1px solid #ddd'}}>{this.getTotalINP(stats)}</td>
                                    </tr>
                                )})
                                }
                                <tr className="even bold">
                                    <td style={{'borderBottom':'1px solid #ddd','padding':'5px'}}>Total</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalLateNEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalLateINP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueTodayNEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueTodayINP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD1NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD1INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD2NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD2INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD3NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD3INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD4NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD4INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD5NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD5INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD6NEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalDueD6INP"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalNEW"]}</td>
                                    <td bgcolor="#ddd" align='center' style={{'borderBottom':'1px solid #ddd'}}>{client_totals["totalINP"]}</td>
                                </tr>
                        </table>
                      </TabPanel>
                </TabView>
            </div>
          </div>
        </div>
      </div>
   )
  }
}

export default StatisticsOverview
