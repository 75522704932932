
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const formatDate = (date) => {
     let d = new Date(date);
     let month = d.getMonth() + 1;
     let day = d.getDate();

     if (month < 10) {
         month = '0' + month;
     }

     if (day < 10) {
         day = '0' + day;
     }
    return d.getFullYear() + '-' + month + '-' + day;

     
 };
 export const formatDateDisplay = (date) => {
      let d = date.split("T");
      let new_date = d[0].toString().split("-");
      return new_date[1] +"/"+new_date[2] + "/" + new_date[0]
 };
 
 export function uuidv4() {
   return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
   );
 }

 export function translateOrderStatusToClientStatus(orderStatus){
   switch(orderStatus){
     case 'PENDING':
      return 'Pending';
    case 'NEW':
     return 'In Production';
     case 'INPROGRESS':
      return 'In Production';
    case 'EMAIL SENT':
      return 'In Production';
    case 'KAR':
     return 'In Production';
    case 'COMPLETED':
      return 'Completed';
    case 'COMPLETED_NEGATIVE':
      return 'Completed';
    case 'TO REVIEW':
      return 'In Production';
    case 'REVIEWED':
      return 'In Production';
    case 'TO CORRECT':
      return 'In Production';
    case 'REOPENED':
      return 'Reopened';
    case 'CANCELED':
      return 'Canceled';
    case 'WAITING_ON_CLIENT':
      return 'In Production';
    default:
      return 'N.A.';
   }
 }
export const CalendarFormatDate = (date) => {
    let d = new Date(date);
     let month = d.getMonth() + 1;
     let day = d.getDate();

     if (month < 10) {
         month = '0' + month;
     }

     if (day < 10) {
         day = '0' + day;
     }
     return day + "/"+month+"/"+d.getFullYear();
 };
 export const getWeekRange = (d) => {
    d = new Date(d); d.setHours(0);	d.setMinutes(0); d.setSeconds(0);

    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday

    var monday = formatDate(new Date(d.setDate(diff)))
    var friday = formatDate(new Date(d.setDate(d.getDate() - d.getDay() + 5)));
    return ({'monday' : monday,'friday' : friday});
  }
  export const diffDate = (date1,date2) => {
    const diffTime = Math.abs(new Date(formatDate(date1)) - new Date(formatDate(date2)));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

export const formatToCurrency = (number) => {
  number = (number > 0) ? number : 0;
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
 }
 function amountBodyTemplate(rowData){
    return rowData;
 }
 export const dataTable = (dataName, columns,records) =>{
    return (
      <div className="col-12" id="dataTable">
          <div><h6>{dataName}</h6></div>
          <DataTable value={records} tableStyle={{ minWidth: '40rem', textAlign: 'right'}}
           paginator rows={5}
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
           rowsPerPageOptions={[5,10,25,50]}
           >
              {columns.map((col, i) => (
                  (col.header == "Balance")? <Column key={col.field} field={col.field} header={col.header} body={amountBodyTemplate}></Column>
                  : <Column key={col.field} field={col.field} header={col.header} />
              ))}
          </DataTable>
      </div>
  );
  }

  