import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';
import CompanyService from '../service/CompanyService';
import MainHeader from '../components/MainHeader'
import { TabView,TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import {uuidv4} from '../components/Utils';
import OrderDetails from './OrderDetails.js';

class Clients extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeIndex: 0,
      selectedField: null,
      selectedCountry: null,
      mainTab: null,
      selectedValue: null,
      countries:[],
      companies: [],
      notif_class : "hidden",
      toast: this.props.toast,
      searchLoading: false,
      tabs: [],
      btnSearchDisable : false

    }

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.cancelSearch = this.cancelSearch.bind(this);
    this.companyNameBodyTemplate = this.companyNameBodyTemplate.bind(this);
    this.companyLegalAddressBodyTemplate = this.companyLegalAddressBodyTemplate.bind(this);
    this.companyTelephonesBodyTemplate = this.companyTelephonesBodyTemplate.bind(this);
    this.companyEmailsBodyTemplate = this.companyEmailsBodyTemplate.bind(this);
    this.companyCountryBodyTemplate = this.companyCountryBodyTemplate.bind(this);
    this.companyLastUpdateDateBody = this.companyLastUpdateDateBody.bind(this);
    this.exportBodyTemplate = this.exportBodyTemplate.bind(this);
    this.quickOrderBodyTemplate = this.quickOrderBodyTemplate.bind(this);
    this.handleXmlExport = this.handleXmlExport.bind(this);
    this.handleDocExport = this.handleDocExport.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.handleCloseTab = this.handleCloseTab.bind(this);
    this.companyHeadquartersBodyTemplate = this.companyHeadquartersBodyTemplate.bind(this);
    this.financialInformationBodyTemplate = this.financialInformationBodyTemplate.bind(this);
    this.inquiryService = new InquiryService();
    this.companyService = new CompanyService();

    this.inquiryService.getCountries().then(data =>{
      this.setState({countries:data})
    })
    const tab_init =  {
        id : 0,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Company Search
                </React.Fragment>,
        content: this.renderTabContent()
    };
    this.state.mainTab = tab_init
    //this.setState({mainTab : tab_init});
  }
  cancelSearch(){
    this.setState({btnSearchDisable : false});
  }
  submitSearch(){
    this.setState({searchLoading: true})
    
    let body = {}
    if(!this.state.selectedField){
      this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "No Field Selected !"});
      return;
    }
    if(!this.state.selectedValue){
      this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "No Value Selected !"});
      return;
    }
    body["field"] = this.state.selectedField
    body["value"] = this.state.selectedValue
    if (this.state.selectedCountry){
      body["country"] = this.state.selectedCountry
    }
    this.setState({btnSearchDisable : true});
    this.companyService.searchForCompany(body).then(data =>{
     
      let sorted = data.sort((a, b) =>  new Date(b.last_update_date) - new Date(a.last_update_date));
    //let filtered =  [...new Map(sorted.map(item => [item['legal_name'], item])).values()];


      var unique = [];
      var distinct = [];
      for( let i = 0; i < sorted.length; i++ ){
        if( !unique[sorted[i].legal_name]){
          distinct.push(sorted[i]);
          unique[sorted[i].legal_name] = 1;
        }
      }     
      this.setState({btnSearchDisable : false});
      this.setState({companies: distinct , searchLoading: false})
      
    }).catch(err =>{
      this.state.toast.current.show({severity:'error', summary:'Cancelled !', detail: JSON.stringify(err.response.data)});
      this.setState({searchLoading: false})
    })
  }


  componentDidMount(){
    const tab_init =  {
        id : 0,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Companies Search
                </React.Fragment>,
        content: this.renderTabContent()
    };
    const tabs_arr = this.state.tabs;
    tabs_arr.push(tab_init);
    this.setState({tabs : tabs_arr});
  }

  handleXmlExport(rowData){
    this.companyService.getCompanyXMLReport(rowData.id).then(data => {
      this.state.toast.current.show({severity:'success', summary:'Download Ready !', detail: "Download is Ready"});
      this.setState({downloadData: data, notif_class: ""})
    }).catch(err => {
      this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: JSON.stringify(err.response.data)});
    })
  }

  handleDocExport(rowData){
    this.companyService.getCompanyWORDReport(rowData.id).then(data => {
      this.state.toast.current.show({severity:'success', summary:'Download Ready !', detail: "Download is Ready"});
      this.setState({downloadData: data, notif_class: ""})
    }).catch(err => {
      this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: JSON.stringify(err.response.data)});
    })
  }

  exportBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>
            <a href="#"><i onClick = {() => this.handleXmlExport(rowData)} className="pi pi-file-excel" style={{'fontSize': '2em'}}></i></a>
            <a href="#"><i onClick = {() => this.handleDocExport(rowData)} className="pi pi-file-o" style={{'fontSize': '2em'}}></i></a>
            </span>
        </React.Fragment>
    );
  }

  quickOrderBodyTemplate(rowData){
    return (
      <React.Fragment>
          <span >
            <Button icon="pi pi-angle-double-right" label = "Order Report"  onClick={() => this.addQuickOrderReport(rowData)} />
          </span>
      </React.Fragment>
    )
  }

  handleCloseTab(tab_id){
      const tab_toClose = tab_id.split("_")
      const id = tab_toClose[1];
      const tab_items = this.state.tabs;
      tab_items.map((tab,i) => {
          if(tab.id == id){
              tab_items.splice(i,1);
          }
      });
     this.setState({tabs:tab_items});
     this.setState({activeTabIndex : (tab_items.length - 1) });

  }

  addQuickOrderReport(data){
    let tabs = this.state.tabs.slice();
    const closeTab = this.handleCloseTab;
    let count = this.state.tabs.length;
    let new_order_id = uuidv4();
    function handleClose(e) {
        e.preventDefault();
        sessionStorage.removeItem('ses_new_order_' + new_order_id + '_selected_customer');
        closeTab(e.currentTarget.id);
    }

    let orderData = {}
    orderData.reference = "New Order"
    orderData.address = data.legal_address
    if (data.legal_address) {
      orderData.country = data.legal_address.country
    }
    orderData.company_name = data.legal_name
    orderData.trade_name = data.trade_names
    orderData.registration_number = data.registration_id
    orderData.cristal_number = data.cristal_number
    orderData.update = true
    let contactInfo = data.contact_info
    if(contactInfo){
      if (contactInfo.telephones.length > 0){
        orderData.phone = contactInfo.telephones.join(",")
      }
      orderData.email = contactInfo.emails
      orderData.website = contactInfo.websites
    }
    let d = new Date();
    let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
    orderData.order_date = date;
    tabs.push({
        id : count,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {orderData.reference} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                </React.Fragment>,
       content: this.renderOrdertDetails(orderData, new_order_id)
    })

    this.setState({tabs: tabs, activeTabIndex: count})

  }

  renderOrdertDetails(orderInfo, newOrderTabCount){
    return(
        <div><OrderDetails new_order_tab_count={newOrderTabCount} toast = {this.state.toast} orderInfo={orderInfo} is_staff ={this.state.isStaff} customers = {this.state.clients} /></div>
    )
  }

  companyNameBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a>{rowData.legal_name}</a></span>
        </React.Fragment>
    );
  }

  companyLegalAddressBodyTemplate(rowData){    
    let legal_address = rowData.registration_info.registrations[0].address//rowData.legal_address
    if (legal_address){
      if (legal_address.full_address && legal_address.full_address != ""){
        return (
          <React.Fragment>
            {legal_address.full_address}<br/>
            {legal_address.country?.name}<br />
            {legal_address.comments}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            {legal_address.street}<br/>
            {legal_address.city},  {legal_address.state} {legal_address.postal_code}<br/>
            {legal_address.country?.name}<br />
            {legal_address.comments}
          </React.Fragment>
        )

      }
    } else {
      return (
        <React.Fragment>
        </React.Fragment>
      )
    }
  }

  financialInformationBodyTemplate(rowData){
    return (
      <React.Fragment>
        {rowData.financial_information}
      </React.Fragment>
    )
  }

  companyHeadquartersBodyTemplate(rowData){
    let headquarters = rowData.operative_address
    if (headquarters){
      if (headquarters.full_address && headquarters.full_address != ""){
        return (
          <React.Fragment>
            {headquarters.full_address}<br/>
            {headquarters.country?.name}<br />
            {headquarters.comments}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            {headquarters.street}<br/>
            {headquarters.city},  {headquarters.state} {headquarters.postal_code}<br/>
            {headquarters.country?.name}<br />
            {headquarters.comments}
          </React.Fragment>
        )

      }
    } else {
      return (
        <React.Fragment>
        </React.Fragment>
      )
    }
  }

  companyTelephonesBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.contact_info?.telephones}</span>
        </React.Fragment>
    );
  }

  companyEmailsBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.contact_info?.emails}</span>
        </React.Fragment>
    );
  }

  companyCountryBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.legal_address.country.name}</span>
        </React.Fragment>
    );
  }

  companyLastUpdateDateBody(rowData){
    if (rowData.last_update_date){
      let date = rowData.last_update_date.split("-")
      return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{date[0] + " / " + date[1]}</span>
        </React.Fragment>
      )

    } else {
      return (
        <React.Fragment>
        </React.Fragment>
      )
    }

  }

  handleFieldChange(e){
    let selectedField = this.state.selectedField
    selectedField = e.target.value
    this.setState({selectedField: selectedField})
  }

  handleCountryChange(e){
    let selectedCountry = this.state.selectedCountry
    selectedCountry = e.value
    this.setState({selectedCountry: selectedCountry})
  }

  handleValueChange(e){
    let selectedValue = this.state.selectedValue
    selectedValue = e.target.value
    this.setState({selectedValue: selectedValue})
  }

  renderResults(){
    return (
      <div className="col-12" id="dataTable">
        <DataTable ref={(el) => this.dt = el}
            value={this.state.companies} loading = {this.state.searchLoading}
            className="p-datatable-striped"
            dataKey="id"
            rowHover
            //  selection={this.state.selectedName}
            // onSelectionChange={e => this.setState({selectedName: e.value})}
            paginator rows={10}
            emptyMessage="No Companies found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10,25,50]}>
            <Column sortField="legal_name" filterField="legal_name" field="legal_name" header="Company" body={this.companyNameBodyTemplate} sortable filter filterPlaceholder="Legal Name"/>
            <Column header="Legal Address" body={this.companyLegalAddressBodyTemplate} />
            <Column header="Headquarters" body={this.companyHeadquartersBodyTemplate} />
            <Column header="Telephones" body={this.companyTelephonesBodyTemplate}  />
            <Column sortField="legal_address.country.name" filterField="legal_address.country.name" field="legal_address.country.name" header="Country" body={this.companyCountryBodyTemplate} sortable filter filterMatchMode="contains"/>
            <Column header="Financial Information" body = {this.financialInformationBodyTemplate}  />
            <Column header="Last Update" body = {this.companyLastUpdateDateBody}  />
            <Column header="Available Downloads" body={this.exportBodyTemplate} />
            <Column header="Quick Order Report" body={this.quickOrderBodyTemplate} />

        </DataTable>
      </div>
    )
  }

  renderDownload(){
    return (
        <div className={"col-sm-8 alert alert-success " + this.state.notif_class} role="alert">
          { <div dangerouslySetInnerHTML={{ __html: this.state.downloadData }} /> }
        </div>
    )
  }

  renderTabContent(){
    const fields = [
      {label: 'Company Name', value: 'legal_name'},
      {label: 'Registration ID', value: 'registration_id'}
    ];
    return (
      <div>
        <div className = "col-12">
          {this.renderDownload()}
          <div className="form-group">
            <div className = "row">
                <div className = "col-3">
                  <div className = "row"  style={{margin:"5px"}}>
                    <div className="col-sm-3">
                      <label className="form-field-bold">Field </label>
                    </div>
                    <div className="col-sm-9">
                      <Dropdown value={this.state.selectedField} options={fields} onChange={this.handleFieldChange} optionLabel="label" placeholder="Select a Field"/>
                    </div>
                  </div>
                  <div className = "row" style={{margin:"5px"}}>
                    <div className="col-sm-3">
                      <label className="form-field-bold">Value </label>
                    </div>
                    <div className="col-sm-9">
                      <input type = "text" value={this.state.selectedValue} onChange={this.handleValueChange} className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className = "row" style={{margin:"5px"}}>
                    <div className="col-sm-3">
                      <label className="form-field-bold">Country </label>
                    </div>
                    <div className="col-sm-6">
                      <Dropdown value = {this.state.selectedCountry} options={this.state.countries} onChange={this.handleCountryChange} optionLabel="name" placeholder="Select a Country" filter/>
                    </div>
                  </div>
                  <div className = "row" style={{margin:"5px"}}>
                    <div className="col-sm-12">
                    <div className = "row">
                      <div className='col-sm-3'>&nbsp;</div>
                      <div className='col-sm-3'>
                        <Button icon="pi pi-search" label = "Search"  onClick={this.submitSearch} disabled={this.state.btnSearchDisable}/>
                      </div>
                      <div className='col-sm-1'>&nbsp;</div>
                      <div className='col-sm-3'>
                       <Button icon="pi pi-times-circle" label = "Cancel" hidden={!this.state.btnSearchDisable}  onClick={this.cancelSearch} />
                      </div>
                      </div>  
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className = "row">
          {this.renderResults()}
        </div>
      </div>
    )
  }

  onTabChange(e){
    let tab_index = e.index;
    const tab_items = this.state.tabs;
    if(tab_items.length == 1){
        tab_index = 0;
    }
    return this.setState({ activeTabIndex: tab_index});
  }

  render() {
      if (this.state.mainTab){
        return (
            <div className="condiv">
               <MainHeader/>
                  <Toast ref={this.state.toast} />
                 <br></br>
                  <div className="col-12">
                      <TabView activeIndex={this.state.activeTabIndex}  onTabChange={this.onTabChange}>
                          {
                              this.state.tabs.map((tab,i) => (
                                  i == 0 ? <TabPanel key="0" header="Company Search">{this.renderTabContent()}</TabPanel>:
                                  <TabPanel key={i} header={tab.title} headerStyle={tab.headerStyle}>{tab.content}</TabPanel>
                              ))
                          }
                      </TabView>
                    </div>



            </div>
            )
          } else{
            return <div></div>
          }

      }

}

export default Clients;
