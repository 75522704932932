import React, { Component } from 'react';
import CompanyService from '../service/CompanyService';
import InquiryService from '../service/InquiryService';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
class AdminClients extends Component {

  constructor(props){
    super(props);
    this.state = {
      toast: this.props.toast,
      clients: [],
      loading: false,
      selectedCountries: [],
      countries: [],
      companyNameSearch: '',
      lazyParams: {
            first: 0,
            rows: 50,
            page: 1
      }
    }
    this.companyService = new CompanyService();
    this.inquiryService = new InquiryService();
    this.companyNameBodyTemplate = this.companyNameBodyTemplate.bind(this);
    this.companyLastUpdateDateBody = this.companyLastUpdateDateBody.bind(this);
    this.loadCompaniesLazy = this.loadCompaniesLazy.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.inquiryService.getCountries().then(data =>{
      this.setState({
        countries: data
      })
    })
    //this.loadCompaniesLazy();
  }

  loadCompaniesLazy(){
    if (this.state.companyNameSearch.trim() == "" && this.state.selectedCountries.length == 0){
      this.state.toast.current.show({severity:'error', summary:'Aborted !', detail: "Please Select at least one country or set a company name in the search field"});
      return
    }
    this.setState({loading: true})
    let searchBody = {search: {countries: this.state.selectedCountries, company_name: this.state.companyNameSearch}, lazy: this.state.lazyParams }
    this.companyService.getLazyCompanies(searchBody).then(data => {
      this.state.toast.current.show({severity:'success', summary:'Companies Search !', detail: "Found " + data.companies.length + " Companies."});
      let companies = data.companies;
      /*let sorted = companies.sort((a, b) =>  new Date(b.last_update_date) - new Date(a.last_update_date));
      let unique = [];
      var distinct = [];

      for( let i = 0; i < sorted.length; i++ ){
        if( !unique[sorted[i].legal_name]){
          distinct.push(sorted[i]);
          unique[sorted[i].legal_name] = 1;
        }
      }*/
      this.setState({clients: companies, totalRecords: data.total_records, loading: false});
    })
  }

  onPage(event) {
    this.setState({loading: true});
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams },function(){
      this.loadCompaniesLazy();
    });
  }

  onSort(event) {
    this.setState({loading: true});
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams },function(){
      this.loadCompaniesLazy();
    });
  }

  handleAddNewCompany(event){
    let companyData = {}
    companyData["legal_name"] = "new company"
    companyData["type"] = "CLNT"
    companyData["legal_address"] = {}
    companyData["legal_address"]["country"] = {}
    companyData["legal_address"]["country"]["name"] = "United States"
    this.props.addCompanyTab(companyData);
  }

  handleCompanyClick(event, rowData){
    this.props.addCompanyTab(rowData);
  }

  companyNameBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a href="#" onClick={(e) => this.handleCompanyClick(e, rowData)}>{rowData.legal_name}</a></span>
        </React.Fragment>
    );
  }

  companyAddressBodyTemplate(rowData){
    return (
        <React.Fragment>

          {rowData.legal_address.street}<br/>
          {rowData.legal_address.city},  {rowData.legal_address.state} {rowData.legal_address.postal_code}<br/>
          {rowData.legal_address.comments}
        </React.Fragment>
    );
  }

  companyEmailsBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.contact_info?.emails}</span>
        </React.Fragment>
    );
  }

  companyCountryBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.legal_address.country.name}</span>
        </React.Fragment>
    );
  }
  companyTelephonesBodyTemplate(rowData){
    return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.contact_info?.telephones}</span>
        </React.Fragment>
    );
  }
  companyLastUpdateDateBody(rowData){
    if (rowData.last_update_date){
      let date = rowData.last_update_date.split("-")
      return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{date[0] + " / " + date[1]}</span>
        </React.Fragment>
      )

    } else {
      return (
        <React.Fragment>
        </React.Fragment>
      )
    }

  }
  renderSearch(){
    return(
      <div className="form-group">
        <div className = "row">
          <div className="col-sm-2">
            <label className="form-field-bold">Company Name </label>
          </div>
          <div className="col-sm-2">
            <input type = "text" value={this.state.companyNameSearch} onChange={(e) => this.setState({companyNameSearch: e.target.value})}
                  className="form-control form-control-sm" />
          </div>
          <div className="col-sm-2">
            <label className="form-field-bold">Countries </label>
          </div>
          <div className="col-sm-2">
            <MultiSelect value={this.state.selectedCountries} options={this.state.countries} optionLabel="name"
                  placeholder="Select Counties" filter="true" onChange = {(e) => this.setState({selectedCountries: e.target.value})} />
          </div>
          <div className="col-sm-2">
            <Button onClick={this.loadCompaniesLazy} label="Submit Search" className="p-button-sm" icon="pi pi-search"/>
          </div>
          <div className="col-sm-2">
            <Button onClick={(e) => this.handleAddNewCompany(e)} label="New Company" className="p-button-sm" icon="pi pi-plus"/>
          </div>
        </div>
      </div>
    )
  }

  render(){
    return (
      <div>
        <div className="condiv">
          <Toast ref={this.state.toast} />
          <br />
          <div className="col-sm-9" style={{'margin': '5px'}}>
            {this.renderSearch()}
          </div>
          <br />
          <div className="col-12" id="dataTable">
            <DataTable lazy ref={(el) => this.dt = el}
                value={this.state.clients} loading = {this.state.loading}
                totalRecords={this.state.totalRecords} onPage={this.onPage}
                first={this.state.lazyParams.first} onSort={this.onSort}
                sortField={this.state.lazyParams.sortField} sortOrder={this.state.lazyParams.sortOrder}
                className="p-datatable-striped"
                dataKey="id"
                rowHover
                paginator rows={this.state.lazyParams.rows}
                emptyMessage="No Companies found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10,25,50]}>
                <Column sortField="legal_name" filterField="legal_name" field="legal_name" header="Company" body={this.companyNameBodyTemplate} sortable/>
                <Column sortField="status" filterField="status" field="status" header="Address" body={this.companyAddressBodyTemplate} sortable/>
                <Column sortField="company_name" filterField="company_name" field="Telephones" header="Telephones" body={this.companyTelephonesBodyTemplate} sortable />
                <Column sortField="company_name" filterField="company_name" field="Email" header="Email" body={this.companyEmailsBodyTemplate} sortable />
                <Column sortField="company_name" filterField="company_name" field="Country" header="Country" body={this.companyCountryBodyTemplate} sortable />
                <Column header="Last Update" body = {this.companyLastUpdateDateBody}  />
            </DataTable>
          </div>
        </div>
      </div>
    )
  }

}

export default AdminClients;
