import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {formatDate} from '../components/Utils';
import { Calendar } from 'primereact/calendar';
import InquiryService from '../service/InquiryService';
import { Translation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Moment from 'moment';
class Logs extends Component {
  constructor(props){
    super(props);
    this.state = {
      logs: [],
      dateFilter: null,
      loading: false,
      search:{},
      toast: this.props.toast
    };
    this.inquiryService = new InquiryService();
    this.onDateFilterChange = this.onDateFilterChange.bind(this);
    this.filterLogDate = this.filterLogDate.bind(this);
    this.exportCSV = this.exportCSV.bind(this);

  }
  componentDidMount(){
    /*this.inquiryService.getLogs().then(data =>{
      this.setState({
        logs: data, loading: false
      })
    });*/
  }
  exportCSV(selectionOnly){
    this.dt.exportCSV({ selectionOnly });
  }

  onDateFilterChange(event) {
    if (event.value !== null)
        this.dt.filter(formatDate(event.value), 'log_date', 'equals');
    else
        this.dt.filter(null, 'log_date', 'equals');

    this.setState({dateFilter: formatDate(event.value)});
}
  logDateBodyTemplate(rowData) {
    Moment.locale('en');
    return (
        <React.Fragment>
          {Moment(rowData.log_date).format('DD MMM yyyy HH:mm:ss Z')}
        </React.Fragment>
    );
}
  logActionBodyTemplate(rowData){
    return (
        <React.Fragment>
           {rowData.log_action}
        </React.Fragment>
    );
  }
  logDetailsBodyTemplate(rowData){
    return (
        <React.Fragment>
           {rowData.log_details}
        </React.Fragment>
    );
  }
  logIPBodyTemplate(rowData){
    return (
        <React.Fragment>
           {rowData.ip_address}
        </React.Fragment>
    );
  }
  logUserBodyTemplate(rowData){
    return (
        <React.Fragment> {rowData.user_log.username}</React.Fragment>
    );
  }
  renderDateFilter() {
    return (
        <Calendar value={this.state.dateFilter} onChange={this.onDateFilterChange} placeholder="Date" dateFormat="yy-mm-dd" className="p-column-filter" />
    );
 }
 onDateFilterChange(event) {
  if (event.value !== null)
      this.dt.filter(formatDate(event.value), 'log_date', 'custom');
  else
      this.dt.filter(null, 'log_date', 'custom');

  this.setState({dateFilter: event.value});
}
filterLogDate(value, filter) {
  if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
      return true;
  }

  if (value === undefined || value === null) {
      return false;
  }
  return formatDate(value) === formatDate(filter);
}

updateSearchParams(field, event){
  let search = this.state.search
  search[field] = (field.includes("date")) ? formatDate(event.target.value) : event.target.value;
  this.setState({search: search})

}

handleSubmitSearch(){
  this.setState({loading: true})
  if (!this.state.search.username && !this.state.search.from_date && !this.state.search.from_date){
    this.state.toast.current.show({severity:'error', summary:'Aborted !', detail: "Please fill in one of the filters"});
  }
  
  this.inquiryService.searchLogs(this.state.search).then(response => {
    console.log("=>",this.state.search);
    this.setState({
      logs: response, loading: false
    })
  }).catch(err => {
    this.state.toast.current.show({severity:'error', summary:'Aborted !', detail: "Network Error while fetching the logs please try again."});
    this.setState({
      loading: false
    })
  })
}

renderSearch(){
  return (
    <div className = "row">
      <div className = "col-12">
        <div className="component-box light-blue-bg">
          <div className="row">
            <div className="col-sm box-title">
              {<Translation>{ t => <span>{t('SEARCH')} </span>}</Translation>}
            </div>
          </div>
          <div className = "row">
            <div className = "col-1">
              <label className="form-field-bold"> Username </label>
            </div>
            <div className = "col-1">
              <input type="text" onChange={(e)=>this.updateSearchParams("username", e)} name="email" value={this.state.search.username} className="form-control form-control-sm"/>
            </div>
            <div className = "col-1">
              <label className="form-field-bold"> From Date </label>
            </div>
            <div className = "col-2">
              <Calendar value={this.state.search.from_date}  onChange={(e) => this.updateSearchParams("from_date", e)} showIcon monthNavigator showButtonBar></Calendar>
            </div>
            <div className = "col-1">
              <label className="form-field-bold"> To Date </label>
            </div>
            <div className = "col-2">
              <Calendar value={this.state.search.to_date}  onChange={(e) => this.updateSearchParams("to_date", e)} showIcon monthNavigator showButtonBar></Calendar>
            </div>
            <div className = "col-1">
              <Button loading = {this.state.searchloading} loadingIcon="pi pi-spin pi-sun"
                    label={<Translation>{ t => <span>{t('SEARCH')} </span>}</Translation>}
                    icon="pi pi-search" onClick = {() => this.handleSubmitSearch()}  />
            </div>
            <div className = "col-2">
             <Button type="button" label="Export CSV" icon="pi pi-file" onClick={() => this.exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}
render(){
    const dateFilter = this.renderDateFilter();
    return (
      <div>
        <Toast ref={this.state.toast} />
        <div className="condiv">
          {this.renderSearch()}
          <div className = "row">
            <div className="col-12" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.logs}
                  loading={this.state.loading}
                  className="p-datatable-striped"
                  dataKey="id"
                  paginator rows={10}
                  emptyMessage="No Logs found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column field="user_log.username" header="User" body={this.logUserBodyTemplate}  sortable filter  />
                  <Column field="log_date" header="Date" filterFunction={this.filterLogDate} body={this.logDateBodyTemplate} sortable filter filterMatchMode="custom"  filterElement={dateFilter} />
                  <Column sortField="log_action" filterField="log_action" field="log_action" header="Action" body={this.logActionBodyTemplate} sortable filter filterPlaceholder="Search Action"/>
                  <Column sortField="log_details" filterField="log_details" field="log_details" header="Details" body={this.logDetailsBodyTemplate} sortable filter filterPlaceholder="Search Details"/>
                  <Column sortField="ip_address" filterField="ip_address" field="ip_address" header="IP Address" body={this.logIPBodyTemplate} sortable filter filterPlaceholder="Search IP Address"/>


              </DataTable>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Logs
