import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Translation } from 'react-i18next';
import Parser from 'html-react-parser';
import {dataTable} from '../components/Utils';
class InquiriesDetailsFinancialsLegalRecords extends Component{
  constructor(props){
    super(props);
    this.state = {
      legalRecords : this.props.legal_records,
      ExperianData : JSON.parse(sessionStorage.getItem('ExperianData')),
      uccFiles: [],
      uccFilePreview : false,
      uccIndicator : "",
      uccFileHTML : "",
      uccSummary : "",
      bankruptcyIndicator : "",
      bankruptcySummary: "",
      bankruptcyDetail: "",
      bankruptcyPreview : false,
      collectionsIndicator : "",
      collectionsSummary : "",
      collectionsDetail: "",
      collectionsPreview : false,
      liensPreview : false,
      lienDetail : "",
      lienIndicator  : "",
      lienSummary : "",
      defaultComment: "There may be various claims, lawsuits, and pending actions against the Company incident to the operations of its businesses. It is the opinion of management, after consultation with counsel, that the ultimate resolution of such claims, lawsuits and pending actions will not have a material adverse effect on the Company's consolidated financial position, results of operations or liquidity."
    }
    if(this.state.legalRecords.ucc_file){
      this.state.uccFiles = this.state.legalRecords.ucc_file.split(",")
    }
    this.handleLegalRecordChange = this.handleLegalRecordChange.bind(this);
    this.handleAddUccFile = this.handleAddUccFile.bind(this);
    this.handleUccFileChange = this.handleUccFileChange.bind(this);
    this.deleteUccFile = this.deleteUccFile.bind(this);
    this.cancelField = this.cancelField.bind(this);
    this.addDefaultComment = this.addDefaultComment.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.viewUCC = this.viewUCC.bind(this);
    this.onHidePreview =  this.onHidePreview.bind(this);
    this.viewBankruptcy = this.viewBankruptcy.bind(this);
  }

  dataRefresh(){
    sessionStorage.setItem('ses_legal_records_' + this.props.report_id, JSON.stringify(this.state.legalRecords));
  }

  addDefaultComment(){
    let legalRecords = this.state.legalRecords
    if (!legalRecords.comments){
      legalRecords.comments = '';
    }
    if(legalRecords.comments.includes(this.state.defaultComment)){
      return;
    }

    legalRecords.comments += "\n" + this.state.defaultComment
    this.setState({legalRecords: legalRecords}, function(){
      this.dataRefresh();
    })
  }

  dataRefresh(){
    let ucc_file = this.state.uccFiles.join(",")
    let legalRecords = this.state.legalRecords
    legalRecords.ucc_file = ucc_file
    sessionStorage.setItem('ses_legal_records_' + this.props.report_id, JSON.stringify(legalRecords));
  }

  cancelField(field){
    let legalRecords = this.state.legalRecords;
    legalRecords[field] = null;
    this.setState({legalRecords: legalRecords}, function(){
      this.dataRefresh();
    })
  }

  deleteUccFile(indx, event){
    let uccFiles = this.state.uccFiles
    uccFiles.splice(indx,  1);
    this.setState({uccFiles: uccFiles}, function(){
      this.dataRefresh();
    })
  }
  handleAddUccFile(){
    let uccFiles = this.state.uccFiles
    uccFiles.push("")
    this.setState({uccFiles: uccFiles}, function(){
      this.dataRefresh();
    })
  }
  handleUccFileChange(idx, event){
    let uccFiles = this.state.uccFiles
    uccFiles[idx] = event.target.value
    this.setState({uccFiles: uccFiles}, function(){
      this.dataRefresh();
    })
  }

  handleLegalRecordChange(field, value){
    var newRecords = this.state.legalRecords
    newRecords[field] = value
    this.setState({legalRecords: newRecords})
    this.dataRefresh();
  }
  viewUCC(event){
    this.setState({uccFilePreview: true});
    let ses_ucc = JSON.parse(sessionStorage.getItem("ExperianData_uccfillings_"+this.props.report_id));
    console.log(ses_ucc);
    
    const detail_columns = [
      {field: 'dateFiled', header: 'Date Filed'},
      {field: 'documentNumber', header: 'Document No.'},
      {field: 'filingLocation', header: 'Filing Location'},
      {field: 'legalAction', header: 'Legal Action'},
      {field: 'legalType', header: 'Legal Type'},
      {field: 'securedParty', header: 'Secured Party'}
   ];
 
   const summary_columns = [
    {field: 'date', header: 'Date'},
    {field: 'amendedAndAssignedCount', header: 'Amended And Assigned Count'},
    {field: 'continuationsCount', header: 'Continuations Count'},
    {field: 'count', header: 'Count'},
    {field: 'derogatoryCount', header: 'Derogatory Count'},
    {field: 'releasesAndTerminationsCount', header: 'Releases And Terminations Count'}
  ];

    
    let uccSummary =  dataTable("uccFilingsSummary", summary_columns, ses_ucc.uccFilingsSummary.uccFilingsTrends);
    let uccFileHTML =  dataTable("UCC Filings Details", detail_columns, ses_ucc.uccFilingsDetail);
    this.setState({uccIndicator : Parser("<div class='col-12'><h6>UCC Filing Indicator : " + ses_ucc.uccFilingIndicator + "</h6></div>")});
    this.setState({uccFileHTML : uccFileHTML});
    this.setState({uccSummary : uccSummary});

  }
  viewBankruptcy(event){
    this.setState({bankruptcyPreview: true});
    let ses_bankruptcy = JSON.parse(sessionStorage.getItem("ExperianData_bankruptcies_"+this.props.report_id));
    
    const detail_columns = [
      {field: 'dateFiled', header: 'Date Filed'},
      {field: 'legalType', header: 'Legal Type'},
      {field: 'legalAction', header: 'Legal Action'},
      {field: 'documentNumber', header: 'Document Number'},
      {field: 'filingLocation', header: 'Filing Location'},
      {field: 'owner', header: 'Owner'},
      {field: 'customerDisputeIndicator', header: 'Customer Dispute Indicator'},
      {field: 'liabilityAmount', header: 'Liability Amount'},
      {field: 'assetAmount', header: 'Asset Amount'},
      {field: 'exemptAmount', header: 'Exempt Amount'}
   ];
 
  /*  const summary_columns = [
    {field: 'bankruptcyIndicator', header: 'Bankruptcy Indicator'},
    {field: 'bankruptcyCount', header: 'Bankruptcy Count'},
  ];
 */

   // let bankruptcySummary =  dataTable("Bankruptcy Summary", summary_columns, ses_bankruptcy.bankruptcySummary);
    let bankruptcyDetail = dataTable("Bankruptcy Detail", detail_columns, ses_bankruptcy.uccFilingsDetail);
    this.setState({bankruptcyIndicator : Parser("<div class='col-12'><h6>Bankruptcy Indicator : " + ses_bankruptcy.bankruptcyIndicator + "</h6></div>")});
    this.setState({bankruptcyDetail : bankruptcyDetail});
  //  this.setState({bankruptcySummary : bankruptcySummary});

  }
  viewCollections(event){
    this.setState({collectionsPreview: true});
    let ses_collections = JSON.parse(sessionStorage.getItem("ExperianData_collections_"+this.props.report_id));
    
    const detail_columns = [
      {field: 'accountStatus', header: 'Account Status'},
      {field: 'datePlacedForCollection', header: 'Date Placed For Collection'},
      {field: 'dateClosed', header: 'Date Closed'},
      {field: 'amountPlacedForCollection', header: 'Amount Placed For Collection'},
      {field: 'amountPaid', header: 'Amount Paid'},
      //{field: 'collectionAgencyInfo', header: 'Owner' , data : true},
    ];
  
   /* const summary_columns = [
    {field: 'collectionCount', header: 'Collection Count'},
    {field: 'collectionBalance', header: 'Collection Balance'},
  ];
 */

 //   let collectionsSummary =  dataTable("Collections Summary", summary_columns, ses_collections.collectionsSummary);
    let collectionsDetail =  dataTable("Collections Detail", detail_columns, ses_collections.collectionsDetail);
    this.setState({collectionsIndicator : Parser("<div class='col-12'><h6>Collections Indicator : " + ses_collections.collectionsIndicator + "</h6></div>")});
    this.setState({collectionsDetail : collectionsDetail});
    //this.setState({collectionsSummary : collectionsSummary});

  }
  viewLiens(event){
    this.setState({liensPreview : true});
    let ses_liens = JSON.parse(sessionStorage.getItem("ExperianData_liens_"+this.props.report_id));
    
    const detail_columns = [
      {field: 'dateFiled', header: 'Date Filed'},
      {field: 'legalType', header: 'Legal Type'},
      {field: 'legalAction', header: 'Legal Action'},
      {field: 'documentNumber', header: 'Document Number'},
      {field: 'filingLocation', header: 'Filing Location'},
      {field: 'owner', header: 'Owner'},
      {field: 'liabilityAmount', header: 'Liability Amount'},
      {field: 'taxLienDescription',header : 'Tax Lien Description'}
   ];
  
  //   const summary_columns = [
  //   {field: 'lienCount', header: 'Lien Count'},
  //   {field: 'lienBalance', header: 'Lien Balance'},
  // ];


   // let lienSummary =  dataTable("Liens Summary", summary_columns, ses_liens.lienSummary);
    let lienDetail =  dataTable("Liens Detail", detail_columns, ses_liens.lienDetail);
    this.setState({lienIndicator : Parser("<div class='col-12'><h6>Liens Indicator : " + ses_liens.lienIndicator + "</h6></div>")});
    this.setState({lienDetail : lienDetail});
   // this.setState({lienSummary : lienSummary});

  }
  onHidePreview(){
    this.setState({uccFilePreview : false});
    this.setState({bankruptcyPreview : false});
    this.setState({collectionsPreview : false});
    this.setState({liensPreview : false}); 
  }
  render() {

    return (
      <div className = "col-12" style={{margin:"15px"}}>
        <div className="form-group">
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}><Translation>{ t => t('UCC_FILES') }</Translation></label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "uccFilesYes" name = "uccFilesYes" value = "yes" checked={true === this.state.legalRecords.ucc_files} onChange={e => this.handleLegalRecordChange("ucc_files", true)} />
                  <label htmlFor = "uccFilesYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES') }</Translation> </strong> </label>
                  <input type = "radio" id = "uccFilesNo" name = "uccFilesNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.ucc_files}
                  onChange={e => this.handleLegalRecordChange("ucc_files", false)} />
                  <label htmlFor = "uccFilesNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO') }</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("ucc_files")}} style={{'top': '2px'}}  />
              </div>
              {/* sessionStorage.getItem("ExperianData_uccfillings_"+this.props.report_id)
                ? <div className='col-sm-6'> 
                    <div className='row'>
                        <div className="col-sm-6">
                            <textarea row="1" value = {this.state.legalRecords.ucc_files_comments} onChange={e => this.handleLegalRecordChange("ucc_files_comments", e.target.value)} className="form-control form-control-sm"/>
                        </div>
                            <div className="col-sm-6">
                              <Button icon="pi pi-eye" label = "View Experian UCC Fillings"  onClick={(e) => this.viewUCC(e)} />
                              <Dialog  header = "UCC Fillings" visible={this.state.uccFilePreview}   onHide={() => this.onHidePreview() }  breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '60vw'}}>
                                 {this.state.uccIndicator}
                                 {this.state.uccFileHTML}
                              </Dialog>
                        </div>
                      </div>
                    </div>
                :  <div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.ucc_files_comments} onChange={e => this.handleLegalRecordChange("ucc_files_comments", e.target.value)} className="form-control form-control-sm"/>
                  </div>
    */}<div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.ucc_files_comments} onChange={e => this.handleLegalRecordChange("ucc_files_comments", e.target.value)} className="form-control form-control-sm"/>
                  </div>
             
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}><Translation>{ t => t('OFAC_LIST') }</Translation></label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "ofacListYes" name = "ofacListYes" value = "yes" checked={true === this.state.legalRecords.ofac_list} onChange={e => this.handleLegalRecordChange("ofac_list", true)} />
                  <label htmlFor = "bankruptcyYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES') }</Translation> </strong> </label>
                  <input type = "radio" id = "ofacListNo" name = "ofacListNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.ofac_list}
                  onChange={e => this.handleLegalRecordChange("ofac_list", false)} />
                  <label htmlFor = "bankruptcyNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO') }</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("ofac_list")}} style={{'top': '2px'}}  />
              </div>
              <div className="col-sm-6">
                <textarea row="1" value = {this.state.legalRecords.ofac_list_comment} onChange={e => this.handleLegalRecordChange("ofac_list_comment", e.target.value)} className="form-control form-control-sm"/>
              </div>
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}>{<Translation>{ t => <span>{t('BANKCRUPTCY')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "bankruptcyYes" name = "bankruptcyYes" value = "yes" checked={true === this.state.legalRecords.bankruptcy} onChange={e => this.handleLegalRecordChange("bankruptcy", true)} />
                  <label htmlFor = "bankruptcyYes" style={{marginLeft:"2px"}}> <strong> {<Translation>{ t => <span>{t('YES')} </span>}</Translation>} </strong> </label>
                  <input type = "radio" id = "bankruptcyNo" name = "bankruptcyNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.bankruptcy}
                  onChange={e => this.handleLegalRecordChange("bankruptcy", false)} />
                  <label htmlFor = "bankruptcyNo" style={{marginLeft:"2px"}}> <strong> {<Translation>{ t => <span>{t('NO')} </span>}</Translation>} </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("bankruptcy")}} style={{'top': '2px'}}  />
              </div>
              {/* sessionStorage.getItem("ExperianData_bankruptcies_"+this.props.report_id)
                ? <div className='col-sm-6'> 
                    <div className='row'>
                        <div className="col-sm-6">
                            <textarea row="1" value = {this.state.legalRecords.bankruptcy_comment} onChange={e => this.handleLegalRecordChange("bankruptcy_comment", e.target.value)} className="form-control form-control-sm"/>
                        </div>
                            <div className="col-sm-6">
                              <Button icon="pi pi-eye" label = "View Experian Bankruptcy"  onClick={(e) => this.viewBankruptcy(e)} />
                              <Dialog  header = "Bankruptcy" visible={this.state.bankruptcyPreview}   onHide={() => this.onHidePreview() }  breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '60vw'}}>
                                 {this.state.bankruptcyIndicator}
                                 {this.state.bankruptcyDetail}
                              </Dialog>
                        </div>
                      </div>
                    </div>
                :  <div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.bankruptcy_comment} onChange={e => this.handleLegalRecordChange("bankruptcy_comment", e.target.value)} className="form-control form-control-sm"/>
                  </div>
                */}<div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.bankruptcy_comment} onChange={e => this.handleLegalRecordChange("bankruptcy_comment", e.target.value)} className="form-control form-control-sm"/>
                  </div>
             


          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}>{<Translation>{ t => <span>{t('LAWSUIT_S')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "lawsuitYes" name = "lawsuitYes" value = "yes" checked={true === this.state.legalRecords.lawsuits} onChange={e => this.handleLegalRecordChange("lawsuits", true)}/>
                  <label htmlFor = "lawsuitYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES')}</Translation> </strong> </label>
                  <input type = "radio" id = "lawsuitNo" name = "lawsuitNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.lawsuits}
                  onChange={e => this.handleLegalRecordChange("lawsuits", false)}/>
                  <label htmlFor = "lawsuitNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO')}</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("lawsuits")}} style={{'top': '2px'}}  />
              </div>
              <div className="col-sm-6">
                <textarea row="1" value = {this.state.legalRecords.lawsuits_comment} onChange={e => this.handleLegalRecordChange("lawsuits_comment", e.target.value)} className="form-control form-control-sm"/>
              </div>
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}><Translation>{ t => t('COLLECTIONS')}</Translation></label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "collectionYes" name = "collectionYes" value = "yes" checked={true === this.state.legalRecords.collections} onChange={e => this.handleLegalRecordChange("collections", true)} />
                  <label htmlFor = "collectionYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES')}</Translation> </strong> </label>
                  <input type = "radio" id = "collectionNo" name = "collectionNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.collections}
                  onChange={e => this.handleLegalRecordChange("collections", false)} />
                  <label htmlFor = "collectionNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO')}</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("collections")}} style={{'top': '2px'}}  />
              </div>
              {/*sessionStorage.getItem("ExperianData_collections_"+this.props.report_id)
                ? <div className='col-sm-6'> 
                    <div className='row'>
                        <div className="col-sm-6">
                            <textarea row="1" value = {this.state.legalRecords.collections_comment} onChange={e => this.handleLegalRecordChange("collections_comment", e.target.value)} className="form-control form-control-sm"/>
                        </div>
                            <div className="col-sm-6">
                              <Button icon="pi pi-eye" label = "View Experian Collections"  onClick={(e) => this.viewCollections(e)} />
                              <Dialog  header = "Collections" visible={this.state.collectionsPreview}   onHide={() => this.onHidePreview() }  breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '60vw'}}>
                                 {this.state.collectionsIndicator}
                                 {this.state.collectionsSummary}
                                 {this.state.collectionsDetail}
                              </Dialog>
                        </div>
                      </div>
                    </div>
                :  <div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.collections_comment} onChange={e => this.handleLegalRecordChange("collections_comment", e.target.value)} className="form-control form-control-sm"/>
                  </div>
                */}
                <div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.collections_comment} onChange={e => this.handleLegalRecordChange("collections_comment", e.target.value)} className="form-control form-control-sm"/>
                </div>
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}><Translation>{ t => t('NOTES_OF_PROTEST')}</Translation></label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "notesYes" name = "notesYes" value = "yes" checked={true === this.state.legalRecords.notes_of_protest} onChange={e => this.handleLegalRecordChange("notes_of_protest", true)} />
                  <label htmlFor = "notesYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES')}</Translation> </strong> </label>
                  <input type = "radio" id = "notesNo" name = "notesNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.notes_of_protest}
                  onChange={e => this.handleLegalRecordChange("notes_of_protest", false)} />
                  <label htmlFor = "collectionNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO')}</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("notes_of_protest")}} style={{'top': '2px'}}  />
              </div>
              <div className="col-sm-6">
                <textarea row="1" value = {this.state.legalRecords.note_of_protest_comments} onChange={e => this.handleLegalRecordChange("note_of_protest_comments", e.target.value)} className="form-control form-control-sm"/>
              </div>
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}><Translation>{ t => t('RETURNED_CHECKS')}</Translation></label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "returnedCheckYes" name = "returnedCheckYes" value = "yes" checked={true === this.state.legalRecords.returned_checks} onChange={e => this.handleLegalRecordChange("returned_checks", true)} />
                  <label htmlFor = "returnedCheckYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES')}</Translation> </strong> </label>
                  <input type = "radio" id = "returnedCheckNo" name = "returnedCheckNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.returned_checks}
                  onChange={e => this.handleLegalRecordChange("returned_checks", false)} />
                  <label htmlFor = "collectionNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO')}</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("returned_checks")}} style={{'top': '2px'}}  />
              </div>
              <div className="col-sm-6">
                <textarea row="1" value = {this.state.legalRecords.returned_checks_comments} onChange={e => this.handleLegalRecordChange("returned_checks_comments", e.target.value)} className="form-control form-control-sm"/>
              </div>
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}><Translation>{ t => t('LIENS')}</Translation></label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "lienYes" name = "lienYes" value = "yes" checked={true === this.state.legalRecords.liens} onChange={e => this.handleLegalRecordChange("liens", true)}/>
                  <label htmlFor = "lienYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES')}</Translation> </strong> </label>
                  <input type = "radio" id = "lienNo" name = "lienNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.liens}
                  onChange={e => this.handleLegalRecordChange("liens", false)}/>
                  <label htmlFor = "lienNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO')}</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("liens")}} style={{'top': '2px'}}  />
              </div>

              {/* sessionStorage.getItem("ExperianData_liens_"+this.props.report_id)
                ? <div className='col-sm-6'> 
                    <div className='row'>
                        <div className="col-sm-6">
                            <textarea row="1" value = {this.state.legalRecords.liens_comments} onChange={e => this.handleLegalRecordChange("liens_comments", e.target.value)} className="form-control form-control-sm"/>
                        </div>
                            <div className="col-sm-6">
                              <Button icon="pi pi-eye" label = "View Experian Liens"  onClick={(e) => this.viewLiens(e)} />
                              <Dialog  header = "Liens" visible={this.state.liensPreview}   onHide={() => this.onHidePreview() }  breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '60vw'}}>
                                 {this.state.lienIndicator}
                                 {this.state.lienSummary}
                                 {this.state.lienDetail}
                              </Dialog>
                        </div>
                      </div>
                    </div>
                :  <div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.liens_comments} onChange={e => this.handleLegalRecordChange("liens_comments", e.target.value)} className="form-control form-control-sm"/>
                  </div>
                */}
                 <div className="col-sm-6">
                    <textarea row="1" value = {this.state.legalRecords.liens_comments} onChange={e => this.handleLegalRecordChange("liens_comments", e.target.value)} className="form-control form-control-sm"/>
                  </div>
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold" style={{paddingTop: '10px'}}>{<Translation>{ t => <span>{t('OUTSTANDING_DEBT')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-3">
                  <input type = "radio" id = "outStandingDebtYes" name = "outStandingDebtYes" value = "yes" checked={true === this.state.legalRecords.out_standing_debts} onChange={e => this.handleLegalRecordChange("out_standing_debts", true)}/>
                  <label htmlFor = "outStandingDebtYes" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('YES')}</Translation> </strong> </label>
                  <input type = "radio" id = "outStandingDebtNo" name = "outStandingDebtNo" style={{marginLeft:"10px"}} value = "no" checked={false === this.state.legalRecords.out_standing_debts}
                  onChange={e => this.handleLegalRecordChange("out_standing_debts", false)}/>
                  <label htmlFor = "outStandingDebtNo" style={{marginLeft:"2px"}}> <strong> <Translation>{ t => t('NO')}</Translation> </strong> </label>
                  <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {this.cancelField("out_standing_debts")}} style={{'top': '2px'}}  />
              </div>
              <div className="col-sm-6">
                <textarea row="1" value = {this.state.legalRecords.out_standing_debts_comment} onChange={e => this.handleLegalRecordChange("out_standing_debts_comment", e.target.value)} className="form-control form-control-sm"/>
              </div>
          </div>
          <br></br>
          <div className="row">
              <div className="col-sm-2">
                <Button icon="pi pi-download" label="Add Default Comment" className="p-button-rounded p-button-info p-button-text"
                  onClick={this.addDefaultComment} style={{'top': '2px'}}  />
              </div>
          </div>
          <br></br>
          <div className="row">
              <div className="col-sm-1">
                  <label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-4">
                  <textarea className="form-control form-text-comments" onChange={e => this.handleLegalRecordChange("comments", e.target.value)} name="comments"  value={this.state.legalRecords.comments} />
              </div>
          </div>
        </div>
      </div>
    )
  }
}
export default InquiriesDetailsFinancialsLegalRecords
