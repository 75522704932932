import React, { Component } from 'react';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Translation } from 'react-i18next';
import {Calendar} from 'primereact/calendar';

class DateComponent extends Component {

  constructor(props){
    super(props);
    var max = new Date().getFullYear()
    var min = max - 50
    let years = []
    for (var i = max; i >= min; i--) {
        years.push(i)
    }

    this.state = {
      date : this.props.date,
      years: years,
      today: new Date()
    }
    /*if(!this.state.date){
      this.state.date = {type: 'DATE', year: 2021, month: null, day: null }
    }*/
    this.getFullDate = this.getFullDate.bind(this);
    this.toggleDateType = this.toggleDateType.bind(this);
    this.changeDateYear = this.changeDateYear.bind(this);
    this.changeDateMonth = this.changeDateMonth.bind(this);
    this.changeDateDay = this.changeDateDay.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  refreshData(){
    this.props.update_date({target: {value: this.state.date}})
  }

  getFullDate(){
    if(!this.state.date){
      return null;
    }
    if (!this.state.date.day){
      return new Date(this.state.date.month + "/01/" + this.state.date.year);
    }
    if (!this.state.date.month){
      return new Date("01/01/" + this.state.date.year);
    }
    return new Date(this.state.date.month + "/" + this.state.date.day + "/" + this.state.date.year);

  }
  getMonthDate(){
    if (!this.state.date.month){
      return new Date("01/01/" + this.state.date.year);
    }
    return new Date(this.state.date.month + "/01/" + this.state.date.year);
  }


  changeDateDay(event){
    let value = event.target.value
    let date = this.state.date
    if (value instanceof Date){
      date = {}
      date.month = value.getMonth() + 1;
      date.year = value.getFullYear();
      date.day = value.getDate();
      date.type = 'DATE'

    } else{
      date = null
    }
    this.setState({date: date}, function(){
      this.refreshData();
    })

  }

  changeDateMonth(event){
    if(!event.target.value || event.target.value == ''){
      this.setState({date: null}, function(){
        this.refreshData();
      })
      return;
    }
    let date = this.state.date
    date.month = event.target.value.getMonth() + 1;
    date.year = event.target.value.getFullYear();
    date.day = null
    this.setState({date: date}, function(){
      this.refreshData();
    })
  }

  changeDateYear(event){
    if(!event.target.value || event.target.value == ''){
      this.setState({date: null}, function(){
        this.refreshData();
      })
      return;
    }
    let date = this.state.date
    date.year = event.target.value
    this.setState({date: date}, function(){
      this.refreshData();
    })
  }

  toggleDateType(){
    let date = this.state.date
    if(!date){
      date = {}
      date.type = 'DATE'
    } else if (date.type == 'DATE'){
      date.type = 'MONTH'
    } else if (date.type == 'MONTH'){
      date.type = 'YEAR'
    } else {
      date = null
    }
    this.setState({date: date}, function(){
      this.refreshData();
    })
  }

  renderToggle(){
    return (
      <Button label = {this.state.date ? this.state.date.type : " N.A."} style={{'fontSize': '10px'}} onClick={this.toggleDateType}/>
    )
  }

  renderCalendar(){
    return (
        <Calendar dateFormat="mm/dd/yy" value = {this.getFullDate()}  onChange={this.changeDateDay}
          name="date" placeholder="Date"  showIcon monthNavigator showButtonBar yearNavigator yearRange="1900:2100"/>
    )
  }

  render(){

    if(!this.state.date){
      return (
        <div>
          {this.renderCalendar()}
          {this.renderToggle()}
        </div>
      )
    }

    if (this.state.date.type == 'YEAR'){
      return (
        <div>
          <input type="number" className="form-control form-control-sm"  value={this.state.date.year} onChange = {this.changeDateYear} placeholder="Year" />
          {this.renderToggle()}
        </div>
      )
    } else if (this.state.date.type == 'MONTH'){
      return (
        <div>
          <Calendar hidden onChange={this.changeDateMonth} value={this.getMonthDate()}
              name="start_month_year" placeholder="Month & Year"  showIcon monthNavigator showButtonBar yearNavigator yearRange="1900:2100" view="month" dateFormat="mm/yy"/>
          {this.renderToggle()}
        </div>
      )
    } else {
      return (
        <div>
          {this.renderCalendar()}
          {this.renderToggle()}
        </div>
      )
    }

  }
}
export default DateComponent
