import React, { Component } from 'react';
import {Link} from "react-router-dom";

class Navitem extends Component {
    render() {
        const id  = this.props.id;
        const item  = this.props.item;
       
        
        let display_menu;
        let nav_icon;
        if(item !== "Home"){
            display_menu = item
            nav_icon = "nav_icon"
        }else{
            nav_icon = "home_icon"
        }
       

        return (
            <li id={id}>
            <Link to={this.props.tolink} onClick={this.props.activec.bind(this,this.props.id)}>
                <div className={nav_icon}/><br/>
                {display_menu}
            </Link>
            </li>
            )
    }
}
        
export default Navitem
        