import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import InquiryService from '../service/InquiryService';

class UploadedFilesComponent extends Component {

  constructor(props){
    super(props);
    this.state = {
      toast: this.props.toast,
      files: this.props.uploaded_files
    }
    this.deleteUploadedFile = this.deleteUploadedFile.bind(this);
    this.inquiryService = new InquiryService();
  }
  parseFileName(fileName){
    let parts = fileName.split("/");
    return parts[parts.length - 1] !== "null" ? parts[parts.length - 1] : "" ;
  }

  deleteUploadedFile(file, i){
    this.inquiryService.deleteUploadedFle(this.props.download_path, file.id).then(response => {
      this.state.toast.current.show({severity:'success', summary:'File Deleted !', detail: this.parseFileName(file.file) + " Has been delete"});
      let files = this.state.files;
      files.splice(i,  1);
      this.props.update_files({value: files})
    }).catch(err => {
      console.log(err)
      this.state.toast.current.show({severity:'error', summary:'Error !', detail: JSON.stringify(err.response.data)});
    })
  }

  componentDidUpdate(prevProps) {
    if(this.props.uploaded_files !== prevProps.uploaded_files){
      this.setState({files: this.props.uploaded_files})
    }
  }

  render(){
    return (
      <div>
        <Toast ref={this.state.toast} />
        <ul>

          {
            this.state.files?.map((file, i) => (
              <li>
                <a href={process.env.REACT_APP_HOST + this.props.download_path + file.id}>
                  <span style = {{'color': 'green'}}><strong>{this.parseFileName(file.file)}</strong></span>
                </a>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" style = {{verticalAlign: 'middle'}}
                        onClick = {(e) => this.deleteUploadedFile(file, i)} />
              </li>
            ))
          }
          </ul>
      </div>
    )
  }
}

export default UploadedFilesComponent;
