import React, { Component } from 'react';
import InquiryService from '../service/InquiryService';
import {formatDate} from '../components/Utils';
import { Translation } from 'react-i18next';
import { Button } from 'primereact/button';
class InquiriesDetailsAdminNotesSideBar extends Component {

  constructor(props){
    super(props);
    this.state = {
      notes : null,
      note : ""
    }
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.HandleSaveNote = this.HandleSaveNote.bind(this);
    this.handleAdminNoteDelete = this.handleAdminNoteDelete.bind(this);
    this.inquiryService = new InquiryService();
  }

  refreshNotes(){
      this.inquiryService.getAdminNotes(this.props.orderInfo.report).then(data => {
          this.setState({ notes: data })
      }).catch(err =>{
          console.log(err)
      });
  }
  componentDidMount(){
      this.refreshNotes();
      let cachedNotes = sessionStorage.getItem('ses_admin_notes_' + this.props.orderInfo.report)
      this.setState({note: cachedNotes})
  }

  handleNoteChange(event){
      sessionStorage.setItem('ses_admin_notes_'+this.props.orderInfo.report, event.target.value);
      this.setState({ note : event.target.value });
  }

  HandleSaveNote(){
      this.inquiryService.createAdminNotes(this.props.orderInfo.report,{notes : this.state.note,report:this.props.orderInfo.report, username: this.props.user_name}).then(data => {
          this.setState({ note : "" });
          sessionStorage.setItem('ses_admin_notes_'+this.props.orderInfo.report, "");
          this.refreshNotes()
      }).catch(err =>{
          console.log(err)
      })
  }

  handleAdminNoteDelete(event, note_indx){
    event.preventDefault();
    let note = this.state.notes[note_indx];
    this.inquiryService.deleteAdminNote(note.id,this.props.orderInfo.report).then(data => {
        this.setState({ note : "" });
        this.refreshNotes()
      }).catch(err => {
        console.log(err)
      })
  }
  render(){
    return (
      <div>
        <h4>{<Translation>{ t => t('ADMIN_NOTES')}</Translation>}</h4>
        <div className="row">
            <div className="col-md-12">
              {
                   this.state.notes?.map((note, i) => {
                        return(
                          <div className="col-12">
                            <a hidden = {this.props.user_group =="Standard"} className="close" href="#" title='Delete Note' onClick={(e) => this.handleAdminNoteDelete(e,i)}>
                              x
                            </a>
                            <i>{formatDate(note.created_at)}</i> - by : {note.username} - 
                            <div className="adminNotes"><p style={{resize: 'auto', fontSize: '12px'}}>{note.notes}</p></div>
                            <hr/>
                          </div>
                        );
                    })
                }
            </div>
          </div>
          <br></br>
          <footer hidden = {this.props.user_group =="Standard"} className = "row" >
            <div className="col-md-12">
                <Translation>{ t => <span>{t('ENTER_NOTES_HERE')} </span>}</Translation> :
                  <textarea autoFocus onChange={(e) => this.handleNoteChange(e)} id = "note" className="form-control form-text-comments" name="note"  value={this.state.note} rows="3" cols="5"></textarea>
                <br/>
                <Button icon="pi pi-save"  label="Save Note" onClick={this.HandleSaveNote} />
            </div>
        </footer>
      </div>
    )
  }

}

export default InquiriesDetailsAdminNotesSideBar;
