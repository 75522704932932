import React, { Component } from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import CompanyService from '../service/CompanyService';
import InquiryService from '../service/InquiryService';
import CountryOverviews from './CountryOverviews.js';
import Currencies from './Currencies.js';
import Countries from './Countries.js';
import Delays from './Delays.js';
import RegistrationType from './RegistrationType';
import CompanyDetails from './CompanyDetails.js';
import Suppliers from './Suppliers.js';
import { Toast } from 'primereact/toast';
import AdminClients from './AdminClients.js';

class AdminLists extends Component {
  constructor(props){
    super(props);
    this.state = {
      toast: this.props.toast,
      activeIndex: 0,
      tabs:[],
    }
    this.companyService = new CompanyService();
    this.inquiryService = new InquiryService();
    this.onTabChange = this.onTabChange.bind(this);
    this.handleCloseTab = this.handleCloseTab.bind(this);
    this.handleAddCompanyTab = this.handleAddCompanyTab.bind(this);

  }

  componentDidMount(){
    const tabs_arr = this.state.tabs;
    // currencies
    const currencies_tab =  {
        id : 1,
        sort_title : 'Currencies',
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Currencies
                </React.Fragment>,
        content: this.renderCurrenciesTabContent()
    };
    tabs_arr.push(currencies_tab);


    // delays
    const delays_tab =  {
        id : 4,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Delays
                </React.Fragment>,
        content: this.renderDelaysTabContent()
    };
    tabs_arr.push(delays_tab);


    // country overviews
    const country_overviews_tab =  {
        id : 6,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Country Overviews
                </React.Fragment>,
        content: this.renderCountryOverviewTabConent()
    };
    tabs_arr.push(country_overviews_tab);

    // countries
    const countries_tab =  {
        id : 7,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Countries
                </React.Fragment>,
        content: this.renderCountriesTabContent()
    };
    tabs_arr.push(countries_tab);

    // clients
    const clients_tab =  {
        id : 1,
        sort_title : "Companies",
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Companies
                </React.Fragment>,
        content: this.renderClientsTabContent()
    };
    tabs_arr.push(clients_tab);


    // suppliers
    const suppliers_tab =  {
        id : 2,
        sort_title : 'Suppliers',
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Suppliers
                </React.Fragment>,
        content: this.renderSuppliersTabContent()
    };
    tabs_arr.push(suppliers_tab);
    this.setState({tabs : tabs_arr});
    
    // Registration Type
    const reg_type_tab =  {
      id : 8,
      sort_title : 'Registration Types',
      title: <React.Fragment>
                      <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Registration Type
              </React.Fragment>,
      content: this.renderRegistrationTypeTabContent()
    };
    tabs_arr.push(reg_type_tab);
    this.setState({tabs : tabs_arr});
  }

  
  renderSuppliersTabContent(){
    return(
      <div>
        <Suppliers toast = {this.state.toast} />
      </div>
    )
  }


  handleCloseTab(tab_id, deletedCompany){
      const tab_toClose = tab_id.split("_")
      const id = tab_toClose[1];
      const tab_items = this.state.tabs;
      tab_items.map((tab,i) => {
          if(tab.id == id){
              tab_items.splice(i,1);
          }
      });
     this.setState({tabs:tab_items});
     this.setState({activeTabIndex : (tab_items.length - 1) });
     if(deletedCompany && this.state.toast.current){
       this.state.toast.current.show({severity:'success', summary:'Updated !', detail: "Company " + deletedCompany + " Has Been deleted !"});
     }

  }
  handleCompanyClick(event, rowData){
    this.handleAddCompanyTab(rowData);
  }
  renderNewCompanyContent(companyInfo, handleClose, tab_id){
    return(
      <div><CompanyDetails companyInfo = {companyInfo} toast = {this.state.toast} close = {handleClose} tab_id = {tab_id}/></div>
    )
  }
  handleAddCompanyTab(companyData){
    let tab = this.state.tabs.slice();
    const closeTab = this.handleCloseTab;
    let count = this.state.tabs.length;

    function handleClose(e, deletedCompany) {
        e.preventDefault();
        closeTab(e.currentTarget.id, deletedCompany);
    }
    tab.push({
        id : count,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {companyData.legal_name}
                        <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                </React.Fragment>,
       content: this.renderNewCompanyContent(companyData, handleClose, 'mainTab_'+count)
    })
   // count = count + 1;
    sessionStorage.setItem('activeTabIndex',count);

    this.setState({tabs:tab})

    this.setState({activeTabIndex : count });
  }
  onTabChange(e){
    let tab_index = e.index;
    const tab_items = this.state.tabs;
    if(tab_items.length == 1){
        tab_index = 0;
    }
    this.setState({ activeTabIndex: tab_index});
  }
  delayNameBodyTemplate(rowData){
    return (
        <React.Fragment>
            {rowData.name}
        </React.Fragment>
    );
  }
  renderCurrenciesTabContent(){
    return (
      <div>
        <Currencies toast = {this.state.toast}/>
      </div>
    )
  }

  renderCountryOverviewTabConent(){
    return (
      <div>
        <CountryOverviews />
      </div>
    )
  }


  renderDelaysTabContent(){
    return (
      <div>
        <Delays />
      </div>
    )
  }
  renderRegistrationTypeTabContent(){
    return (
      <div>
        <RegistrationType />
      </div>
    )
  }
  renderCountriesTabContent(){
    return(
      <div><Countries  /></div>
    )
  }






  renderClientsTabContent(){
    return (
      <div><AdminClients addCompanyTab = {this.handleAddCompanyTab} toast = {this.state.toast} /></div>
    )
  }

  render() {
      return (


          <div className="condiv">
          <Toast ref={this.state.toast} />
            <div className="col-12">

                <TabView activeIndex={this.state.activeTabIndex}  onTabChange={this.onTabChange}>
                    {
                      this.state.tabs.map((tab,i) => (
                        <TabPanel key={i} header={tab.title} headerStyle={tab.headerStyle}>{tab.content}</TabPanel>
                      ))
                    }
                </TabView>
            </div>
          </div>
          )
      }

}

export default AdminLists;
