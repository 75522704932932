import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';

class CommentsOnClients extends Component {
  constructor(props){
    super(props);
    this.state = {
      commentsOnClients: [],
      loading: true,
      selectedComment: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    }
    this.commentNameBodyTemplate = this.commentNameBodyTemplate.bind(this);
    this.handleCommentClick = this.handleCommentClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCommentNameChange = this.handleCommentNameChange.bind(this);
    this.inquiryService = new InquiryService();
  }

  componentDidMount(){
    this.inquiryService.getCommentsOnClients().then(data => {
      this.setState({
        commentsOnClients: data, loading: false
      })
    })
  }

  handleSaveClick(){
    if(this.state.selectedComment.id == 0){
      this.inquiryService.createCommentsOnClients(this.state.selectedComment).then(data => {
        let commentsOnClients = this.state.commentsOnClients
        commentsOnClients.push(data)
        this.setState({commentsOnClients:commentsOnClients, selectedComment: data,button:{label:'Save',icon: 'pi pi-check'}})
        alert("Comment Created !");
      }).catch(err=>{
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        alert(alertBody);
      })
    } else {
        this.inquiryService.updateCommentsOnClients(this.state.selectedComment.id, this.state.selectedComment).then(data => {
          alert("Comment Updated !");
        }).catch(err=>{
          var alertBody = ""
          for(var index in err.response.data){
            if(err.response.data[index][0]){
              alertBody += index + "=>" + err.response.data[index][0] + "\n"
            } else{
              for(var childIndex in err.response.data[index]){
                alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
              }
            }

          }
          alert(alertBody);
        })
    }
  }

  cancel(){
    this.setState({
      selectedComment: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }

    handleCommentClick(rowData){
      this.setState({
        selectedComment: rowData,
        button:{
          label:'Save',
          icon: 'pi pi-check'
        }
      })
    }

    commentNameBodyTemplate(rowData){
      return (
          <React.Fragment>
              <a href="#" onClick={()=>this.handleCommentClick(rowData)} > {rowData.name}</a>
          </React.Fragment>
      );
    }

    handleCommentNameChange(event){
      var selectedComment  = this.state.selectedComment
      selectedComment.name = event.target.value
      this.setState({selectedComment: selectedComment})
    }

    render(){
      return (
        <div>
          <div className="condiv">
            <div className="row">
              <div className="col-6" id="dataTable">
                <DataTable ref={(el) => this.dt = el}
                    value={this.state.commentsOnClients}
                    loading={this.state.loading}
                    className="p-datatable-striped"
                    dataKey="id"
                    rowHover
                    //  selection={this.state.selectedName}
                    // onSelectionChange={e => this.setState({selectedName: e.value})}
                    paginator rows={10}
                    emptyMessage="No Comments On Clients found"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10,25,50]}>
                    <Column sortField="name" filterField="name" field="name" header="Name" body={this.commentNameBodyTemplate} sortable filter filterPlaceholder="Search by comment"/>
                </DataTable>
              </div>
              <div className = "col-4">
                <div className="col-sm-9" style={{'margin': '5px'}}>
                  <input id = "name" onChange={this.handleCommentNameChange} type="text" placeholder="comment on clients" value={this.state.selectedComment.name} className="form-control form-control-sm" style={{marginBottom:'25px', marginTop:'15px'}}/>
                  <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                  <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    }

}

export default CommentsOnClients;
