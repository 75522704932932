import axios from 'axios';
import { api } from './baseService'

export default class UserService {

  getRequestOption(){
    const tokenJSON = JSON.parse(sessionStorage.getItem("token"));
    const options = {
     headers: {
         'Content-Type' : 'application/json',
         'Accept': '*/*',
         'Authorization': 'JWT ' + tokenJSON.token
     }
   };
   return options
  }


  getUsers = async()=>{
   // return axios.get(process.env.REACT_APP_HOST + '/wand2/users/', this.getRequestOption()).then(res => res.data);
   if(sessionStorage.getItem('users') !== null){
    return JSON.parse(sessionStorage.getItem('users'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/users/',"get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('users',JSON.stringify(data));
      return data;
    } 
  }

  getLazyUsers = async(lazyParams)=>{
   // return axios.post(process.env.REACT_APP_HOST + '/wand2/users/lazy',  lazyParams, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/users/lazy', "post", lazyParams, this.getRequestOption()).then(res => res.data);
  }

  getUserReportCount = async()=>{
    //return axios.get(process.env.REACT_APP_HOST + '/wand2/user/reports_counter', this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/user/reports_counter', "get","",this.getRequestOption()).then(res => res.data);
  }

  assignUserToCustomer(user_id, company_id){
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/user/' + user_id + '/assign/company/' + company_id, {}, this.getRequestOption()).then(res => res.data);
  }

  updateUser(user_id, user_data){
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/user/' + user_id, user_data, this.getRequestOption()).then(res => res.data);
  }

  createUser = async(user_data)=>{
    //return axios.post(process.env.REACT_APP_HOST + '/wand2/users/', user_data, this.getRequestOption()).then(res => res.data);
    return await api(process.env.REACT_APP_HOST + '/wand2/users/',"post", user_data, this.getRequestOption()).then(res => res.data);
  }
  deleteUser(id){
    return axios.delete(process.env.REACT_APP_HOST + '/wand2/user/' + id, this.getRequestOption()).then(res => res.data);
  }
  
  assignStaffToGroup(user_id, group_id){
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/user/' + user_id + '/assign/group/' + group_id, {}, this.getRequestOption()).then(res => res.data);
  }
  

//---------------------- Added Session Storage Function ---------------------------//
/***************** Company CCP Groups ********************/
  getCCPCompanyGroups = async()=> {
    if(sessionStorage.getItem('api_company_groups') !== null){
      return JSON.parse(sessionStorage.getItem('api_company_groups'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/users/staff/groups/', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_company_groups',JSON.stringify(data));
      return data;
    } 
  }

  createCCPCompanyGroup(groupInfo){
    sessionStorage.removeItem("api_company_groups");
    return axios.post(process.env.REACT_APP_HOST + '/wand2/users/staff/groups/', groupInfo, this.getRequestOption()).then(res => res.data);
  }

  updateCCPCompanyGroup(groupId, groupInfo){
    sessionStorage.removeItem("api_company_groups");
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/users/staff/group/' + groupId, groupInfo, this.getRequestOption()).then(res => res.data);
  }
/***************** END Company CCP Groups ********************/

  getClientSummary = async()=>{
    if(sessionStorage.getItem('api_client_summary') !== null){
      return JSON.parse(sessionStorage.getItem('api_client_summary'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/users/clients_summary',"get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_client_summary',JSON.stringify(data));
      return data;
    } 
  }

  getStaff = async()=>{
    if(sessionStorage.getItem('api_staff') !== null){
      return JSON.parse(sessionStorage.getItem('api_staff'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/users/staff',"get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_staff',JSON.stringify(data));
      return data;
    } 
  }

  getStaffSummary = async()=>{
    if(sessionStorage.getItem('api_staff_summary') !== null){
      return JSON.parse(sessionStorage.getItem('api_staff_summary'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/users/staff_summary',"get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_staff_summary',JSON.stringify(data));
      return data;
    } 
  }

}
