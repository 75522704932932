import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiryService from '../service/InquiryService';
import { Dropdown } from 'primereact/dropdown';

class Currencies extends Component {
  constructor(props){
    super(props);
    this.state = {
      currencies : [],
      loading: true,
      countries: [],
      selectedCurrency: {
        id: 0,
        code: '',
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      },
      toast : this.props.toast
    }
    this.currencyCodeBodyTemplate = this.currencyCodeBodyTemplate.bind(this);
    this.currencyCodeBodyTemplate = this.currencyCodeBodyTemplate.bind(this);
    this.handleCurrencyClick = this.handleCurrencyClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inquiryService = new InquiryService();
    this.inquiryService.getCountries().then(data =>{
      this.setState({
        countries: data
      })
    })
    this.inquiryService.getCurrencies().then(data =>{
      this.setState({
        currencies: data, loading: false
      })
    })
  }

  componentDidMount(){

  }

  handleSaveClick(){
    if(this.state.selectedCurrency.id == 0){
      this.inquiryService.createCurrencies(this.state.selectedCurrency).then(data => {
        let currencies = this.state.currencies
        currencies.push(data)
        this.setState({currencies:currencies,selectedCurrency: {id: 0,code: '',name: ''}})
        this.state.toast.current.show({severity:'success', summary:'Created !', detail:'Currency Created'});
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Something Went Wrong !', detail:JSON.stringify(err.response.data)});
      })
    } else{
      this.inquiryService.updateCurrencies(this.state.selectedCurrency.id, this.state.selectedCurrency).then(data => {
        this.state.toast.current.show({severity:'success', summary:'Updated !', detail:'Currency Updated'});
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Something Went Wrong !', detail:JSON.stringify(err.response.data)});
    })
  }
}

  handleCurrencyChange(field, event){
    var selectedCurrency  = this.state.selectedCurrency
    selectedCurrency[field] = event.target.value

    this.setState({selectedCurrency: selectedCurrency})
  }


  handleCurrencyClick(rowData){
    this.setState({
      selectedCurrency: rowData,
      button:{
        label:'Save',
        icon: 'pi pi-check'
      }
    })
  }

  cancel(){
    this.setState({
      selectedCurrency: {
        id: 0,
        code: '',
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }

  currencyCodeBodyTemplate(rowData){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleCurrencyClick(rowData)} > {rowData.code}</a>
        </React.Fragment>
    );
  }

  currencyNameBodyTemplate(rowData){
    return (
        <React.Fragment>
           {rowData.name}
        </React.Fragment>
    );
  }

  render(){
    return(
      <div>
        <div className="condiv">
          <div className="row">
            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.currencies}
                  loading={this.state.loading}
                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No Currencies found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="code" filterField="code" field="code" header="code" body={this.currencyCodeBodyTemplate} sortable filter filterPlaceholder="Search by Code"/>
                  <Column sortField="name" filterField="name" field="name" header="Name" body={this.currencyNameBodyTemplate} sortable filter filterPlaceholder="Search by Name"/>

              </DataTable>
            </div>
            <div className = "col-6">
              <div className="col-sm-9" style={{'margin': '5px'}}>
                <div className="form-group">
                  <div className="row" >
                    <div className="col-sm-3">
                        <label className="form-field-bold">Code</label>
                    </div>
                    <div className="col-sm-5">
                      <input type = "text" id = "code" onChange={(e)=>this.handleCurrencyChange("code", e)}  name = "code" value={this.state.selectedCurrency.code} className="form-control form-control-sm"/>

                    </div>
                  </div>
                  <div className="row" >
                    <div className="col-sm-3">
                        <label className="form-field-bold">Name</label>
                    </div>
                    <div className="col-sm-5">
                      <input type = "text" id = "name" onChange={(e)=>this.handleCurrencyChange("name", e)}  name = "name" value={this.state.selectedCurrency.name} className="form-control form-control-sm"/>
                    </div>
                  </div>
                  <div className = "row">
                    <div className = "col-sm-3">
                      <label className="form-field-bold">Country</label>
                    </div>
                    <div className = "col-sm-5">
                      <Dropdown optionLabel="name" value={this.state.selectedCurrency.country} options={this.state.countries}
                          onChange={(e)=>this.handleCurrencyChange("country", e)} filter />
                    </div>
                  </div>
                </div>
                <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Currencies;
