import axios  from "axios"; 
import { api } from './baseService'
export default class ExperianService {

getRequestOption(){
  const tokenJSON = JSON.parse(sessionStorage.getItem("token"))
  const options = {
    headers: {
        'Content-Type' : 'application/json',
        'Accept': '*/*',
        'Authorization': 'JWT ' + tokenJSON.token
    }
  };
  return options
}
getToken(){
  const tokenJSON = JSON.parse(sessionStorage.getItem('experian_tokens'));
  return `${tokenJSON.access_token}`
}
getExperianUrls(){
  /*const experianUrls = {
      auth_url  : "https://sandbox-us-api.experian.com/oauth2/v1/token",
      main_hosts : "https://sandbox-us-api.experian.com/eits/gdp/v1/request",
      business_url : "https://sandbox-us-api.experian.com/businessinformation/businesses"

  }*/
  const experianUrls = {
    auth_url  : "https://us-api.experian.com/oauth2/v1/token",
    main_hosts : "https://us-api.experian.com/eits/gdp/v1/request",
    business_url : "https://us-api.experian.com/businessinformation/businesses"

  }
  return experianUrls;
}
experianSubcode(){
  return "0732240";
}
initRefreshToken = async()=>{
  let data = await api(process.env.REACT_APP_HOST + '/wand2/experian/token',"get","","").then(res => res.data);
  return data;
}
searchExperianBusiness = async(searchData)=>{
  let getExperianUrls = this.getExperianUrls(); 
  let body = {
     "name": searchData.name,
     "city": searchData.city, 
     "state": searchData.state,
     "subcode" : this.experianSubcode(),
     "geo" : true,
     //"api_url" : getExperianUrls.main_hosts + "?targeturl="+getExperianUrls.business_url+'/v1/search',
     "api_url" : getExperianUrls.business_url+'/v1/search',
     "token" : this.getToken()
   }
  console.log("fetching business" , body);
  let data =  await axios.post(process.env.REACT_APP_HOST + '/wand2/experian/experian_business', JSON.stringify(body),this.getRequestOption(),{mode: "no-cors"}).then(res => res.data);
  return data;
}
fetchExperianLegalRecords = async(type, searchData) =>{
  let getExperianUrls = this.getExperianUrls();
  searchData.subcode = this.experianSubcode();
  searchData.token = this.getToken();
  searchData.api_url = getExperianUrls.business_url+'/v1/'+type;
  let data =  await axios.post(process.env.REACT_APP_HOST + '/wand2/experian/experian_business', JSON.stringify(searchData),this.getRequestOption(),{mode: "no-cors"}).then(res => res.data);
  return data;
}
fetchHMTL = async(searchData)=>{
  let getExperianUrls = this.getExperianUrls();
  let body = {
    "bin": searchData.bin,
    "subcode": this.experianSubcode(),
    "token": this.getToken(),
    "name": searchData.businessName,
    "api_url" : getExperianUrls.business_url+'/v1/reports/premierprofiles/html'
  }

  let data =  await axios.post(process.env.REACT_APP_HOST + '/wand2/experian/experian_business', JSON.stringify(body),this.getRequestOption(),{mode: "no-cors"}).then(res => res.data);
  return data;
}
}