import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import DateComponent from '../components/DateComponent';
import InquiryService from '../service/InquiryService';
import { Translation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';

class InquiriesDetailsShareholdersInformation extends Component {
  constructor(props){
    super(props);
    this.state = {
      shareholders : this.props.shareholders,
      countries: [],
      toast: this.props.toast
    }
    this.inquiryService = new InquiryService();
    this.handleAddShareholder = this.handleAddShareholder.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.renderCompanyShareHolder = this.renderCompanyShareHolder.bind(this);
    this.renderIndividualShareholder = this.renderIndividualShareholder.bind(this);
    this.handleShareHolderChange = this.handleShareHolderChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleDeleteShareholder = this.handleDeleteShareholder.bind(this);

    if(!sessionStorage.getItem('ses_countries')){
      this.inquiryService.getCountries().then(data =>{
        this.setState({countries:data})
        sessionStorage.setItem('ses_countries',JSON.stringify(data))
      });
    }else{
      this.setState({countries : JSON.parse(sessionStorage.getItem('ses_countries')) })  
    }

    this.types =[
      {value: "NA", label: "Not Available"},
    //  {value: "DIRECT", label: "Direct Shareholder"},
    //  {value: "INDIRECT", label: "Indirect Shareholder"},
      {value: "SHAREHOLDER", label: "Shareholder"},
      {value: "DIRECT_PARENT", label:"Direct parent company"},
      {value: "INTERMEDIATE_PARENT", label:"Intermediate parent company"},
      {value:"ULTIMATE_PARENT", label: "Ultimate parent company"},
    ]

    this.individual_types = [
      {value: 'NA', label: 'Not Available'},
      {value: 'MINOR_SHAREHOLDER', label: 'Minor Shareholder'},
      {value: 'MAJOR_SHAREHOLDER', label: 'Major Shareholder'},
      {value: 'ULTIMATE_SHAREHOLDER', label: 'Ultimate Shareholder'}
    ]
  }

  componentDidMount(){
    if(!sessionStorage.getItem('ses_countries')){
      this.inquiryService.getCountries().then(data =>{
        this.setState({countries:data})
        sessionStorage.setItem('ses_countries',JSON.stringify(data))
      });
    }else{  
      this.setState({countries: JSON.parse(sessionStorage.getItem('ses_countries'))});   
    }
  }
  dataRefresh(){
    // function that reads the state and updates the session storage
    const shareholding = sessionStorage.getItem('ses_shareholding_' + this.props.report_id);
    let shareholdingJSON = JSON.parse(shareholding)
    shareholdingJSON.shareholders = this.state.shareholders
    sessionStorage.setItem('ses_shareholding_' + this.props.report_id, JSON.stringify(shareholdingJSON));
  }

  handleDeleteShareholder(indx, event){
    const shareholders_arr = this.state.shareholders;
    shareholders_arr.splice(indx,  1);
    this.setState({shareholders: shareholders_arr}, function(){
      this.dataRefresh();
    })
  }

  handleAddShareholder(isCompany, e){
    const shareholders_arr = this.state.shareholders;
    
    let obj = {
      shareholder_type : isCompany ? "company": "individual"
    };

    if(shareholders_arr.length == 0 || shareholders_arr[shareholders_arr.length - 1].shareholder_name){
      shareholders_arr.push(obj);
      this.setState({shareholders: shareholders_arr}, function(){
        this.dataRefresh();
      });
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter  shareholder  name"});            
    }
  }

  handleShareHolderChange(share_idx, parentField, childField, event){
    var shareholders = this.state.shareholders
    if(!shareholders[share_idx][parentField]){
      shareholders[share_idx][parentField] = {};
    }
    if(childField){
      shareholders[share_idx][parentField][childField] = event.target.value
    } else{
      // type and shares percentage
      shareholders[share_idx][parentField] = event.target.value
    }

    this.setState({shareholders: shareholders}, function(){
      this.dataRefresh();
    });
  }

  renderIndividualShareholder(i, share){
    return(
      <div>
         <div className="spacer"/>
         <div className="col-9">
             <div className="form-group">
               <div className="row">
                   <div className="col-sm-3">
                       <label className="form-field-bold">{<Translation>{ t => <span>{t('STREET_ADDRESS')} </span>}</Translation>}</label>
                   </div>
                   <div className="col-sm-3">
                        <input type="text" placeholder="street address"  onChange={(e) => this.handleShareHolderChange(i, "address", "street", e)}
                          name={"shareholder.company.legal_address.street_"+i} value={share.address?.street} className="form-control form-control-sm"/>
                    </div>
                   <div className="col-sm-2">
                       <label className="form-field-bold"></label>
                   </div>
                   <div className="col-sm-3">

                   </div>
               </div>
             </div>
             <div className="form-group">
                 <div className="row">
                     <div className="col-sm-3">
                         <label className="form-field-bold">{<Translation>{ t => <span>{t('CITY')} </span>}</Translation>}</label>
                     </div>
                     <div className="col-sm-3">
                          <input type="text" placeholder="street address"  onChange={(e) => this.handleShareHolderChange(i, "address", "city", e)}
                            name={"shareholder.company.legal_address.street_"+i} value={share.address?.city} className="form-control form-control-sm"/>
                      </div>
                     <div className="col-sm-2">
                         <label className="form-field-bold">{<Translation>{ t => <span>{t('PERCENTAGE')} </span>}</Translation>}</label>
                     </div>
                     <div className="col-sm-3">
                         <input type="number" placeholder="percentage"  onChange={(e) => this.handleShareHolderChange(i, "shares_percentage", null, e)}
                         name={"shares_percentage_"+i} value={share.shares_percentage} className="form-control form-control-sm"/>
                     </div>
                 </div>
             </div>
             <div className="form-group">
                 <div className="row">
                     <div className="col-sm-3">
                         <label className="form-field-bold">{<Translation>{ t => <span>{t('STATE')} </span>}</Translation>}</label>
                     </div>
                     <div className="col-sm-3">
                          <input type="text" placeholder="street address"  onChange={(e) => this.handleShareHolderChange(i, "address", "state", e)}
                            name={"shareholder.company.legal_address.street_"+i} value={share.address?.state} className="form-control form-control-sm"/>
                    </div>

                 </div>
             </div>
             <div className="form-group">
                 <div className="row">
                     <div className="col-sm-3">
                         <label className="form-field-bold">{<Translation>{ t => <span>{t('DATE_OF_BIRTH')} </span>}</Translation>}</label>
                     </div>
                     <div className="col-sm-3">
                         <DateComponent date = {share.date_of_birth} update_date = {(e) => this.handleShareHolderChange(i, "date_of_birth", null, e)}/>
                     </div>
                 </div>
             </div>
             <div className="form-group">
                 <div className="row">
                     <div className="col-sm-3">
                         <label className="form-field-bold">{<Translation>{ t => <span>{t('ID_NUMBER')} </span>}</Translation>}</label>
                     </div>
                     <div className="col-sm-3">
                         <input  onChange={(e) => this.handleShareHolderChange(i, "registration_id", null, e)} name="individual.id_number"
                          placeholder="ID Number" type="text" value={share.registration_id} class="form-control form-control-sm" />
                     </div>
                 </div>
             </div>
             <div className="form-group">
                 <div className="row">
                     <div className="col-sm-12">
                         <textarea onChange={(e) => this.handleShareHolderChange(i, "comments", null, e)}
                          className="form-control form-text-comments" name="comments"  value={share.comments}  rows="3"></textarea>
                     </div>
                 </div>
             </div>
         </div>
      </div>
    )
  }

  handlePhoneChange(event){
    let shareholders = this.state.shareholders
    let contactInfo = shareholders[event.target.id].contact_info
    if(!contactInfo){
      contactInfo = {telephones: []}
    }
    if(!contactInfo.telephones){
      contactInfo.telephones = []
    }
    if (contactInfo.telephones.length == 0){
      let obj = {}
      obj[event.target.name] = event.target.value
      contactInfo.telephones.push(obj)
    } else {
      contactInfo.telephones[0][event.target.name] = event.target.value
    }

    shareholders[event.target.id].contact_info = contactInfo
    this.setState({shareholders:shareholders}, function(){
      this.dataRefresh();
    })
  }

  renderCompanyShareHolder(i, share){
    let telephone_cc = null;
    let telephone_num = null;
    if(share.contact_info && share.contact_info.telephones && share.contact_info.telephones.length > 0){
      telephone_cc = share.contact_info.telephones[0].country_code
      telephone_num = share.contact_info.telephones[0].number
    }

    return(
      <div>
       <div className="spacer"/>
       <div className="col-9">
         <div className="form-group">
             <div className="row">
                 <div className="col-sm-3">
                     <label className="form-field-bold">{<Translation>{ t => <span>{t('STREET_ADDRESS')} </span>}</Translation>}</label>
                 </div>
                <div className="col-sm-3">
                     <input type="text" placeholder="street address"  onChange={(e) => this.handleShareHolderChange(i, "address", "street", e)}
                      name={"shareholder.company.legal_address.street_"+i} value={share.address?.street} className="form-control form-control-sm"/>
                 </div>
                 <div className="col-sm-2">
                     <label className="form-field-bold">{<Translation>{ t => <span>{t('REGISTRATION_NUMBER')} </span>}</Translation>}</label>
                 </div>
                 <div className="col-sm-3">
                     <input type="text" placeholder="registration" onChange={(e) => this.handleShareHolderChange(i,"registration_id", null, e)}
                      name={"supplier.company.registration_id"+i} value={share.registration_id} className="form-control form-control-sm"/>
                 </div>
             </div>
          </div>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('CITY')} </span>}</Translation>}</label>
                  </div>
                 <div className="col-sm-3">
                      <input type="text" placeholder="city"   onChange={(e) => this.handleShareHolderChange(i, "address", "city", e)}
                        name={"shareholder.company.legal_address.city_"+i} value={share.address?.city} className="form-control form-control-sm"/>
                  </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('STATE') + "/" + t('REGION')} </span>}</Translation>}</label>
                  </div>
                 <div className="col-sm-3">
                      <input type="text" placeholder="state"  onChange={(e) => this.handleShareHolderChange(i, "address", "state", e)}
                        name={"shareholder.company.legal_address.state_"+i} value={share.address?.state} className="form-control form-control-sm"/>
                  </ div>
                  <div className="col-sm-2">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('PERCENTAGE')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-3">
                      <input type="number" placeholder="percentage"  onChange={(e) => this.handleShareHolderChange(i, "shares_percentage", null, e)}
                        name={"shares_percentage_"+i} value={share.shares_percentage} className="form-control form-control-sm"/>
                  </ div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('PHONE_NUMBER')} </span>}</Translation>}</label>
                  </div>
                 <div className="col-sm-3">
                        <div className = "row phone">
                          <div className = "col-4">
                            <input type = "number" id = {i} name = "country_code" style={{'width':'150%'}}
                                className="form-control form-control-sm" placeholder = "Country Code" onChange={this.handlePhoneChange} value ={telephone_cc}/>
                          </div>
                          <div className = "col-8">
                            <input type = "number" className="form-control form-control-sm"  id = {i} name = "number" style={{'width':'100%'}}
                              placeholder = "N" onChange={this.handlePhoneChange} value ={telephone_num} />
                          </div>
                        </div>
                  </ div>
                  <div className="col-sm-2">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('STOCK_EXCHANGE')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-3">
                      <input type = "text" placeholder = "stock exchange" onChange={(e) => this.handleShareHolderChange(i, "stock_exchange", null, e)}
                        className="form-control form-control-sm" value={share.stock_exchange} />
                  </div>
              </div>
          </div>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-3">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('WEBSITE')} </span>}</Translation>}</label>
                  </div>
                 <div className="col-sm-3">
                      <input type="text" placeholder="website"  onChange={(e) => this.handleShareHolderChange(i, "contact_info", "websites", e)}
                        name={"company.contact_info.websites_"+i} value={share.contact_info?.websites} className="form-control form-control-sm"/>
                  </ div>
                  <div className="col-sm-2">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('TICKER')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-3">
                      <input type = "text" placeholder = "ticker" onChange={(e) => this.handleShareHolderChange(i, "ticker", null, e)}
                        className="form-control form-control-sm" value={share.ticker}/>
                  </ div>
              </div>
          </div>
          <br></br>
          <div className="form-group">
              <div className="row">
                  <div className="col-sm-2">
                      <label className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTS')} </span>}</Translation>}</label>
                  </div>
                  <div className="col-sm-12">
                      <textarea  className="form-control form-text-comments" name="comments" onChange={(e) => this.handleShareHolderChange(i, "comments", null, e)}
                        placeholder="comments"  rows="2" value={share.comments}></textarea>
                  </div>
              </div>
          </div>
        </div>
     </div>
    )

  }


  render(){
    return(
      <div className="form-group">
        <div className="row" >
          <div className="col-sm-2">
            <div className = "row" style = {{margin:"10px"}}>
              <label className="form-field-bold" style = {{marginRight:"10px"}}>{<Translation>{ t => <span>{t('ADD_COMPANY')} </span>}</Translation>}</label>
              <Button icon="pi pi-plus"  onClick={(e) => this.handleAddShareholder(true, e)}  className="p-button-square p-button-secondary ccp-add-btn" />
            </div>
            <div className = "row" style = {{margin:"10px"}}>
              <label className="form-field-bold" style = {{marginRight:"10px"}}>{<Translation>{ t => <span>{t('ADD_INDIVIDUAL')} </span>}</Translation>}</label>
              <Button icon="pi pi-plus"  onClick={(e) => this.handleAddShareholder(false, e)}  className="p-button-square p-button-secondary ccp-add-btn" />
            </div>
          </div>
          <div className="col-sm-10">
            <Accordion activeIndex="0">
              {
                this.state.shareholders.map((share, i)=> (
                  (share.shareholder_type == "company")?
                    <AccordionTab header={

                    <React.Fragment>
                            <div className="row">
                                <div className="col-5">
                                    <input type="text" autoFocus onChange={(e) => this.handleShareHolderChange(i, "shareholder_name", null, e )}
                                      placeholder="Name" name={"shareholder.company.legal_name"} value={share.shareholder_name} className="form-control form-control-sm"/>
                                </div>
                                <div className="col-3">
                                     <Dropdown value = {share.address?.country}  options={this.state.countries} optionLabel="name"
                                      placeholder="Select A Country" filter filterBy="name" onChange={(e) => this.handleShareHolderChange(i, "address", "country", e)}/>
                                </div>
                                <div className="col-3">
                                     <Dropdown value = {share.type}  options={this.types} optionLabel="label" placeholder="Select A Type"
                                      onChange={(e) => this.handleShareHolderChange(i, "type", null, e)}/>
                                </div>
                                <div className="col-1">
                                      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                        onClick = {(e) => this.handleDeleteShareholder(i, e)} value = {null}  />
                                </div>
                            </div>

                    </React.Fragment>

                    }>
                      {this.renderCompanyShareHolder(i, share)}
                    </AccordionTab>
                  :
                  <AccordionTab header={
                    <React.Fragment>
                          <div className="row">
                              <div className="col-5">
                                    <input type="text" autoFocus onChange={(e) => this.handleShareHolderChange(i, "shareholder_name", null, e)} placeholder="Name" name={"individual.name"} value={share.shareholder_name} className="form-control form-control-sm"/>
                              </div>
                              <div className="col-3">
                                  <Dropdown value = {share.address?.country}  options={this.state.countries} optionLabel="name" placeholder="Select A Country" filter filterBy="name" onChange={(e) => this.handleShareHolderChange(i, "address", "country", e)}/>
                              </div>
                              <div className="col-3">
                                  <Dropdown value = {share.type}  options={this.individual_types} optionLabel="label" placeholder="Select A Type" filter filterBy="name" onChange={(e) => this.handleShareHolderChange(i, "type", null, e)}/>
                              </div>
                              <div className="col-1">
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                      onClick = {(e) => this.handleDeleteShareholder(i, e)} value = {null}  />
                              </div>
                          </div>
                    </React.Fragment>}>

                    {this.renderIndividualShareholder(i, share)}
                  </AccordionTab>
                ))
                }
            </Accordion>
          </div>
        </div>
      </div>
    )
  }
}
export default InquiriesDetailsShareholdersInformation;
