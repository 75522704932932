import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SelectButton } from 'primereact/selectbutton';
import CompanyService from '../service/CompanyService';
import { Calendar } from 'primereact/calendar';
import { Dropdown  } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

class ApiKeys extends Component {
  constructor(props){

    super(props);
    this.state = {
      toast: this.props.toast,
      loading: true,
      apiKeys: [],
      customers: [],
      selectedKey: {
        id : 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    }

    this.companyService = new CompanyService();
    this.handleApiKeyClick = this.handleApiKeyClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.apiKeyNameBodyTemplate = this.apiKeyNameBodyTemplate.bind(this);
    this.handleApiKeyChange = this.handleApiKeyChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.clearToast = this.clearToast.bind(this);
  }

  componentDidMount() {
    this.companyService.getApiKeys().then(data => {
      this.setState({apiKeys: data, loading: false})
    })
    this.companyService.getCustomersSummary().then(data => {
      this.setState({customers: data})
    })
  }

  clearToast(key){
    this.state.toast.current.clear();
    navigator.clipboard.writeText(key);
    this.state.toast.current.show({severity:'info', summary:'Copied !', detail: "Key Copied to Clipboard."});
    //this.state.toast.current.clear();
  }


  keyPostCreation(data){
    this.state.toast.current.show({ severity: 'warn', sticky: true, content: (
        <div className="col-sm-12" style={{flex: '1'}}>
            <div className="p-text-center">
                <i className="pi pi-exclamation-triangle" style={{fontSize: '1rem'}}></i>
                <h4>Warning !</h4>
                <p> This is the only time you will be able to see this key </p>
                <p> Please Copy it somewhere safe and send it to the client </p>
                 Key :  {data.key}
                <p>Confirm to proceed</p>
            </div>
            <div className="p-grid p-fluid">
                <div className="p-col-6">
                    <Button onClick={() => this.clearToast(data.key)} type="button" label="Copy Key" className="p-button-info" />
                </div>
            </div>
        </div>
    ) });
  }

  handleSaveClick(){
    let selectedKey = this.state.selectedKey;
    if (selectedKey.company?.id){
      selectedKey.company = selectedKey.company.id;
    }
    if (this.state.selectedKey.id == 0){
      this.companyService.createApiKey(this.state.selectedKey).then(data => {
        let keys = this.state.apiKeys
        keys.push(data);
        this.setState({apiKeys: keys, selectedKey: data})
        this.keyPostCreation(data);
      }).catch(err =>{
        console.log(err);
        var alertBody = ""
        for(var index in err.response.data){
          if(err.response.data[index][0]){
            alertBody += index + "=>" + err.response.data[index][0] + "\n"
          } else{
            for(var childIndex in err.response.data[index]){
              alertBody += index + "=>" + childIndex + "=>" +  err.response.data[index][childIndex][0] + "\n"
            }
          }

        }
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: alertBody});
      })
    } else{
      return;
    }

  }

  handleApiKeyChange(field, event){
    let selectedKey = this.state.selectedKey
    selectedKey[field] = event.target.value
    this.setState({selectedKey:selectedKey})
  }

  cancel(){
    this.setState({
      selectedKey: {
        id: 0,
        name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      }
    })
  }

  handleApiKeyClick(rowData){
    let selectedData = {...rowData}
    selectedData.company = this.state.customers?.find(c => c.id === selectedData.company)

    this.setState({
      selectedKey: selectedData,
      button:{
        label:'Save',
        icon: 'pi pi-check'
      }
    })
  }

  apiKeyNameBodyTemplate(rowData){
    return (
        <React.Fragment>
            <a href="#" onClick={()=>this.handleApiKeyClick(rowData)} > {rowData.name}</a>
        </React.Fragment>
    );
  }
  render(){
    const revokeOptions = [{value : true, label: "OFF"}, {value : false, label: "ON"}];
    return (
      <div>
        <Toast ref={this.state.toast} />
        <div className="condiv">
          <div className="row">
            <div className="col-6" id="dataTable">
              <DataTable ref={(el) => this.dt = el}
                  value={this.state.apiKeys}
                  loading={this.state.loading}
                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  //  selection={this.state.selectedName}
                  // onSelectionChange={e => this.setState({selectedName: e.value})}
                  paginator rows={10}
                  emptyMessage="No API Keys found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}>
                  <Column sortField="name" filterField="name" field="name" header="Name" body={this.apiKeyNameBodyTemplate} sortable filter filterPlaceholder="Search by Position"/>
              </DataTable>
            </div>
            <div className = "col-6">
              <div className="col-sm-9" style={{'margin': '5px'}}>
                <div className="form-group">
                  <div className="row" >
                    <div className = "col-sm-3">
                      <label className="form-field-bold"> Name </label>
                    </div>
                    <div className = "col-sm-5">
                      <input id = "name" onChange={(e) => this.handleApiKeyChange("name", e)} type="text" placeholder="Api Key Name" value={this.state.selectedKey.name} className="form-control form-control-sm"/>
                    </div>
                  </div>
                  <div className="row" >
                    <div className = "col-sm-3">
                      <label className="form-field-bold"> Revoked ? </label>
                    </div>
                    <div className = "col-sm-5">
                      <SelectButton value={this.state.selectedKey.revoked} options={revokeOptions}  onChange={(e) => this.handleApiKeyChange("revoked", e)} optionLabel="label"/>
                    </div>
                  </div>
                  <div className="row" >
                    <div className = "col-sm-3">
                      <label className="form-field-bold"> CreationDate </label>
                    </div>
                    <div className = "col-sm-5">
                      <Calendar value={new Date(this.state.selectedKey.created)}></Calendar>
                    </div>
                  </div>
                  <div className="row" >
                    <div className = "col-sm-3">
                      <label className="form-field-bold"> Customer </label>
                    </div>
                    <div className = "col-sm-5">
                      <Dropdown optionLabel="legal_name" value={this.state.selectedKey.company} options={this.state.customers} onChange={(e) => this.handleApiKeyChange("company", e)} />
                    </div>
                  </div>
                </div>


                <br></br>
                <Button onClick={this.handleSaveClick} label={this.state.button.label} className="p-button-sm" icon={this.state.button.icon}/>
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default ApiKeys;
