import React, { Component } from 'react';
import { Button } from 'primereact/button';
import InquiryService from '../service/InquiryService';
import Address from '../components/Address.js';
import { Dropdown } from 'primereact/dropdown';
import CompanyService from '../service/CompanyService';
import UserService from '../service/UserService';
import { Calendar } from 'primereact/calendar';
import { SelectButton } from 'primereact/selectbutton';
import { FileUpload } from 'primereact/fileupload';
import {formatDate} from '../components/Utils';
import { Toast } from 'primereact/toast';
import FormattedNumberComponent from '../components/FormattedNumberComponent';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Translation } from 'react-i18next';
import UploadedFilesComponent from '../components/UploadedFilesComponent';

class OrderDetails extends Component {

  constructor(props){
    super(props);
    const tokenJSON = JSON.parse(sessionStorage.getItem("token"));
    this.state = {
      toast: this.props.toast,
      orderInfo: {...this.props.orderInfo},
      address: {...this.props.orderInfo.address},
      status: this.props.orderInfo.status,
      maximum_required_credit: this.props.orderInfo.required_credit == null,
      delays:[],
      countries:[],
      currencies: [],
      staff: [],
      suppliers: [],
      isStaff: tokenJSON.user.is_staff,
      selectedCustomer: this.props.customers?.find(c => c.id === this.props.orderInfo.ordered_by?.user?.company?.id),
      customerInfo: this.props.orderInfo.ordered_by?.user?.company.customer_info,
      providedFiles: [],
      customers: this.props.customers,
      hide: false,
      toggleCreditLabel: 'Set Credit',
      displayReportImportFromCompany: false,
      searchValue: '',
      searchField: '',
      searchCompanies: [],
      newOrderId: this.props.new_order_tab_count,
      orderDates: {},
      saveBtnDisable : false
    }
    if(this.state.orderInfo.reference != "New Order"){
      if (this.state.orderInfo.order_date){
        let order_date = this.state.orderInfo.order_date.split("-")
        this.state.orderDates.order_date = new Date(order_date[0], order_date[1] - 1, order_date[2]);
      }
      if (this.state.orderInfo.due_date){
        let due_date = this.state.orderInfo.due_date.split("-")
        this.state.orderDates.due_date = new Date(due_date[0], due_date[1] - 1, due_date[2]);
      }
      if (this.state.orderInfo.delivery_date){
        let delivery_date = this.state.orderInfo.delivery_date.split("-")
        this.state.orderDates.delivery_date = new Date(delivery_date[0], delivery_date[1] - 1, delivery_date[2]);
      }
    }
    this.inquiryService = new InquiryService();
    this.companyService = new CompanyService();
    this.userService = new UserService();
    this.orderDetailsUpload = React.createRef();
    this.toast = React.createRef();
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleOrderCreatedSuccessfully = this.handleOrderCreatedSuccessfully.bind(this)
    this.save = this.save.bind(this);
    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSelectedCustomerChange = this.handleSelectedCustomerChange.bind(this);
    this.loadDelays = this.loadDelays.bind(this);
    this.handleFormDateChange = this.handleFormDateChange.bind(this);
    this.calculateDueDate = this.calculateDueDate.bind(this);
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
    this.createNewOrder = this.createNewOrder.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.parseOrderInfo = this.parseOrderInfo.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.toggleMaximumCredit = this.toggleMaximumCredit.bind(this);
    this.submitCompanySearch = this.submitCompanySearch.bind(this);
    this.importCompanyBodyTemplate = this.importCompanyBodyTemplate.bind(this);

    this.userService.getStaff().then(data => {
      this.setState({
        staff: data
      })
    });

    // suppliers
    this.companyService.getSuppliers().then(data =>{
      this.setState({
        suppliers: data
      })
    });

    this.inquiryService.getCurrencies().then(data =>{
      this.setState({
        currencies: data
      })
    })
  }


  toggleMaximumCredit(event){
    let orderInfo = this.state.orderInfo
    let maximum_required_credit = !this.state.maximum_required_credit
    if(maximum_required_credit){
      orderInfo.required_credit = null
      this.setState({maximum_required_credit: maximum_required_credit, toggleCreditLabel: 'Set Credit'})
    } else {
      this.setState({maximum_required_credit: maximum_required_credit, toggleCreditLabel: 'Set Credit To Maximum'})
    }

  }
  handleFileUploadChange(event){
    if (!event.files) {
      this.setState({providedFiles: null})
      return;
    }
    for (let file in event.files){
      this.setState({providedFiles: event.files})
    }
  }

  calculateDueDate(){
    if(!this.state.selectedCustomer){
      this.toast.current.show({severity:'error', summary:'Failure !', detail: "PLease Select A Customer"});
      return;
    }

    if(!this.state.orderInfo.delay){
      this.toast.current.show({severity:'error', summary:'Failure !', detail: "PLease Select A Delay"});
      return;
    }

    if(!this.state.orderInfo.country){
      this.toast.current.show({severity:'error', summary:'Failure !', detail: "PLease Select A Country"});
      return;
    }

    this.inquiryService.calculateDueDate({customer: this.state.selectedCustomer, delay: this.state.orderInfo.delay, country: this.state.orderInfo.country.country})
        .then(data=>{
          let orderInfo = this.state.orderInfo
          let orderDates = this.state.orderDates
          orderInfo.due_date = data
          let splitData = data.split("-")
          orderDates.due_date = new Date(splitData[0], splitData[1] - 1, splitData[2]);
          this.setState({orderInfo: orderInfo, orderDates: orderDates})
          this.toast.current.show({severity:'success', summary:'Loaded !', detail: "Due Date Loaded !"});

        }).catch(err=>{
          this.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
        })
  }

  compare( a, b ) {
  if ( a.country.name < b.country.name ){
    return -1;
  }
  if ( a.country.name > b.country.name ){
    return 1;
  }
  return 0;
}

  loadDelays(){
    if (this.state.isStaff && this.state.customerInfo == null){
      this.toast.current.show({severity:'warn', summary:'Required Information !', detail: "Please Make Sure to Choose a Customer To Load Their Default Settings !"});
      return;
    } else {

      if (this.state.customerInfo == null){
        this.toast.current.show({severity:'info', summary:'Loading !', detail: "Loading your default settings !"});
        const token = JSON.parse(sessionStorage.getItem("token"))
        this.companyService.getCustomerInfoByUserName(token.user.username).then(data => {
          this.setState({customerInfo: data})
          this.toast.current.show({severity:'success', summary:'Loaded !', detail: "Default Settings Loaded !"});
          this.loadDelays();
        }).catch(err => {
          this.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
        })
      } else {
        let customer_delays = this.state.customerInfo.customer_delays
        let delays = [];
        let countries = [];
        for (let indx in customer_delays){
          countries = countries.concat(customer_delays[indx]["delay_countries"])
          delays.push(customer_delays[indx]["delay"])
        }
        let orderInfo = this.state.orderInfo
        orderInfo.rating = this.state.customerInfo.default_rating
        if(orderInfo.required_credit && !orderInfo.required_credit.currency){
          orderInfo.required_credit.currency = this.state.customerInfo.default_currency
        }
        countries = countries.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.country.name === value.country.name
          ))
        )
        countries.sort(this.compare);
        this.setState({countries: countries, orderInfo : orderInfo}, function(){
          //console.log(this.state.countries)
          if(orderInfo.country && orderInfo.country.country){
            this.loadDelayCountries(orderInfo.country)
          }
        })
      }
    }
  }

  parseFileName(fileName){
    let parts = fileName.split("/");
    return parts[parts.length - 1] !== "null" ? parts[parts.length - 1] : "" ;
  }

  parseOrderInfo(orderInfo){
    //orderInfo.delay = {delay: orderInfo.delay}
    orderInfo.country = {country: orderInfo.country}

    return orderInfo
  }

  componentDidMount(){
    if(this.state.orderInfo.reference != "New Order"){
      this.companyService.getCustomerByLegalName(this.state.orderInfo.client).then(data => {

        this.setState({orderInfo: this.parseOrderInfo(this.state.orderInfo), customerInfo: data.customer_info}, function(){
          this.loadDelays();
        })
      }).catch(err => {
        this.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    } else {
      if (this.state.isStaff){
        let cachedCustomer = sessionStorage.getItem('ses_new_order_' + this.state.newOrderId + "_selected_customer");
        if (cachedCustomer){
          cachedCustomer = JSON.parse(cachedCustomer)
          this.setState({selectedCustomer: cachedCustomer, customerInfo: cachedCustomer.customer_info})
          this.loadDelays();
        }
      } else{
        this.loadDelays();
      }


    }
  }

  handleAddressChange(field, address_data){
    let orderInfo = this.state.orderInfo
    orderInfo[field] = address_data
    this.setState({orderInfo: orderInfo, address: address_data})
  }

  handleFormChange(field, childField, event){
    var orderInfo = this.state.orderInfo
    if(childField){
      if(!orderInfo[field]){
        orderInfo[field] = {}
      }
      orderInfo[field][childField] = event.target.value
    } else{
      orderInfo[field] = event.target.value
    }
    if(field == "country"){
      orderInfo["address"]["country"] = event.target.value.country
    }
    if (field == "country"){
      this.loadDelayCountries(event.target.value)
    }
   
    this.setState({orderInfo: orderInfo})
    if(field == "update"){
      console.log("UPDATE VALUE " + event.target.value)
      console.log(this.state.orderInfo);
    }
  }

  loadDelayCountries(country){
    this.setState({delays: this.getListOfDelays(country)})
  }

  getListOfDelays(country){
    let delays = []
    this.state.customerInfo.customer_delays.forEach(e => {
      let delayCountry = e.delay_countries.find(c => c.country.name == country.country.name)
      if (delayCountry){
        delays.push(e.delay)
      }

    })
    return delays
  }



  handleFormDateChange(field, event){
    let value = event.target.value
    var orderInfo = this.state.orderInfo
    let orderDates = this.state.orderDates
    if (value){
      let d = new Date(value);
      orderInfo[field] = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
    } else {
      orderInfo[field] = value;
    }
    orderDates[field] = event.value
    this.setState({orderInfo: orderInfo, orderDates : orderDates})
  }
  handleOrderCreatedSuccessfully(orderInfo){
    let orderData = {}
    orderData.reference = "New Order"
    orderData.delay = {}
    orderData.country = {}
    orderData.required_credit = null
    orderData.address = {country: {}}
    orderData.update = null
    let d = new Date();
    let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
    orderData.order_date = date;

    let selectedCustomer = this.state.selectedCustomer
    let customerInfo = this.state.customerInfo
    if(!this.state.isStaff){
      selectedCustomer = null
      customerInfo = null
    }
    this.setState({orderInfo: orderData, selectedCustomer: selectedCustomer, customerInfo: customerInfo, hide: true,providedFiles:[]})
  	this.loadDelays();
  	this.setState({hide: false})
    this.orderDetailsUpload.current.clear();
  }

  handleRatingChange(rating){
    var orderInfo = this.state.orderInfo
    orderInfo.rating = rating
    this.setState({orderInfo: orderInfo})
  }
  sendEmail(data){
    let sendTo = (data.client.includes("AF-") == true) ? ["africa-orders@cristalgroupinternational.com"] : ["orders@cristalcredit.com"];
    if (this.state.isStaff){
      if(data.assigned_to.user.email != null)
        sendTo = sendTo.concat(data.assigned_to.user.email);

      if(data.assigned_to.user.email_ccs != null){
        let email_ccs = data.assigned_to.user.email_ccs.split(",");
        sendTo = sendTo.concat(email_ccs);
      }
    } else {
      if(data.ordered_by.user.email != null)
        sendTo = sendTo.concat(data.ordered_by.user.email);
      if(data.ordered_by.user.email_ccs != null){
        let email_ccs = data.ordered_by.user.email_ccs.split(",");
        sendTo = sendTo.concat(email_ccs);
      }
    }

    /** Added this for the customer to receive a notification */
     if (data.ordered_by.user.company.customer_info != null){
      if (data.ordered_by.user.company.customer_info.emails != null){
        let company_emails = data.ordered_by.user.company.customer_info.emails.split(",");
        sendTo = sendTo.concat(company_emails);
      }
    } else if(data.ordered_by.user.company.contact_info != null){
      if(data.ordered_by.user.company.contact_info.emails != null){
        let company_emails = data.ordered_by.user.company.contact_info.emails.split(",");
        sendTo = sendTo.concat(company_emails);
      }
    }



    let required_credit = "MAXIMUM";
    if (data.required_credit){
      required_credit = data.required_credit.value + " " + data.required_credit.currency.code
    }
    let msg  =  "Date : " + formatDate(data.created_at) +
                "\r\nClient Reference : " + data.client_reference +
                "\r\nLegal Name : " + data.company_name +
                "\r\nTrade Name : " + data.trade_name;

    if(data.address.full_address != null){
      msg +=  "\r\nAddress : "+ data.address.full_address;
    }else{
      msg +=  "\r\nAddress : "+ data.address.street + " "+ data.address.state;
    }
    if(data.country){
      msg +=  "\r\nCountry : " + data.country.name;
    } else {
      msg +=  "\r\nCountry : N.A.";
    }

    if(data.phone != null)
        msg += "\r\nPhone : " + data.phone;
    if(data.email != null)
        msg += "\r\Email : " + data.email;
    if(data.website != null)
        msg += "\r\Website : " + data.website;
    if(data.registration_number != null)
        msg += "\r\Registration number : " + data.registration_number;
    if(data.inquiring_party_name != null)
      msg += "\r\nInquiring party's name : " + data.inquiring_party_name;
    if(data.comments != null)
      msg += "\r\nComments : " + data.comments;

    msg += "\r\nDelay : " + data.delay.name +
          "\r\nRequired Credit : " + required_credit



    let subject = "New Order has been created with the reference : " + data.client_reference;

    let customerName = data.ordered_by.user.company.legal_name

    if(data.update == true || data.update == 'true'){ 
      subject = "UPDATE " + data.company_name + " " + data.delay.name + " " + formatDate(data.due_date) + " " + customerName
    } else {
      subject = "NEW ORDER " + data.company_name + " " + data.delay.name + " " + formatDate(data.due_date) + " " + customerName
    }


    this.inquiryService.sendEmail({subject: subject,
                                  message : msg,
                                  to : sendTo })
  }

  createNewOrder(sendEmail){
    let orderInfo = {...this.state.orderInfo}
    orderInfo.customer = this.state.selectedCustomer
    orderInfo.country = orderInfo.country?.country
    var bodyFormData = new FormData();
    bodyFormData.append('data', JSON.stringify(orderInfo));
    const files = this.state.providedFiles;
    for (let i = 0; i < files.length; i++) {
        bodyFormData.append("file_" + i, files[i])
    }
    this.inquiryService.createOrder(bodyFormData).then(data=>{
      this.toast.current.show({severity:'success', summary:'Created !', detail: "Order has been created with the reference : " + data.reference});
      this.handleOrderCreatedSuccessfully(data);
      this.setState({saveBtnDisable : false}); 
      if (sendEmail){
        this.sendEmail(data);
      }
      
    }).catch(err=>{
      console.log(err);
      if(err.response){
        this.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      }

    })
  }

  updateOrder(sendEmail) {
    let orderInfo = {...this.state.orderInfo}
    orderInfo.customer = this.state.selectedCustomer
    //orderInfo.delay = orderInfo.delay.delay
    orderInfo.country = orderInfo.country.country
    var bodyFormData = new FormData();
    const files = this.state.providedFiles;
    for (let i = 0; i < files.length; i++) {
        bodyFormData.append("file_" + i, files[i])
    }
    bodyFormData.append('data', JSON.stringify(orderInfo));
    let updateOrder = orderInfo.update;

    this.inquiryService.updateOrder(orderInfo.id, bodyFormData).then(data=>{
      this.toast.current.show({severity:'success', summary:'Success !', detail: "Order Has Been Saved !"});
      //if(data.update == null || data.update == ''){
        data.update = updateOrder;
     // }
      this.setState({orderInfo: this.parseOrderInfo(data)});
      this.setState({saveBtnDisable : false}); 
      if (sendEmail){
        this.sendEmail(data);
      }
    }).catch(err=>{
      console.log(err);
      this.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
    })
  }

  save(sendEmail){
    this.setState({saveBtnDisable : true}); 
    if (!this.state.isStaff){
      if(this.state.orderInfo.reference === "New Order"){
        // new order
        return this.createNewOrder(sendEmail);
      }
      else if(this.state.status !== "PENDING" ){
        this.toast.current.show({severity:'error', summary:'Canceled !',
            detail: "Order Can not be edited as it has already been assigned to a resource . Please contact us to apply changes to your order"});
        return null;
      }
      else{
        // order update
        return this.updateOrder(sendEmail);
      }
    } else {
      if(!this.state.selectedCustomer){
        this.toast.current.show({severity:'error', summary:'Canceled !', detail: "Please Select a Customer"});
        return null;
      }
      if(this.state.orderInfo.reference === "New Order"){
        // new order
        return this.createNewOrder(sendEmail)

      }
      else{
        // order update
        return this.updateOrder(sendEmail);
      }
    }
    
  }

  async handleSelectedCustomerChange(event) {
    let orderInfo = this.state.orderInfo;
    let customerInfo = null;

    try {
      customerInfo = await this.companyService.getCustomerInfoByCustomerId(event.target.value.id);
      if (customerInfo) {
        orderInfo.ordered_by = customerInfo.default_user
      }
    } catch (err) {}

    this.setState({
      orderInfo: orderInfo,
      selectedCustomer: event.target.value,
      customerInfo: customerInfo
    }, function() {
        sessionStorage.setItem('ses_new_order_' + this.state.newOrderId + "_selected_customer", JSON.stringify(event.target.value));
        this.loadDelays();
      }
    );
  }

  // renderCompanyAssign(){
  //   if(this.state.isStaff){
  //     return (
  //       <div className="row" >
  //         <div className = "row">
  //           <div className = "col-sm-6">
  //             <h5> PLease Assign a customer to this order </h5>
  //           </div>
  //         </div>
  //         <div className = "row">
  //           <div className = "col-sm-3">
  //             <label className="form-field-bold"> Customer </label>
  //           </div>save
  //           <div className = "col-sm-5">
  //             <Dropdown optionLabel="legal_name" value={this.state.selectedCustomer} options={this.state.customers} onChange={(e) => this.handleSelectedCustomerChange(e)} />
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   }

  // }

  renderSaveButtons(){
    if(this.state.isStaff){
      return(
        <div className="col-sm-9" style={{'marginLeft': '10px'}}>
          <br></br>
          <Button onClick={() => this.save(false)} label="Save" className="p-button-sm saveBtn" icon="pi pi-check" />
          <Button onClick={() => this.save(false)} label="Save And Send Email" className="p-button-sm saveBtn" icon="pi pi-check" />
        </div>
      )
    } else {
      return(
        <div className="col-sm-9" style={{'marginLeft': '10px'}}>
          <br></br>
          <Button onClick={() => this.save(true)} label="Save" className="p-button-sm saveBtn" icon="pi pi-check" disabled={this.state.saveBtnDisable}/>
        </div>
      )
    }
  }
  submitCompanySearch(){
    let body = {}
    body.field = this.state.searchField;
    body.value = this.state.searchValue;
    this.companyService.searchForCompany(body).then(data => {
      this.setState({searchCompanies: data})
    })
  }
  importCompanyBodyTemplate(rowData){
    return (
        <React.Fragment>
            <Button icon="pi pi-upload" label = "Assign"  onClick={(e) => this.assignCompanyToOrder(rowData, e)} />
        </React.Fragment>
    );
  }
  assignCompanyToOrder(companyInfo, event){
    let orderInfo = this.state.orderInfo
    orderInfo.company = companyInfo.id
    orderInfo.update = true
    orderInfo.cristal_number = companyInfo.cristal_number
    this.setState({orderInfo: orderInfo, displayReportImportFromCompany: false})
  }

  renderImportFromCompany(){
    const fields = [
      {label: 'Company Name', value: 'legal_name'},
      {label: 'Cristal Number', value: 'cristal_number'}
    ];
    return(
      <div>
        <div className="form-group">
          <div className="row">
              <div className="col-sm-1">
                  <label className="form-field-bold">Field</label>
              </div>
              <div className="col-sm-1">
                <Dropdown value={this.state.searchField} options={fields} onChange={(e) => {this.setState({searchField: e.target.value})}} optionLabel="label" placeholder="Select a Field"/>
              </div>
              <div className="col-sm-1">
                <label className="form-field-bold">Value</label>
              </div>
              <div className="col-sm-1">
                <input type = "text" value={this.state.searchValue} onChange={(e) => {this.setState({searchValue: e.target.value})}} className="form-control form-control-sm" />
              </div>
              <div className="col-sm-1">
                <Button icon="pi pi-search" label = "Search"  onClick={this.submitCompanySearch} />
              </div>
          </div>
        </div>
        <div className="col-12" id="dataTable">
          <DataTable ref={(el) => this.dt = el}
              value={this.state.searchCompanies}
              className="p-datatable-striped"
              dataKey="id"
              rowHover
              //  selection={this.state.selectedName}
              // onSelectionChange={e => this.setState({selectedName: e.value})}
              paginator rows={10}
              emptyMessage="No orders found"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[10,25,50]}
              globalFilter={this.state.globalFilter}>
              <Column field="legal_name" header={<Translation>{ t => <span>{t('company')} </span>}</Translation>}/>
              <Column field="legal_address.country.name"  header={<Translation>{ t => <span>{t('country')} </span>}</Translation>} />
              <Column field="legal_address.street"  header={<Translation>{ t => <span>{t('address')} </span>}</Translation>} />
              <Column field="cristal_number" header={<Translation>{ t => <span>{t('CRISTAL_NUMBER')}  </span>}</Translation>}/>
              <Column field = "" body = {this.importCompanyBodyTemplate}  />
          </DataTable>
        </div>
      </div>
    )
  }

  render(){
    const options = [{value : true, label: "Yes"}, {value : false, label: "No"}];
    if(this.state.hide){
      return (<div><Toast ref={this.toast} /></div>);
    }
    return (
      <div className = "row">
        <Toast ref={this.toast} />
        <Dialog header = "Import From Company" visible = {this.state.displayReportImportFromCompany} onHide={() => {this.setState({displayReportImportFromCompany: false})}}>
            {this.renderImportFromCompany()}
        </Dialog>
        <div className = "col-3">
          <div className="form-group">
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Customer</label>
                </div>
                <div className="col-sm-5">
                    <Dropdown optionLabel="legal_name" value={this.state.selectedCustomer} options={this.state.customers}
                      onChange={(e) => this.handleSelectedCustomerChange(e)} filter filterBy = "legal_name" />
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Client Reference</label>
                </div>
                <div className="col-sm-5">
                    <input ref="name" value="" onChange={(e)=>this.handleFormChange("client_reference", null, e)} autoFocus autoComplete="something"
                      className="form-control form-control-sm" name="company_name" type="text" value={this.state.orderInfo.client_reference}/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Name in the order</label>
                </div>
                <div className="col-sm-5">
                    <input ref="company_name" onChange={(e)=>this.handleFormChange("company_name", null, e)} autoComplete="something"
                      className="form-control form-control-sm" name="company_name" type="text" value={this.state.orderInfo.company_name}/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Trade name</label>
                </div>
                <div className="col-sm-5">
                    <input ref="name" onChange={(e)=>this.handleFormChange("trade_name", null, e)} autoFocus autoComplete="something"
                      className="form-control form-control-sm" ref="trade_name" name="trade_name" type="text" value={this.state.orderInfo.trade_name}/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Country</label>
                </div>
                <div className="col-sm-2">
                  <Dropdown value = {this.state.orderInfo.country} options={this.state.countries} onChange={(e)=>this.handleFormChange("country", null, e)}
                      optionLabel="country.name" placeholder="Select a Country" filter />
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Phone</label>
                </div>
                <div className="col-sm-5">
                    <input ref="phone" onChange={(e)=>this.handleFormChange("phone", null, e)} autoFocus className="form-control form-control-sm"
                            name="phone" type="text" value={this.state.orderInfo.phone} autoComplete="something" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Email</label>
                </div>
                <div className="col-sm-5">
                    <input ref="phone" onChange={(e)=>this.handleFormChange("email", null, e)} autoFocus className="form-control form-control-sm"
                            name="email" type="text" value={this.state.orderInfo.email} autoComplete="something" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Website</label>
                </div>
                <div className="col-sm-5">
                    <input ref="phone" onChange={(e)=>this.handleFormChange("website", null, e)} autoFocus className="form-control form-control-sm"
                            name="email" type="text" value={this.state.orderInfo.website} autoComplete="something" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Registration Number</label>
                </div>
                <div className="col-sm-5">
                    <input ref="phone" onChange={(e)=>this.handleFormChange("registration_number", null, e)} autoFocus className="form-control form-control-sm"
                            name="registration_number" type="text" value={this.state.orderInfo.registration_number} autoComplete="something" />
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Delay</label>
                </div>
                <div className="col-sm-5">
                    <Dropdown value = {this.state.orderInfo.delay} options={this.state.delays} onChange={(e)=>this.handleFormChange("delay", null, e)}
                      optionLabel="name" placeholder="Select a Delay" filter/>
                </div>
            </div>
            <br></br>
            <div  hidden = {this.state.orderInfo.reference === "New Order"} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Reference</label>
                </div>
                <div className="col-sm-5">
                    <input disabled ref="reference" autoFocus className="form-control form-control-sm"
                          name="reference" type="text" value={this.state.orderInfo.reference} autoComplete="something" />
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Required Credit</label>
                </div>
                <div hidden = {this.state.maximum_required_credit} className="col-sm-4">
                    <FormattedNumberComponent name = "value" type='input' update_value={(e)=>this.handleFormChange("required_credit", "value", e)}
                      value={this.state.orderInfo.required_credit?.value} />
                </div>
                <div hidden = {this.state.maximum_required_credit} className="col-sm-2">
                  <Dropdown value = {this.state.orderInfo.required_credit?.currency} options={this.state.currencies} onChange={(e)=>this.handleFormChange("required_credit", "currency", e)} optionLabel="code" placeholder="Select a Currency" filter/>
                </div>
                <div hidden = {!this.state.maximum_required_credit} className="col-sm-2">
                  <Button disabled label = "MAXIMUM" className="p-button-rounded p-button-warning" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <Button label = {this.state.toggleCreditLabel} className="p-button-rounded" onClick={this.toggleMaximumCredit}/>
                </div>
            </div>
            <div hidden className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold" style={{marginTop:"10px"}}>Rating</label>
                </div>
                <div className="col-sm-5">
                    <input type="radio" style={{marginTop:"13px"}} value="yes" checked={true === this.state.orderInfo.rating} onChange={e => this.handleRatingChange(true)}/>
                    <label style={{marginLeft:"2px"}}> <strong> Yes </strong> </label>
                    <input type="radio" style={{margin:"10px"}} value="no" checked={false === this.state.orderInfo.rating} onChange={e => this.handleRatingChange(false)}/>
                    <label style={{marginLeft:"2px"}}> <strong> No </strong> </label>
                </div>
            </div>

          </div>
        </div>
        <div className = "col-4">
          <Address AddressChange={this.handleAddressChange} field_name = "address" label = {"Order Address"} address = {this.state.orderInfo.address} country_disabled = {true}/>
        </div>
        <div className = "col-4">
          <div className="form-group">
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-3">
                    <label className="form-field-bold">Client's Requirements</label>
                </div>
                <div className="col-sm-5">
                    <textarea disabled row = "3" ref="reference" autoFocus className="form-control form-control-sm"
                          name="reference" type="text" value={this.state.customerInfo?.customer_comments} autoComplete="something" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <label className="form-field-bold">Cristal Number</label>
                </div>
                <div className="col-sm-5">
                    <input ref="cristal_number" autoFocus className="form-control form-control-sm" onChange={(e)=>this.handleFormChange("cristal_number", null, e)}
                          name="cristal_number" type="text" value={this.state.orderInfo.cristal_number} autoComplete="something" />
                </div>
                <div hidden = {!this.state.isStaff} className="col-sm-2">
                    <Button className="p-button-rounded" icon="pi pi-search-plus" onClick={() => {this.setState({displayReportImportFromCompany: true})}}/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <label className="form-field-bold">Comments On the Order</label>
                </div>
                <div className="col-sm-5">
                    <textarea rows = "3" ref="reference" autoFocus className="form-control form-control-sm" onChange={(e)=>this.handleFormChange("comments", null, e)}
                          value={this.state.orderInfo.comments} autoComplete="something" />
                </div>
                <div className="col-sm-4">
                  <ul hidden>
                    {
                      this.state.orderInfo.provided_files?.map((file, i) => (
                        <li>
                          <a href={process.env.REACT_APP_HOST + "/wand2/order/provided_file/upload/" + file.id}>
                            <span style = {{'color': 'green'}}><strong>{this.parseFileName(file.file)}</strong></span>
                          </a>
                        </li>
                      ))
                    }
                    </ul>
                </div>
                <div className="col-sm-4">
                    <UploadedFilesComponent download_path = "/wand2/order/provided_file/upload/" uploaded_files = {this.state.orderInfo.provided_files}
                      toast = {this.props.toast} update_files =  {(e) => this.setState({providedFiles: e.value})}/>
                </div>
                <div className="col-sm-9">
                  <FileUpload ref={this.orderDetailsUpload} customUpload uploadHandler={this.handleFileUploadChange}
                      onRemove = {this.handleFileUploadChange} name="file" maxFileSize="1000000" auto chooseLabel="Add file"/>
                </div>
            </div>
            <br></br>
            <br></br>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Order Date</label>
                </div>
                <div className="col-sm-5">
                    <Calendar onChange = {(e) => this.handleFormDateChange("order_date", e)} value = {this.state.orderDates.order_date}
                          showIcon monthNavigator showButtonBar panelClassName="order_details_calendar"/>
                </div>
            </div>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Due Date</label>
                </div>
                <div className="col-sm-5">
                    <Calendar onChange = {(e) => this.handleFormDateChange("due_date", e)} value = {this.state.orderDates.due_date}
                        showIcon monthNavigator showButtonBar panelClassName="order_details_calendar" />
                </div>
                <div className="col-sm-3">
                    <Button label = "load" className="p-button-rounded" onClick={this.calculateDueDate}/>
                </div>
            </div>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Delivery Date</label>
                </div>
                <div className="col-sm-5">
                    <Calendar onChange = {(e) => this.handleFormDateChange("delivery_date", e)} value = {this.state.orderDates.delivery_date}
                        showIcon monthNavigator showButtonBar panelClassName="order_details_calendar" keepInvalid = {true}/>
                </div>
            </div>
            <br></br>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Status</label>
                </div>
                <div className="col-sm-5">
                    <input disabled type = "text"  className="form-control form-control-sm" value={this.state.orderInfo.status} />
                </div>
            </div>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Update</label>
                </div>
                <div className="col-sm-5">
                  <SelectButton value={this.state.orderInfo.update} options={options} onChange={(e)=>this.handleFormChange("update", null, e)}
                      optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                </div>
            </div>
            <br></br>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Supplier</label>
                </div>
                <div className="col-sm-5">
                  <Dropdown value = {this.state.orderInfo.ccp_supplier} options={this.state.suppliers} name="suppliers"
                    onChange={(e)=>this.handleFormChange("ccp_supplier", null, e)} placeholder="Select an Supplier" optionLabel = "supplier_name"/>
                </div>
            </div>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">Analyst</label>
                </div>
                <div className="col-sm-5">
                  <Dropdown value = {this.state.orderInfo.assigned_to} options={this.state.staff} name="staff"
                    onChange={(e)=>this.handleFormChange("assigned_to", null, e)} placeholder="Select an analyst" optionLabel = "username"/>
                </div>
            </div>
            <div hidden = {!this.state.isStaff} className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">GC Comments</label>
                </div>
                <div className="col-sm-5">
                    <textarea row = "2"  className="form-control form-control-sm" onChange={(e)=>this.handleFormChange("gc_comments", null, e)}
                      value = {this.state.orderInfo.gc_comments} autoComplete="something" />
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-field-bold">inquiring party's name</label>
                </div>
                <div className="col-sm-5">
                    <input type = "text"  className="form-control form-control-sm" value = {this.state.orderInfo.inquiring_party_name}
                      onChange={(e)=>this.handleFormChange("inquiring_party_name", null, e)} autoComplete="something" />
                </div>
            </div>
          </div>
        </div>
        {this.renderSaveButtons()}


      </div>

    )
  }



}

export default OrderDetails
