import React, { Component } from 'react';
import MainHeader from '../components/MainHeader'
import { TabView,TabPanel } from 'primereact/tabview';
import InquiryService from '../service/InquiryService';
import UserService from '../service/UserService';
import CompanyService from '../service/CompanyService';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import InquiriesDetails from '../contents/InquiriesDetails'
import OrderDetails from './OrderDetails.js';
import OrderAssign from './OrderAssign.js';
import CompanyDetails from './CompanyDetails.js';
import OrderComments from './OrderComments.js';
import OrderSearch from '../components/OrderSearch.js';
import ClientsOrderSearch from '../components/ClientsOrderSearch.js';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import InquiriesPreview from '../contents/InquiriesPreview';
import { Tooltip } from 'primereact/tooltip';
import { Translation } from 'react-i18next';
import { Calendar } from 'primereact/calendar';
import {translateOrderStatusToClientStatus,formatDate,diffDate,uuidv4} from '../components/Utils';

class Orders extends Component {
    constructor(props){
      super(props);
      this.orderSearch = React.createRef();
      this.ClientsOrderSearch = React.createRef();
      const tokenJSON = JSON.parse(sessionStorage.getItem("token"));
      this.state = {
        activeIndex: 0,
        tabs:[],
        orders: [],
        displayMaximizable : false,
        isStaff: tokenJSON.user.is_staff,
        toast: this.props.toast,
        preview : false,
        previewOrder: null,
        previewReport: null,
        notif_class : "hidden",
        communications : [],
        delays:[],
        suppliers : [],
        countries: [],
        clients : [],
        users: [],
        statuses: [],
        selectedCountries: [],
        selectedAnalysts: [],
        selectedClients: [],
        selectedSuppliers: [],
        selectedStatuses: [],
        selectedDelays: [],
        orderDateFrom: null,
        orderDateTo: null,
        dueDateFrom: null,
        dueDateTo:null,
        deliveryDateFrom: null,
        deliveyrDateTo:null,
        lockOrderCreation: true,
        searchloading: false, //tokenJSON.user.is_staff ? false :true,
        lazyParams: {
              first: 0,
              rows: 50,
              page: 1
          },
        dateFilter: null,
      }

      this.inquiryService = new InquiryService();
      this.userService = new UserService();
      this.companyService = new CompanyService();

      this.orderNameBodyTemplate = this.orderNameBodyTemplate.bind(this);
      this.orderDateBodyTemplate = this.orderDateBodyTemplate.bind(this);
      this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
      this.companyNameBodyTemplate = this.companyNameBodyTemplate.bind(this);
      this.goToBodyTemplate = this.goToBodyTemplate.bind(this);
      this.goTo = this.goTo.bind(this);
      this.handleCloseTab = this.handleCloseTab.bind(this);
      this.onTabChange = this.onTabChange.bind(this);
      this.handleAddReportEditTab = this.handleAddReportEditTab.bind(this);
      this.handleAddOrderEditTab = this.handleAddOrderEditTab.bind(this);
      this.hanleAddAssignTab = this.hanleAddAssignTab.bind(this);
      this.handleAddNewOrderTab = this.handleAddNewOrderTab.bind(this);
      this.handleAddCompanyTab = this.handleAddCompanyTab.bind(this);
      this.handleAddCommentTab = this.handleAddCommentTab.bind(this);
      this.onHidePreview = this.onHidePreview.bind(this);
      this.handlePreview = this.handlePreview.bind(this);
      this.handleXmlExport = this.handleXmlExport.bind(this);
      this.exportBodyTemplate = this.exportBodyTemplate.bind(this);
      this.handleSearchResults = this.handleSearchResults.bind(this);
      this.handleDeleteOrder = this.handleDeleteOrder.bind(this);
      this.handleDeleteOrderClick = this.handleDeleteOrderClick.bind(this);
      this.loadOrdersLazy = this.loadOrdersLazy.bind(this);
      this.onPage = this.onPage.bind(this);
      this.onSort = this.onSort.bind(this);
      this.filterOrderDate = this.filterOrderDate.bind(this);
      this.onDateFilterChange = this.onDateFilterChange.bind(this);
      this.inquiryService.getOrderStatusList().then(data =>{
        data.map(field => {
          this.state.statuses.push({label: field[1], value: field[0]})
        })
      })

      if (this.state.isStaff == true){
        this.userService.getStaff().then(data => {
          this.setState({
            users: data
          })
        });

        this.userService.getCCPCompanyGroups().then(data =>{
          this.setState({
            groups: data
          })
        });

        this.companyService.getCustomersSummary().then(data =>{
          this.setState({
            clients: data,
            lockOrderCreation: false
          })
        })

        this.companyService.getSuppliers().then(data =>{
          this.setState({
            suppliers: data
          })
        })
        
        if(!sessionStorage.getItem('ses_delays')){
          this.inquiryService.getDelays().then(data =>{
            this.setState({ delays: data})
            sessionStorage.setItem('ses_delays',JSON.stringify(data))
          })
        } else {
          this.setState({delays : JSON.parse(sessionStorage.getItem('ses_delays')) })  
        }

        if(!sessionStorage.getItem('ses_delays')){
          this.inquiryService.getDelays().then(data =>{
            this.setState({ delays: data})
            sessionStorage.setItem('ses_delays',JSON.stringify(data))
          })
        } else {
          this.setState({delays : JSON.parse(sessionStorage.getItem('ses_delays')) })  
        }

        if(!sessionStorage.getItem('ses_countries')){
          this.inquiryService.getCountries().then(data =>{
            this.setState({countries:data})
            sessionStorage.setItem('ses_countries',JSON.stringify(data))
          });
        }else{
          this.setState({countries : JSON.parse(sessionStorage.getItem('ses_countries')) })  
        }



      } else {
        //this.loadOrdersLazy();
      }
    }

    loadOrdersLazy(){
      this.inquiryService.getLazyOrders({lazy: this.state.lazyParams }).then(data => {
        this.setState({orders: data.orders, totalRecords: data.total_records, searchloading: false});
      })
    }

    onPage(event) {
      let isStaff = this.state.isStaff
      this.setState({searchloading: true});
      let lazyParams = { ...this.state.lazyParams, ...event };
      this.setState({ lazyParams },function(){
        if (isStaff){
          this.orderSearch.current.loadLazyData(lazyParams);
        } else {
          this.loadOrdersLazy(lazyParams);
        }
      });
    }

    onSort(event) {
      let isStaff = this.state.isStaff
      this.setState({searchloading: true});
      let lazyParams = { ...this.state.lazyParams, ...event };
      this.setState({ lazyParams },function(){
        if (isStaff){
          this.orderSearch.current.loadLazyData(lazyParams);
        } else {
          this.loadOrdersLazy(lazyParams);
        }
      });
  }

    onTabChange(e){
      let tab_index = e.index;
      const tab_items = this.state.tabs;
      if(tab_items.length == 1){
          tab_index = 0;
      }
      return this.setState({ activeTabIndex: tab_index});
    }

    handleCloseTab(tab_id){
        const tab_toClose = tab_id.split("_")
        const id = tab_toClose[1];
        const tab_items = this.state.tabs;
        tab_items.map((tab,i) => {
            if(tab.id == id){
                tab_items.splice(i,1);
            }
        });
       this.setState({tabs:tab_items});
       this.setState({activeTabIndex : (tab_items.length - 1) });

    }
    handleAddNewOrderTab(){
      let tab = this.state.tabs.slice();
      const closeTab = this.handleCloseTab;
      let count = this.state.tabs.length;
      let new_order_id = uuidv4();
      function handleClose(e) {
          e.preventDefault();
          sessionStorage.removeItem('ses_new_order_' + new_order_id + '_selected_customer');
          closeTab(e.currentTarget.id);
      }
      let orderData = {}
      orderData.reference = "New Order"

      //orderData.required_credit = {}
      orderData.address = {country: {}}
      let d = new Date();
      let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
      orderData.order_date = date;
      this.setState({newOrderCache: orderData}, function(){

        tab.push({
            id : count,
            title: <React.Fragment>
                            <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {orderData.reference} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                    </React.Fragment>,
           content: this.renderOrdertDetails(this.state.newOrderCache, new_order_id)
        })
       // count = count + 1;
        sessionStorage.setItem('activeTabIndex',count);
        this.setState({tabs:tab})

        this.setState({activeTabIndex : count });
      })

    }
    handleAddOrderEditTab(orderData){
      let tab = this.state.tabs.slice();
      const closeTab = this.handleCloseTab;
      let count = this.state.tabs.length;
      function handleClose(e) {
          e.preventDefault();
          closeTab(e.currentTarget.id);
      }
      this.inquiryService.getOrder(orderData.id).then(data => {
        tab.push({
            id : count,
            title: <React.Fragment>
                            <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {orderData.company_name} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                    </React.Fragment>,
           content: this.renderOrdertDetails(data)
        })
       // count = count + 1;
        sessionStorage.setItem('activeTabIndex',count);

        this.setState({tabs:tab})

        this.setState({activeTabIndex : count });

      }).catch(err => {
        console.log(err)
      })

    }
    handleAddReportEditTab(orderData){
        if (orderData.status === "PENDING"){
          this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "Report is not ready for viewing. status is : " + orderData.status});
          return
        }
        if (!this.state.isStaff && orderData.status !== "COMPLETED"){
          this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "Report is not ready for viewing. status is : " + orderData.status});
          return
        }
        let tab = this.state.tabs.slice();
        const closeTab = this.handleCloseTab;
        let count = this.state.tabs.length;

        function handleClose(e) {
            e.preventDefault();
            closeTab(e.currentTarget.id);
        }
        tab.push({
            id : count,
            title: <React.Fragment>
                            <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {orderData.company_name} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                    </React.Fragment>,
           content: this.renderReportDetails(orderData)
        })
       // count = count + 1;
        sessionStorage.setItem('activeTabIndex',count);

        this.setState({tabs:tab})

        this.setState({activeTabIndex : count });
    }
    hanleAddAssignTab(orderData){
      let tab = this.state.tabs.slice();
      const closeTab = this.handleCloseTab;
      let count = this.state.tabs.length;

      function handleClose(e) {
          e.preventDefault();
          closeTab(e.currentTarget.id);
      }
      tab.push({
          id : count,
          title: <React.Fragment>
                          <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Assign  {orderData.reference} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                  </React.Fragment>,
         content: this.renderAssignContent(orderData)
      })
     // count = count + 1;
      sessionStorage.setItem('activeTabIndex',count);

      this.setState({tabs:tab})

      this.setState({activeTabIndex : count });
    }

    handleAddCommentTab(orderData){
      let tab = this.state.tabs.slice();
      const closeTab = this.handleCloseTab;
      let count = this.state.tabs.length;

      function handleClose(e) {
          e.preventDefault();
          closeTab(e.currentTarget.id);
      }

      tab.push({
          id : count,
          title: <React.Fragment>
                          <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Communications  {orderData.reference} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                  </React.Fragment>,
         content: this.renderCommentContent(orderData)
      })
     // count = count + 1;
      sessionStorage.setItem('activeTabIndex',count);

      this.setState({tabs:tab})

      this.setState({activeTabIndex : count });
    }

    handleAddCompanyTab(){
      let tab = this.state.tabs.slice();
      const closeTab = this.handleCloseTab;
      let count = this.state.tabs.length;
      let companyData = {}
      companyData.legal_name = "New Company"
      companyData.legal_address = {"name" : "New Company Legal Address"}
      companyData.legal_address.country = {}
      companyData.type = 'CLNT'
      function handleClose(e) {
          e.preventDefault();
          closeTab(e.currentTarget.id);
      }
      tab.push({
          id : count,
          title: <React.Fragment>
                          <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {companyData.legal_name} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                  </React.Fragment>,
         content: this.renderNewCompanyContent(companyData)
      })
     // count = count + 1;
      sessionStorage.setItem('activeTabIndex',count);

      this.setState({tabs:tab})

      this.setState({activeTabIndex : count });
    }

    renderNewCompanyContent(companyInfo){
      return(
        <div><CompanyDetails companyInfo = {companyInfo}  /></div>
      )
    }
    renderAssignContent(orderInfo){
      return(
        <div><OrderAssign goToReport={this.handleAddReportEditTab} addCompanyTab = {this.handleAddCompanyTab} orderInfo={orderInfo} /></div>
      )
    }
    //Render Inquiry Details
    renderReportDetails(orderInfo){
        return(
            <div><InquiriesDetails orderInfo={orderInfo}  /></div>
        )
    }

    renderOrdertDetails(orderInfo, newOrderTabCount){
      return(
          <div><OrderDetails new_order_tab_count={newOrderTabCount} toast = {this.state.toast} orderInfo={orderInfo} is_staff ={this.state.isStaff} customers = {this.state.clients} /></div>
      )
    }

    renderCommentContent(orderInfo){
      return(
        <div>
          <OrderComments toast = {this.state.toast} orderInfo = {orderInfo}  />
        </div>
      )
    }

    renderHeader(){
      return(<span></span>)
    }
    formatDate(date) {
       let d = new Date(date);
        let month = d.getMonth() + 1;
        let day = d.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return d.getFullYear() + '-' + month + '-' + day;
    }

    orderNameBodyTemplate(rowData) {
        let ordername = rowData.client_reference;
        let orderData = rowData;
        let report_id = rowData.report;
        const newTab = this.handleAddOrderEditTab;
        function handleClick(e) {
            e.preventDefault();
           newTab(orderData);
        }
        return (
            <React.Fragment>
                <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a id={report_id} href="#" onClick={handleClick}>{ordername}</a></span>
            </React.Fragment>
        );

    }
    statusBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span>{translateOrderStatusToClientStatus(rowData.status)}</span>
            </React.Fragment>
        );
    }

    orderDateBodyTemplate(rowData) {

      if(rowData.order_date){
        let order_date = rowData.order_date.split("-")
        let d = new Date(order_date[0], order_date[1] - 1, order_date[2]);
        return (
            <React.Fragment>
              <span>{this.formatDate(d)}</span>
            </React.Fragment>
          
             
        );
      }

    }
    companyNameBodyTemplate(rowData) {
        let company = rowData.company_name;
        let companyInfo = rowData.company_info;

        if(companyInfo){
          return(
            <React.Fragment>
               <Tooltip target={"#company-info-tooltip-"+rowData.id}>
                  {company}<br/>
                  <span className="form-field-bold">Company Info</span><br/>

                  {<Translation>{ t => t('PREVIOUSNAMES')}</Translation>} : <strong> {companyInfo.previous_name} </strong><br/>
                  {<Translation>{ t => t('TRADENAMES')}</Translation>} : <strong> {companyInfo.trade_names} </strong><br/>
                  {<Translation>{ t => t('ADDRESS')}</Translation>}: <br/> <strong> {companyInfo.country} </strong><br/>
              </Tooltip>
              <span id ={"company-info-tooltip-"+rowData.id} style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{company}</span>
            </React.Fragment>
          )

        } else {
          return (
              <React.Fragment>
                  <Tooltip target={"#company-info-tooltip-"+rowData.id}>
                     {company}<br/>
                     <span className="form-field-bold">Company Info</span><br/>
                     <strong> This Order is Not Assigned to Any Company </strong>
                 </Tooltip>
                  <span id ={"company-info-tooltip-"+rowData.id} style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{company}</span>
              </React.Fragment>
          );
        }


    }
    handleDeleteOrder(rowData){
      this.setState({showDelete: true, orderToDelete: rowData})
    }
    handleDeleteOrderClick(){
      if(this.state.orderToDelete){
        this.inquiryService.deleteOrder(this.state.orderToDelete.id).then(response => {
          this.state.toast.current.show({severity:'success', summary:'Delete !', detail: "Order Deleted"});

          let orders = this.state.orders.filter(order => order.reference != this.state.orderToDelete.reference)
          this.setState({showDelete: false, orders: orders})
        }).catch(err => {
          console.log(err)
          this.state.toast.current.show({severity:'error', summary:'Failed !', detail: JSON.stringify(err.response.data)});
          this.setState({showDelete: false})
        })
      } else {
        this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "No Order Selected"});
        this.setState({showDelete: false})
      }
    }
    goTo(rowData, event){

      const newEditReportTab = this.handleAddReportEditTab;
      const newEditOrderTab = this.handleAddOrderEditTab;
      const newAssignTab = this.hanleAddAssignTab;
      const newCommentTab = this.handleAddCommentTab;
      const handlePreview = this.handlePreview;
      const deleteOrder = this.handleDeleteOrder;
      var e = document.getElementById("goto-options_" + rowData.reference);

      if (e.value === "viewReport"){
        newEditReportTab(rowData)
      }else if (e.value === "editOrder"){
        newEditOrderTab(rowData)
      } else if(e.value == "deleteOrder"){
        deleteOrder(rowData)
      } else if (e.value == "assign"){
        newAssignTab(rowData)
      } else if (e.value == "communications"){
        newCommentTab(rowData)
      } else if(e.value == "previewReport"){
          handlePreview(rowData)
      } else {
        alert("Something went wrong")
      }
    }

    goToBodyTemplate(rowData, el){
      if(this.state.isStaff){
        let button_new = null;

        if(diffDate(new Date(),rowData.new_comment_update) <= 5) {
          button_new = <Button  onClick={(e) => this.handleAddCommentTab(rowData)} tooltip="New Added Comment from the last 5 days" tooltipOptions={{ position: 'top' }}  className="p-button pi pi-info-circle p-button-secondary p-button-sm" />
         }
        return (
            <React.Fragment>
              <div className="col-sm-12">
                <select id={"goto-options_" + rowData.reference} name="GO TO" style={{marginRight : "25px"}} defaultValue="editOrder">
                    <option selected value="editOrder"> Edit Order </option>
                    <option value="viewReport"> View Report </option>
                    <option hidden value="assign"> Assign </option>
                    <option value="communications"> Communications</option>
                    <option value="deleteOrder"> Delete Order</option>
                    <option hidden value="editStatus"> Edit Status </option>
                </select>
                <Button  onClick={(e) => this.goTo(rowData,e)} className="p-button pi pi-check p-button-secondary p-button-sm" style={{marginRight:'10px'}} />
                {button_new}

              </div>

            </React.Fragment>
        );

      }else{
        return (
            <React.Fragment>
            <div className="col-sm-12">
              <select id={"goto-options_" + rowData.reference} name="GO TO" style={{marginRight : "25px"}}>
                  <option selected value="editOrder"> Edit Order </option>
                  <option value="previewReport" hidden> Preview Report </option>
                  <option value="communications"> Communications</option>
              </select>
              <Button  onClick={(e) => this.goTo(rowData,e)} className="p-button pi pi-check p-button-secondary p-button-sm" style={{'fontSize': '1px'}}/>


            </div>
            </React.Fragment>
        );
      }



    }

    exportBodyTemplate(rowData){
      return (
          <React.Fragment>
              <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>
                <a href="#"><i onClick = {() => this.handleXmlExport(rowData)} className="pi pi-file-excel" style={{'fontSize': '2em'}}></i></a>
                <a href="#"><i onClick = {() => this.handleDocExport(rowData)} className="pi pi-file-o" style={{'fontSize': '2em'}}></i></a>
              </span>
          </React.Fragment>
      );
    }

    handleXmlExport(rowData){
      if (rowData.status != "COMPLETED"){
        this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "Report is not ready for viewing. status is : " + rowData.status});
        return
      }
      this.inquiryService.getXMLReport(rowData.id).then(data => {
        this.state.toast.current.show({severity:'success', summary:'Download Ready !', detail: "Download is Ready"});
        this.setState({downloadData: data, notif_class: ""})
      }).catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: JSON.stringify(err.response.data)});
      })
    }

    handleDocExport(rowData){
      if (rowData.status != "COMPLETED"){
        this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "Report is not ready for viewing. status is : " + rowData.status});
        return
      }
      this.inquiryService.getWordReport(rowData.id).then(data => {
        this.state.toast.current.show({severity:'success', summary:'Download Ready !', detail: "Download is Ready"});
        this.setState({downloadData: data, notif_class: ""})
      }).catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: JSON.stringify(err.response.data)});
      })
    }

    handleSearchResults(orders, total_records){
      this.setState({orders: orders, ordersLazy: orders, totalRecords: total_records, searchloading: false});
    }
    countryBodyTemplate(rowData){
        return (
            <React.Fragment>
                {rowData.country}
            </React.Fragment>
        );
    }
    dueDateBodyTemplate(rowData) {
        if(rowData.due_date != null){
            let due_date = rowData.due_date.split("-")
            let d = new Date(due_date[0], due_date[1] - 1, due_date[2]);
            return (
                <React.Fragment>
                  <span>{formatDate(d)}</span>
                </React.Fragment>
            );
        }
    }
    filterOrderDate(value, filter) {
      if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
          return true;
      }
    
      if (value === undefined || value === null) {
          return false;
      }
      return formatDate(value) === formatDate(filter);
    }
    renderDateFilter() {
      return (
          <Calendar value={this.state.dateFilter} onChange={this.onDateFilterChange} placeholder="Date" dateFormat="yy-mm-dd" className="p-column-filter" />
      );
   }
   onDateFilterChange(event) {
    if (event.value !== null)
        this.dt.filter(formatDate(event.value), 'order.created_at', 'equals');
    else
        this.dt.filter(null, 'created_at', 'equals');
    this.setState({dateFilter: formatDate(event.value)});
  }
    renderTabContent(){
      const dateFilter = this.renderDateFilter();
      
      if (this.state.isStaff){
        return (
          <div className="condiv">
            <div className="col-sm-9" style={{'margin': '5px'}}>
              <Button disabled={this.state.lockOrderCreation} onClick={() => this.handleAddNewOrderTab()} label="New Order" className="p-button-sm" icon="pi pi-plus"/>
            </div>
            <OrderSearch ref={this.orderSearch} toast = {this.state.toast} readResults = {this.handleSearchResults} />
            <div className="col-12" id="dataTable">
              <DataTable lazy ref={(el) => this.dt = el}
                  value={this.state.ordersLazy} loading={this.state.searchloading}
                  totalRecords={this.state.totalRecords} onPage={this.onPage}
                  first={this.state.lazyParams.first} onSort={this.onSort}
                  sortField={this.state.lazyParams.sortField} sortOrder={this.state.lazyParams.sortOrder}
                  className="p-datatable-striped"
                  dataKey="id"
                  rowHover
                  paginator rows={this.state.lazyParams.rows}
                  emptyMessage="No orders found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[25, 50, 100, 200]}>
                  <Column sortField="client_reference" filter filterField="client_reference" field="client_reference" header="Order Reference" body={this.orderNameBodyTemplate} sortable filterPlaceholder="Order Reference"/>
                  <Column sortField="company_name" filterField="company_name" field="company_name" header="Name In Order" body={this.companyNameBodyTemplate} filter sortable />
                  <Column sortField="delay" filterField="delay" field="delay" header="Delay" filter sortable filterPlaceholder="Order Delay"/>
                  <Column field="created_at" header="Order Date" body={this.orderDateBodyTemplate} sortable filter filterMatchMode="custom" filterFunction={this.filterOrderDate} filterElement={dateFilter} />
                  <Column field="due_date" header="Due Date" body={this.dueDateBodyTemplate} sortable filterMatchMode="custom" filterFunction={this.filterDate} filter />
                  <Column field="country" header="Country" body={this.countryBodyTemplate} sortable filterField="country" sortField="country" filter />
                  <Column field="go_to" header="Go To" body={(rowData, el) => this.goToBodyTemplate(rowData, el)}  />
              </DataTable>
            </div>
          </div>
        )
      }
      return (
        <div className="condiv">
          <div className="col-sm-5" style={{'margin': '5px'}}>
            {this.renderDownload()}
          </div>
          <div className="col-sm-9" style={{'margin': '5px'}}>
            <Button onClick={() => this.handleAddNewOrderTab()} label="New Order" className="p-button-sm" icon="pi pi-plus"/>
          </div>
          <ClientsOrderSearch ref={this.ClientsOrderSearch} toast = {this.state.toast} readResults = {this.handleSearchResults} />
          <div className="col-12" id="dataTable">
            <DataTable lazy ref={(el) => this.dt = el}
                value={this.state.orders} loading={this.state.searchloading}
                totalRecords={this.state.totalRecords} onPage={this.onPage}
                first={this.state.lazyParams.first} onSort={this.onSort}
                sortField={this.state.lazyParams.sortField} sortOrder={this.state.lazyParams.sortOrder}
                className="p-datatable-striped"
                dataKey="id"
                rowHover
                paginator rows={this.state.lazyParams.rows}
                emptyMessage="No orders found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[25, 50, 100, 200]}>
                <Column sortField="reference" field="reference" header="Order Reference" body={this.orderNameBodyTemplate} sortable />
                <Column sortField="status" field="status" header="Order Status" body={this.statusBodyTemplate} sortable />
                <Column sortField="company_name" field="company_name" header="Registered Name" body={this.companyNameBodyTemplate} sortable />
                <Column sortField="created_at" field="created_at" header="Order Date" body={this.orderDateBodyTemplate} sortable />
                <Column header="Available Downloads" body={this.exportBodyTemplate} />
                <Column field="go_to" header="Go To" body={(rowData, el) => this.goToBodyTemplate(rowData, el)}  />
            </DataTable>
          </div>
        </div>
      )
    }

    componentDidMount() {
      /*if (!this.state.isStaff){
        this.inquiryService.getInquiries().then(data => {
          this.datasource = data;
          this.setState({orders: this.datasource.slice(0, this.rows)})
          const tab_init =  {
              id : 0,
              title: <React.Fragment>
                              <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Orders Search
                      </React.Fragment>,
              content: this.renderTabContent()
          };
          const tabs_arr = this.state.tabs;
          tabs_arr.push(tab_init);
          this.setState({tabs : tabs_arr});
        })
      } else {
        const tab_init =  {
            id : 0,
            title: <React.Fragment>
                            <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Orders Search
                    </React.Fragment>,
            content: this.renderTabContent()
        };
        const tabs_arr = this.state.tabs;
        tabs_arr.push(tab_init);
        this.setState({tabs : tabs_arr});
    }*/
    const tab_init =  {
        id : 0,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Orders Search
                </React.Fragment>,
        content: this.renderTabContent()
    };
    const tabs_arr = this.state.tabs;
    tabs_arr.push(tab_init);
    this.setState({tabs : tabs_arr});
  }

    onHidePreview(){
      this.setState({preview : false, showDelete: false});
    }
    handlePreview(rowData) {
      if (rowData.status !== "COMPLETED"){
        this.state.toast.current.show({severity:'error', summary:'Canceled !', detail: "Report is not ready for viewing. status is : " + rowData.status});
        return
      }
      this.inquiryService.getReport(rowData.report).then(data => {
          this.setState({previewReport : data[0], previewOrder: rowData, preview: true});
      });
    }
    renderDownload(){
      return (
          <div className={"col-sm-8 alert alert-success " + this.state.notif_class} role="alert">
            { <div dangerouslySetInnerHTML={{ __html: this.state.downloadData }} /> }
          </div>
      )
    }
    render() {
        return (
            <div className="condiv">
              <MainHeader/>
              <br></br>
              <Toast ref={this.state.toast} />
              {this.renderDownload()}
              <div className="col-12">
                <TabView activeIndex={this.state.activeTabIndex}  onTabChange={this.onTabChange}>
                    {
                        this.state.tabs.map((tab,i) => (
                            i == 0 ? <TabPanel key="0" header={tab.title} headerStyle={tab.headerStyle}>{this.renderTabContent()}</TabPanel>:
                            <TabPanel key={i} header={tab.title} headerStyle={tab.headerStyle}>{tab.content}</TabPanel>
                        ))
                    }
                </TabView>
                <Dialog visible={this.state.preview} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} onHide={() => this.onHidePreview() }>
                  <InquiriesPreview report={this.state.previewReport} orderInfo ={this.state.previewOrder} />
                </Dialog>
                <Dialog visible={this.state.showDelete} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} onHide={() => this.onHidePreview() }>
                  <div class="row">
                    <div className="col-6">
                      Are you sure you want to delete the order {this.state.orderToDelete?.reference} ?
                    </div>
                    <div className="col-6">
                      <Button  label="CONFIRM" className="p-button pi pi-danger-circle p-button-danger p-button-sm" onClick={() => this.handleDeleteOrderClick()}/>
                    </div>
                  </div>
                </Dialog>
                </div>
            </div>
            )
        }
    }

export default Orders
