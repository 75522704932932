import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

import InquiriesDetailsBusinessActivity from '../contents/InquiriesDetailsBusinessActivity';
import InquiriesDetailsBusinessIdentification from '../contents/InquiriesDetailsBusinessIdentification';
import InquiriesDetailsShareholders from '../contents/InquiriesDetailsShareholders';

import { Translation } from 'react-i18next';

//import ID from '../contents/InquiriesDetailsBusinessIdentification.bkp'
//Content I {this.props.orderID}
class InquiriesDetailsBusinesss extends Component {
    constructor(props){
        super(props);
        this.state = {
           activeIndex  : null,
           registrationInfo: this.props.report.registration_info,
           registrationChanges: this.props.report.registration_changes,
           contactInfo: this.props.report.contact_info,
           shareholding: this.props.report.shareholding,
           management: this.props.report.management,
           affiliations: this.props.report.affiliations,
           suppliers: this.props.report.suppliers,
           clients: this.props.report.clients,
           paymentBehavior : this.props.report.payment_behavior,
           companyInfo: this.props.report.company_info,
           business: this.props.report.business,
           report: this.props.report,
           insurances: this.props.report.insurances,
           toast: this.props.toast
        }
    }
    componentDidMount(){
      // registration info
      const registrationInfo = sessionStorage.getItem('ses_registration_info_' + this.props.report.id);
      if (registrationInfo === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_registration_info_' + this.props.report.id, JSON.stringify(this.state.registrationInfo));
      } else{
          this.setState({registrationInfo : JSON.parse(registrationInfo)})
      }
      // registration changes
      const registrationChanges = sessionStorage.getItem('ses_registration_changes_' + this.props.report.id);
      if (registrationChanges === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_registration_changes_' + this.props.report.id, JSON.stringify(this.state.registrationChanges));
      } else{
          this.setState({registrationChanges : JSON.parse(registrationChanges)})
      }
      const contactInfo = sessionStorage.getItem('ses_contact_info_' + this.props.report.id);
      if (contactInfo === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_contact_info_' + this.props.report.id, JSON.stringify(this.state.contactInfo));
      } else{
          this.setState({contactInfo : JSON.parse(contactInfo)})
      }
      const companyInfo = sessionStorage.getItem('ses_company_info_' + this.props.report.id);
      if (companyInfo === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_company_info_' + this.props.report.id, JSON.stringify(this.state.companyInfo));
      } else{
          this.setState({companyInfo : JSON.parse(companyInfo)})
      }
      /*const shareholders = sessionStorage.getItem('ses_shareholders_' + this.props.report.id);
      if (shareholders === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_shareholders_' + this.props.report.id, JSON.stringify(this.state.shareholders));
      } else{
          this.setState({shareholders : JSON.parse(shareholders)})
      }
      const managers = sessionStorage.getItem('ses_managers_' + this.props.report.id);
      if (managers === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_managers_' + this.props.report.id, JSON.stringify(this.state.managers));
      } else{
          this.setState({managers : JSON.parse(managers)})
      }*/
      const business = sessionStorage.getItem('ses_business_' + this.props.report.id);
      if (business === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(this.state.business));
      } else{
          this.setState({business : JSON.parse(business)})
      }
      const clients = sessionStorage.getItem('ses_clients_' + this.props.report.id);
      if (clients === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_clients_' + this.props.report.id, JSON.stringify(this.state.clients));
      } else{
          this.setState({clients : JSON.parse(clients)})
      }
      const suppliers = sessionStorage.getItem('ses_suppliers_' + this.props.report.id);
      if (suppliers === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_suppliers_' + this.props.report.id, JSON.stringify(this.state.suppliers));
      } else{
          this.setState({suppliers : JSON.parse(suppliers)})
      }
      const payment_behavior = sessionStorage.getItem('ses_payment_behavior_' + this.props.report.id);
      if (payment_behavior === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_payment_behavior_' + this.props.report.id, JSON.stringify(this.state.paymentBehavior));
      } else{
          this.setState({paymentBehavior : JSON.parse(payment_behavior)})
      }
      const insurances = sessionStorage.getItem('ses_insurances_' + this.props.report.id);
      if (insurances === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_insurances_' + this.props.report.id, JSON.stringify(this.state.insurances));
      } else{
          this.setState({insurances : JSON.parse(insurances)})
      }
    }
    render(){
      return(
        <Accordion multiple activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>

              <AccordionTab contentStyle={{background:"aliceblue"}}  key="1" header={<Translation>{ t => <span>{t('IDENTIFICATION_TAB')} </span>}</Translation>}>
                  <InquiriesDetailsBusinessIdentification report={this.props.report} orderInfo={this.props.orderInfo} registration_info = {this.state.registrationInfo}
                              registration_changes = {this.state.registrationChanges} contact_info = {this.state.contactInfo} company_info = {this.state.companyInfo}
                                business = {this.state.business} toast = {this.state.toast}  />
              </AccordionTab>
              <AccordionTab key="2" header={<Translation>{ t => <span>{t('ACTIVITY_TAB')} </span>}</Translation>}>
                  <InquiriesDetailsBusinessActivity report={this.props.report} orderInfo={this.props.orderInfo} business = {this.state.business}
                              suppliers = {this.state.suppliers} clients = {this.state.clients} payment_behavior = {this.state.paymentBehavior}
                              insurances = {this.state.insurances} toast = {this.state.toast}/>
              </AccordionTab>
              <AccordionTab key="3"  header={<Translation>{ t => <span>{t('SHAREHOLDERS_TAB')} </span>}</Translation>}>
                  <InquiriesDetailsShareholders report={this.props.report} orderInfo={this.props.orderInfo} shareholding = {this.state.shareholding}
                              management = {this.state.management} affiliations = {this.state.affiliations} toast = {this.state.toast} />
              </AccordionTab>
      </Accordion>
      )

    }


}
export default InquiriesDetailsBusinesss
