import React, { Component } from 'react';
import { Translation } from 'react-i18next';

class InquiriesDetailsSummaryCountryOverView extends Component{
    constructor(props){
        super(props);
    }

    render() {
      return (
        <div className="form-group">
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('FORM_OF_GOVERNMENT')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <input type = "text" id = "form-of-government" name = "formOfGovernment" value ={this.props.country_overview?.form_of_government} disabled/>
            </div>
          </div>
          <br></br>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('ECONOMIC_RISK')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <input type = "text" id = "economic-risk" name = "economicRisk" value ={this.props.country_overview?.economic_risk}  disabled />
            </div>
          </div>
          <br></br>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('OVERVIEW')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <textarea class="form-control form-text-comments" name="overview" rows="3" value ={this.props.country_overview?.overview} disabled></textarea>
            </div>
          </div>
        </div>
      )

    }
}

export default InquiriesDetailsSummaryCountryOverView
